import React, { useEffect, useRef } from 'react';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfig, saveConfig } from './redux/actions/configActions';
import { Box, Button, Typography } from '@mui/material';

const ConfigEditorWithJsonEditor = ({ configName }) => {
  const dispatch = useDispatch();
  const configData = useSelector((state) => state.config.configs[configName]);
  const loading = useSelector((state) => state.config.loading);
  const saving = useSelector((state) => state.config.saving);
  const error = useSelector((state) => state.config.error);

  const editorContainerRef = useRef(null);
  const editorInstanceRef = useRef(null);

  // Fetch the configuration on component mount
  useEffect(() => {
    if (configName) {
      dispatch(fetchConfig(configName));
    }
  }, [dispatch, configName]);

  // Initialize JSONEditor when configData is available
  useEffect(() => {
    if (configData && editorContainerRef.current && !editorInstanceRef.current) {
      editorInstanceRef.current = new JSONEditor(editorContainerRef.current, {
        mode: 'tree',
        onChange: () => {
          try {
            const updatedData = editorInstanceRef.current.get();
            console.log('Updated data:', updatedData); // For debugging
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        },
      });

      editorInstanceRef.current.set(configData);
    }

    return () => {
      if (editorInstanceRef.current) {
        editorInstanceRef.current.destroy();
        editorInstanceRef.current = null;
      }
    };
  }, [configData]);



const handleSave = async () => {
    if (!editorInstanceRef.current) return;
  
    try {
      const updatedData = editorInstanceRef.current.get();
      dispatch(saveConfig(configName, updatedData));
      // Refetch or update the current config after save
      dispatch(fetchConfig(configName)); // Ensure the editor syncs with updated state
    } catch (error) {
      console.error('Error saving JSON data:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="60vh">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="h6" align="center" sx={{ mt: 4 }}>
        Error loading configuration: {error}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Edit Configuration: {configName}
      </Typography>
      <Box
        ref={editorContainerRef}
        sx={{ border: '1px solid #ddd', borderRadius: '8px', height: '400px', mt: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        disabled={saving}
        sx={{ mt: 2 }}
      >
        {saving ? 'Saving...' : 'Save Changes'}
      </Button>
    </Box>
  );
};

export default ConfigEditorWithJsonEditor;