import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TableContainer, Table, TableHead, TableRow, TableCell,
  TableBody, Tabs, Tab, IconButton, Snackbar, Alert, Typography, Paper, Collapse, Badge, Box, Tooltip
} from '@mui/material';
import {
  Save as SaveIcon, Edit as EditIcon, Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Refresh as RefreshIcon
} from '@mui/icons-material';
import { checkAndGenerateSecurityControls, saveSecurityControl, fetchSecurityControls } from './redux/actions/securityActions';
import IconWithProgress from './IconWithProgress';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import AutosaveIndicator from './AutoSaveIndicator';

function SecurityActionPlan({ modelId, assessmentUuid }) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const tabStatus = ['generated', 'saved', 'discarded'];
  const securityRisks = useSelector(state => state.security.models[modelId]?.securityRisks || []);
  const securityControls = useSelector(state => state.security.models[modelId]?.securityControls || {});
  const isLoading = useSelector(state => state.security.loading);
  const [warningMessage, setWarningMessage] = useState('');
  // Local state for autosave indicator
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    Promise.all(securityRisks.map(risk =>
      dispatch(fetchSecurityControls(modelId, risk.risk_id))
    )).then(() => {
      // After all fetches are done, check if any risk still lacks controls and needs generation
      dispatch(checkAndGenerateSecurityControls(modelId));
    });
  }, [dispatch, modelId, securityRisks]);

  const handleExpand = (risk_id) => {
    setExpanded(expanded === risk_id ? null : risk_id);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSaveControl = async (control, riskId) => {
    if (!riskId) {
      setWarningMessage('Please save the risk before saving controls.');
      return;
    }
    setIsSaving(true);
    const controlData = control;
    console.log('ControlData to dispatch to save sec ctrls', control);
    
    try {
      await dispatch(saveSecurityControl(controlData, modelId, riskId, assessmentUuid));
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 2000); // Clear saved state after 2 seconds
    } catch (error) {
      console.error('Error saving control:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleRefreshControls = () => {
    // Add logic for refreshing the controls here
  };

  const countControls = (riskId, status) => {
    return (securityControls[riskId] || []).filter(control => control.status === status).length;
  };

  return (
    <div style={{ margin: '16px' }}>
      <Paper elevation={2} style={{ padding: '20px', marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6">Security Risk Action Plan</Typography>
        {/* Autosave Indicator 10px to the right of the title */}
        <Box sx={{ marginLeft: '10px' }}>
          <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
        </Box>
        {/* Refresh Icon and Loading Indicator */}
        <Box sx={{ marginLeft: 'auto', paddingRight: '10px', display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Refresh Controls">
            <IconButton onClick={handleRefreshControls}>
              <RefreshIcon>
                <SmartToyOutlinedIcon style={{ position: 'absolute', fontSize: '0.8em' }} />
              </RefreshIcon>
            </IconButton>
          </Tooltip>
          {isLoading ? (
            <IconWithProgress isLoading={isLoading} />
          ) : null}
        </Box>
      </Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Risk Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Controls</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {securityRisks.map(risk => (
              <React.Fragment key={risk.risk_id}>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => handleExpand(risk.risk_id)}>
                      {expanded === risk.risk_id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {risk.risk_name}
                  </TableCell>
                  <TableCell />
                </TableRow>
                {expanded === risk.risk_id && (
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <Tabs value={activeTab} onChange={handleTabChange} aria-label="Control Tabs">
                          {tabStatus.map((status, index) => (
                            <Tab key={status} label={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {status.charAt(0).toUpperCase() + status.slice(1)}
                                <Badge color="primary" badgeContent={countControls(risk.risk_id, status)} sx={{ ml: 2 }} />
                              </Box>
                            } />
                          ))}
                        </Tabs>
                        <Table size="small" aria-label="controls">
                          <TableHead>
                            <TableRow>
                              <TableCell>Control Name</TableCell>
                              <TableCell align="right">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* {(securityControls[risk.risk_id] || []).filter(control => control.status === tabStatus[activeTab]).map(control => (
                              <TableRow key={control.controlId}>
                                <TableCell component="th" scope="row">
                                  {control.name}
                                </TableCell>
                                <TableCell align="right">
                                <IconButton onClick={() => {
                                        console.log('risk.risk_id:', risk.risk_id);
                                        handleSaveControl(control, risk.risk_id);
                                      }}>
                                        <SaveIcon />
                                      </IconButton>
                                  <IconButton><EditIcon /></IconButton>
                                  <IconButton><DeleteIcon /></IconButton>
                                </TableCell>
                              </TableRow>
                            ))} */}
                            {(securityControls[risk.risk_id] || []).filter(control => control.status === tabStatus[activeTab]).map(control => (
                            <TableRow key={control.controlId}>
                              <TableCell component="th" scope="row">
                                {control.name}
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title={!risk.risk_id ? 'Save the risk first' : 'Save Control'}>
                                  <span>
                                    <IconButton
                                      onClick={() => handleSaveControl(control, risk.risk_id)}
                                      disabled={!risk.risk_id}
                                    >
                                      <SaveIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                {/* Other action buttons */}
                                <IconButton disabled={!risk.risk_id}><EditIcon /></IconButton>
                                <IconButton disabled={!risk.risk_id}><DeleteIcon /></IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar open={isLoading} autoHideDuration={6000} onClose={() => {}}>
        <Alert severity="info">
          Fetching controls...
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(warningMessage)}
        autoHideDuration={6000}
        onClose={() => setWarningMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setWarningMessage('')} severity="warning" sx={{ width: '100%' }}>
          {warningMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SecurityActionPlan;
