// src/redux/reducers/configReducer.js

import {
    FETCH_CONFIG_START,
    FETCH_CONFIG_SUCCESS,
    FETCH_CONFIG_FAILURE,
    FETCH_ALL_CONFIGS_START,
    FETCH_ALL_CONFIGS_SUCCESS,
    FETCH_ALL_CONFIGS_FAILURE,
    SAVE_CONFIG_START,
    SAVE_CONFIG_SUCCESS,
    SAVE_CONFIG_FAILURE,
    CONFIG_UPDATE_SUCCESS,
    CONFIG_UPDATE_FAILURE
  } from '../actions/actionTypes.js';
  
  const initialState = {
    configs: {}, // { configName: configData }
    configNames: [], // List of all config names
    loading: false,
    saving: false,
    error: null,
  };
  
  const configReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CONFIG_START:
      case FETCH_ALL_CONFIGS_START:
        return { ...state, loading: true, error: null };
      
      case FETCH_CONFIG_SUCCESS:
        console.log('Reducer - FETCH_CONFIG_SUCCESS:', action.payload);
        return {
          ...state,
          loading: false,
          configs: { ...state.configs, [action.payload.name]: action.payload.data },
        };
      
      case FETCH_ALL_CONFIGS_SUCCESS:
        return {
          ...state,
          loading: false,
          configNames: action.payload,
        };
      
      case FETCH_CONFIG_FAILURE:
      case FETCH_ALL_CONFIGS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      
      case SAVE_CONFIG_START:
        return { ...state, saving: true, error: null };
      
      case SAVE_CONFIG_SUCCESS:
        return {
          ...state,
          saving: false,
          configs: { ...state.configs, [action.payload.name]: action.payload.data },
        };
      
      case SAVE_CONFIG_FAILURE:
        return { ...state, saving: false, error: action.payload };
        
        case CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        configs: { ...state.configs, [action.payload.name]: action.payload.data },
      };
    
    case CONFIG_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
      
      default:
        return state;
    }
  };
  
  export default configReducer;