// IconWithProgress.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import { styled, keyframes } from '@mui/system';

// Define spin animation
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

// Styled icon with optional spin
const SpinningIcon = styled(SmartToyOutlinedIcon)(({ isLoading }) => ({
  animation: isLoading ? `${spin} 1s linear infinite` : 'none',
}));

// IconWithProgress Component
const IconWithProgress = ({ onClick, isLoading, tooltip, disabled }) => {
  const iconButton = (
    <IconButton
      onClick={onClick}
      color="primary"
      aria-label={tooltip || 'Action'}
      disabled={isLoading || disabled} // Disable based on state
      sx={{ position: 'relative' }}
    >
      {!isLoading ? (
        <SmartToyOutlinedIcon />
      ) : (
        <>
          <SpinningIcon isLoading={isLoading} />
          <CircularProgress
            size={24}
            thickness={4}
            sx={{
              color: 'primary.main',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        </>
      )}
    </IconButton>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>
      {iconButton}
    </Tooltip>
  ) : (
    iconButton
  );
};

// Define prop types
IconWithProgress.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

// Define default props
IconWithProgress.defaultProps = {
  isLoading: false,
  tooltip: '',
  disabled: false,
};

export default IconWithProgress;
// // import React from 'react';
// // import { CircularProgress, IconButton } from '@mui/material';
// // import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
// // import { styled, keyframes } from '@mui/system';

// // // Define spin animation
// // const spin = keyframes`
// //   from { transform: rotate(0deg); }
// //   to { transform: rotate(360deg); }
// // `;

// // // Styled icon with optional spin
// // const SpinningIcon = styled(SmartToyOutlinedIcon)(({ isLoading }) => ({
// //   animation: isLoading ? `${spin} 1s linear infinite` : 'none',
// // }));

// // // IconWithProgress Component
// // const IconWithProgress = ({ onClick, isLoading }) => (
// //   <IconButton
// //     onClick={onClick}
// //     color="primary"
// //     aria-label="Set Priority Automatically"
// //     disabled={isLoading} // Disable button during loading
// //     sx={{ position: 'relative' }}
// //   >
// //     {!isLoading ? (
// //       <SmartToyOutlinedIcon /> // Show icon when not loading
// //     ) : (
// //       <>
// //         <SpinningIcon isLoading={isLoading} /> {/* Spinning icon */}
// //         <CircularProgress
// //           size={24}
// //           thickness={4}
// //           sx={{
// //             color: 'primary.main',
// //             position: 'absolute',
// //             top: '50%',
// //             left: '50%',
// //             marginTop: '-12px',
// //             marginLeft: '-12px',
// //           }}
// //         /> {/* Overlay spinner */}
// //       </>
// //     )}
// //   </IconButton>
// // );

// // export default IconWithProgress;
// // IconWithProgress.jsx
// import React from 'react';
// import PropTypes from 'prop-types';
// import { CircularProgress, IconButton, Tooltip } from '@mui/material';
// import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
// import { styled, keyframes } from '@mui/system';

// // Define spin animation
// const spin = keyframes`
//   from { transform: rotate(0deg); }
//   to { transform: rotate(360deg); }
// `;

// // Styled icon with optional spin
// const SpinningIcon = styled(SmartToyOutlinedIcon)(({ isLoading }) => ({
//   animation: isLoading ? `${spin} 1s linear infinite` : 'none',
// }));

// // IconWithProgress Component
// const IconWithProgress = ({ onClick, isLoading, tooltip }) => {
//   const iconButton = (
//     <IconButton
//       onClick={onClick}
//       color="primary"
//       aria-label={tooltip || 'Action'}
//       disabled={isLoading} // Disable button during loading
//       sx={{ position: 'relative' }}
//     >
//       {!isLoading ? (
//         <SmartToyOutlinedIcon /> // Show icon when not loading
//       ) : (
//         <>
//           <SpinningIcon isLoading={isLoading} /> {/* Spinning icon */}
//           <CircularProgress
//             size={24}
//             thickness={4}
//             sx={{
//               color: 'primary.main',
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               marginTop: '-12px',
//               marginLeft: '-12px',
//             }}
//           /> {/* Overlay spinner */}
//         </>
//       )}
//     </IconButton>
//   );

//   // Conditionally wrap with Tooltip if tooltip text is provided
//   return tooltip ? (
//     <Tooltip title={tooltip}>
//       {iconButton}
//     </Tooltip>
//   ) : (
//     iconButton
//   );
// };

// // Define prop types
// IconWithProgress.propTypes = {
//   onClick: PropTypes.func.isRequired,
//   isLoading: PropTypes.bool,
//   tooltip: PropTypes.string,
// };

// // Define default props
// IconWithProgress.defaultProps = {
//   isLoading: false,
//   tooltip: '', // Default to empty string if not provided
// };

// export default IconWithProgress;