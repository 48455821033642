// src/redux/selectors/riskFormSelectors.js

import { createSelector } from 'reselect';

// Input Selectors
const selectRiskSources = (state) => state.config.configs.riskSources;
const selectRiskRootCauses = (state) => state.config.configs.riskRootCauses;

/**
 * Selector to combine riskSources and riskRootCauses into riskForm.
 * Structure:
 * {
 *   categoryName: {
 *     riskSources: [
 *       {
 *         name,
 *         description,
 *         value,
 *         type,
 *         options,
 *         causes: { ...riskRootCauses }
 *       },
 *       ...
 *     ]
 *   },
 *   ...
 * }
 */
export const RiskFormGenerator = createSelector(
  [selectRiskSources, selectRiskRootCauses],
  (riskSources, riskRootCauses) => {
    if (!riskSources || !riskRootCauses) {
      console.warn('RiskSources or RiskRootCauses are undefined.');
      return {};
    }

    const riskForm = {};

    // Iterate over each category in riskSources (e.g., humanRights, wellbeing)
    Object.keys(riskSources).forEach((category) => {
      const sources = riskSources[category].riskSources;

      if (!sources || !Array.isArray(sources)) {
        console.warn(`RiskSources for category "${category}" are missing or not an array.`);
        return;
      }

      riskForm[category] = {
        riskSources: sources.map((source) => ({
          ...source,
          causes: { ...riskRootCauses }, // Attach a copy of riskRootCauses to each riskSource
        })),
      };
    });

    return riskForm;
  }
);