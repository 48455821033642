// src/components/ImportBedrockButton.js

import React, { useState } from 'react';
import { Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  importBedrockModelsRequest,
  importBedrockModelsSuccess,
  importBedrockModelsFailure,
} from '../redux/actions/cloudModels';

function ImportBedrockModels() {
  const dispatch = useDispatch();
  const [isImporting, setIsImporting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleImport = async () => {
    setIsImporting(true);
    dispatch(importBedrockModelsRequest());
    try {
      const response = await fetch('/.netlify/functions/fetchBedrockModelCards');
      const result = await response.json();

      if (result.success) {
        // Append source information
        const modelsWithSource = result.data.map(model => ({
          ...model,
          source: 'Bedrock',
        }));
        dispatch(importBedrockModelsSuccess(modelsWithSource));
        setSnackbar({ open: true, message: 'Models imported successfully from Bedrock.', severity: 'success' });
      } else {
        console.error('Error importing models from Bedrock:', result.error);
        dispatch(importBedrockModelsFailure(result.error));
        setSnackbar({ open: true, message: 'Failed to import models from Bedrock.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error importing models from Bedrock:', error);
      dispatch(importBedrockModelsFailure(error.message));
      setSnackbar({ open: true, message: 'Failed to import models from Bedrock.', severity: 'error' });
    }
    setIsImporting(false);
  };

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        onClick={handleImport}
        disabled={isImporting}
        startIcon={isImporting && <CircularProgress size={20} />}
        style={{ textTransform: 'none' }}
      >
        {isImporting ? 'Importing...' : 'Import from Bedrock'}
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ImportBedrockModels;