import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Paper, Stepper, Step, StepLabel, Button, Collapse, LinearProgress, Fab, Zoom, useMediaQuery } from '@mui/material';
import { ExpandMore,  ArrowForward, ArrowBack,  CheckCircle } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import ContextAssessment from './contextAssessment.js';
import RiskCard from './RiskCard.js';
import RiskCatalogue from './RiskCatalogue.js';
import RiskEvaluation from './RiskEvaluation.js';
import ActionPlan from './ActionPlan.js';
import Compliance from './Compliance.js';
import ResidualRisk from './ResidualRisk.js';
import SecurityActionPlan from './SecurityActionPlan.js';
import SecurityRiskCatalogue from './SecurityRiskCatalogue.js';
import SecurityRiskAssessment from './SecurityRiskAssessment.js';
import AssessmentReport from './AssessmentReport.js';
import { fetchThemes } from './redux/actions/themeActions';
import { fetchRiskCardData, updateAssessmentStep, updateAssessmentStatus, setSection, setStep, updateSectionProgress } from './redux/actions/riskActions';
import { fetchContextData } from './redux/actions/contextActions';
import contextForm from './contextForm.json';
//import riskForm from './riskForm.json';
import { RiskFormGenerator } from './redux/selectors/riskFormGenerator.js';
function RiskCardForm() {
  let { modelId, modelName, assessmentUuid } = useParams();
  modelName = decodeURIComponent(modelName);
  const dispatch = useDispatch();
  const theme = useTheme(); // Use theme from MUI
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  const assessmentStatus = useSelector(state => state.risks.models[modelId]?.assessmentStatus);
  const riskForm = useSelector(RiskFormGenerator);
  const navigate = useNavigate();

  
  const securitySteps = useMemo(() => ['threatsAndVulnerabilities', 'securityRisks', 'securityControls'], []);
  const riskCardSteps = useMemo(() => ['riskCard', 'riskIdentification', 'riskEvaluation', 'actionPlan','residualRisk'], []);
  const [activeStep, setActiveStep] = useState(0);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [activeRiskCardStep, setActiveRiskCardStep] = useState(0);
  const [expandedSections, setExpandedSections] = useState({});

  const riskFormData = useSelector(state => state.risks.models[modelId]?.riskFormData || {});
  const contextData = useSelector(state => state.context.data[modelId] || {});

  // Deriving narratives from contextData
  const narratives = useMemo(() => {
    return Object.keys(contextData).reduce((acc, section) => {
      const narrative = contextData[section]?.narrative ? contextData[section].narrative : `No narrative for ${section}`;
      acc[section] = narrative;
      return acc;
    }, {});
  }, [contextData]);

  const steps = useMemo(() => [
    { label: 'modelDetails', sections: Object.keys(contextForm) },
    { label: 'modelRisks', sections: Object.keys(riskForm) },
    { label: 'securityAssessment', sections: securitySteps },
    { label: 'compliance', sections: [] },
    { label: 'assessmentReport', sections: [] }
  ], [securitySteps, riskForm]);

  // Update section progress when in modelRisks step
  useEffect(() => {
    if (activeStep === 1) {
      const sectionName = steps[1].sections[activeSectionIndex];
      const progress = ((activeRiskCardStep + 1) / riskCardSteps.length) * 100;
      dispatch(updateSectionProgress(modelId, sectionName, progress));
    }
  }, [activeRiskCardStep, activeStep, activeSectionIndex, steps, riskCardSteps.length, dispatch, modelId]);

  const modelRisksProgress = steps[1].sections.reduce((acc, section) => {
    acc[section] = riskFormData[section]?.assessmentProgress || 0;
    return acc;
  }, {});

  // Fetch initial data
  useEffect(() => {
    dispatch(fetchThemes());
    dispatch(fetchContextData(modelId, assessmentUuid));
    dispatch(fetchRiskCardData(modelId, assessmentUuid));
  
    if (!assessmentStatus || assessmentStatus === 'Not Started') {
      dispatch(updateAssessmentStatus(modelId, assessmentUuid, 'On-Going'));
    }
  }, [dispatch, modelId, assessmentUuid, assessmentStatus]);
  

  // Reset the activeRiskCardStep to 0 (first step) when the section changes
  useEffect(() => {
    setActiveRiskCardStep(0);
  }, [activeSectionIndex]);

  
  useEffect(() => {
    if (activeStep === steps.length - 1) {
      dispatch(updateAssessmentStatus(modelId, assessmentUuid, 'Completed'));
    } else if (assessmentStatus === 'Completed') {
      dispatch(updateAssessmentStatus(modelId, assessmentUuid, 'On-Going'));
    }
  }, [activeStep, steps.length, dispatch, modelId, assessmentUuid, assessmentStatus]);


  // Handler for modelRisks step changes
  const handleModelRisksStepChange = (stepIndex, sectionIndex = 0) => {
    setActiveStep(stepIndex);
    setActiveSectionIndex(sectionIndex);
    const sectionName = steps[stepIndex].sections[sectionIndex];

    if (sectionName) {
      dispatch(setSection(sectionName, modelId));
    }

    const currentStep = steps[stepIndex].label;
    dispatch(updateAssessmentStep(modelId, assessmentUuid, currentStep));
    
  };

  // Handler for contextAssessment step changes
  const handleContextStepChange = (sectionIndex) => {
    console.log('ContextAssessment step clicked:', { sectionIndex, sectionName: steps[0].sections[sectionIndex] });
    setActiveSectionIndex(sectionIndex);
    setActiveStep(0); // Manage section index for ContextAssessment
    const sectionName = steps[0].sections[sectionIndex]; // ContextAssessment section

    if (sectionName) {
      dispatch(setSection(sectionName, modelId));
    }

    const currentStep = steps[0].label;
    dispatch(updateAssessmentStep(modelId, assessmentUuid, currentStep));
   
  };

  const handleRiskCardStepChange = (stepIndex) => {
    setActiveRiskCardStep(stepIndex);

    const sectionName = steps[1].sections[activeSectionIndex]; // 'modelRisks' section
    const progress = ((stepIndex + 1) / riskCardSteps.length) * 100; // Calculate progress as a percentage

    dispatch(setStep(modelId, sectionName, riskCardSteps[stepIndex]));
    dispatch(updateSectionProgress(modelId, sectionName, progress));
  };

  // Handler for securityAssessment step changes
const handleSecurityAssessmentStepChange = (sectionIndex) => {
  console.log('SecurityAssessment step clicked:', { sectionIndex, sectionName: steps[2].sections[sectionIndex] });
  setActiveSectionIndex(sectionIndex);
  setActiveStep(2); // Set to securityAssessment step

  const sectionName = steps[2].sections[sectionIndex]; // SecurityAssessment section
  if (sectionName) {
    dispatch(setSection(sectionName, modelId));
  }

  const currentStep = steps[2].label;
  dispatch(updateAssessmentStep(modelId, assessmentUuid, currentStep));
  
};



  const handleNext = () => {
    // If we are in 'modelRisks' step and not at the last risk card step
    if (activeStep === 1 && activeRiskCardStep < riskCardSteps.length - 1) {
      setActiveRiskCardStep(activeRiskCardStep + 1);
    } 
    // If we are not at the last section within the current step
    else if (activeSectionIndex < steps[activeStep].sections.length - 1) {
      setActiveSectionIndex(activeSectionIndex + 1);
    } 
    // If we are at the last section of the current step but not at the last major step
    else if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
      setActiveSectionIndex(0); // Reset section index for the new step
      setActiveRiskCardStep(0); // Reset sub-step for 'modelRisks' step
    }
  };
  
  const handleBack = () => {
    // If we are in 'modelRisks' step and not at the first risk card step
    if (activeStep === 1 && activeRiskCardStep > 0) {
      setActiveRiskCardStep(activeRiskCardStep - 1);
    } 
    // If we are not at the first section within the current step
    else if (activeSectionIndex > 0) {
      setActiveSectionIndex(activeSectionIndex - 1);
    } 
    // If we are at the first section of the current step but not at the first major step
    else if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      setActiveSectionIndex(steps[activeStep - 1].sections.length - 1); // Move to the last section of the previous step
      setActiveRiskCardStep(riskCardSteps.length - 1); // Move to the last sub-step for 'modelRisks' step
    }
  };
  

  const handleSectionToggle = (sectionName, sectionIdx) => {
        // Only perform this action if the activeStep is for 'modelRisks'
        if (activeStep === 1) { // Step 1 is 'modelRisks'
          setExpandedSections(prev => ({
            ...prev,
            [sectionName]: true, // Auto-expand the section when selected
          }));
          handleModelRisksStepChange(1, sectionIdx); // Move to the selected section within 'modelRisks'
        }
      };
      
      const toTitleCase = (str) => {
        if (!str) return ''; // Return an empty string if the input is undefined or null
        return str
          .replace(/([A-Z])/g, ' $1')
          .replace(/([A-Z]+)/g, (match) => match.toUpperCase())
          .trim()
          .split(/\s+/)
          .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
          .join(' ');
      };
      

  const getSectionBackgroundStyle = (progress) => ({
    background: `linear-gradient(to right, rgba(0, 128, 0, 0.1) ${progress}%, rgba(200, 200, 200, 0.05) ${progress}%)`,
  });

  const transitionDuration = {
    enter: 500, // Customize enter animation duration
    exit: 300,  // Customize exit animation duration
  };

  const getNextMajorStepLabel = () => {
    if (activeStep < steps.length - 1) {
      return toTitleCase(steps[activeStep + 1].label);
    } else {
      return 'Complete';
    }
  };
  

  const isLastSection =
  steps[activeStep].sections.length === 0
    ? true
    : activeSectionIndex === steps[activeStep].sections.length - 1;
    
  const handleFabClick = () => {
      if (activeStep < steps.length - 1) {
        // Navigate to the next major step
        setActiveStep(activeStep + 1);
        setActiveSectionIndex(0); // Reset section index
        setActiveRiskCardStep(0); // Reset sub-step for 'modelRisks' step
        const nextStepLabel = steps[activeStep + 1].label;
        dispatch(updateAssessmentStep(modelId, assessmentUuid, nextStepLabel));
      } else {
        // On the last step, navigate to /risk-assessments
        navigate('/risk-assessments');
      }
    };
    
  return (

    <Grid container spacing={2}>

{!isMobile && (
      <Grid item xs={3}>
        <Paper elevation={2} style={{ padding: '16px', height: '100%' }}>


<Stepper activeStep={activeStep} orientation="vertical" nonLinear>
  {steps.map((step, index) => (
    <Step key={step.label}>
      <StepLabel
        onClick={() => {
          // Set the main step
          setActiveStep(index);

          if (index === 0) {
            // ContextAssessment
            
            handleContextStepChange(activeSectionIndex); // Pass current section index for contextAssessment
          } else if (index === 1) {
            // ModelRisks
           
            handleModelRisksStepChange(index, activeSectionIndex); // Pass the current section for modelRisks
          } else if (index === 2) {
            // SecurityAssessment
            
            handleSecurityAssessmentStepChange(activeSectionIndex); // Pass current section index for securityAssessment
          }
        }}
        sx={{
          '&:hover': {
            backgroundColor: '#f5f5f5',
            cursor: 'pointer',
          },
        }}
      >
        {toTitleCase(step.label)}
      </StepLabel>

      {/* Only render sections if this is the active step */}
      {index === activeStep && step.sections.map((subStep, sectionIdx) => (
        <Step key={subStep} sx={{ pl: 4 }}>
          <StepLabel
            onClick={() => {
              if (index === 0) {
                // Handle clicks for ContextAssessment sections
                
                handleContextStepChange(sectionIdx);
              } else if (index === 1) {
                handleSectionToggle(subStep, sectionIdx); // Handle sections for modelRisks
              } else if (index === 2) {
                // Handle clicks for SecurityAssessment sections
               
                handleSecurityAssessmentStepChange(sectionIdx);
              }
            }}
            sx={{
              ...getSectionBackgroundStyle(modelRisksProgress[subStep] || 0), // Background based on progress
              position: 'relative', // Position relative to enable icon placement
              paddingRight: '30px', // Ensure space for the icon
              backgroundColor: activeSectionIndex === sectionIdx ? '#f0f0f0' : 'inherit',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f0f0f0',
                '& .expand-icon': {
                  visibility: 'visible', // Show icon on hover
                },
              },
            }}
          >
            {toTitleCase(subStep)}

            {activeStep === 1 && (
              <>
                <ExpandMore
                  className="expand-icon"
                  sx={{
                    visibility: 'hidden',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    transition: 'visibility 0.3s ease',
                    color: '#6E6E6E',
                  }}
                />

                <LinearProgress
                  variant="determinate"
                  value={modelRisksProgress[subStep] || 0}
                  style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '2px', backgroundColor: '#d3d3d3' }}
                />
              </>
            )}
          </StepLabel>

          {/* Collapse the section if it's expanded */}
          <Collapse in={expandedSections[subStep]}>
            {step.label === 'modelRisks' && activeSectionIndex === sectionIdx && (
              <>
                <LinearProgress
                  variant="determinate"
                  value={modelRisksProgress[subStep] || 0}
                  style={{ margin: '8px 0', width: '80%' }}
                />
                <Stepper activeStep={activeRiskCardStep} orientation="vertical" nonLinear>
                  {riskCardSteps.map((riskStep, riskStepIdx) => (
                    <Step key={riskStep}>
                      <StepLabel
                        onClick={() => handleRiskCardStepChange(riskStepIdx)}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                            cursor: 'pointer',
                          },
                        }}
                      >
                        {toTitleCase(riskStep)}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </>
            )}
          </Collapse>
        </Step>
      ))}
    </Step>
  ))}
</Stepper>

        </Paper>
      </Grid>
)}

      <Grid item xs={isMobile ? 12 : 9} style={{ padding: '16px 24px' }}>
        <Paper elevation={2} style={{ width: '100%' }}>
          <Typography variant="h5" style={{ padding: '8px 16px', display: 'flex', alignItems: 'center' }}>
            Risk assessment - {toTitleCase(modelName)}
          </Typography>
        </Paper>

        <Card elevation={2} style={{ height: '100%' }}>
          <CardContent>
            {activeStep === 0 && (
              <ContextAssessment
                modelId={modelId}
                assessmentUuid={assessmentUuid}
                // sectionName={steps[activeStep].sections[activeSectionIndex]}
                sectionName={steps[0].sections[activeSectionIndex]}
              />
            )}
            {activeStep === 1 && (
              <>
                {activeRiskCardStep === 0 && (
                  <RiskCard
                    modelId={modelId}
                    assessmentUuid={assessmentUuid}
                    narratives={narratives}
                    sectionName={steps[activeStep].sections[activeSectionIndex]}
                  />
                )}
                {activeRiskCardStep === 1 && (
                  <RiskCatalogue
                    modelId={modelId}
                    sectionName={steps[activeStep].sections[activeSectionIndex]}
                    assessmentUuid={assessmentUuid}
                    narratives={narratives}
                  />
                )}
                {activeRiskCardStep === 2 && (
                  <RiskEvaluation
                    modelId={modelId}
                    assessmentUuid={assessmentUuid}
                    sectionName={steps[activeStep].sections[activeSectionIndex]}
                  />
                )}
                {activeRiskCardStep === 3 && (
                  <ActionPlan
                    riskFormData={riskFormData}
                    modelId={modelId}
                    assessmentUuid={assessmentUuid}
                    sectionName={steps[activeStep].sections[activeSectionIndex]}
                    narratives={narratives}
                  />
                )}

                {activeRiskCardStep === 4 && (
                  <ResidualRisk
                    modelId={modelId}
                    assessmentUuid={assessmentUuid}
                    sectionName={steps[activeStep].sections[activeSectionIndex]}
                  />
                )}
              </>
            )}
            {activeStep === 2 && (
              <>
        
                {activeSectionIndex === 0 && (
                  <SecurityRiskAssessment modelId={modelId} assessmentUuid={assessmentUuid} />
                )}
                {activeSectionIndex === 1 && (
                  <SecurityRiskCatalogue modelId={modelId} assessmentUuid={assessmentUuid} />
                )}
                {activeSectionIndex === 2 && (
                  <SecurityActionPlan modelId={modelId} assessmentUuid={assessmentUuid} />
                )}
              </>
            )}

                {activeStep === 3 && (
                  <Compliance
                    modelId={modelId}
                    assessmentUuid={assessmentUuid}
                    riskFormData={riskFormData}
                    narratives={narratives}
                  />
                )}
            
            {activeStep === 4 && (
              <AssessmentReport modelId={modelId} assessmentUuid={assessmentUuid} />
            )}
          </CardContent>



<Grid container justifyContent="space-between" style={{ padding: '16px' }}>
            <Button
              onClick={handleBack}
              startIcon={<ArrowBack />}
              disabled={activeStep === 0 && activeSectionIndex === 0 && activeRiskCardStep === 0}
            >
              {activeStep === 0 || activeRiskCardStep === 0
                ? 'Back'
                : activeSectionIndex > 0 // Ensure we have a valid index
                ? `Back to ${toTitleCase(steps[activeStep].sections[activeSectionIndex - 1])}`
                : 'Back'}
            </Button>
            {!(activeSectionIndex === steps[activeStep].sections.length - 1 && activeStep < steps.length - 1) && (
              <Button
                onClick={handleNext}
                endIcon={<ArrowForward />}
                disabled={activeStep === steps.length - 1 && activeSectionIndex === steps[activeStep].sections.length - 1 && activeRiskCardStep === riskCardSteps.length - 1}
              >
                {activeStep === 1 && activeRiskCardStep < riskCardSteps.length - 1
                  ? `Next: ${toTitleCase(riskCardSteps[activeRiskCardStep + 1])}`
                  : activeSectionIndex < steps[activeStep].sections.length - 1
                  ? `Next: ${toTitleCase(steps[activeStep].sections[activeSectionIndex + 1])}`
                  : 'Next'}
              </Button>
            )}
          </Grid>
        </Card>
      </Grid>

      {/* FAB for major step transitions */}
      {/* <Zoom
        in={activeSectionIndex === steps[activeStep].sections.length - 1 && activeStep < steps.length - 1}
        timeout={transitionDuration}
        unmountOnExit
      >
        <Fab
          variant="extended"
          color="primary"
          onClick={() => {
            setActiveStep(activeStep + 1);
            setActiveSectionIndex(0); // Reset section index
            setActiveRiskCardStep(0); // Reset sub-step for 'modelRisks' step
          }}
          style={{ position: 'fixed', bottom: '16px', right: '16px', zIndex: 1000 }}
        >
          <ArrowForward sx={{ mr: 1 }} />
          Next Step
        </Fab>
      </Zoom> */}

      {/* FAB for major step transitions */}
<Zoom
  // in={activeSectionIndex === steps[activeStep].sections.length - 1}
  in={isLastSection}
  timeout={transitionDuration}
  unmountOnExit
>
<Fab
  variant="extended"
  color="primary"
  onClick={handleFabClick}
  style={{ position: 'fixed', bottom: '16px', right: '16px', zIndex: 1000 }}
>
  {activeStep === steps.length - 1 ? (
    <>
      <CheckCircle sx={{ mr: 1 }} />
      Complete
    </>
  ) : (
    <>
      <ArrowForward sx={{ mr: 1 }} />
      {getNextMajorStepLabel()}
    </>
  )}
</Fab>

</Zoom>

    </Grid>
  );
}

export default RiskCardForm;
