// src/fileUploader.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconWithProgress from './IconWithProgress';

// Import your tab/subtab constants
import { 
  MAIN_TABS, 
  GOVERNANCE_SUBTABS, 
  AI_CONTROL_SUBTABS,
  CATEGORIES
} from './utils/policyConstants';

// If you have a separate constants file for categories, import it; otherwise define it here:

// Styled Components for aesthetics
const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f9f9f9',
  minHeight: '100vh',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: '#ffffff',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  textTransform: 'none',
}));

// Reusable DocumentUploadSection
const DocumentUploadSection = ({
  title,
  allowedTypes,
  onFileChange,
  onUpload,
  uploadedFiles,
  handleEmbed,
  handleDelete,
  embeddingFiles,
}) => (
  <StyledPaper sx={{ mb: 4 }}>
    <Typography variant="h6" gutterBottom color="textPrimary">
      {title}
    </Typography>

    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <input
        type="file"
        accept={allowedTypes}
        multiple
        onChange={onFileChange}
        style={{ display: 'none' }}
        id={`${title}-input`}
      />
      <label htmlFor={`${title}-input`}>
        <StyledButton
          variant="outlined"
          color="primary"
          component="span"
          startIcon={<AddIcon />}
        >
          Add
        </StyledButton>
      </label>
    </Box>

    <Typography variant="body2" color="textSecondary" gutterBottom>
      Allowed file types: {allowedTypes.replace(/,/g, ', ')}
    </Typography>

    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Date Added</TableCell>
            <TableCell align="center">Upload</TableCell>
            <TableCell align="center">Embed</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(uploadedFiles) && uploadedFiles.length > 0 ? (
            uploadedFiles.map((file, idx) => (
              <TableRow key={idx}>
                <TableCell>{file.name}</TableCell>
                <TableCell>{file.date}</TableCell>

                <TableCell align="center">
                  <Tooltip title="Upload File">
                    <IconButton
                      color="primary"
                      onClick={() => onUpload(file)}
                      disabled={file.uploaded || file.isUploading}
                    >
                      {file.isUploading ? <CircularProgress size={24} /> : <CloudUploadOutlinedIcon />}
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell align="center">
                  <IconWithProgress
                    onClick={() => handleEmbed(file.name, file.fileType, file.category)}
                    isLoading={embeddingFiles[file.name] || false}
                    tooltip="Embed File"
                    disabled={!file.canEmbed}
                  />
                </TableCell>

                <TableCell align="center">
                  <Tooltip title="Delete File">
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(file.name, file.category)}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No files added yet.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </StyledPaper>
);

const FileUpload = () => {
  const [mainTabIndex, setMainTabIndex] = useState(MAIN_TABS.RISK_CATALOGUES);
  const [govSubTabIndex, setGovSubTabIndex] = useState(GOVERNANCE_SUBTABS.AI_GOVERNANCE_POLICIES);
  const [modelCtrlSubTabIndex, setModelCtrlSubTabIndex] = useState(AI_CONTROL_SUBTABS.DATA_GOVERNANCE_POLICIES);

  // We store file arrays under each category key
  const [uploadedFiles, setUploadedFiles] = useState({
    [CATEGORIES.RISK_CATALOGUES]: [],
    [CATEGORIES.RISK_POLICIES]: [],
    [CATEGORIES.AUDIT_POLICIES]: [],
    [CATEGORIES.AI_GOVERNANCE_POLICIES]: [],
    [CATEGORIES.SECURITY_POLICIES]: [],
    [CATEGORIES.BIAS_POLICIES]: [],
    [CATEGORIES.DATA_GOVERNANCE_POLICIES]: [],
    [CATEGORIES.LOGGING_MONITORING_POLICIES]: [],
    [CATEGORIES.QUALITY_TEST_POLICIES]: [],
    [CATEGORIES.INCIDENT_MANAGEMENT_POLICIES]: [],
    [CATEGORIES.DOCUMENTATION_RECORD_KEEPING_POLICIES]: []
  });

  // For tracking embedding state by filename
  const [embeddingFiles, setEmbeddingFiles] = useState({});

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null); // { name, category }

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Fetch existing uploaded files on mount (for each category)
  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const categories = Object.keys(uploadedFiles);
        const allUploadedFiles = {};

        // Create an array of promises for each category fetch
        const fetchPromises = categories.map((category) =>
          fetch('/.netlify/functions/listCatalogues', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ category }),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error(`Failed to fetch files for category: ${category}`);
              }
              return response.json();
            })
            .then((filesData) => ({
              category,
              filesData: filesData.map((f) => ({
                ...f,
                isUploading: false,
                // canEmbed is true if 'uploaded' is true
                canEmbed: !!f.uploaded,
                originalFile: null
              })),
            }))
        );

        // Wait for all fetches
        const results = await Promise.all(fetchPromises);

        // Aggregate results
        results.forEach(({ category, filesData }) => {
          allUploadedFiles[category] = filesData;
        });

        setUploadedFiles(allUploadedFiles);
        console.log('Uploaded files fetched successfully:', allUploadedFiles);
      } catch (error) {
        console.error('Error fetching uploaded files:', error);
        setSnackbarMessage(`Error fetching files: ${error.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchUploadedFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MAIN TAB (4 possible values)
  const handleMainTabChange = (event, newValue) => {
    setMainTabIndex(newValue);
  };

  // GOVERNANCE SUB-TABS
  const handleGovSubTabChange = (event, newValue) => {
    setGovSubTabIndex(newValue);
  };

  // MODEL CONTROL SUB-TABS
  const handleModelCtrlSubTabChange = (event, newValue) => {
    setModelCtrlSubTabIndex(newValue);
  };

  // Based on the current mainTab + subTab, return the correct category string
  const getCurrentCategory = () => {
    // 0) Risk Catalogues & Registers
    if (mainTabIndex === MAIN_TABS.RISK_CATALOGUES) {
      return CATEGORIES.RISK_CATALOGUES; 
    }

    // 1) Governance Policies
    if (mainTabIndex === MAIN_TABS.GOVERNANCE_POLICIES) {
      switch (govSubTabIndex) {
        case GOVERNANCE_SUBTABS.RISK_POLICIES:
          return CATEGORIES.RISK_POLICIES;
        case GOVERNANCE_SUBTABS.AUDIT_POLICIES:
          return CATEGORIES.AUDIT_POLICIES;
        case GOVERNANCE_SUBTABS.AI_GOVERNANCE_POLICIES:
          return CATEGORIES.AI_GOVERNANCE_POLICIES;
        default:
          return CATEGORIES.RISK_POLICIES;
      }
    }

    // 2) Model Control Baseline
    if (mainTabIndex === MAIN_TABS.AI_CONTROL_BASELINE) {
      switch (modelCtrlSubTabIndex) {
        case AI_CONTROL_SUBTABS.BIAS_POLICIES:
          return CATEGORIES.BIAS_POLICIES;
        case AI_CONTROL_SUBTABS.DATA_GOVERNANCE_POLICIES:
          return CATEGORIES.DATA_GOVERNANCE_POLICIES;
        case AI_CONTROL_SUBTABS.LOGGING_MONITORING_POLICIES:
          return CATEGORIES.LOGGING_MONITORING_POLICIES;
        case AI_CONTROL_SUBTABS.QUALITY_TEST_POLICIES:
          return CATEGORIES.QUALITY_TEST_POLICIES;
        case AI_CONTROL_SUBTABS.INCIDENT_MANAGEMENT_POLICIES:
          return CATEGORIES.INCIDENT_MANAGEMENT_POLICIES;
        case AI_CONTROL_SUBTABS.DOCUMENTATION_RECORD_KEEPING_POLICIES:
          return CATEGORIES.DOCUMENTATION_RECORD_KEEPING_POLICIES;
        default:
          return CATEGORIES.BIAS_POLICIES;
      }
    }

    // 3) Security Policies
    if (mainTabIndex === MAIN_TABS.SECURITY_POLICIES) {
      return CATEGORIES.SECURITY_POLICIES;
    }

    // Fallback
    return '';
  };

  // Restrict allowed file types per category
  const getAllowedTypes = (category) => {
    // You can customize per category or keep them all the same
    const types = {
      [CATEGORIES.RISK_CATALOGUES]: '.pdf,.txt,.csv',
      [CATEGORIES.RISK_POLICIES]: '.pdf,.txt,.csv',
      [CATEGORIES.AUDIT_POLICIES]: '.pdf,.txt,.csv',
      [CATEGORIES.AI_GOVERNANCE_POLICIES]: '.pdf,.txt,.csv',
      [CATEGORIES.SECURITY_POLICIES]: '.pdf,.txt,.csv',
      [CATEGORIES.BIAS_POLICIES]: '.pdf,.txt,.csv',
      [CATEGORIES.DATA_GOVERNANCE_POLICIES]: '.pdf,.txt,.csv',
      [CATEGORIES.LOGGING_MONITORING_POLICIES]: '.pdf,.txt,.csv',
      [CATEGORIES.QUALITY_TEST_POLICIES]: '.pdf,.txt,.csv',
      [CATEGORIES.INCIDENT_MANAGEMENT_POLICIES]: '.pdf,.txt,.csv',
      [CATEGORIES.DOCUMENTATION_RECORD_KEEPING_POLICIES]: '.pdf,.txt,.csv',
    };
    return types[category] || '.pdf,.txt,.csv';
  };

  // User selected files from the input
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const currentCategory = getCurrentCategory();
    const allowed = getAllowedTypes(currentCategory);

    if (!currentCategory) {
      console.error('No valid category selected.');
      setSnackbarMessage('No valid category selected.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (!allowed) {
      console.error(`No allowed types defined for category: ${currentCategory}`);
      setSnackbarMessage(`No allowed types defined for category: ${currentCategory}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    // Filter out invalid file types
    const validFiles = selectedFiles.filter((file) => {
      const fileType = file.name.split('.').pop().toLowerCase();
      return allowed.includes(`.${fileType}`);
    });

    if (validFiles.length === 0) {
      setSnackbarMessage('No valid files selected.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    // Map them into our structure
    const newFiles = validFiles.map((file) => ({
      name: file.name,
      date: new Date().toLocaleString(),
      uploaded: false, // will be true after we actually upload it
      fileType: file.name.split('.').pop().toLowerCase(),
      category: currentCategory,
      isUploading: false,
      canEmbed: false,
      originalFile: file
    }));

    setUploadedFiles((prev) => ({
      ...prev,
      [currentCategory]: [...(prev[currentCategory] || []), ...newFiles],
    }));
  };

  // Upload file to blob storage (Netlify function: blobManager)
  const handleUpload = async (file) => {
    const { category, name, originalFile } = file;

    // Mark isUploading = true
    setUploadedFiles((prev) => ({
      ...prev,
      [category]: prev[category].map((f) =>
        f.name === name ? { ...f, isUploading: true } : f
      ),
    }));

    try {
      const formData = new FormData();
      formData.append('file', originalFile);
      formData.append('category', category);

      const response = await fetch('/.netlify/functions/blobManager', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to upload file');
      }

      // Mark file as "uploaded" and canEmbed = true
      setUploadedFiles((prev) => ({
        ...prev,
        [category]: prev[category].map((f) =>
          f.name === name
            ? { ...f, uploaded: true, isUploading: false, canEmbed: true }
            : f
        ),
      }));

      setSnackbarMessage(`File "${name}" uploaded successfully!`);
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadedFiles((prev) => ({
        ...prev,
        [category]: prev[category].map((f) =>
          f.name === name ? { ...f, isUploading: false } : f
        ),
      }));
      setSnackbarMessage(`Error uploading "${name}": ${error.message}`);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  // Delete file from blob (Netlify function: deleteCatalogue)
  const handleDelete = (fileName, category) => {
    // Set the file to delete and open the confirmation modal
    setFileToDelete({ name: fileName, category });
    setConfirmDeleteOpen(true);
  };

  // Confirm deletion after user confirms in the modal
  const confirmDelete = async () => {
    if (!fileToDelete) {
      setConfirmDeleteOpen(false);
      return;
    }

    const { name, category } = fileToDelete;

    try {
      const response = await fetch('/.netlify/functions/deleteCatalogue', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName: name, category }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete file and embeddings');
      }

      // Remove from local state
      setUploadedFiles((prev) => ({
        ...prev,
        [category]: prev[category].filter((file) => file.name !== name),
      }));

      setSnackbarMessage(`File "${name}" and its embeddings deleted successfully!`);
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error deleting file and embeddings:', error);
      setSnackbarMessage(`Error deleting "${name}": ${error.message}`);
      setSnackbarSeverity('error');
    } finally {
      setConfirmDeleteOpen(false);
      setFileToDelete(null);
      setSnackbarOpen(true);
    }
  };

  // Cancel deletion
  const cancelDelete = () => {
    setConfirmDeleteOpen(false);
    setFileToDelete(null);
  };

  // Embed file into Pinecone (Netlify function: processFile -> embedPolicy)
  const handleEmbed = async (fileName, fileType, category) => {
    // Show loading indicator
    setEmbeddingFiles((prev) => ({
      ...prev,
      [fileName]: true,
    }));

    try {
      const response = await fetch('/.netlify/functions/processFile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName, fileType, category }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to process and embed file');
      }

      setSnackbarMessage(`File "${fileName}" processed and embedded successfully!`);
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error processing and embedding file:', error);
      setSnackbarMessage(`Error embedding "${fileName}": ${error.message}`);
      setSnackbarSeverity('error');
    } finally {
      // Hide loading indicator
      setEmbeddingFiles((prev) => ({
        ...prev,
        [fileName]: false,
      }));
      setSnackbarOpen(true);
    }
  };

  // Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom color="textPrimary">
        Catalogues, Policies & Procedures
      </Typography>

      {/* Main Tabs: Risk Catalogues, Governance, Model Control, Security */}
      <Tabs
        value={mainTabIndex}
        onChange={handleMainTabChange}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Risk Catalogues & Registers" />
        <Tab label="Corporate Governance" />
        <Tab label="AI Control Baseline" />
        <Tab label="Security" />
      </Tabs>

      {/* ===== RISK CATALOGUES TAB ===== */}
      {mainTabIndex === MAIN_TABS.RISK_CATALOGUES && (
        <DocumentUploadSection
          title="Risk Catalogues"
          allowedTypes={getAllowedTypes(CATEGORIES.RISK_CATALOGUES)}
          onFileChange={handleFileChange}
          onUpload={handleUpload}
          handleEmbed={handleEmbed}
          handleDelete={handleDelete}
          uploadedFiles={uploadedFiles[CATEGORIES.RISK_CATALOGUES] || []}
          embeddingFiles={embeddingFiles}
        />
      )}

      {/* ===== GOVERNANCE POLICIES TAB ===== */}
      {mainTabIndex === MAIN_TABS.GOVERNANCE_POLICIES && (
        <Box sx={{ mt: 2 }}>
          <Tabs value={govSubTabIndex} onChange={handleGovSubTabChange}>
            <Tab label="Risk Policies" />
            <Tab label="Audit Policies" />
            <Tab label="AI Governance Policies" />
          </Tabs>

          {govSubTabIndex === GOVERNANCE_SUBTABS.RISK_POLICIES && (
            <DocumentUploadSection
              title="Risk Policies"
              allowedTypes={getAllowedTypes(CATEGORIES.RISK_POLICIES)}
              onFileChange={handleFileChange}
              onUpload={handleUpload}
              handleEmbed={handleEmbed}
              handleDelete={handleDelete}
              uploadedFiles={uploadedFiles[CATEGORIES.RISK_POLICIES] || []}
              embeddingFiles={embeddingFiles}
            />
          )}
          {govSubTabIndex === GOVERNANCE_SUBTABS.AUDIT_POLICIES && (
            <DocumentUploadSection
              title="Audit Policies"
              allowedTypes={getAllowedTypes(CATEGORIES.AUDIT_POLICIES)}
              onFileChange={handleFileChange}
              onUpload={handleUpload}
              handleEmbed={handleEmbed}
              handleDelete={handleDelete}
              uploadedFiles={uploadedFiles[CATEGORIES.AUDIT_POLICIES] || []}
              embeddingFiles={embeddingFiles}
            />
          )}
          {govSubTabIndex === GOVERNANCE_SUBTABS.AI_GOVERNANCE_POLICIES && (
            <DocumentUploadSection
              title="AI Governance Policies"
              allowedTypes={getAllowedTypes(CATEGORIES.AI_GOVERNANCE_POLICIES)}
              onFileChange={handleFileChange}
              onUpload={handleUpload}
              handleEmbed={handleEmbed}
              handleDelete={handleDelete}
              uploadedFiles={uploadedFiles[CATEGORIES.AI_GOVERNANCE_POLICIES] || []}
              embeddingFiles={embeddingFiles}
            />
          )}
        </Box>
      )}

      {/* ===== AI CONTROL BASELINE TAB ===== */}
      {mainTabIndex === MAIN_TABS.AI_CONTROL_BASELINE && (
        <Box sx={{ mt: 2 }}>
          <Tabs
            value={modelCtrlSubTabIndex}
            onChange={handleModelCtrlSubTabChange}
            variant="scrollable"
          >
           <Tab label="Data Governance & Management" />
            <Tab label="Bias & Fairness" />
            <Tab label="Logging/Monitoring" />
            <Tab label="Quality/Test" />
            <Tab label="Incident Management" />
            <Tab label="Documentation/Record Keeping" />
          </Tabs>

          {modelCtrlSubTabIndex === AI_CONTROL_SUBTABS.DATA_GOVERNANCE_POLICIES && (
            <DocumentUploadSection
              title="Data Governance & Management Policies"
              allowedTypes={getAllowedTypes(CATEGORIES.DATA_GOVERNANCE_POLICIES)}
              onFileChange={handleFileChange}
              onUpload={handleUpload}
              handleEmbed={handleEmbed}
              handleDelete={handleDelete}
              uploadedFiles={uploadedFiles[CATEGORIES.DATA_GOVERNANCE_POLICIES] || []}
              embeddingFiles={embeddingFiles}
            />
          )}
          {modelCtrlSubTabIndex === AI_CONTROL_SUBTABS.BIAS_POLICIES && (
            <DocumentUploadSection
              title="Bias Policies"
              allowedTypes={getAllowedTypes(CATEGORIES.BIAS_POLICIES)}
              onFileChange={handleFileChange}
              onUpload={handleUpload}
              handleEmbed={handleEmbed}
              handleDelete={handleDelete}
              uploadedFiles={uploadedFiles[CATEGORIES.BIAS_POLICIES] || []}
              embeddingFiles={embeddingFiles}
            />
          )}
          
          {modelCtrlSubTabIndex === AI_CONTROL_SUBTABS.LOGGING_MONITORING_POLICIES && (
            <DocumentUploadSection
              title="Logging & Monitoring Policies"
              allowedTypes={getAllowedTypes(CATEGORIES.LOGGING_MONITORING_POLICIES)}
              onFileChange={handleFileChange}
              onUpload={handleUpload}
              handleEmbed={handleEmbed}
              handleDelete={handleDelete}
              uploadedFiles={uploadedFiles[CATEGORIES.LOGGING_MONITORING_POLICIES] || []}
              embeddingFiles={embeddingFiles}
            />
          )}
          {modelCtrlSubTabIndex === AI_CONTROL_SUBTABS.QUALITY_TEST_POLICIES && (
            <DocumentUploadSection
              title="Quality/Test Policies"
              allowedTypes={getAllowedTypes(CATEGORIES.QUALITY_TEST_POLICIES)}
              onFileChange={handleFileChange}
              onUpload={handleUpload}
              handleEmbed={handleEmbed}
              handleDelete={handleDelete}
              uploadedFiles={uploadedFiles[CATEGORIES.QUALITY_TEST_POLICIES] || []}
              embeddingFiles={embeddingFiles}
            />
          )}
          {modelCtrlSubTabIndex === AI_CONTROL_SUBTABS.INCIDENT_MANAGEMENT_POLICIES && (
            <DocumentUploadSection
              title="Incident Management Policies"
              allowedTypes={getAllowedTypes(CATEGORIES.INCIDENT_MANAGEMENT_POLICIES)}
              onFileChange={handleFileChange}
              onUpload={handleUpload}
              handleEmbed={handleEmbed}
              handleDelete={handleDelete}
              uploadedFiles={uploadedFiles[CATEGORIES.INCIDENT_MANAGEMENT_POLICIES] || []}
              embeddingFiles={embeddingFiles}
            />
          )}
          {modelCtrlSubTabIndex === AI_CONTROL_SUBTABS.DOCUMENTATION_RECORD_KEEPING_POLICIES && (
            <DocumentUploadSection
              title="Documentation & Record-Keeping Policies"
              allowedTypes={getAllowedTypes(CATEGORIES.DOCUMENTATION_RECORD_KEEPING_POLICIES)}
              onFileChange={handleFileChange}
              onUpload={handleUpload}
              handleEmbed={handleEmbed}
              handleDelete={handleDelete}
              uploadedFiles={uploadedFiles[CATEGORIES.DOCUMENTATION_RECORD_KEEPING_POLICIES] || []}
              embeddingFiles={embeddingFiles}
            />
          )}
        </Box>
      )}

      {/* ===== SECURITY POLICIES TAB ===== */}
      {mainTabIndex === MAIN_TABS.SECURITY_POLICIES && (
        <DocumentUploadSection
          title="Security Policies"
          allowedTypes={getAllowedTypes(CATEGORIES.SECURITY_POLICIES)}
          onFileChange={handleFileChange}
          onUpload={handleUpload}
          handleEmbed={handleEmbed}
          handleDelete={handleDelete}
          uploadedFiles={uploadedFiles[CATEGORIES.SECURITY_POLICIES] || []}
          embeddingFiles={embeddingFiles}
        />
      )}

      <Dialog
        open={confirmDeleteOpen}
        onClose={cancelDelete}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            Deleting the catalogue will remove its embeddings from the search index. This action cannot be undone.
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>


      {/* Global Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledBox>
  );
};

export default FileUpload;

// // src/fileUploader.js

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   Tabs,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Snackbar,
//   Alert,
//   CircularProgress,
//   Tooltip,
//   IconButton,
// } from '@mui/material';
// import { styled } from '@mui/system';
// import AddIcon from '@mui/icons-material/Add';
// import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
// import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// import IconWithProgress from './IconWithProgress';
// import { MAIN_TABS, GOVERNANCE_SUBTABS, MODEL_CONTROL_SUBTABS } from './utils/policyConstants';

// // Styled Components for Scandinavian Aesthetics
// const StyledBox = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(4),
//   backgroundColor: '#f9f9f9',
//   minHeight: '100vh',
// }));

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderRadius: theme.spacing(1),
//   backgroundColor: '#ffffff',
//   boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
// }));

// const StyledButton = styled(Button)(({ theme }) => ({
//   borderRadius: theme.spacing(2),
//   textTransform: 'none',
// }));

// // DocumentUploadSection Component
// const DocumentUploadSection = ({
//   title,
//   allowedTypes,
//   onFileChange,
//   onUpload,
//   uploadedFiles,
//   handleEmbed,
//   handleDelete,
//   embeddingFiles,
// }) => (
//   <StyledPaper sx={{ mb: 4 }}>
//     <Typography variant="h6" gutterBottom color="textPrimary">
//       {title}
//     </Typography>
//     <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//       <input
//         type="file"
//         accept={allowedTypes}
//         multiple
//         onChange={onFileChange}
//         style={{ display: 'none' }}
//         id={`${title}-input`}
//       />
//       <label htmlFor={`${title}-input`}>
//         <StyledButton
//           variant="outlined"
//           color="primary"
//           component="span"
//           startIcon={<AddIcon />}
//         >
//           Add
//         </StyledButton>
//       </label>
//     </Box>
//     <Typography variant="body2" color="textSecondary" gutterBottom>
//       Allowed file types: {allowedTypes.replace(/,/g, ', ')}
//     </Typography>
//     <TableContainer component={Paper} sx={{ mt: 2 }}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>File Name</TableCell>
//             <TableCell>Date Added</TableCell>
//             <TableCell align="center">Upload</TableCell>
//             <TableCell align="center">Embed</TableCell>
//             <TableCell align="center">Delete</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {Array.isArray(uploadedFiles) && uploadedFiles.length > 0 ? (
//             uploadedFiles.map((file, idx) => (
//               <TableRow key={idx}>
//                 <TableCell>{file.name}</TableCell>
//                 <TableCell>{file.date}</TableCell>

//                 <TableCell align="center">
//                   <Tooltip title="Upload File">
//                     <IconButton
//                       color="primary"
//                       onClick={() => onUpload(file)}
//                       disabled={file.uploaded || file.isUploading}
//                     >
//                       {file.isUploading ? <CircularProgress size={24} /> : <CloudUploadOutlinedIcon />}
//                     </IconButton>
//                   </Tooltip>
//                 </TableCell>

//                 <TableCell align="center">
//                   <IconWithProgress
//                     onClick={() => handleEmbed(file.name, file.fileType, file.category)}
//                     isLoading={embeddingFiles[file.name] || false}
//                     tooltip="Embed File"
//                     disabled={!file.canEmbed}
//                   />
//                 </TableCell>
//                 <TableCell align="center">
//                   <Tooltip title="Delete File">
//                     <IconButton
//                       color="secondary"
//                       onClick={() => handleDelete(file.name, file.category)}
//                     >
//                       <DeleteOutlinedIcon />
//                     </IconButton>
//                   </Tooltip>
//                 </TableCell>
//               </TableRow>
//             ))
//           ) : (
//             <TableRow>
//               <TableCell colSpan={5} align="center">
//                 No files added yet.
//               </TableCell>
//             </TableRow>
//           )}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   </StyledPaper>
// );

// // Main FileUpload Component
// const FileUpload = () => {
//   const [mainTabIndex, setMainTabIndex] = useState(MAIN_TABS.RISK_CATALOGUES);
//   const [govSubTabIndex, setGovSubTabIndex] = useState(GOVERNANCE_SUBTABS.RISK_POLICIES);
//   const [modelCtrlSubTabIndex, setModelCtrlSubTabIndex] = useState(MODEL_CONTROL_SUBTABS.BIAS_POLICIES);
//   const [uploadedFiles, setUploadedFiles] = useState({
//     [CATEGORIES.RISK_CATALOGUES]: [],
//     [CATEGORIES.RISK_POLICIES]: [],
//     [CATEGORIES.AI_GOVERNANCE_POLICIES]: [],
//     [CATEGORIES.SECURITY_POLICIES]: [],
//     [CATEGORIES.DATA_GOVERNANCE_POLICIES]: [],
//   });
//   const [embeddingFiles, setEmbeddingFiles] = useState({});
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');

//   // Fetch uploaded files on component mount
//   useEffect(() => {
//     const fetchUploadedFiles = async () => {
//       try {
//         const categories = Object.keys(uploadedFiles);
//         const allUploadedFiles = {};

//         // Create an array of promises for each category fetch
//         const fetchPromises = categories.map(category =>
//           fetch('/.netlify/functions/listCatalogues', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ category }),
//           })
//             .then(response => {
//               if (!response.ok) {
//                 throw new Error(`Failed to fetch files for category: ${category}`);
//               }
//               return response.json();
//             })
//             .then(filesData => ({
//               category,
//               filesData: filesData.map(file => ({
//                 ...file,
//                 isUploading: false,
//                 canEmbed: file.uploaded,
//                 originalFile: null,
//               })),
//             }))
//         );
// // 
//         // Wait for all fetches to complete
//         const results = await Promise.all(fetchPromises);

//         // Aggregate results
//         results.forEach(({ category, filesData }) => {
//           allUploadedFiles[category] = filesData;
//         });

//         setUploadedFiles(allUploadedFiles);
//         console.log('Uploaded files fetched successfully:', allUploadedFiles);
//       } catch (error) {
//         console.error('Error fetching uploaded files:', error);
//         setSnackbarMessage(`Error: ${error.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//       }
//     };

//     fetchUploadedFiles();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const handleMainTabChange = (event, newValue) => {
//     setMainTabIndex(newValue);
//   };

//   // For the governance sub-tabs
//   const handleGovSubTabChange = (event, newValue) => {
//     setGovSubTabIndex(newValue);
//   };

//   // For the model control sub-tabs
//   const handleModelCtrlSubTabChange = (event, newValue) => {
//     setModelCtrlSubTabIndex(newValue);
//   };

//   const getCurrentCategory = () => {
//     // If user is on "Risk Catalogues & Registers" tab:
//     if (mainTabIndex === MAIN_TABS.RISK_CATALOGUES) {
//       return 'risk-catalogues'; 
//     }
//     // If user is on "Governance Policies" tab:
//     if (mainTabIndex === MAIN_TABS.GOVERNANCE_POLICIES) {
//       switch (govSubTabIndex) {
//         case GOVERNANCE_SUBTABS.RISK_POLICIES:
//           return 'risk-policies';
//         case GOVERNANCE_SUBTABS.AUDIT_POLICIES:
//           return 'audit-policies';
//         case GOVERNANCE_SUBTABS.AI_GOVERNANCE_POLICIES:
//           return 'ai-governance-policies';
//         default:
//           return 'risk-policies'; // fallback
//       }
//     }
//     // If user is on "Model Control Baseline" tab:
//     if (mainTabIndex === MAIN_TABS.AI_CONTROL_BASELINE) {
//       switch (modelCtrlSubTabIndex) {
//         case MODEL_CONTROL_SUBTABS.BIAS_POLICIES:
//           return 'bias-policies';
//         case MODEL_CONTROL_SUBTABS.DATA_GOV_POLICIES:
//           return 'data-governance-policies';
//         case MODEL_CONTROL_SUBTABS.LOGGING_MONITORING_POLICIES:
//           return 'logging-monitoring-policies';
//         // ... etc for the rest ...
//         default:
//           return 'bias-policies';
//       }
//     }
//     // If user is on "Security Policies" tab (with or without sub-tabs):
//     if (mainTabIndex === MAIN_TABS.SECURITY_POLICIES) {
//       return 'security-policies';
//     }
//     return '';
//   };

//   const handleFileChange = (event, existingFile = null) => {
//     const selectedFiles = Array.from(event.target.files);
//     const currentCategory = getCurrentCategory();
//     const allowed = getAllowedTypes(currentCategory);

//     if (!currentCategory) {
//       console.error('No valid category selected.');
//       setSnackbarMessage('No valid category selected.');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (!allowed) {
//       console.error(`No allowed types defined for category: ${currentCategory}`);
//       setSnackbarMessage(`No allowed types defined for category: ${currentCategory}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const validFiles = selectedFiles.filter((file) => {
//       const fileType = file.name.split('.').pop().toLowerCase();
//       return allowed.includes(`.${fileType}`);
//     });

//     if (validFiles.length === 0) {
//       setSnackbarMessage('No valid files selected.');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     const newFiles = validFiles.map((file) => ({
//       name: file.name,
//       date: new Date().toLocaleString(),
//       uploaded: false,
//       fileType: file.name.split('.').pop().toLowerCase(),
//       category: currentCategory,
//       isUploading: false,
//       canEmbed: false,
//       originalFile: file,
//     }));

//     setUploadedFiles((prev) => ({
//       ...prev,
//       [currentCategory]: [...(prev[currentCategory] || []), ...newFiles],
//     }));
//   };

//   // const getCurrentCategory = () => {
//   //   if (mainTabIndex === 0) return CATEGORIES.RISK_CATALOGUES;
//   //   if (mainTabIndex === 1) {
//   //     if (policyTabIndex === 0) return CATEGORIES.AI_GOVERNANCE_POLICIES;
//   //     if (policyTabIndex === 1) return CATEGORIES.RISK_POLICIES;
//   //     if (policyTabIndex === 2) return CATEGORIES.SECURITY_POLICIES;
//   //     if (policyTabIndex === 3) return CATEGORIES.DATA_GOVERNANCE_POLICIES;
//   //   }
//   //   return '';
//   // };

//   const getAllowedTypes = (category) => {
//     const types = {
//       [CATEGORIES.RISK_CATALOGUES]: '.pdf,.txt,.csv',
//       [CATEGORIES.RISK_POLICIES]: '.pdf,.txt,.csv',
//       [CATEGORIES.SECURITY_POLICIES]: '.pdf,.txt,.csv',
//       [CATEGORIES.DATA_GOVERNANCE_POLICIES]: '.pdf,.txt,.csv',
//       [CATEGORIES.AI_GOVERNANCE_POLICIES]: '.pdf,.txt,.csv',
//     };
//     return types[category] || '';
//   };

//   const handleUpload = async (file) => {
//     const { category, name, originalFile } = file;
//     setUploadedFiles((prev) => ({
//       ...prev,
//       [category]: prev[category].map(f =>
//         f.name === name ? { ...f, isUploading: true } : f
//       ),
//     }));

//     try {
//       const formData = new FormData();
//       formData.append('file', originalFile);
//       formData.append('category', category);

//       const response = await fetch('/.netlify/functions/blobManager', {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Failed to upload file');
//       }

//       setUploadedFiles((prev) => ({
//         ...prev,
//         [category]: prev[category].map(f =>
//           f.name === name
//             ? { ...f, uploaded: true, isUploading: false, canEmbed: true }
//             : f
//         ),
//       }));

//       setSnackbarMessage(`File "${name}" uploaded successfully!`);
//       setSnackbarSeverity('success');
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       setUploadedFiles((prev) => ({
//         ...prev,
//         [category]: prev[category].map(f =>
//           f.name === name ? { ...f, isUploading: false } : f
//         ),
//       }));
//       setSnackbarMessage(`Error uploading "${name}": ${error.message}`);
//       setSnackbarSeverity('error');
//     } finally {
//       setSnackbarOpen(true);
//     }
//   };

//   const handleDelete = async (fileName, category) => {
//     try {
//       const response = await fetch('/.netlify/functions/deleteCatalogue', {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ fileName, category }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Failed to delete file');
//       }

//       setUploadedFiles((prev) => ({
//         ...prev,
//         [category]: prev[category].filter((file) => file.name !== fileName),
//       }));
//       setSnackbarMessage('File deleted successfully!');
//       setSnackbarSeverity('success');
//     } catch (error) {
//       console.error('Error deleting file:', error);
//       setSnackbarMessage(`Error: ${error.message}`);
//       setSnackbarSeverity('error');
//     } finally {
//       setSnackbarOpen(true);
//     }
//   };

//   const handleEmbed = async (fileName, fileType, category) => {
//     // Set the loading state for this specific file
//     setEmbeddingFiles((prev) => ({
//       ...prev,
//       [fileName]: true,
//     }));

//     try {
//       // Call the processFile.js function regardless of category
//       const response = await fetch('/.netlify/functions/processFile', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ fileName, fileType, category }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Failed to process and embed file');
//       }

//       setSnackbarMessage('File processed and embedded successfully!');
//       setSnackbarSeverity('success');
//     } catch (error) {
//       console.error('Error processing and embedding file:', error);
//       setSnackbarMessage(`Error: ${error.message}`);
//       setSnackbarSeverity('error');
//     } finally {
//       setEmbeddingFiles((prev) => ({
//         ...prev,
//         [fileName]: false,
//       }));
//       setSnackbarOpen(true);
//     }
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <StyledBox>
//       <Typography variant="h4" gutterBottom color="textPrimary">
//         Catalogues, Policies & Procedures
//       </Typography>

//       {/* <Tabs
//         value={mainTabIndex}
//         onChange={handleMainTabChange}
//         aria-label="main categories"
//         textColor="primary"
//         indicatorColor="primary"
//       >
//         <Tab label="Risk Catalogues & Registers" />
//         <Tab label="Policies & Procedures" />
//       </Tabs> */}

//         <Tabs 
//         value={mainTabIndex} 
//         onChange={handleMainTabChange}
//           textColor="primary"
//           indicatorColor="primary"
//           >
//                 <Tab label="Risk Catalogues & Registers" />
//                 <Tab label="Governance Policies" />
//                 <Tab label="Model Control Baseline" />
//                 <Tab label="Security Policies" />
//               </Tabs>

//               {mainTabIndex === MAIN_TABS.RISK_CATALOGUES && (
//         <DocumentUploadSection
//           title="Risk Catalogues"
//           allowedTypes=".pdf,.txt,.csv" // or dynamic
//           onFileChange={handleFileChange}
//           onUpload={handleUpload}
//           uploadedFiles={uploadedFiles['risk-catalogues'] || []}
//           // ...
//         />
//       )}

//       {/* GOVERNANCE TAB */}
//       {mainTabIndex === MAIN_TABS.GOVERNANCE_POLICIES && (
//         <Box sx={{ mt: 2 }}>
//           <Tabs value={govSubTabIndex} onChange={handleGovSubTabChange}>
//             <Tab label="Risk Policies" />
//             <Tab label="Audit Policies" />
//             <Tab label="AI Governance Policies" />
//           </Tabs>
//           {govSubTabIndex === GOVERNANCE_SUBTABS.RISK_POLICIES && (
//             <DocumentUploadSection
//               title="Risk Policies"
//               allowedTypes=".pdf,.txt,.csv"
//               onFileChange={handleFileChange}
//               onUpload={handleUpload}
//               uploadedFiles={uploadedFiles['risk-policies'] || []}
//               // ...
//             />
//           )}
//           {govSubTabIndex === GOVERNANCE_SUBTABS.AUDIT_POLICIES && (
//             <DocumentUploadSection
//               title="Audit Policies"
//               allowedTypes=".pdf,.txt,.csv"
//               onFileChange={handleFileChange}
//               onUpload={handleUpload}
//               uploadedFiles={uploadedFiles['audit-policies'] || []}
//               // ...
//             />
//           )}
//           {govSubTabIndex === GOVERNANCE_SUBTABS.AI_GOVERNANCE_POLICIES && (
//             <DocumentUploadSection
//               title="AI Governance Policies"
//               allowedTypes=".pdf,.txt,.csv"
//               onFileChange={handleFileChange}
//               onUpload={handleUpload}
//               uploadedFiles={uploadedFiles['ai-governance-policies'] || []}
//               // ...
//             />
//           )}
//         </Box>
//       )}

//       {/* MODEL CONTROL BASELINE TAB */}
//       {mainTabIndex === MAIN_TABS.AI_CONTROL_BASELINE && (
//         <Box sx={{ mt: 2 }}>
//           <Tabs value={modelCtrlSubTabIndex} onChange={handleModelCtrlSubTabChange} variant="scrollable">
//             <Tab label="Bias Policies" />
//             <Tab label="Data Governance" />
//             <Tab label="Logging/Monitoring" />
//             <Tab label="Quality/Test" />
//             <Tab label="Incident Management" />
//             <Tab label="Documentation/Record Keeping" />
//           </Tabs>

//           {modelCtrlSubTabIndex === MODEL_CONTROL_SUBTABS.BIAS_POLICIES && (
//             <DocumentUploadSection
//               title="Bias Policies"
//               allowedTypes=".pdf,.txt,.csv"
//               onFileChange={handleFileChange}
//               onUpload={handleUpload}
//               uploadedFiles={uploadedFiles['bias-policies'] || []}
//               // ...
//             />
//           )}
//           {modelCtrlSubTabIndex === MODEL_CONTROL_SUBTABS.DATA_GOV_POLICIES && (
//             <DocumentUploadSection
//               title="Data Governance Policies"
//               allowedTypes=".pdf,.txt,.csv"
//               onFileChange={handleFileChange}
//               onUpload={handleUpload}
//               uploadedFiles={uploadedFiles['data-governance-policies'] || []}
//               // ...
//             />
//           )}
//         </Box>
//       )}
//       {/* SECURITY POLICIES TAB */}
//       {mainTabIndex === MAIN_TABS.SECURITY_POLICIES && (
//         <DocumentUploadSection
//           title="Security Policies"
//           allowedTypes=".pdf,.txt,.csv"
//           onFileChange={handleFileChange}
//           onUpload={handleUpload}
//           uploadedFiles={uploadedFiles['security-policies'] || []}
//           // ...
//         />
//       )}

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity}
//           variant="filled"
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </StyledBox>
//   );
// };

// export default FileUpload;
