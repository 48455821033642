// src/redux/utils/riskFormUtils.js

import _ from 'lodash';

/**
 * Merges updated riskSources data into existing riskFormData.
 * Allows creation, updating, and deletion of categories and risk sources within them.
 * @param {Object} existingRiskFormData - The current riskFormData.
 * @param {Object} updatedRiskSources - The updated riskSources configuration.
 * @returns {Object} - The merged riskFormData with updated riskSources.
 */
export const mergeRiskSources = (existingRiskFormData, updatedRiskSources) => {
  // Clone existing riskFormData to avoid mutations
  const mergedRiskFormData = _.cloneDeep(existingRiskFormData);

  // Iterate over each category in the updatedRiskSources
  Object.keys(updatedRiskSources).forEach((category) => {
    const updatedCategory = updatedRiskSources[category];
    const existingCategory = mergedRiskFormData[category];

    if (!existingCategory) {
      // **Addition**: Add the entire new category
      mergedRiskFormData[category] = _.cloneDeep(updatedCategory);
    } else {
      // **Update**: Merge risk sources within the existing category
      const existingRiskSources = existingCategory.riskSources || [];
      const updatedRiskSourcesArray = updatedCategory.riskSources || [];

      // Create a map for existing and updated risk sources by name
      const existingRiskSourcesMap = _.keyBy(existingRiskSources, 'name');
      const updatedRiskSourcesMap = _.keyBy(updatedRiskSourcesArray, 'name');

      const mergedRiskSources = [];

      // Iterate over existing risk sources to handle updates and deletions
      Object.keys(existingRiskSourcesMap).forEach((riskSourceName) => {
        if (updatedRiskSourcesMap[riskSourceName]) {
          // **Update**: Merge existing risk source with updated risk source
          const updatedRiskSource = updatedRiskSourcesMap[riskSourceName];
          const existingRiskSource = existingRiskSourcesMap[riskSourceName];

          const mergedRiskSource = _.mergeWith(
            {},
            existingRiskSource, // Start with existing data
            updatedRiskSource,  // Apply updates
            (objValue, srcValue, key) => {
              if (key === 'value') {
                return objValue; // Preserve existing 'value'
              }
              return undefined; // Use default merging for other keys
            }
          );

          mergedRiskSources.push(mergedRiskSource);

          // Remove from updated map to identify new additions
          delete updatedRiskSourcesMap[riskSourceName];
        }
        // **Deletion**: If risk source not in updated map, omit it (i.e., delete)
      });

      // **Addition**: Add any new risk sources that were not in existing ones
      const newRiskSources = _.values(updatedRiskSourcesMap);
      if (newRiskSources.length > 0) {
        mergedRiskSources.push(...newRiskSources);
      }

      // Assign the merged risk sources back to the category
      mergedRiskFormData[category].riskSources = mergedRiskSources;
    }
  });

  // **Deletion**: Remove entire categories that no longer exist in updatedRiskSources
  Object.keys(mergedRiskFormData).forEach((category) => {
    // Skip if the key is not a category (e.g., 'riskFormData' itself)
    if (category === 'riskFormData') return;

    if (!updatedRiskSources.hasOwnProperty(category)) {
      delete mergedRiskFormData[category];
    }
  });

  return mergedRiskFormData;
};

// src/redux/utils/riskFormUtils.js

/**
 * Merges updated riskRootCauses data into existing riskFormData.
 * Updates the 'causes' field of each riskSource within each category.
 * @param {Object} existingRiskFormData - The current riskFormData.
 * @param {Object} updatedRiskRootCauses - The updated riskRootCauses configuration.
 * @returns {Object} - The merged riskFormData with updated causes in each riskSource.
 */
export const mergeRiskRootCauses = (existingRiskFormData, updatedRiskRootCauses) => {
  // Clone existing riskFormData to avoid mutations
  const mergedRiskFormData = _.cloneDeep(existingRiskFormData);

  // Iterate over each category in riskFormData
  Object.keys(mergedRiskFormData).forEach((category) => {
    const categoryData = mergedRiskFormData[category];
    const riskSourcesArray = categoryData.riskSources;

    if (!Array.isArray(riskSourcesArray)) {
      console.warn(`riskSources for category "${category}" is not an array.`);
      return;
    }

    // Iterate over each riskSource in the category
    riskSourcesArray.forEach((riskSource) => {
      if (!riskSource.causes) {
        // Initialize causes if not present
        riskSource.causes = {};
      }

      const existingCauses = riskSource.causes;
      const updatedCauses = updatedRiskRootCauses;

      // Merge the updatedRiskRootCauses into existingCauses
      Object.keys(updatedCauses).forEach((causeKey) => {
        const updatedCause = updatedCauses[causeKey];
        const existingCause = existingCauses[causeKey];

        if (!existingCause) {
          // **Addition**: Add the entire cause if it doesn't exist
          existingCauses[causeKey] = _.cloneDeep(updatedCause);
        } else {
          // **Update**: Merge existing properties within the cause
          existingCauses[causeKey] = _.mergeWith(
            {},
            existingCause,      // Start with existing cause data
            updatedCause,       // Apply updates
            (objValue, srcValue, key) => {
              if (key === 'value') {
                return objValue; // Preserve existing 'value'
              }
              return undefined; // Use default merging for other keys
            }
          );
        }
      });

      // **Removal**: Remove causes that no longer exist in updatedRiskRootCauses
      Object.keys(existingCauses).forEach((causeKey) => {
        if (!updatedRiskRootCauses.hasOwnProperty(causeKey)) {
          delete existingCauses[causeKey];
        }
      });
    });
  });

  return mergedRiskFormData;
};

// /**
//  * Merges updated riskRootCauses data into existing riskFormData.
//  * Updates the 'causes' field of each riskSource within each category.
//  * @param {Object} existingRiskFormData - The current riskFormData.
//  * @param {Object} updatedRiskRootCauses - The updated riskRootCauses configuration.
//  * @returns {Object} - The merged riskFormData with updated riskRootCauses in causes.
//  */
// export const mergeRiskRootCauses = (existingRiskFormData, updatedRiskRootCauses) => {
//   // Clone existing riskFormData to avoid mutations
//   const mergedRiskFormData = _.cloneDeep(existingRiskFormData);

//   // Ensure riskSources section exists
//   if (!mergedRiskFormData.riskSources) {
//     console.warn('riskSources section is missing in riskFormData.');
//     return mergedRiskFormData;
//   }

//   // Iterate over each category in riskSources
//   Object.keys(mergedRiskFormData.riskSources).forEach((category) => {
//     const riskSourcesArray = mergedRiskFormData.riskSources[category].riskSources;

//     if (!Array.isArray(riskSourcesArray)) {
//       console.warn(`riskSources for category "${category}" is not an array.`);
//       return;
//     }

//     // Iterate over each riskSource in the category
//     riskSourcesArray.forEach((riskSource) => {
//       if (!riskSource.causes) {
//         // Initialize causes if not present
//         riskSource.causes = {};
//       }

//       const existingCauses = riskSource.causes;
//       const updatedCauses = updatedRiskRootCauses;

//       // Merge the updatedRiskRootCauses into existingCauses
//       Object.keys(updatedCauses).forEach((causeKey) => {
//         const updatedCause = updatedCauses[causeKey];
//         const existingCause = existingCauses[causeKey];

//         if (!existingCause) {
//           // **Addition**: Add the entire cause if it doesn't exist
//           existingCauses[causeKey] = _.cloneDeep(updatedCause);
//         } else {
//           // **Update**: Merge existing properties within the cause
//           existingCauses[causeKey] = _.mergeWith(
//             {},
//             updatedCause,
//             existingCause,
//             (objValue, srcValue, key) => {
//               if (key === 'value') {
//                 return objValue; // Preserve existing 'value'
//               }
//               return undefined; // Use default merging for other keys
//             }
//           );
//         }
//       });

//       // **Removal**: Remove causes that no longer exist in updatedRiskRootCauses
//       Object.keys(existingCauses).forEach((causeKey) => {
//         if (!updatedRiskRootCauses.hasOwnProperty(causeKey)) {
//           delete existingCauses[causeKey];
//         }
//       });
//     });
//   });

//   return mergedRiskFormData;
// };