
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';

const AuthPage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignIn = () => {
    loginWithRedirect();
  };

  const handleSignUp = () => {
    loginWithRedirect({ screen_hint: 'signup' });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h4" gutterBottom>
        Welcome to RiskGPT
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please sign in or sign up to continue.
      </Typography>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSignIn} style={{ marginRight: '10px' }}>
          Sign In
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSignUp}>
          Sign Up
        </Button>
      </Box>
    </Box>
  );
};

export default AuthPage;

