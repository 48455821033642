// RiskAssessments.jsx
import React, { useEffect, useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Box,
  Badge
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  PlayArrow,
  PlayCircleOutline,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AssessmentReport from './AssessmentReport';
import { setModelId } from './redux/actions/riskActions';

const RiskAssessments = () => {
  const allModels = useSelector((state) => state.risks.models);

  console.log ('models in ra', allModels)

  const normalizeStatus = (status) => {
    switch (status?.toLowerCase()) {
      case 'not started':
        return 'Not Started';
      case 'on-going':
      case 'ongoing':
        return 'On-Going';
      case 'completed':
        return 'Completed';
      default:
        return 'Not Started';
    }
  };

  // const modelsArray = useMemo(() => {
  //   return Object.entries(allModels).map(([modelId, model]) => ({
  //     ...model,
  //     modelId,
  //     assessmentStatus: normalizeStatus(model.assessmentStatus),
  //     currentStep: model.step,
  //   }));
  // }, [allModels]);
  const modelsArray = useMemo(() => {
    const arr = Object.entries(allModels).map(([modelId, model]) => ({
      ...model,
      modelId,
      assessmentStatus: normalizeStatus(model.assessmentStatus),
      currentStep: model.step,
    }));
  
    arr.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return arr;
  }, [allModels]);
  // After constructing modelsArray
const hasNotStarted = modelsArray.some(model => model.assessmentStatus === 'Not Started');

  const [isLoading, setIsLoading] = useState(true);
  const [expandedModel, setExpandedModel] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('On-Going'); // Default to 'On-Going'

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (allModels && Object.keys(allModels).length > 0) {
      setIsLoading(false);
    }
  }, [allModels]);

  const handleExpandModel = (modelId) => {
    setExpandedModel((prev) => (prev === modelId ? null : modelId));
  };

  const filteredModels = useMemo(() => {
    // Only include 'On-Going' and 'Not Started' statuses
    return modelsArray.filter(
      (model) =>
        model.assessmentStatus === 'On-Going' ||
        model.assessmentStatus === 'Not Started'
    );
  }, [modelsArray]);

  const handleModelSelection = async (model) => {
    dispatch(setModelId(model.modelId, model.modelName));

    try {
      const response = await fetch('/.netlify/functions/manageAssessment', {
        method: 'POST',
        body: JSON.stringify({ modelId: model.modelId, operation: 'fetch' }),
        headers: { 'Content-Type': 'application/json' },
      });

      let assessmentUuid;
      if (!response.ok) {
        // Create a new assessment if one doesn't exist
        const createResponse = await fetch('/.netlify/functions/manageAssessment', {
          method: 'POST',
          body: JSON.stringify({
            modelId: model.modelId,
            operation: 'update',
            currentStep: 'contextAssessment',
            assessmentStatus: 'On-Going',
          }),
          headers: { 'Content-Type': 'application/json' },
        });

        if (!createResponse.ok) {
          throw new Error('Failed to create a new assessment.');
        }

        const createResult = await createResponse.json();
        assessmentUuid = createResult.assessmentUuid;
      } else {
        const result = await response.json();
        assessmentUuid = result.assessmentUuid;
      }

      navigate(
        `/risk-form/${model.modelId}/${encodeURIComponent(model.modelName)}/${assessmentUuid}`
      );
    } catch (error) {
      console.error('Error handling model selection:', error);
    }
  };

  const toTitleCase = (str) => {
    if (!str) return '';
    return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  const handleTabChange = (event, newValue) => {
    setSelectedStatus(newValue);
  };

  const displayModels = useMemo(() => {
    return filteredModels.filter(
      (model) => model.assessmentStatus === selectedStatus
    );
  }, [filteredModels, selectedStatus]);

  return (
    <Paper elevation={2} style={{ padding: '20px', marginBottom: '16px' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Risk Assessments
        </Typography>
      </Box>

      {isLoading ? (
        <CircularProgress style={{ margin: '20px' }} />
      ) : (
        <>
          {/* Status Tabs */}
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '20px' }}>
            <Tabs
              value={selectedStatus}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="assessment status tabs"
            >
              <Tab label="On-Going" value="On-Going" />
              <Tab label="Not Started" value="Not Started" />
            </Tabs>
          </Box> */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '20px' }}>
  <Tabs
    value={selectedStatus}
    onChange={handleTabChange}
    indicatorColor="primary"
    textColor="primary"
    aria-label="assessment status tabs"
  >
    <Tab label="On-Going" value="On-Going" />
    <Tab
  value="Not Started"
  label={
    
    <Badge
      variant="dot"
      color="primary"
      overlap="rectangular"
      invisible={!hasNotStarted}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Box display="inline-flex" alignItems="center" position="relative">
        Not Started
      </Box>
    </Badge>
  }
/>
          {/* )}
        </Box> */}
      {/* }
    /> */}
  </Tabs>
</Box>

          {/* Models Table */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Model Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Assessment Status
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Current Step
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayModels.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No assessments found for "{selectedStatus}" status.
                    </TableCell>
                  </TableRow>
                ) : (
                  displayModels.map((model) => (
                    <React.Fragment key={model.modelId}>
                      <TableRow>
                        <TableCell>{model.modelName}</TableCell>
                        <TableCell>{model.assessmentStatus}</TableCell>
                        <TableCell>
                          {model.assessmentStatus === 'On-Going'
                            ? toTitleCase(model.step || 'N/A')
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {model.assessmentStatus !== 'Completed' ? (
                            <Tooltip
                              title={
                                model.assessmentStatus === 'On-Going'
                                  ? 'Resume Assessment'
                                  : 'Start Assessment'
                              }
                            >
                              <IconButton onClick={() => handleModelSelection(model)}>
                                {model.assessmentStatus === 'On-Going' ? (
                                  <PlayArrow />
                                ) : (
                                  <PlayCircleOutline />
                                )}
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="View Assessment Report">
                              <IconButton
                                onClick={() => handleExpandModel(model.modelId)}
                              >
                                {expandedModel === model.modelId ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                      {expandedModel === model.modelId &&
                        model.assessmentStatus === 'Completed' && (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <AssessmentReport
                                modelId={model.modelId}
                                assessmentUuid={model.assessmentUuid}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
};

export default RiskAssessments;
