// SingleSecurityRiskReport.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Select,
  IconButton,
  Divider,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import useSaveOperation from './useSaveOperation';
// Import existing actions
import {
  saveSecurityRisk,
  discardSecurityRisk,
} from './redux/actions/securityActions';
import SecurityControlsList from './SecurityControlsList'; // Component for displaying controls
import { generateSecurityControls } from './redux/actions/securityActions';
import IconWithProgress from './IconWithProgress';

const SingleSecurityRiskReport = ({
    risk,
    modelId,
    assessmentUuid,
    onClose,
  }) => {
    const dispatch = useDispatch();
    const { handleSaveOperation } = useSaveOperation();
    const [isGeneratingControls, setIsGeneratingControls] = useState(false);
    // State variables
    const [editedRiskName, setEditedRiskName] = useState(risk.risk_name || '');
    const [isEditingThreatLevel, setIsEditingThreatLevel] = useState(false);
    const [selectedThreatLevel, setSelectedThreatLevel] = useState(risk.threat_level || '');
    const [newJustification, setNewJustification] = useState(risk.justification || '');

    // Fetch updated risk from Redux store
    const updatedRisk = useSelector((state) => {
      const securityRisks = state.security.models[modelId]?.securityRisks || [];
      return securityRisks.find((r) => r.risk_id === risk.risk_id) || risk;
    });

    const controls = useSelector((state) =>
      state.security.models[modelId]?.securityControls?.[risk.risk_id] || []
    );
  
    useEffect(() => {
      // Update local state when risk is updated in Redux
      setEditedRiskName(updatedRisk.risk_name || '');
      setSelectedThreatLevel(updatedRisk.threat_level || '');
      setNewJustification(updatedRisk.justification || '');
    }, [updatedRisk]);
  
    // Handlers
    const handleSaveRisk = async () => {
      // Save the updated risk
      handleSaveOperation(async () => {
        const updatedRiskData = {
          ...updatedRisk,
          risk_name: editedRiskName,
          // Only include threat_level and justification if they exist
          ...(selectedThreatLevel && { threat_level: selectedThreatLevel }),
          ...(newJustification && { justification: newJustification }),
        };
        await dispatch(saveSecurityRisk(modelId, assessmentUuid, updatedRiskData));
      });
    };
  
    const handleDiscardRisk = () => {
      // Discard the risk
      dispatch(discardSecurityRisk(modelId, risk.risk_id));
      onClose(); // Close the editor
    };
  
    const handleUpdateThreatLevel = (e) => {
      setSelectedThreatLevel(e.target.value);
    };
  
    const handleSaveUpdatedThreatLevel = async () => {
      // Save updated threat level and justification
      await handleSaveRisk();
      setIsEditingThreatLevel(false);
    };
  
    const handleEditThreatLevel = () => {
      setIsEditingThreatLevel(true);
    };
  
    const handleCancelUpdatedThreatLevel = () => {
      setSelectedThreatLevel(updatedRisk.threat_level || '');
      setNewJustification(updatedRisk.justification || '');
      setIsEditingThreatLevel(false);
    };

    const handleGenerateControls = async () => {
      setIsGeneratingControls(true);
      try {
        await dispatch(generateSecurityControls(modelId, [risk]));
        // Controls will be updated in the Redux store and re-fetched via useSelector
      } catch (error) {
        console.error('Error generating controls:', error);
      } finally {
        setIsGeneratingControls(false);
      }
    };
  
    const capitalizeFirstLetter = (string) => {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
  
    // Component rendering
    return (
        <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
        {/* Header with risk name and actions */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              label="Risk Name"
              value={editedRiskName}
              onChange={(e) => setEditedRiskName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} sx={{ textAlign: 'right' }}>
            <IconButton onClick={handleSaveRisk} color="primary">
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleDiscardRisk} color="secondary">
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
  
        <Divider sx={{ my: 2 }} />
  
        {/* Threat Level Section */}
        <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Threat Level</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {updatedRisk.threat_level ? (
              <>
                {/* Display Threat Level and Justification */}
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="body1">
                      {capitalizeFirstLetter(updatedRisk.threat_level)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleEditThreatLevel} color="primary" aria-label="Edit Threat Level">
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {updatedRisk.justification && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    <strong>Justification:</strong> {updatedRisk.justification}
                  </Typography>
                )}
              </>
            ) : (
              <>
                {/* Display 'None' and Option to Set Threat Level */}
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="body1">None</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleEditThreatLevel} color="primary" aria-label="Set Threat Level">
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
  
        {/* Threat Level Editing Section */}
        {isEditingThreatLevel && (
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="update-threat-level-select-label">Select Threat Level</InputLabel>
                <Select
                  labelId="update-threat-level-select-label"
                  value={selectedThreatLevel}
                  label="Select Threat Level"
                  onChange={handleUpdateThreatLevel}
                >
                  <MenuItem value="critical">Critical</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="significant">Significant</MenuItem>
                  <MenuItem value="moderate">Moderate</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="negligible">Negligible</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Justification"
                value={newJustification}
                onChange={(e) => setNewJustification(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleSaveUpdatedThreatLevel} sx={{ mr: 1 }}>
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="secondary" onClick={handleCancelUpdatedThreatLevel}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
  
        <Divider sx={{ my: 2 }} />
  
        {/* Controls Section */}
        {/* Controls Section */}
<Typography variant="h6">Controls</Typography>
{controls.length > 0 ? (
  <SecurityControlsList
    controls={controls}
    riskId={risk.risk_id}
    modelId={modelId}
    assessmentUuid={assessmentUuid}
    handleSaveOperation={handleSaveOperation}
  />
) : (
  <Box sx={{ mt: 2 }}>
    <Typography variant="body1">No controls available for this risk.</Typography>
    <Button
      variant="contained"
      color="primary"
      onClick={handleGenerateControls}
      disabled={isGeneratingControls}
      startIcon={
        <IconWithProgress
          isLoading={isGeneratingControls}
          disabled={isGeneratingControls}
        />
      }
      sx={{ mt: 1 }}
    >
      {isGeneratingControls ? 'Generating...' : 'Generate Controls'}
    </Button>
  </Box>
)}
      </Paper>
    );
  };
  
  export default SingleSecurityRiskReport;