// src/redux/actions/complianceActions.js

// Baseline Control Action Types
export const FETCH_BASELINE_CONTROLS_START = 'FETCH_BASELINE_CONTROLS_START';
export const FETCH_BASELINE_CONTROLS_SUCCESS = 'FETCH_BASELINE_CONTROLS_SUCCESS';
export const FETCH_BASELINE_CONTROLS_FAILURE = 'FETCH_BASELINE_CONTROLS_FAILURE';

// Action Creators
export const fetchBaselineControlsStart = () => ({
  type: FETCH_BASELINE_CONTROLS_START,
});

export const fetchBaselineControlsSuccess = (baselineControls) => ({
  type: FETCH_BASELINE_CONTROLS_SUCCESS,
  payload: baselineControls,
});

export const fetchBaselineControlsFailure = (error) => ({
  type: FETCH_BASELINE_CONTROLS_FAILURE,
  payload: error,
});

// Thunk to Fetch Baseline Controls
export const fetchBaselineControls = (modelId,assessmentUuid, risks, narratives) => async (dispatch) => {
  dispatch(fetchBaselineControlsStart());
  try {
    const body = JSON.stringify({ modelId,assessmentUuid, risks, narratives });
    const response = await fetch('/.netlify/functions/generateControlBaseline', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    if (!response.ok) throw new Error(`Failed to generate baseline controls: ${response.statusText}`);

    const data = await response.json();
    dispatch(fetchBaselineControlsSuccess(data.baselineControls));
  } catch (error) {
    dispatch(fetchBaselineControlsFailure(error.message));
  }
};