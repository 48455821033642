
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllModels } from './redux/actions/riskActions';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Box, Avatar, Button, Menu, MenuItem, Tooltip, Fade, Badge } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import Settings from '@mui/icons-material/Settings';
import RiskCardForm from './RiskCardForm.js';
import RiskCatalogue from './RiskCatalogue.js';
import RiskEvaluation from './RiskEvaluation.js';
import ResidualRisk from './ResidualRisk.js';
import ModelInventory from './ModelInventory.js';
import ModelDetailsModal from './ModelDetailsModal.js';
import AssessmentCompleted from './AssessmentCompleted.js';
import RiskFormManager from './riskFormManager.js';
import RiskThemes from './RiskThemes.js';
import ContextAssessment from './contextAssessment.js';
import RiskLandscape from './RiskLandscape';
import RisksBySource from './risksBySource.js';
import RisksByModel from './RisksByModel';
import RiskAssessments from './RiskAssessments'; 
import Logo from './images/logobw.svg';
import { useAuth0 } from '@auth0/auth0-react';
import IconWithProgress from './IconWithProgress.js';
import FileUpload from './fileUploader.js';
import {jwtDecode} from 'jwt-decode';
import AdminConfigHome from './AdminConfigHome';
import AssessmentReport from './AssessmentReport'; 
import './App.css'

const TopAppBar = styled(AppBar)({
  background: '#fbfbfe',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
});



const MenuButton = styled(Button)({
  fontSize: '1rem',
  color: '#42424a', // Darker color for visibility
  fontWeight: 'bold',
  marginLeft: '30px', // Add some space between logo and menu items
  textTransform: 'none',
});

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LoadingContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column', // Ensures elements are stacked vertically
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: '#f7f9fc',
  animation: `${fadeIn} 1s ease-in-out`,
});

const LoadingText = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: '500',
  marginLeft: '15px',
  color: '#42424a',
  animation: `${fadeIn} 2s ease-in-out`,
});

function App() {
  const { user, isAuthenticated, isLoading, logout,loginWithRedirect,  getAccessTokenSilently} = useAuth0();
 
  const modelsLoading = useSelector(state => state.risks.modelsLoading);

  const [menuState, setMenuState] = useState({
    settings: null,
    user: null,
    riskReports: null,
  });

  const [userRoles, setUserRoles] = useState([]);
  const [fadeIn, setFadeIn] = useState(false);
  const combinedLoading = isLoading || modelsLoading;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allModels = useSelector((state) => state.risks.models);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'auth.riskgpt.io', 
            scope: 'read:models write:models delete:models' 
          }
           
        });
        
        console.log ('user token obtained in inventory', token)

        dispatch(fetchAllModels(token));
      } catch (error) {
        console.error('Error fetching access token:', error);
        // Handle the error appropriately (e.g., show a message to the user)
      }
    };

    fetchData();
  }, [dispatch, getAccessTokenSilently]);
  

  const handleMenuClick = (event, menuKey) => {
    setMenuState({
      ...menuState,
      [menuKey]: event.currentTarget,
    });
  };

  const handleMenuClose = (menuKey) => {
    setMenuState({
      ...menuState,
      [menuKey]: null,
    });
  };

  const hasNotStarted = Object.values(allModels).some(
    (model) => (model.assessmentStatus || '').toLowerCase() === 'not started'
  );

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'auth.riskgpt.io', 
            scope: 'openid profile email read:models write:models delete:models' 
          }
        });

        const decoded = jwtDecode(token);
        const roles = decoded['https://riskgpt.io/claims/roles'] || [];
        setUserRoles(roles);
        console.log('User roles:', roles);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    if (isAuthenticated) {
      fetchRoles();
    }
  }, [isAuthenticated, getAccessTokenSilently]);




  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      console.log('User is not authenticated. Initiating login.');
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);
  
  useEffect(() => {
    if (!combinedLoading) {
      setFadeIn(true);
    }
  }, [combinedLoading]);
  
  
  if (combinedLoading) {
    return (
      <LoadingContainer>
        <IconWithProgress isLoading={true} />
        <LoadingText>Loading RiskGPT...</LoadingText>
      </LoadingContainer>
    );
  }

  return (
    <div className="App"> 
    <>

       <TopAppBar position="sticky" sx={{ height: '60px' }}>
        <Toolbar
          style={{
            minHeight: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" alignItems="center">
          <img 
            src={Logo} 
            alt="logo" 
            style={{ 
              width: 'clamp(80px, 12vw, 100px)', 
              height: 'auto', 
              maxHeight: '40px',
              marginRight: '10px' 
            }} 
          />

             <MenuButton onClick={() => navigate('/')}>
              Risk Landscape
            </MenuButton>
            <MenuButton onClick={() => navigate('/risk-assessments')}>
              <Badge
                variant="dot"
                color="primary"
                invisible={!hasNotStarted}
                sx={{
                  '& .MuiBadge-badge': {
                    top: 0,
                    right: -6,
                    transform: 'none'
                  }
                }}
              >
                Risk Assessments
              </Badge>
            </MenuButton>
            <MenuButton onClick={(event) => handleMenuClick(event, 'riskReports')}>
              Risk Reports
            </MenuButton>
            <MenuButton onClick={() => navigate('/model-inventory')}>
              Model Inventory
            </MenuButton>
            <Menu
              anchorEl={menuState.riskReports}
              open={Boolean(menuState.riskReports)}
              onClose={() => handleMenuClose('riskReports')}
            >
            
                <MenuItem onClick={() => { navigate('/risks-by-model'); handleMenuClose('riskReports'); }}>
                Model Risk Reports
              </MenuItem>
              <MenuItem onClick={() => { navigate('/risks-by-source'); handleMenuClose('riskReports'); }}>
                Risks by Source
              </MenuItem>

            </Menu>
            
            <MenuButton onClick={(event) => handleMenuClick(event, 'settings')}>
              Settings
            </MenuButton>
            <Menu
              anchorEl={menuState.settings}
              open={Boolean(menuState.settings)}
              onClose={() => handleMenuClose('settings')}
            >
              <MenuItem
                onClick={() => {
                  navigate('/admin/config');
                  handleMenuClose('settings');
                }}
              >
                Assessment Templates
              </MenuItem>
              <MenuItem onClick={() => { navigate('/risk-themes'); handleMenuClose('settings'); }}>
                Risk Themes
              </MenuItem>
              <MenuItem onClick={() => { navigate('/file-upload'); handleMenuClose('settings'); }}>
                Risk & Control Catalogues
              </MenuItem>
            </Menu>
          </Box>

          {isAuthenticated && user && (
            <Box display="flex" alignItems="center">
               <IconButton onClick={() => navigate('/risk-form-manager')}>
               <Settings />
               </IconButton>
          
               <Tooltip title={`Role: ${userRoles.join(', ') || 'User'}`}>
                <IconButton onClick={(event) => handleMenuClick(event, 'user')}>
                  <Avatar
                    src={user.picture}
                    alt={user.name || 'User'}
                    sx={{ width: 40, height: 40 }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={menuState.user}
                open={Boolean(menuState.user)}
                onClose={() => handleMenuClose('user')}
              >
                <MenuItem disabled>Hello, {user?.name || 'User'}</MenuItem>
                <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </TopAppBar>
    <Fade in={fadeIn} timeout={800}>
      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#FAFAFA' }}>
        <Routes>
        <Route path="/" element={<RiskLandscape />} />
        <Route path="/risk-assessments" element={<RiskAssessments />} />
        <Route path="/model-inventory" element={<ModelInventory />} />
        <Route path="/model-details/:modelId" element={<ModelDetailsModal />} />
          <Route path="/risk-reports" element={<RiskLandscape />} />
          <Route path="/risks-by-source" element={<RisksBySource />} />
          <Route path="/risks-by-model" element={<RisksByModel />} />
          <Route path="/risk-assessments" element={<RiskAssessments />} />
          <Route path="/risk-themes" element={<RiskThemes />} />
          <Route path="/risk-form-manager" element={<RiskFormManager />} />
          <Route path="/context-evaluation" element={<ContextAssessment />} />
          <Route path="/risk-form/:modelId/:modelName/:assessmentUuid" element={<RiskCardForm/>} />
          <Route path="/risk-catalogue/:modelId/:modelName" element={<RiskCatalogue />} />
          <Route path="/risk-evaluation" element={<RiskEvaluation />} />
          <Route path="/residual-risk/:modelId/:assessmentUuid" element={<ResidualRisk />} />
          <Route path="/assessment-completed/:modelId/:modelName/:assessmentUuid" element={<AssessmentCompleted />} />
          <Route path="/assessment-report/:modelId/:assessmentUuid" element={<AssessmentReport />} />
          <Route path="/file-upload" element={<FileUpload />} />
          <Route path="/admin/config" element={<AdminConfigHome />} />
          <Route path="*" element={<div>Not Found</div>} />
        </Routes>
     </Box>
    </Fade>
    </>
    </div>
  );
}

export default App;
