// src/Compliance.js

import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
} from '@mui/material';

// Import necessary MUI icons
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIcon from '@mui/icons-material/Edit';

import { fetchBaselineControls } from './redux/actions/complianceActions'; // Ensure correct path
import IconWithProgress from './IconWithProgress';

/**
 * Aggregates all saved risks from the provided riskFormData.
 *
 * @param {Object} riskFormData - The comprehensive risk form data containing multiple sections.
 * @returns {Array} - An array of saved risk objects with their respective section and risk source.
 */
function aggregateRisks(riskFormData) {
  const aggregatedRisks = [];

  // Iterate over each section in riskFormData
  Object.entries(riskFormData).forEach(([sectionName, sectionData]) => {
    const { riskSources, sectionRisks } = sectionData;

    // Ensure both riskSources and sectionRisks exist
    if (riskSources && sectionRisks) {
      riskSources.forEach((riskSource) => {
        const { name: riskSourceName } = riskSource;

        // Retrieve risks associated with the current risk source
        const risks = sectionRisks[riskSourceName] || [];

        risks.forEach((risk) => {
          const {
            riskId,
            riskName,
            category,
            type,
            sourceFile,
            causes,
            status,
            owner,
          } = risk;

          // **Filter out risks that are not "Saved"**
          if (status !== 'Saved') return;

          // Construct the aggregated risk object
          const aggregatedRisk = {
            riskId: riskId || null, // Handle cases where riskId might be undefined
            riskName,
            category,
            type,
            sourceFile,
            causes,
            status,
            owner: owner || null, // Handle cases where owner might be undefined
            section: sectionName,
            riskSource: riskSourceName,
          };

          aggregatedRisks.push(aggregatedRisk);
        });
      });
    }
  });

  return aggregatedRisks;
}

/**
 * Converts a string to Title Case.
 *
 * @param {string} str - The string to convert.
 * @returns {string} - The Title Cased string.
 */
const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 * Compliance Component
 *
 * @param {Object} props
 * @param {string} props.modelId - The ID of the model.
 * @param {string} props.assessmentUuid - The UUID of the assessment.
 * @param {Object} props.riskFormData - The entire risk form data containing multiple sections.
 * @param {Object} props.narratives - The narratives associated with each section.
 */
function Compliance({ modelId, assessmentUuid, riskFormData, narratives }) {
  const dispatch = useDispatch();
  const { baselineControls, loading, error } = useSelector((state) => state.compliance);

  // Snackbar State
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Aggregated Risks
  const aggregatedRisks = useMemo(() => aggregateRisks(riskFormData), [riskFormData]);

  /**
   * Handler to generate baseline controls.
   * This function aggregates risks and dispatches the action to fetch baseline controls.
   */
  const handleGenerateControls = async () => {
    if (modelId && aggregatedRisks.length > 0 && narratives) {
      try {
        await dispatch(fetchBaselineControls(modelId, assessmentUuid, aggregatedRisks, narratives));
        setSnackbarMessage('Baseline controls fetched successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (err) {
        setSnackbarMessage('Failed to fetch baseline controls.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('Insufficient data to generate baseline controls.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  /**
   * Groups controls by their associated risk.
   *
   * @returns {Object} - An object where each key is a risk ID and the value is an array of controls.
   */
  const groupedByRisk = useMemo(() => {
    const groups = {};
    baselineControls.forEach((control) => {
      const riskId = control.riskId;
      if (!groups[riskId]) {
        groups[riskId] = [];
      }
      groups[riskId].push(control);
    });
    return groups;
  }, [baselineControls]);

  /**
   * Finds the risk name by its ID.
   *
   * @param {string} riskId - The ID of the risk.
   * @returns {string} - The name of the risk.
   */
//   const findRiskName = (riskId) => {
//     const risk = aggregatedRisks.find((r) => r.riskId === riskId);
//     return risk ? risk.riskName : 'Unknown Risk';
//   };


  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Header Section */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6">Compliance Assessment</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        Risk based compliance review of policies and procedures.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleGenerateControls}
            disabled={loading}
            startIcon={<IconWithProgress isLoading={loading} size={20} />}
          >
            {loading ? 'Refreshing...' : 'Refresh Baseline Controls'}
          </Button>
        </Box>
      </Paper>

      {/* Grouped Controls by Risk */}
      {aggregatedRisks.map((risk) => (
        <Box key={risk.riskId} sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Risk: {risk.riskName}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Control Name</strong></TableCell>
                  <TableCell><strong>Policy Domain</strong></TableCell>
                  <TableCell><strong>Actions</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedByRisk[risk.riskId] && groupedByRisk[risk.riskId].length > 0 ? (
                  groupedByRisk[risk.riskId].map((control, index) => (
                    <TableRow key={index}>
                      <TableCell>{control.control}</TableCell>
                      <TableCell>{toTitleCase(control.namespace)}</TableCell>
                      <TableCell>
                        {/* Action Icons with Tooltips */}
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Tooltip title="Add to Plan" arrow>
                            <IconButton
                              onClick={() => {
                                // Placeholder for Add to Plan action
                                console.log('Add to Plan clicked for control:', control.controlId);
                              }}
                              size="small"
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Add to Action Plan" arrow>
                            <IconButton
                              onClick={() => {
                                // Placeholder for Add to Action Plan action
                                console.log('Add to Action Plan clicked for control:', control.controlId);
                              }}
                              size="small"
                            >
                              <PlaylistAddIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit Control" arrow>
                            <IconButton
                              onClick={() => {
                                // Placeholder for Edit action
                                console.log('Edit clicked for control:', control.controlId);
                              }}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <Typography>No controls available for this risk.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}

      {/* If no baseline controls are present */}
      {baselineControls.length === 0 && !loading && !error && (
        <Box sx={{ mt: 2 }}>
          <Typography>No baseline controls generated yet.</Typography>
        </Box>
      )}

      {/* If an error occurs */}
      {error && (
        <Box sx={{ mt: 2 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Compliance;
// // src/Compliance.js

// import React, { useEffect, useState, useMemo } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import {
//   Box,
//   Paper,
//   Typography,
//   Button,
//   Table,
//   TableContainer,
//   TableHead,
//   TableBody,
//   TableRow,
//   TableCell,
//   //CircularProgress,
//   Snackbar,
//   Alert,
// } from '@mui/material';

// import { fetchBaselineControls } from './redux/actions/complianceActions'; // Ensure correct path
// import IconWithProgress from './IconWithProgress';
// /**
//  * Aggregates all saved risks from the provided riskFormData.
//  *
//  * @param {Object} riskFormData - The comprehensive risk form data containing multiple sections.
//  * @returns {Array} - An array of saved risk objects with their respective section and risk source.
//  */
// function aggregateRisks(riskFormData) {
//   const aggregatedRisks = [];

//   // Iterate over each section in riskFormData
//   Object.entries(riskFormData).forEach(([sectionName, sectionData]) => {
//     const { riskSources, sectionRisks } = sectionData;

//     // Ensure both riskSources and sectionRisks exist
//     if (riskSources && sectionRisks) {
//       riskSources.forEach((riskSource) => {
//         const { name: riskSourceName } = riskSource;

//         // Retrieve risks associated with the current risk source
//         const risks = sectionRisks[riskSourceName] || [];

//         risks.forEach((risk) => {
//           const {
//             riskId,
//             riskName,
//             category,
//             type,
//             sourceFile,
//             causes,
//             status,
//             owner,
//           } = risk;

//           // **Filter out risks that are not "Saved"**
//           if (status !== 'Saved') return;

//           // Construct the aggregated risk object
//           const aggregatedRisk = {
//             riskId: riskId || null, // Handle cases where riskId might be undefined
//             riskName,
//             category,
//             type,
//             sourceFile,
//             causes,
//             status,
//             owner: owner || null, // Handle cases where owner might be undefined
//             section: sectionName,
//             riskSource: riskSourceName,
//           };

//           aggregatedRisks.push(aggregatedRisk);
//         });
//       });
//     }
//   });

//   return aggregatedRisks;
// }

// /**
//  * Compliance Component
//  *
//  * @param {Object} props
//  * @param {string} props.modelId - The ID of the model.
//  * @param {string} props.assessmentUuid - The UUID of the assessment.
//  * @param {Object} props.riskFormData - The entire risk form data containing multiple sections.
//  * @param {Object} props.narratives - The narratives associated with each section.
//  */
// function Compliance({ modelId, assessmentUuid, riskFormData, narratives }) {
//   const dispatch = useDispatch();
//   const { baselineControls, loading, error } = useSelector((state) => state.compliance);

//   // Snackbar State
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');

//   // Aggregated Risks
//   const [aggregatedRisks, setAggregatedRisks] = useState([]);

//   useEffect(() => {
//     // Aggregate risks whenever riskFormData changes
//     const allRisks = aggregateRisks(riskFormData);
//     setAggregatedRisks(allRisks);
//   }, [riskFormData]);



//   // Effect to handle snackbar messages based on baselineControls and error
//   useMemo(() => {
//     if (baselineControls.length > 0) {
//       setSnackbarMessage('Baseline controls fetched successfully!');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     }
//     if (error) {
//       setSnackbarMessage(error);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   }, [baselineControls, error]);

//   const handleSnackbarClose = (_, reason) => {
//     if (reason === 'clickaway') return;
//     setSnackbarOpen(false);
//   };

//   const handleGenerateBaselineControls = () => {
//     if (aggregatedRisks.length > 0) {
//       dispatch(fetchBaselineControls(modelId,assessmentUuid, aggregatedRisks, narratives));
//     } else {
//       setSnackbarMessage('Insufficient data to generate baseline controls.');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//     }
//   };

//   // **Grouping Controls by Policy Domain**
//   const groupedControls = useMemo(() => {
//     const groups = {};
//     baselineControls.forEach((control) => {
//       const policyDomain = control.namespace || 'General';
//       if (!groups[policyDomain]) {
//         groups[policyDomain] = [];
//       }
//       groups[policyDomain].push(control);
//     });
//     return groups;
//   }, [baselineControls]);

//   return (
//     <Box sx={{ p: 2 }}>
//       <Paper sx={{ p: 3, mb: 4 }}>
//         <Typography variant="h6">Compliance Assessment</Typography>
//         <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
//           Ensure risks are handled in compliance with policies and procedures.
//         </Typography>
//         <Box sx={{ mt: 3 }}>
//           <Button
//             variant="outlined"
//             size="small"
//             onClick={handleGenerateBaselineControls}
//             disabled={loading}
//             startIcon={ <IconWithProgress 
//                 isLoading={loading} size={20} />}
//           >
//             {loading ? 'Refreshing...' : 'Refresh Baseline Controls'}
//           </Button>
//         </Box>
//       </Paper>

//       {/* Grouped Controls */}
//       {Object.keys(groupedControls).map((policyDomain) => (
//         <Box key={policyDomain} sx={{ mb: 4 }}>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Policy Domain: {policyDomain}
//           </Typography>
//           <TableContainer component={Paper}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell><strong>Control Name</strong></TableCell>
//                   <TableCell><strong>Relevant Risk</strong></TableCell>
//                   <TableCell><strong>Actions</strong></TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {groupedControls[policyDomain].map((control, index) => (
//                   <TableRow key={index}>
//                     <TableCell>{control.control}</TableCell>
//                     <TableCell>{control.riskName}</TableCell>
//                     <TableCell>
//                       {/* Action Placeholders */}
//                       <Box sx={{ display: 'flex', gap: 1 }}>
//                         <Button variant="outlined" size="small">
//                           Save
//                         </Button>
//                         <Button variant="outlined" size="small">
//                           Edit
//                         </Button>
//                         <Button variant="outlined" size="small">
//                           Add to Baseline
//                         </Button>
//                       </Box>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//                 {groupedControls[policyDomain].length === 0 && (
//                   <TableRow>
//                     <TableCell colSpan={3} align="center">
//                       <Typography>No controls available in this policy domain.</Typography>
//                     </TableCell>
//                   </TableRow>
//                 )}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Box>
//       ))}

//       {/* If no baseline controls are present */}
//       {baselineControls.length === 0 && !loading && !error && (
//         <Box sx={{ mt: 2 }}>
//           <Typography>No baseline controls generated yet.</Typography>
//         </Box>
//       )}

//       {/* If an error occurs */}
//       {error && (
//         <Box sx={{ mt: 2 }}>
//           <Typography color="error">{error}</Typography>
//         </Box>
//       )}

//       {/* Snackbar */}
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity}
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// }

// export default Compliance;


// // import React, { useEffect, useState } from 'react';
// // import { useSelector, useDispatch } from 'react-redux';
// // import {
// //   Box,
// //   Paper,
// //   Typography,
// //   Button,
// //   Table,
// //   TableContainer,
// //   TableHead,
// //   TableBody,
// //   TableRow,
// //   TableCell,
// //   CircularProgress,
// //   Snackbar,
// //   Alert,
// // } from '@mui/material';

// // import { fetchBaselineControls } from './redux/actions/complianceActions'; // Ensure correct path

// // /**
// //  * Aggregates all risks from the provided riskFormData.
// //  *
// //  * @param {Object} riskFormData - The comprehensive risk form data containing multiple sections.
// //  * @returns {Array} - An array of all risk objects with their respective section and risk source.
// //  */
// // function aggregateRisks(riskFormData) {
// //   const aggregatedRisks = [];

// //   // Iterate over each section in riskFormData
// //   Object.entries(riskFormData).forEach(([sectionName, sectionData]) => {
// //     const { riskSources, sectionRisks } = sectionData;

// //     // Ensure both riskSources and sectionRisks exist
// //     if (riskSources && sectionRisks) {
// //       riskSources.forEach((riskSource) => {
// //         const { name: riskSourceName } = riskSource;

// //         // Retrieve risks associated with the current risk source
// //         const risks = sectionRisks[riskSourceName] || [];

// //         risks.forEach((risk) => {
// //           const {
// //             riskId,
// //             riskName,
// //             category,
// //             type,
// //             sourceFile,
// //             causes,
// //             status,
// //             owner,
// //           } = risk;
// //           if (status !== 'Saved') return;

// //           // Construct the aggregated risk object
// //           const aggregatedRisk = {
// //             riskId: riskId || null, // Handle cases where riskId might be undefined
// //             riskName,
// //             category,
// //             type,
// //             sourceFile,
// //             causes,
// //             status,
// //             owner: owner || null, // Handle cases where owner might be undefined
// //             section: sectionName,
// //             riskSource: riskSourceName,
// //           };

// //           aggregatedRisks.push(aggregatedRisk);
// //         });
// //       });
// //     }
// //   });

// //   return aggregatedRisks;
// // }

// // /**
// //  * Compliance Component
// //  *
// //  * @param {Object} props
// //  * @param {string} props.modelId - The ID of the model.
// //  * @param {string} props.assessmentUuid - The UUID of the assessment.
// //  * @param {Object} props.riskFormData - The entire risk form data containing multiple sections.
// //  * @param {Object} props.narratives - The narratives associated with each section.
// //  */
// // function Compliance({ modelId, assessmentUuid, riskFormData, narratives }) {
// //   const dispatch = useDispatch();
// //   const { baselineControls, loading, error } = useSelector((state) => state.compliance);

// //   // Snackbar State
// //   const [snackbarOpen, setSnackbarOpen] = useState(false);
// //   const [snackbarMessage, setSnackbarMessage] = useState('');
// //   const [snackbarSeverity, setSnackbarSeverity] = useState('success');

// //   // Aggregated Risks
// //   const [aggregatedRisks, setAggregatedRisks] = useState([]);

// //   useEffect(() => {
// //     // Aggregate risks whenever riskFormData changes
// //     const allRisks = aggregateRisks(riskFormData);
// //     setAggregatedRisks(allRisks);
// //   }, [riskFormData]);

// //   useEffect(() => {
// //     if (modelId && aggregatedRisks.length > 0 && narratives) {
// //       // Dispatch action to fetch baseline controls with aggregated risks
// //       dispatch(fetchBaselineControls(modelId, aggregatedRisks, narratives));
// //     }
// //   }, [dispatch, modelId, aggregatedRisks, narratives]);

// //   useEffect(() => {
// //     if (baselineControls.length > 0) {
// //       setSnackbarMessage('Baseline controls fetched successfully!');
// //       setSnackbarSeverity('success');
// //       setSnackbarOpen(true);
// //     }
// //     if (error) {
// //       setSnackbarMessage(error);
// //       setSnackbarSeverity('error');
// //       setSnackbarOpen(true);
// //     }
// //   }, [baselineControls, error]);

// //   const handleSnackbarClose = (_, reason) => {
// //     if (reason === 'clickaway') return;
// //     setSnackbarOpen(false);
// //   };

// //   return (
// //     <Box sx={{ p: 2 }}>
// //       <Paper sx={{ p: 3, mb: 4 }}>
// //         <Typography variant="h6">Compliance - Baseline Controls</Typography>
// //         <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
// //           This section manages baseline controls based on all saved risks.
// //         </Typography>
// //         <Box sx={{ mt: 3 }}>
// //           <Button
// //             variant="contained"
// //             onClick={() =>
// //               dispatch(fetchBaselineControls(modelId, assessmentUuid,aggregatedRisks, narratives))
// //             }
// //             disabled={loading}
// //             startIcon={loading && <CircularProgress size={20} />}
// //           >
// //             {loading ? 'Refreshing...' : 'Refresh Baseline Controls'}
// //           </Button>
// //         </Box>
// //       </Paper>

// //       <TableContainer component={Paper}>
// //         <Table>
// //           <TableHead>
// //             <TableRow>
// //               <TableCell><strong>Control Name</strong></TableCell>
// //               <TableCell><strong>Policy Domain</strong></TableCell>
// //             </TableRow>
// //           </TableHead>
// //           <TableBody>
// //             {baselineControls.map((control, index) => (
// //               <TableRow key={index}>
// //                 <TableCell>{control.control}</TableCell>
// //                 <TableCell>{control.namespace || 'N/A'}</TableCell>
// //               </TableRow>
// //             ))}
// //             {baselineControls.length === 0 && !loading && !error && (
// //               <TableRow>
// //                 <TableCell colSpan={2} align="center">
// //                   <Typography>No baseline controls generated yet.</Typography>
// //                 </TableCell>
// //               </TableRow>
// //             )}
// //             {error && (
// //               <TableRow>
// //                 <TableCell colSpan={2} align="center">
// //                   <Typography color="error">{error}</Typography>
// //                 </TableCell>
// //               </TableRow>
// //             )}
// //           </TableBody>
// //         </Table>
// //       </TableContainer>

// //       {/* Snackbar */}
// //       <Snackbar
// //         open={snackbarOpen}
// //         autoHideDuration={6000}
// //         onClose={handleSnackbarClose}
// //       >
// //         <Alert
// //           onClose={handleSnackbarClose}
// //           severity={snackbarSeverity}
// //           sx={{ width: '100%' }}
// //         >
// //           {snackbarMessage}
// //         </Alert>
// //       </Snackbar>
// //     </Box>
// //   );
// // }

// // export default Compliance;