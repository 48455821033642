// src/RiskAccordion.js

import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Button,
  Tooltip,
  Popover,
  Grow,
  Select,
  MenuItem,
  Drawer,
  Box
} from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import EditIcon from '@mui/icons-material/Edit';


import IconWithProgress from './IconWithProgress.js';
import RiskEvaluationForm from './RiskEvaluationForm.js';
import {
  setRiskEvaluation,
  setRiskPriority,
  updateRiskPriority
} from './redux/actions/riskActions.js';

const RiskAccordion = ({
  risk,
  modelId,
  assessmentUuid,
  handleSaveOperation,
  sectionName,
  category
}) => {
  const themes = useSelector((state) => state.themes);
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Access the current risk from Redux
  const currentRisk = useSelector(
    (state) =>
      state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks?.[category]?.find(
        (r) => r.riskId === risk.riskId
      ) || {},
    shallowEqual // Add shallowEqual here
  );

  const isLoading = currentRisk?.loading;

  const handlePriorityClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleOpenDrawer = () => {
    // "currentRisk" presumably has the updated evaluationData from Redux
    themes.forEach((theme) => {
      const themeId = theme.theme_id;
  
      // If currentRisk has evaluationData for this theme, pass it.
      // Otherwise, pass an empty object (or placeholders).
      const existingThemeData =
        currentRisk?.evaluationData?.[themeId] || {};

        console.log('data sent to set risk eval from accordion', existingThemeData)
  
      dispatch(
        setRiskEvaluation(
          modelId,
          risk.riskId,
          themeId,
          existingThemeData,
          sectionName,
          risk.category
        )
      );
    });
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleSetPriority = (theRisk) => {
    handleSaveOperation(async () => {
      console.log('Evaluating risk:', theRisk);
      const themeIds = Object.keys(theRisk.evaluationData || {});

      try {
        await Promise.all(
          themeIds.map(async (themeId) => {
            const numericThemeId = parseInt(themeId, 10);
            const themeName = themes.find((t) => t.theme_id === numericThemeId)?.name;
            const evaluationData = theRisk.evaluationData[themeId].impact;

            // Dispatch and wait for each risk priority setting to complete
            await dispatch(
              setRiskPriority(
                modelId,
                theRisk.riskId,
                sectionName,
                category,
                theRisk.riskName,
                numericThemeId,
                themeName,
                evaluationData,
                assessmentUuid
              )
            );
          })
        );
      } catch (error) {
        console.error('Error setting priority:', error);
      }
    });
  };

  const handlePriorityChange = (e) => {
    const newPriority = e.target.value;
    handleSaveOperation(async () => {
      await dispatch(
        updateRiskPriority(modelId, risk.riskId, sectionName, category, newPriority, assessmentUuid)
      );
    });
  };

  const getPriorityColor = (priority) => {
    const colors = {
      critical: 'red',
      high: 'amber',
      significant: 'orange',
      moderate: 'grey',
      low: 'blue',
      negligible: 'green'
    };
    return colors[priority?.toLowerCase()] || 'black';
  };

  // Reintroduced capitalizeFirstLetter function
  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  // Determine evaluation state
  const hasBeenEvaluated = useMemo(() => {
    return risk.evaluationData && Object.keys(risk.evaluationData).length > 0;
  }, [risk]);

  const hasPrioritySet = useMemo(() => {
    return currentRisk.priority && currentRisk.priority !== 'Select';
  }, [currentRisk]);

  return (
    <>
      <TableRow>
        {/* Risk Name */}
        <TableCell style={{ width: '60%' }}>
          <Typography variant="body1">{risk.riskName}</Typography>
        </TableCell>

        {/* Priority */}
        <TableCell style={{ width: '15%' }}>
          {!hasPrioritySet && hasBeenEvaluated ? (
            <Button
              variant="outlined"
              onClick={() => handleSetPriority(risk)}
              startIcon={isLoading ? <IconWithProgress isLoading /> : <SmartToyOutlinedIcon />}
              color="primary"
              size="small"
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? 'Setting Priority...' : 'Set Priority'}
            </Button>
          ) : hasPrioritySet ? (
            isLoading ? (
              <Box display="flex" alignItems="center">
                <IconWithProgress isLoading />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Updating...
                </Typography>
              </Box>
            ) : (
              <Select
                value={currentRisk.priority || 'Select'}
                onChange={handlePriorityChange}
                fullWidth
                variant="outlined"
                size="small"
                disabled={isLoading}
                sx={{ color: getPriorityColor(currentRisk.priority) }}
              >
                <MenuItem value="Select" disabled>
                  Select
                </MenuItem>
                <MenuItem value="critical">Critical</MenuItem>
                <MenuItem value="high">High</MenuItem>
                <MenuItem value="significant">Significant</MenuItem>
                <MenuItem value="moderate">Moderate</MenuItem>
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="negligible">Negligible</MenuItem>
              </Select>
            )
          ) : (
            <Typography variant="body2">-</Typography>
          )}
        </TableCell>

        {/* Rationale */}
        <TableCell style={{ width: '15%' }}>
          {hasPrioritySet && (
            <Tooltip title="View Rationale" arrow>
              <IconButton onClick={handlePriorityClick} size="small">
                <SmartToyOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          <Popover
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            TransitionComponent={Grow}
          >
            <Box sx={{ padding: '16px', maxWidth: '300px' }}>
              <Typography variant="h6">Rationale</Typography>
              <Typography>{currentRisk.rationale || 'No rationale provided.'}</Typography>
            </Box>
          </Popover>
        </TableCell>

        {/* Actions */}
        <TableCell style={{ width: '10%' }}>
          {hasPrioritySet && (
            <IconButton onClick={handleOpenDrawer} color="primary" size="small">
              <EditIcon />
            </IconButton>
          )}
          {!hasBeenEvaluated && (
              <Tooltip title="Evaluate Risk" arrow>
              <IconButton onClick={handleOpenDrawer} color="primary" size="small">
                <ListAltOutlinedIcon />
              </IconButton>
            </Tooltip>
     
      
      )}
        </TableCell>
      </TableRow>

      {/* Handle different action states */}
      

      {/* The Drawer for RiskEvaluationForm */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            width: { xs: '90%', sm: '70%', md: '60%', lg: '40%' },
            maxWidth: '100vw'
          }
        }}
      >
        {/* Pass necessary props to RiskEvaluationForm */}
        <RiskEvaluationForm
          modelId={modelId}
          riskName={risk.riskName}
          riskId={risk.riskId}
          assessmentUuid={assessmentUuid}
          sectionName={sectionName}
          category={risk.category}
          handleSaveOperation={handleSaveOperation}
        />
      </Drawer>
    </>
  );
};
export default RiskAccordion;
