import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllConfigs } from './redux/actions/configActions';

import ConfigEditorWithJsonEditor from './ConfigAdmin3';
import { List, ListItem, ListItemText, CircularProgress, Typography, Box, Button } from '@mui/material';

const AdminConfigHome = () => {
  const dispatch = useDispatch();
  const configNames = useSelector((state) => state.config.configNames);
  const loading = useSelector((state) => state.config.loading);
  const error = useSelector((state) => state.config.error);

  const [selectedConfig, setSelectedConfig] = useState(null);
  const [editorType, setEditorType] = useState('jsoneditor'); // Default editor type

  useEffect(() => {
    dispatch(fetchAllConfigs());
  }, [dispatch]);

  const handleConfigSelect = (configName) => {
    setSelectedConfig(configName);
  };

  const handleBack = () => {
    setSelectedConfig(null);
  };

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="60vh">
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: '10px' }}>
          Loading configurations...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="h6" align="center" sx={{ mt: 4 }}>
        Error loading configurations: {error}
      </Typography>
    );
  }

  if (selectedConfig) {
    return (
      <Box>
        <Button variant="outlined" onClick={handleBack} sx={{ mb: 2 }}>
          Back to Configurations
        </Button>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>

          <Button
            variant={editorType === 'jsoneditor' ? 'contained' : 'outlined'}
            onClick={() => setEditorType('jsoneditor')}
          >
            JSON Editor
          </Button>
        </Box>

        {editorType === 'jsoneditor' && <ConfigEditorWithJsonEditor configName={selectedConfig} />}
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px', margin: '20px', backgroundColor: '#fff', borderRadius: '8px' }}>
      <Typography variant="h4" gutterBottom>
        Assessment Templates
      </Typography>
      <List>
        {configNames.map((configName) => (
          <ListItem component="button" key={configName} onClick={() => handleConfigSelect(configName)}>
            <ListItemText primary={configName} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AdminConfigHome;
