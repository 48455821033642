import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Paper,
  Avatar,
  AvatarGroup,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';
import SecurityReportData from './SecurityReportData';
import RiskCounters from './RiskCounters';

function RisksByModel() {
  const models = useSelector(state => state.risks.models);
  const securityReportData = SecurityReportData();
  const [viewMode, setViewMode] = useState('grid');
  const navigate = useNavigate();

  // Existing data handling functions remain unchanged
  const capitalizeFirstLetter = (string) => {
    if (!string) return 'None';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const teamMembers = [
    { 
      name: "Sarah Chen", 
      avatar: "https://i.pravatar.cc/150?img=1" 
    },
    { 
      name: "Marcus Peterson", 
      avatar: "https://i.pravatar.cc/150?img=2" 
    },
    { 
      name: "Aisha Patel", 
      avatar: "https://i.pravatar.cc/150?img=3" 
    },
    { 
      name: "Thomas Berg", 
      avatar: "https://i.pravatar.cc/150?img=4" 
    },
    { 
      name: "Maria Santos", 
      avatar: "https://i.pravatar.cc/150?img=5" 
    }
  ];

  const calculateRiskCounts = (modelUuid) => {
        const counts = {
          Critical: 0,
          High: 0,
          Significant: 0,
          Moderate: 0,
          Low: 0,
          Negligible: 0,
          None: 0,
        };
       
        // Get model data
    const modelData = models[modelUuid];
        if (!modelData) return counts;
    
        const riskFormData = modelData.riskFormData || {};
        // Process model risks
        Object.values(riskFormData).forEach(section => {
          const sectionRisks = section.sectionRisks || {};
          Object.values(sectionRisks).forEach(categoryRisks => {
            Array.isArray(categoryRisks) && categoryRisks.forEach(risk => {
              const priority = capitalizeFirstLetter(risk.priority || 'None');
              if (counts.hasOwnProperty(priority)) {
                counts[priority] += 1;
              } else {
                counts['None'] += 1;
                console.warn(`Unexpected priority "${priority}" encountered in model risks. Assigned to 'None'.`);
              }
            });
          });
        });
    
        // Process security risks
        const securityRisks = securityReportData.dataByModel?.[modelUuid]?.risks || [];
        securityRisks.forEach(risk => {
          const priority = capitalizeFirstLetter((risk.threat_level || 'None').trim());
          if (counts.hasOwnProperty(priority)) {
            counts[priority] += 1;
          } else {
            counts['None'] += 1;
            console.warn(`Unexpected threat level "${priority}" in security risks. Assigned to 'None'.`);
          }
        });
    
        return counts;
      };
    
      // Filter models that have a 'Completed' assessment
      const modelReportData = useMemo(() => {
        const data = {};
        Object.entries(models || {}).forEach(([modelUuid, model]) => {
          if (model.assessmentStatus === 'Completed') {
            data[modelUuid] = {
              modelId: modelUuid,
              modelName: model.modelName,
              assessmentUuid: model.assessmentUuid,
              assessmentStatus: model.assessmentStatus,
              createdByName: model.createdByName || 'Owner',
              avatar: model.avatar,
              created_by: model.created_by || [],
              step: model.step || 'N/A',
              // We won't rely on precomputed model.riskCounts here; we'll compute using calculateRiskCounts
            };
          }
        });
        return data;
      }, [models]);

  const hasNoAssessments = Object.keys(modelReportData).length === 0;
  const switchToGrid = () => setViewMode('grid');
  const switchToTable = () => setViewMode('table');
  const renderRiskCounters = (riskCounts) => <RiskCounters riskCounts={riskCounts} />;
  const getTotalRisks = (riskCounts) => Object.values(riskCounts).reduce((acc, val) => acc + val, 0);
  const handleOpenReport = (modelUuid, assessmentUuid) => {
    navigate(`/assessment-report/${modelUuid}/${assessmentUuid}`);
  };

  const EmptyState = () => (
    <Paper 
      sx={{ 
        p: 4, 
        textAlign: 'center', 
        borderRadius: '12px',
        bgcolor: 'background.default',
        border: '1px solid',
        borderColor: 'divider',
        boxShadow: 'none'
      }}
    >
      <Typography variant="h6" gutterBottom color="text.secondary" sx={{ fontWeight: 400 }}>
        No Completed Assessments
      </Typography>
      <Typography variant="body2" color="text.secondary">
        No models with completed assessments are available.
      </Typography>
    </Paper>
  );

  const generateTrendData = (modelUuid) => {
    const data = [];
    for (let i = 0; i < 7; i++) {
      data.push({
        name: `Week ${i + 1}`,
        value: Math.floor(Math.random() * 40) + 60
      });
    }
    return data;
  };

  const SectionDivider = () => (
    <Divider 
      sx={{ 
        my: 2,
        borderColor: 'divider',
        opacity: 0.5
      }} 
    />
  );

  const RiskTrend = ({ data }) => (
    <Box sx={{ width: '100%', height: 40 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <Line 
            type="monotone" 
            dataKey="value" 
            stroke="currentColor" 
            strokeWidth={1.5}
            dot={false}
            style={{ opacity: 0.5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );


  // const renderGridView = () => {
  //   if (hasNoAssessments) return <EmptyState />;

  //   return (
  //     <Grid container spacing={3}>
  //       {Object.entries(modelReportData).map(([modelUuid, model]) => {
  //         const { modelName, createdByName, avatar, created_by } = model;
  //         const riskCounts = calculateRiskCounts(modelUuid);
  //         const totalRisks = getTotalRisks(riskCounts);
  //         const trendData = generateTrendData(modelUuid);

  //         return (
  //           <Grid item xs={12} sm={6} lg={4} key={modelUuid}>
  //             <Card
  //               onClick={() => handleOpenReport(modelUuid)}
  //               sx={{
  //                 height: '100%',
  //                 cursor: 'pointer',
  //                 transition: 'all 0.2s ease-in-out',
  //                 borderRadius: 2,
  //                 border: '1px solid',
  //                 borderColor: 'divider',
  //                 bgcolor: 'background.default',
  //                 boxShadow: 'none',
  //                 '&:hover': {
  //                   transform: 'translateY(-2px)',
  //                   boxShadow: '0 4px 12px rgba(0,0,0,0.04)',
  //                   borderColor: 'primary.light',
  //                 },
  //               }}
  //             >
  //               <CardContent sx={{ p: 3, height: '100%' }}>
  //                 <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
  //                   <Typography 
  //                     variant="h6" 
  //                     sx={{ 
  //                       fontWeight: 500,
  //                       color: 'text.primary',
  //                       fontSize: '1.1rem'
  //                     }}
  //                   >
  //                     {modelName}
  //                   </Typography>
  //                   <Tooltip title={createdByName} arrow>
  //                     <Avatar
  //                       src={avatar}
  //                       alt={createdByName}
  //                       sx={{
  //                         width: 32,
  //                         height: 32,
  //                         borderRadius: '8px',
  //                       }}
  //                     />
  //                   </Tooltip>
  //                 </Box>

  //                 <Box 
  //                   display="flex" 
  //                   flexDirection="column" 
  //                   gap={2}
  //                   sx={{ mt: 2 }}
  //                 >
  //                   <Box>
  //                     <Typography 
  //                       variant="body2" 
  //                       color="text.secondary" 
  //                       sx={{ mb: 1 }}
  //                     >
  //                       Risk Assessment
  //                     </Typography>
  //                     {renderRiskCounters(riskCounts)}
  //                     <Typography 
  //                       variant="caption" 
  //                       sx={{ 
  //                         display: 'block',
  //                         mt: 1,
  //                         color: 'text.secondary' 
  //                       }}
  //                     >
  //                       Total Risks: {totalRisks}
  //                     </Typography>
  //                   </Box>

  //                   <Box>
  //                     <Typography 
  //                       variant="body2" 
  //                       color="text.secondary" 
  //                       sx={{ mb: 1 }}
  //                     >
  //                       Team Members
  //                     </Typography>
  //                     <AvatarGroup 
  //                       max={4}
  //                       sx={{
  //                         '& .MuiAvatar-root': {
  //                           width: 28,
  //                           height: 28,
  //                           fontSize: '0.875rem',
  //                           borderRadius: '6px',
  //                           border: 'none'
  //                         }
  //                       }}
  //                     >
  //                       <Avatar key={created_by} src={avatar} alt={createdByName} />
  //                     </AvatarGroup>
  //                   </Box>
  //                 </Box>
  //               </CardContent>
  //             </Card>
  //           </Grid>
  //         );
  //       })}
  //     </Grid>
  //   );
  // };

  // const renderTableView = () => {
  //   if (hasNoAssessments) return <EmptyState />;

  //   return (
  //     <TableContainer 
  //       component={Paper} 
  //       sx={{ 
  //         borderRadius: 2,
  //         border: '1px solid',
  //         borderColor: 'divider',
  //         boxShadow: 'none',
  //         bgcolor: 'background.default',
  //         overflow: 'hidden'
  //       }}
  //     >
  //       <Table>
  //         <TableHead>
  //           <TableRow>
  //             <TableCell 
  //               sx={{ 
  //                 fontWeight: 500,
  //                 color: 'text.secondary',
  //                 bgcolor: 'background.default',
  //                 borderBottom: '1px solid',
  //                 borderBottomColor: 'divider',
  //                 py: 2
  //               }}
  //             >
  //               Model Name
  //             </TableCell>
  //             <TableCell sx={{ fontWeight: 500, color: 'text.secondary' }}>Team</TableCell>
  //             <TableCell sx={{ fontWeight: 500, color: 'text.secondary' }}>Risks</TableCell>
  //             <TableCell sx={{ fontWeight: 500, color: 'text.secondary' }}>Actions</TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {Object.entries(modelReportData).map(([modelUuid, model]) => {
  //             const { modelName, createdByName, avatar, created_by } = model;
  //             const riskCounts = calculateRiskCounts(modelUuid);
  //             const totalRisks = getTotalRisks(riskCounts);

  //             return (
  //               <TableRow 
  //                 key={modelUuid} 
  //                 hover
  //                 sx={{
  //                   '&:hover': {
  //                     bgcolor: 'action.hover',
  //                   },
  //                   '& td': {
  //                     borderBottom: '1px solid',
  //                     borderBottomColor: 'divider'
  //                   }
  //                 }}
  //               >
  //                 <TableCell sx={{ color: 'text.primary' }}>
  //                   {modelName}
  //                 </TableCell>
  //                 <TableCell>
  //                   <AvatarGroup 
  //                     max={4}
  //                     sx={{
  //                       '& .MuiAvatar-root': {
  //                         width: 28,
  //                         height: 28,
  //                         fontSize: '0.875rem',
  //                         borderRadius: '6px',
  //                         border: 'none'
  //                       }
  //                     }}
  //                   >
  //                     <Avatar key={created_by} src={avatar} alt={createdByName} />
  //                   </AvatarGroup>
  //                 </TableCell>
  //                 <TableCell>
  //                   <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  //                     {renderRiskCounters(riskCounts)}
  //                     <Typography 
  //                       variant="caption" 
  //                       sx={{ color: 'text.secondary' }}
  //                     >
  //                       ({totalRisks})
  //                     </Typography>
  //                   </Box>
  //                 </TableCell>
  //                 <TableCell>
  //                   <Tooltip title="View Assessment Report">
  //                     <IconButton 
  //                       onClick={() => handleOpenReport(modelUuid)}
  //                       size="small"
  //                       sx={{ 
  //                         color: 'text.secondary',
  //                         '&:hover': {
  //                           color: 'primary.main',
  //                           bgcolor: 'primary.lighter'
  //                         }
  //                       }}
  //                     >
  //                       <VisibilityIcon fontSize="small" />
  //                     </IconButton>
  //                   </Tooltip>
  //                 </TableCell>
  //               </TableRow>
  //             );
  //           })}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //   );
  // };

  const StyledAvatarGroup = ({ members }) => (
    <AvatarGroup 
      max={4}
      sx={{
        justifyContent: 'flex-start',
        '& .MuiAvatar-root': {
          width: 28,
          height: 28,
          fontSize: '0.875rem',
          borderRadius: '6px',
          border: 'none',
          marginLeft: '0 !important',
          marginRight: '4px !important',
        },
        '& .MuiAvatarGroup-avatar': {
          border: '2px solid',
          borderColor: 'background.default',
          backgroundColor: 'primary.lighter',
          color: 'primary.main',
        }
      }}
    >
      {members.map((member, index) => (
        <Tooltip key={index} title={member.name} arrow>
          <Avatar 
            src={member.avatar} 
            alt={member.name}
          />
        </Tooltip>
      ))}
    </AvatarGroup>
  );

  const renderGridView = () => {
    if (hasNoAssessments) return <EmptyState />;

    return (
      <Grid container spacing={3}>
        {Object.entries(modelReportData).map(([modelUuid, model]) => {
          const { modelName, createdByName, avatar } = model;
          //, created_by
          const riskCounts = calculateRiskCounts(modelUuid);
          const totalRisks = getTotalRisks(riskCounts);
          const trendData = generateTrendData(modelUuid);

          return (
            <Grid item xs={12} sm={6} lg={4} key={modelUuid}>
              <Card
                onClick={() => handleOpenReport(modelUuid)}
                sx={{
                  height: '100%',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease-in-out',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  bgcolor: 'background.default',
                  boxShadow: 'none',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.04)',
                    borderColor: 'primary.light',
                  },
                }}
              >
                <CardContent sx={{ p: 3, height: '100%' }}>
                  {/* Header Section */}
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 500,
                        color: 'text.primary',
                        fontSize: { xs: '1rem', sm: '1.1rem' }
                      }}
                    >
                      {modelName}
                    </Typography>
                    <Tooltip title={createdByName} arrow>
                      <Avatar
                        src={avatar}
                        alt={createdByName}
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: '8px',
                        }}
                      />
                    </Tooltip>
                  </Box>

                  <SectionDivider />

                  {/* Risk Assessment Section */}
                  <Box>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ mb: 1 }}
                    >
                      Risks by priority
                    </Typography>
                    {renderRiskCounters(riskCounts)}
                    <Box sx={{ mt: 2, color: 'text.secondary' }}>
                      <RiskTrend data={trendData} />
                    </Box>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        display: 'block',
                        mt: 1,
                        color: 'text.secondary' 
                      }}
                    >
                      Total Risks: {totalRisks}
                    </Typography>
                  </Box>

                  <SectionDivider />

                  {/* Team Section */}
                  {/* <Box>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ mb: 1 }}
                    >
                      Team
                    </Typography>
                    <AvatarGroup 
                      max={4}
                      sx={{
                        '& .MuiAvatar-root': {
                          width: 28,
                          height: 28,
                          fontSize: '0.875rem',
                          borderRadius: '6px',
                          border: 'none'
                        }
                      }}
                    >
                      <Avatar key={created_by} src={avatar} alt={createdByName} />
                    </AvatarGroup>
                  </Box> */}
                  <Box>
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        mb: 1
                      }}
                    >
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                      >
                        Team
                      </Typography>
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                      >
                        {teamMembers.length} members
                      </Typography>
                    </Box>
                    <StyledAvatarGroup members={teamMembers} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderTableView = () => {
    if (hasNoAssessments) return <EmptyState />;

    return (
      <TableContainer 
        component={Paper} 
        sx={{ 
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'divider',
          boxShadow: 'none',
          bgcolor: 'background.default',
          overflow: { xs: 'auto', sm: 'hidden' }
        }}
      >
        <Table sx={{ minWidth: { xs: 650, sm: 'auto' } }}>
          <TableHead>
            <TableRow>
              <TableCell 
                sx={{ 
                  fontWeight: 500,
                  color: 'text.secondary',
                  bgcolor: 'background.default',
                  borderBottom: '1px solid',
                  borderBottomColor: 'divider',
                  py: 2,
                  minWidth: { xs: '120px', sm: 'auto' }
                }}
              >
                Model Name
              </TableCell>
              <TableCell 
                sx={{ 
                  fontWeight: 500, 
                  color: 'text.secondary',
                  minWidth: { xs: '100px', sm: 'auto' }
                }}
              >
                Team
              </TableCell>
              <TableCell 
                sx={{ 
                  fontWeight: 500, 
                  color: 'text.secondary',
                  minWidth: { xs: '200px', sm: 'auto' }
                }}
              >
                Risks
              </TableCell>
              <TableCell 
                sx={{ 
                  fontWeight: 500, 
                  color: 'text.secondary',
                  width: '100px'
                }}
              >
                Trend
              </TableCell>
              <TableCell 
                sx={{ 
                  fontWeight: 500, 
                  color: 'text.secondary',
                  width: '80px'
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(modelReportData).map(([modelUuid, model]) => {
              const { modelName } = model;
              //, createdByName, avatar, created_by
              const riskCounts = calculateRiskCounts(modelUuid);
              const totalRisks = getTotalRisks(riskCounts);
              const trendData = generateTrendData(modelUuid);

              return (
                <TableRow 
                  key={modelUuid} 
                  hover
                  sx={{
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                    '& td': {
                      borderBottom: '1px solid',
                      borderBottomColor: 'divider'
                    }
                  }}
                >
                  <TableCell sx={{ color: 'text.primary' }}>
                    {modelName}
                  </TableCell>
                  <TableCell>
                    {/* **Team Section Using Placeholder Members** */}
                    <StyledAvatarGroup members={teamMembers} />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {renderRiskCounters(riskCounts)}
                      <Typography 
                        variant="caption" 
                        sx={{ color: 'text.secondary' }}
                      >
                        ({totalRisks})
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ width: 100, height: 40, color: 'text.secondary' }}>
                      <RiskTrend data={trendData} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="View Assessment Report">
                      <IconButton 
                        onClick={() => handleOpenReport(modelUuid)}
                        size="small"
                        sx={{ 
                          color: 'text.secondary',
                          '&:hover': {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                          }
                        }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  return (
    <Box 
      sx={{ 
        p: { xs: 2, sm: 3 }, 
        bgcolor: 'background.paper',
        minHeight: '100vh'
      }}
    >
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center" 
        mb={3}
      >
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 500,
            color: 'text.primary',
            fontSize: { xs: '1.5rem', sm: '2rem' }
          }}
        >
          Model Risk Reports
        </Typography>
        <Box 
          display="flex" 
          alignItems="center" 
          gap={1}
          sx={{
            bgcolor: 'background.default',
            p: 0.5,
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Tooltip title="Grid View" arrow>
            <IconButton 
              onClick={switchToGrid} 
              size="small"
              sx={{ 
                color: viewMode === 'grid' ? 'primary.main' : 'text.secondary',
                '&:hover': { bgcolor: 'action.hover' }
              }}
            >
              <ViewModuleIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Table View" arrow>
            <IconButton 
              onClick={switchToTable}
              size="small"
              sx={{ 
                color: viewMode === 'table' ? 'primary.main' : 'text.secondary',
                '&:hover': { bgcolor: 'action.hover' }
              }}
            >
              <ViewListIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Divider sx={{ mb: 3 }} />

      {viewMode === 'grid' ? renderGridView() : renderTableView()}
    </Box>
  );
}

export default RisksByModel;

// import React, { useMemo, useState } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   Box,
//   Typography,
//   Divider,
//   IconButton,
//   Tooltip,
//   Paper,
//   Avatar,
//   AvatarGroup,
//   Grid,
//   Card,
//   CardContent,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from '@mui/material';
// import ViewModuleIcon from '@mui/icons-material/ViewModule';
// import ViewListIcon from '@mui/icons-material/ViewList';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import { useNavigate } from 'react-router-dom';
// import SecurityReportData from './SecurityReportData'; // Reintroduce SecurityReportData
// import RiskCounters from './RiskCounters'; // Reintroduce RiskCounters

// function RisksByModel() {
//   const models = useSelector(state => state.risks.models);
//   const securityReportData = SecurityReportData(); // Reinstated

//   const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'table'
//   const navigate = useNavigate();

//   // Function to capitalize priorities
//   const capitalizeFirstLetter = (string) => {
//     if (!string) return 'None';
//     return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
//   };

//   // Calculate combined risk counts from model and security
//   const calculateRiskCounts = (modelUuid) => {
//     const counts = {
//       Critical: 0,
//       High: 0,
//       Significant: 0,
//       Moderate: 0,
//       Low: 0,
//       Negligible: 0,
//       None: 0,
//     };

//     // Get model data
//     const modelData = models[modelUuid];
//     if (!modelData) return counts;

//     const riskFormData = modelData.riskFormData || {};
//     // Process model risks
//     Object.values(riskFormData).forEach(section => {
//       const sectionRisks = section.sectionRisks || {};
//       Object.values(sectionRisks).forEach(categoryRisks => {
//         Array.isArray(categoryRisks) && categoryRisks.forEach(risk => {
//           const priority = capitalizeFirstLetter(risk.priority || 'None');
//           if (counts.hasOwnProperty(priority)) {
//             counts[priority] += 1;
//           } else {
//             counts['None'] += 1;
//             console.warn(`Unexpected priority "${priority}" encountered in model risks. Assigned to 'None'.`);
//           }
//         });
//       });
//     });

//     // Process security risks
//     const securityRisks = securityReportData.dataByModel?.[modelUuid]?.risks || [];
//     securityRisks.forEach(risk => {
//       const priority = capitalizeFirstLetter((risk.threat_level || 'None').trim());
//       if (counts.hasOwnProperty(priority)) {
//         counts[priority] += 1;
//       } else {
//         counts['None'] += 1;
//         console.warn(`Unexpected threat level "${priority}" in security risks. Assigned to 'None'.`);
//       }
//     });

//     return counts;
//   };

//   // Filter models that have a 'Completed' assessment
//   const modelReportData = useMemo(() => {
//     const data = {};
//     Object.entries(models || {}).forEach(([modelUuid, model]) => {
//       if (model.assessmentStatus === 'Completed') {
//         data[modelUuid] = {
//           modelId: modelUuid,
//           modelName: model.modelName,
//           assessmentUuid: model.assessmentUuid,
//           assessmentStatus: model.assessmentStatus,
//           createdByName: model.createdByName || 'Owner',
//           avatar: model.avatar,
//           created_by: model.created_by || [],
//           step: model.step || 'N/A',
//           // We won't rely on precomputed model.riskCounts here; we'll compute using calculateRiskCounts
//         };
//       }
//     });
//     return data;
//   }, [models]);

//   const hasNoAssessments = Object.keys(modelReportData).length === 0;

//   const switchToGrid = () => setViewMode('grid');
//   const switchToTable = () => setViewMode('table');

//   // Renders risk priority badges using RiskCounters component
//   const renderRiskCounters = (riskCounts) => {
//     return <RiskCounters riskCounts={riskCounts} />;
//   };

//   const getTotalRisks = (riskCounts) => {
//     return Object.values(riskCounts).reduce((acc, val) => acc + val, 0);
//   };

//   const handleOpenReport = (modelUuid, assessmentUuid) => {
//     navigate(`/assessment-report/${modelUuid}/${assessmentUuid}`);
//   };

//   // Card View
//   const renderGridView = () => {
//     if (hasNoAssessments) {
//       return (
//         <Paper sx={{ p: 3, textAlign: 'center', borderRadius: '8px' }}>
//           <Typography variant="h6" gutterBottom>No Completed Assessments</Typography>
//           <Typography variant="body1">
//             No models with completed assessments are available.
//           </Typography>
//         </Paper>
//       );
//     }

//     return (
//       <Grid container spacing={3}>
//         {Object.entries(modelReportData).map(([modelUuid, model]) => {
//           const { modelName, createdByName, avatar, created_by } = model;
//           const riskCounts = calculateRiskCounts(modelUuid);
//           const totalRisks = getTotalRisks(riskCounts);

//           return (
//             <Grid item xs={12} sm={6} md={4} key={modelUuid}>
//               <Card
//                 sx={{
//                   position: 'relative',
//                   cursor: 'pointer',
//                   transition: 'box-shadow 0.3s',
//                   '&:hover': { boxShadow: '0 4px 12px rgba(0,0,0,0.1)' },
//                   borderRadius: '12px',
//                   backgroundColor: '#FFFFFF',
//                 }}
//                 onClick={() => handleOpenReport(modelUuid)}
//               >
//                 {/* Owner avatar at top right with tooltip */}
//                 <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
//                   <Tooltip title={createdByName} arrow>
//                     <Avatar
//                       src={avatar}
//                       alt={createdByName}
//                       sx={{
//                         width: 40,
//                         height: 40,
//                         border: '2px solid #FFFFFF',
//                         boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//                       }}
//                     />
//                   </Tooltip>
//                 </Box>
//                 <CardContent sx={{ p: 3 }}>
//                   <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: '#42424a' }}>
//                     {modelName}
//                   </Typography>
//                   <Box display="flex" alignItems="center">
//                     <Typography variant="body2" sx={{ mr: 1, color: '#42424a' }}>Risks:</Typography>
//                     {renderRiskCounters(riskCounts)}
//                     <Typography variant="caption" sx={{ ml: 1, color: '#888888' }}>
//                       Total: {totalRisks}
//                     </Typography>
//                   </Box>
                 
//                   <Box display="flex" alignItems="center">
//                     <Typography variant="body2" sx={{ mr: 1, color: '#42424a' }}>Team:</Typography>
//                     <AvatarGroup max={4} spacing="small">
                      
//                         <Avatar key={created_by} src={avatar} alt={createdByName} />
                      
//                     </AvatarGroup>
//                   </Box>

//                 </CardContent>
//               </Card>
//             </Grid>
//           );
//         })}
//       </Grid>
//     );
//   };

//   // Table View
//   const renderTableView = () => {
//     if (hasNoAssessments) {
//       return (
//         <Paper sx={{ p: 3, textAlign: 'center', borderRadius: '8px' }}>
//           <Typography variant="h6" gutterBottom>No Completed Assessments</Typography>
//           <Typography variant="body1">
//             No models with completed assessments are available.
//           </Typography>
//         </Paper>
//       );
//     }

//     return (
//       <TableContainer component={Paper} sx={{ borderRadius: '12px' }}>
//         <Table aria-label="risks by model table">
//           <TableHead>
//             <TableRow>
//               <TableCell sx={{ fontWeight: 'bold', color: '#42424a' }}>Model Name</TableCell>
//               <TableCell sx={{ fontWeight: 'bold', color: '#42424a' }}>Risks</TableCell>
//               <TableCell sx={{ fontWeight: 'bold', color: '#42424a' }}>Team</TableCell>
//               <TableCell sx={{ fontWeight: 'bold', color: '#42424a' }}>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {Object.entries(modelReportData).map(([modelUuid, model]) => {
//               const { modelName, createdByName, avatar, created_by} = model;
//               const riskCounts = calculateRiskCounts(modelUuid);
//               const totalRisks = getTotalRisks(riskCounts);

//               return (
//                 <TableRow key={modelUuid} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
//                   <TableCell component="th" scope="row" sx={{ color: '#42424a' }}>
//                     {modelName}
//                   </TableCell>
               
//                   <TableCell>
//                     <AvatarGroup max={4} spacing="small">
//                          <Avatar key={created_by} src={avatar} alt={createdByName} />
//                       </AvatarGroup>
//                   </TableCell>
//                   <TableCell>
//                     {renderRiskCounters(riskCounts)}
//                     <Typography variant="caption" sx={{ ml: 1, color: '#888888' }}>
//                       Total: {totalRisks}
//                     </Typography>
//                   </TableCell>
//                   <TableCell>
//                     <Tooltip title="View Assessment Report">
//                       <IconButton onClick={() => handleOpenReport(modelUuid)} sx={{ color: '#42424a' }}>
//                         <VisibilityIcon />
//                       </IconButton>
//                     </Tooltip>
//                   </TableCell>
//                 </TableRow>
//               );
//             })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     );
//   };

//   return (
//     <Box sx={{ p: 3, backgroundColor: '#FAFAFA', minHeight: '100vh' }}>
//       <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
//         <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#42424a' }}>
//           Model Risk Reports
//         </Typography>
//         <Box display="flex" alignItems="center" gap={1}>
//           <Tooltip title="Grid View" arrow>
//             <IconButton onClick={switchToGrid} sx={{ color: viewMode === 'grid' ? '#8FB996' : '#888888' }}>
//               <ViewModuleIcon />
//             </IconButton>
//           </Tooltip>
//           <Tooltip title="Table View" arrow>
//             <IconButton onClick={switchToTable} sx={{ color: viewMode === 'table' ? '#8FB996' : '#888888' }}>
//               <ViewListIcon />
//             </IconButton>
//           </Tooltip>
//         </Box>
//       </Box>

//       <Divider sx={{ mb: 3 }} />

//       {viewMode === 'grid' ? renderGridView() : renderTableView()}
//     </Box>
//   );
// }

// export default RisksByModel;
