import React from 'react';
import { Text } from 'recharts';
import PropTypes from 'prop-types';

const DynamicLabel = ({ x, y, width, height, categoryName }) => {
  if (!categoryName) return null;

  // Define padding and fixed font size
  const padding = 2;
  const fontSize = 12;

  // Calculate the maximum available width for text
  const availableWidth = width - padding * 2;

  // Approximate average character width
  const averageCharWidth = fontSize * 0.6;

  // Minimum width to display '..'
  const minWidthForDots = averageCharWidth * 2;

  // If the bar is too small, show minimal dots
  if (availableWidth < minWidthForDots) {
    return (
      <Text
        x={x + width / 2}
        y={y + height / 2}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={fontSize}
        pointerEvents="none"
      >
        {/* {'..'} */}
      </Text>
    );
  }

  // Calculate the maximum number of characters that fit in the available space
  const maxChars = Math.floor(availableWidth / averageCharWidth);

  let displayText = '';

  if (categoryName.length * averageCharWidth <= availableWidth) {
    // Text fits, display it fully
    displayText = categoryName;
  } else {
    // Adjust maxChars to account for '..' at the end
    const truncatedLength = Math.max(0, maxChars - 2);

    if (truncatedLength <= 0) {
      // Not enough space for characters, display '..'
      displayText = '..';
    } else {
      // Display as many characters as possible followed by '..'
      displayText = categoryName.slice(0, truncatedLength) + '..';
    }
  }

  return (
    <Text
      x={x + width / 2}
      y={y + height / 2}
      fill="#fff"
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize={fontSize}
      pointerEvents="none"
    >
      {displayText}
    </Text>
  );
};

DynamicLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  categoryName: PropTypes.string.isRequired,
};

export default DynamicLabel;