// RisksBySourceTable.jsx
import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  Divider,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import RiskCounters from './RiskCounters'; // Ensures consistent color coding
import SingleRiskReport from './SingleRiskReport';
import SingleSecurityRiskReport from './SingleSecurityRiskReport';

const RisksBySourceTable = ({ reportData }) => {
  // State to track expanded sources and categories
  const [openSources, setOpenSources] = useState({});
  const [openCategories, setOpenCategories] = useState({});
  const [openRisks, setOpenRisks] = useState({});

  // Handler to toggle source expansion
  const handleToggleSource = (source) => {
    setOpenSources(prev => ({
      ...prev,
      [source]: !prev[source],
    }));
  };

  // Handler to toggle category expansion within a source
  const handleToggleCategory = (source, category) => {
    const key = `${source}-${category}`;
    setOpenCategories(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Handler to toggle risk expansion within a category
  const handleToggleRisk = (source, category, riskId) => {
    const key = `${source}-${category}-${riskId}`;
    setOpenRisks(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Function to calculate risk counts by priority for a given array of risks
  const calculateRiskCounts = (risks) => {
    const counts = {
      Critical: 0,
      High: 0,
      Significant: 0,
      Moderate: 0,
      Low: 0,
      Negligible: 0,
      None: 0,
    };

    risks.forEach(risk => {
      const priority = risk.priority || (risk.threat_level ? risk.threat_level : 'None');
      const formattedPriority = capitalizeFirstLetter(priority);
      if (counts.hasOwnProperty(formattedPriority)) {
        counts[formattedPriority] += 1;
      } else {
        counts['None'] += 1;
        console.warn(`Unexpected priority "${formattedPriority}" encountered. Assigned to 'None'.`);
      }
    });

    return counts;
  };

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    if (!string) return 'None';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  
  const toTitleCase = (str) => {
    if (!str) return ''; // Return an empty string if the input is undefined or null
    return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/([A-Z]+)/g, (match) => match.toUpperCase())
      .replace(/\b([Aa]nd)\b/g, '&')
      .trim()
      .split(/\s+/)
      .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .join(' ');
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Risks by Source & Category
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <TableContainer component={Paper}>
        <Table aria-label="risks by source table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell sx={{ fontWeight: 'bold' }}>Risk Source</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Risks By Priority</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Total Risks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(reportData).map(([source, categories]) => {
              // Flatten all risks under this source to calculate counts
              const allRisks = Object.values(categories).flat();
              const riskCounts = calculateRiskCounts(allRisks);

              return (
                <React.Fragment key={source}>
                  <TableRow>
                    <TableCell>
                      <IconButton onClick={() => handleToggleSource(source)} aria-label={openSources[source] ? 'Collapse source' : 'Expand source'}>
                        {openSources[source] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </TableCell>
                    <TableCell>{toTitleCase(source)}</TableCell>
                    <TableCell>
                      <RiskCounters riskCounts={riskCounts} />
                    </TableCell>
                    <TableCell>{allRisks.length}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                      <Collapse in={openSources[source]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          {Object.keys(categories).length === 0 ? (
                            <Typography variant="body1">No categories available for this source.</Typography>
                          ) : (
                            <Table size="small" aria-label={`${source} categories`}>
                              <TableHead>
                                <TableRow>
                                  <TableCell />
                                  <TableCell sx={{ fontWeight: 'bold' }}>Category</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold' }}>Risks By Priority</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold' }}>Total Risks</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.entries(categories).map(([category, risks]) => {
                                  const categoryRiskCounts = calculateRiskCounts(risks);
                                  return (
                                    <React.Fragment key={category}>
                                      <TableRow>
                                        <TableCell>
                                          <IconButton onClick={() => handleToggleCategory(source, category)} aria-label={openCategories[`${source}-${category}`] ? 'Collapse category' : 'Expand category'}>
                                            {openCategories[`${source}-${category}`] ? <ExpandLess /> : <ExpandMore />}
                                          </IconButton>
                                        </TableCell>
                                        <TableCell>{toTitleCase(category)}</TableCell>
                                        <TableCell>
                                          <RiskCounters riskCounts={categoryRiskCounts} />
                                        </TableCell>
                                        <TableCell>{risks.length}</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                          <Collapse in={openCategories[`${source}-${category}`]} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                              {risks.length === 0 ? (
                                                <Typography variant="body1">No risks available for this category.</Typography>
                                              ) : (
                                                <Table size="small" aria-label={`${category} risks`}>
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell />
                                                      <TableCell sx={{ fontWeight: 'bold' }}>Risk Name</TableCell>
                                                      <TableCell sx={{ fontWeight: 'bold' }}>Priority</TableCell>
                                                      <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    {risks.map(risk => {
                                                      const isSecurityRisk = risk.isSecurityRisk || false;
                                                      const riskId = risk.riskId || risk.risk_id; // Handle both model and security risks

                                                      return (
                                                        <React.Fragment key={riskId}>
                                                          <TableRow>
                                                            <TableCell>
                                                              <IconButton
                                                                onClick={() => handleToggleRisk(source, category, riskId)}
                                                                aria-label={openRisks[`${source}-${category}-${riskId}`] ? 'Collapse risk' : 'Expand risk'}
                                                              >
                                                                {openRisks[`${source}-${category}-${riskId}`] ? <ExpandLess /> : <ExpandMore />}
                                                              </IconButton>
                                                            </TableCell>
                                                            <TableCell>{risk.riskName || risk.risk_name || 'Unnamed Risk'}</TableCell>
                                                            <TableCell>{capitalizeFirstLetter(risk.priority || risk.threat_level || 'None')}</TableCell>
                                                            <TableCell>
                                                              {/* Additional Actions can be added here if needed */}
                                                            </TableCell>
                                                          </TableRow>
                                                          <TableRow>
                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                                              <Collapse in={openRisks[`${source}-${category}-${riskId}`]} timeout="auto" unmountOnExit>
                                                                <Box margin={1}>
                                                                  {isSecurityRisk ? (
                                                                    <SingleSecurityRiskReport
                                                                      risk={risk}
                                                                      modelId={risk.modelUuid}
                                                                      assessmentUuid={risk.assessmentUuid}
                                                                      onClose={() => handleToggleRisk(source, category, riskId)}
                                                                    />
                                                                  ) : (
                                                                    <SingleRiskReport
                                                                      risk={risk}
                                                                      sectionName={source}
                                                                      category={category}
                                                                      modelId={risk.modelUuid}
                                                                      assessmentUuid={risk.assessmentUuid}
                                                                      onClose={() => handleToggleRisk(source, category, riskId)}
                                                                    />
                                                                  )}
                                                                </Box>
                                                              </Collapse>
                                                            </TableCell>
                                                          </TableRow>
                                                        </React.Fragment>
                                                      );
                                                    })}
                                                  </TableBody>
                                                </Table>
                                              )}
                                            </Box>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RisksBySourceTable;


// // RisksBySourceTable.jsx
// import React, { useState } from 'react';
// import {
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   Collapse,
//   Typography,
//   Divider,

// } from '@mui/material';
// import { ExpandMore, ExpandLess } from '@mui/icons-material';
// import RiskCounters from './RiskCounters'; // Ensures consistent color coding
// import SingleRiskReport from './SingleRiskReport';
// import SingleSecurityRiskReport from './SingleSecurityRiskReport';

// const RisksBySourceTable = ({ reportData }) => {
//   // State to track which category rows are expanded
//   const [openCategories, setOpenCategories] = useState({});

//   // State to track which risk rows are expanded
//   const [openRisks, setOpenRisks] = useState({});

//   // Handler to toggle category expansion
//   const handleToggleCategory = (category) => {
//     setOpenCategories(prev => ({
//       ...prev,
//       [category]: !prev[category],
//     }));
//   };

//   // Handler to toggle risk expansion
//   const handleToggleRisk = (category, riskId) => {
//     setOpenRisks(prev => ({
//       ...prev,
//       [`${category}-${riskId}`]: !prev[`${category}-${riskId}`],
//     }));
//   };

//   // Function to calculate risk counts by priority for a given category
//   const calculateRiskCounts = (risks) => {
//     const counts = {
//       Critical: 0,
//       High: 0,
//       Significant: 0,
//       Moderate: 0,
//       Low: 0,
//       Negligible: 0,
//       None: 0,
//     };

//     risks.forEach(risk => {
//       const priority = risk.priority || (risk.threat_level ? risk.threat_level : 'None');
//       const formattedPriority = capitalizeFirstLetter(priority);
//       if (counts.hasOwnProperty(formattedPriority)) {
//         counts[formattedPriority] += 1;
//       } else {
//         counts['None'] += 1;
//         console.warn(`Unexpected priority "${formattedPriority}" encountered. Assigned to 'None'.`);
//       }
//     });

//     return counts;
//   };

//   // Helper function to capitalize the first letter
//   const capitalizeFirstLetter = (string) => {
//     if (!string) return 'None';
//     return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
//   };

//   return (
//     <Box sx={{ padding: 2 }}>
//       <Typography variant="h5" gutterBottom>
//         Risks by Source
//       </Typography>
//       <Divider sx={{ mb: 2 }} />

//       <TableContainer component={Paper}>
//         <Table aria-label="risks by source table">
//           <TableHead>
//             <TableRow>
//               <TableCell />
//               <TableCell sx={{ fontWeight: 'bold' }}>Risk Source</TableCell>
//               <TableCell sx={{ fontWeight: 'bold' }}>Risks By Priority</TableCell>
//               <TableCell sx={{ fontWeight: 'bold' }}>Total Risks</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {Object.entries(reportData).map(([category, risks]) => {
//               const riskCounts = calculateRiskCounts(risks);

//               return (
//                 <React.Fragment key={category}>
//                   <TableRow>
//                     <TableCell>
//                       <IconButton onClick={() => handleToggleCategory(category)} aria-label={openCategories[category] ? 'Collapse category' : 'Expand category'}>
//                         {openCategories[category] ? <ExpandLess /> : <ExpandMore />}
//                       </IconButton>
//                     </TableCell>
//                     <TableCell>{capitalizeFirstLetter(category)}</TableCell>
//                     <TableCell>
//                       <RiskCounters riskCounts={riskCounts} />
//                     </TableCell>
//                     <TableCell>{risks.length}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
//                       <Collapse in={openCategories[category]} timeout="auto" unmountOnExit>
//                         <Box margin={1}>
//                           {risks.length === 0 ? (
//                             <Typography variant="body1">No risks available for this source.</Typography>
//                           ) : (
//                             <Table size="small" aria-label={`${category} risks`}>
//                               <TableHead>
//                                 <TableRow>
//                                   <TableCell />
//                                   <TableCell sx={{ fontWeight: 'bold' }}>Risk Name</TableCell>
//                                   <TableCell sx={{ fontWeight: 'bold' }}>Priority</TableCell>
//                                   <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
//                                 </TableRow>
//                               </TableHead>
//                               <TableBody>
//                                 {risks.map(risk => {
//                                   const isSecurityRisk = risk.isSecurityRisk || false;
//                                   const riskId = risk.riskId || risk.risk_id; // Handle both model and security risks

//                                   return (
//                                     <React.Fragment key={riskId}>
//                                       <TableRow>
//                                         <TableCell>
//                                           <IconButton
//                                             onClick={() => handleToggleRisk(category, riskId)}
//                                             aria-label={openRisks[`${category}-${riskId}`] ? 'Collapse risk' : 'Expand risk'}
//                                           >
//                                             {openRisks[`${category}-${riskId}`] ? <ExpandLess /> : <ExpandMore />}
//                                           </IconButton>
//                                         </TableCell>
//                                         <TableCell>{risk.riskName || risk.risk_name || 'Unnamed Risk'}</TableCell>
//                                         <TableCell>{capitalizeFirstLetter(risk.priority || risk.threat_level || 'None')}</TableCell>
//                                         <TableCell>
//                                           {/* Additional Actions can be added here if needed */}
//                                         </TableCell>
//                                       </TableRow>
//                                       <TableRow>
//                                         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
//                                           <Collapse in={openRisks[`${category}-${riskId}`]} timeout="auto" unmountOnExit>
//                                             <Box margin={1}>
//                                               {isSecurityRisk ? (
//                                                 <SingleSecurityRiskReport
//                                                   risk={risk}
//                                                   modelId={risk.modelUuid}
//                                                   assessmentUuid={risk.assessmentUuid}
//                                                   onClose={() => handleToggleRisk(category, riskId)}
//                                                 />
//                                               ) : (
//                                                 <SingleRiskReport
//                                                   risk={risk}
//                                                   sectionName={category}
//                                                   category={risk.category}
//                                                   modelId={risk.modelUuid}
//                                                   assessmentUuid={risk.assessmentUuid}
//                                                   onClose={() => handleToggleRisk(category, riskId)}
//                                                 />
//                                               )}
//                                             </Box>
//                                           </Collapse>
//                                         </TableCell>
//                                       </TableRow>
//                                     </React.Fragment>
//                                   );
//                                 })}
//                               </TableBody>
//                             </Table>
//                           )}
//                         </Box>
//                       </Collapse>
//                     </TableCell>
//                   </TableRow>
//                 </React.Fragment>
//               );
//             })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// };

// export default RisksBySourceTable;
// // import React, { useState } from 'react';
// // import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Collapse, Tooltip } from '@mui/material';
// // import { ExpandMore, ExpandLess } from '@mui/icons-material';
// // import { useSelector } from 'react-redux';
// // import RiskReport from './RiskReport';
// // import SecurityRisksReport from './SecurityRiskReport';
// // import RiskCounters from './RiskCounters';

// // const SourceViewTable = ({ reportData, securityReportData }) => {
// //   const [expandedSection, setExpandedSection] = useState({});
// //   const [selectedModel, setSelectedModel] = useState(null);

// //   // Access the models from the risks state to get model names
// //   const models = useSelector(state => state.risks.models);

// //   const handleExpandSection = (sectionName) => {
// //     console.log(`Expanding section: ${sectionName}`);
// //     setExpandedSection(prev => ({ ...prev, [sectionName]: !prev[sectionName] }));
// //   };

// //   const handleSelectModel = (modelData, sectionName) => {
// //     console.log(`Selecting model: ${modelData ? modelData.modelName : 'null'} in section: ${sectionName}`);

// //     if (sectionName === 'security') {
// //       const selectedSecurityModel = {
// //         ...securityReportData.dataByModel[modelData.modelUuid],
// //         modelName: models[modelData.modelUuid]?.modelName || 'Unknown Model'
// //       };
// //       console.log('Selected security model:', selectedSecurityModel);
// //       setSelectedModel({ model: selectedSecurityModel, sectionName });
// //     } else {
// //       setSelectedModel({ model: modelData, sectionName });
// //     }
// //   };

// //   const getModelName = (modelUuid) => {
// //     const modelName = models[modelUuid]?.modelName || "Unknown Model";
// //     console.log(`Fetched model name for UUID ${modelUuid}: ${modelName}`);
// //     return modelName;
// //   };

// //   const toTitleCase = (str) => {
// //     return str
// //       .replace(/([A-Z])/g, ' $1')
// //       .replace(/([A-Z]+)/g, (match) => match.toUpperCase())
// //       .trim()
// //       .split(/\s+/)
// //       .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
// //       .join(' ');
// //   };

// //   const calculateRiskCounts = (modelsData) => {
// //     const counts = { critical: 0, high: 0, significant: 0, moderate: 0, low: 0, negligible: 0, none: 0 };
// //     Object.values(modelsData).forEach((modelData) => {
// //       modelData.risks.forEach((risk) => {
// //         counts[risk.priority] = (counts[risk.priority] || 0) + 1;
// //       });
// //     });
// //     return counts;
// //   };

// //   return (
// //     <TableContainer component={Paper}>
// //       <Table>
// //         <TableHead>
// //           <TableRow>
// //             <TableCell sx={{ fontWeight: 'bold' }}>Risk Source</TableCell>
// //             <TableCell sx={{ fontWeight: 'bold' }}>Risks By Priority</TableCell>
// //             <TableCell sx={{ fontWeight: 'bold' }}>Affected Models</TableCell>
// //           </TableRow>
// //         </TableHead>
// //         <TableBody>
// //           {Object.entries(reportData).map(([sectionName, sectionData]) => {
// //             const riskCounts = calculateRiskCounts(sectionData.models);

// //             return (
// //               <React.Fragment key={sectionName}>
// //                 <TableRow>
// //                   <TableCell>
// //                     <IconButton onClick={() => handleExpandSection(sectionName)}>
// //                       {expandedSection[sectionName] ? <ExpandLess /> : <ExpandMore />}
// //                     </IconButton>
// //                     {toTitleCase(sectionName)}
// //                   </TableCell>
// //                   <TableCell>
// //                     <RiskCounters riskCounts={riskCounts} />
// //                   </TableCell>
// //                   <TableCell>{Object.keys(sectionData.models).length}</TableCell>
// //                 </TableRow>
// //                 {expandedSection[sectionName] && (
// //                   <TableRow>
// //                     <TableCell colSpan={3}>
// //                       <Collapse in={expandedSection[sectionName]} timeout="auto" unmountOnExit>
// //                         <Table size="small">
// //                           <TableHead>
// //                             <TableRow>
// //                               <TableCell sx={{ fontWeight: 'bold' }}>Model Name</TableCell>
// //                               <TableCell sx={{ fontWeight: 'bold' }}>Risks</TableCell>
// //                               <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
// //                             </TableRow>
// //                           </TableHead>
// //                           <TableBody>
// //                             {Object.entries(sectionData.models).map(([modelKey, modelData]) => (
// //                               <React.Fragment key={modelKey}>
// //                                 <TableRow onClick={() => handleSelectModel(modelData, sectionName)}>
// //                                   <TableCell>{sectionName === 'security' ? getModelName(modelKey) : modelData.modelName}</TableCell>
// //                                   <TableCell>{modelData.risks.length}</TableCell>
// //                                   <TableCell>
// //                                     <Tooltip title="Show Risks & Controls">
// //                                       <IconButton onClick={() => setSelectedModel(prev => prev?.model?.modelUuid === modelData.modelUuid ? null : { model: modelData, sectionName })}>
// //                                         {selectedModel?.model?.modelUuid === modelData.modelUuid ? <ExpandLess /> : <ExpandMore />}
// //                                       </IconButton>
// //                                     </Tooltip>
// //                                   </TableCell>
// //                                 </TableRow>
// //                                 {selectedModel?.model?.modelUuid === modelData.modelUuid && selectedModel?.sectionName === sectionName && (
// //                                   <>
// //                                     {console.log(`Rendering report for modelId: ${modelData.modelId} in section: ${sectionName}`)}
// //                                     {console.log('Selected Model for Risk Report Details:', selectedModel.model)}
// //                                     <TableRow>
// //                                       <TableCell colSpan={3}>
// //                                         {sectionName === 'security' ? (
// //                                           <>
// //                                             {console.log('Rendering SecurityRisksReport')}
// //                                             <SecurityRisksReport model={selectedModel.model} />
// //                                           </>
// //                                         ) : (
// //                                           <>
// //                                             {console.log('Rendering RiskReport')}
// //                                             <RiskReport model={selectedModel.model} sectionName={sectionName} />
// //                                           </>
// //                                         )}
// //                                       </TableCell>
// //                                     </TableRow>
// //                                   </>
// //                                 )}
// //                               </React.Fragment>
// //                             ))}
// //                           </TableBody>
// //                         </Table>
// //                       </Collapse>
// //                     </TableCell>
// //                   </TableRow>
// //                 )}
// //               </React.Fragment>
// //             );
// //           })}
// //         </TableBody>
// //       </Table>
// //     </TableContainer>
// //   );
// // };

// // export default SourceViewTable;

