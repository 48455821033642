// // src/riskgpt/src/RootCauseAnalysis.js


import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Typography,
  Drawer,
  Divider,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  Paper,
} from '@mui/material';
import {
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@mui/icons-material';
import AutosaveIndicator from './AutoSaveIndicator.js';
import debounce from 'lodash/debounce';
import { setFormData, saveRiskSource, saveCauses } from './redux/actions/riskActions.js';

const RootCauseAnalysis = ({
  selectedRisk,
  open,
  onClose,
  modelId,
  assessmentUuid,
  dispatch,
  toTitleCase,
}) => {
  // Component Render Log
  console.log('RootCauseAnalysis Rendered with props:', {
    selectedRisk,
    open,
    modelId,
    assessmentUuid,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Identify Risk Sources', 'Identify Root Causes'];
  const [hoverStates, setHoverStates] = useState({});

  const [localSnackbar, setLocalSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // Debounced functions for saving risk sources and causes
  const debouncedSaveRiskSource = useMemo(
    () =>
      debounce(async (riskSourceData) => {
        console.log('Debounced Save Risk Source Triggered with data:', riskSourceData);
        setIsSaving(true);
        try {
          await dispatch(saveRiskSource(riskSourceData));
          console.log('Risk Source saved successfully:', riskSourceData);
          setIsSaving(false);
          setIsSaved(true);
          setTimeout(() => setIsSaved(false), 2000);
        } catch (error) {
          console.error('Error saving risk source:', error);
          setLocalSnackbar({
            open: true,
            message: error.message || 'Failed to save risk source.',
            severity: 'error',
          });
          setIsSaving(false);
        }
      }, 1000),
    [dispatch]
  );

  const debouncedSaveCauses = useMemo(
    () =>
      debounce(async (causesData) => {
        console.log('Debounced Save Causes Triggered with data:', causesData);
        setIsSaving(true);
        try {
          await dispatch(saveCauses(causesData));
          console.log('Causes saved successfully:', causesData);
          setIsSaving(false);
          setIsSaved(true);
          setTimeout(() => setIsSaved(false), 2000);
        } catch (error) {
          console.error('Error saving causes:', error);
          setLocalSnackbar({
            open: true,
            message: error.message || 'Failed to save causes.',
            severity: 'error',
          });
          setIsSaving(false);
        }
      }, 1000),
    [dispatch]
  );

  // Cleanup on unmount
  useEffect(() => {
    console.log('RootCauseAnalysis component mounted.');
    return () => {
      console.log('RootCauseAnalysis component unmounted. Cleaning up debounced functions.');
      debouncedSaveRiskSource.cancel();
      debouncedSaveCauses.cancel();
    };
  }, [debouncedSaveRiskSource, debouncedSaveCauses]);

  const handleSnackbarClose = () => {
    console.log('Snackbar closed.');
    setLocalSnackbar((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    // Reset the Stepper to the first step whenever a new risk source is selected
    console.log('selectedRisk changed:', selectedRisk);
    setActiveStep(0);
  }, [selectedRisk]);

  const handleFieldChange = (
    section,
    riskSource,
    fieldType,
    fieldName,
    subCategory = null,
    event
  ) => {
    let value;
    switch (fieldType) {
      case 'text':
      case 'dropdown':
        value = event.target.value;
        break;
      case 'checkbox':
        value = event.target.checked;
        break;
      case 'multiselect':
        value = Array.isArray(event.target.value)
          ? event.target.value
          : [event.target.value];
        break;
      default:
        value = event.target.value;
    }

    console.log('Field Change Detected:', {
      section,
      riskSource,
      fieldType,
      fieldName,
      subCategory,
      value,
    });

    let updatedFormData = { ...selectedRisk };

    if (subCategory) {
      // Handling changes to causes fields
      if (!updatedFormData.riskSource.causes[subCategory]) {
        console.error(
          `Sub-category "${subCategory}" does not exist under risk source "${riskSource}".`
        );
        return;
      }

      updatedFormData.riskSource.causes[subCategory].value = value;
    } else {
      // Handling changes to risk source fields
      if (riskSource) {
        updatedFormData.riskSource.value = value;
      } else {
        // Handle non-risk source fields if any
        // Add your logic here
        console.warn('Handling non-risk source fields is not implemented.');
      }
    }

    // Dispatch the updated form data to Redux
    console.log('Dispatching setFormData with:', {
      sectionName: section,
      riskSource,
      subCategory,
      value,
      modelId,
    });
    dispatch(
      setFormData(
        {
          sectionName: section,
          riskSource,
          subCategory, // Include subCategory if updating causes
          value,
        },
        modelId
      )
    );

    // Save the updated risk source or causes to the database
    if (subCategory) {
      // If updating causes
      const causesData = {
        modelId,
        assessmentUuid,
        risk_area: section,
        risk_source: riskSource,
        causes: {
          [subCategory]: value,
        },
      };
      console.log('Saving causes with data:', causesData);
      debouncedSaveCauses(causesData);
    } else {
      // If updating risk source
      const riskSourceData = {
        modelId,
        assessmentUuid,
        risk_area: section,
        risk_source: riskSource,
        values: value,
      };
      console.log('Saving risk source with data:', riskSourceData);
      debouncedSaveRiskSource(riskSourceData);
    }
  };

  const handleFieldBlur = () => {
    console.log('Field blurred. Flushing debounced save functions.');
    debouncedSaveRiskSource.flush();
    debouncedSaveCauses.flush();
  };

  /**
 * Renders the risk sources in the first step with identical design and behavior to renderCauses.
 * This includes the multiselect field with the help button inside the adornment.
 * @returns JSX.Element
 */
const renderSources = () => {
  console.log('Rendering Sources with selectedRisk:', selectedRisk);
  if (!selectedRisk || !selectedRisk.riskSource) {
    console.error('No selectedRisk or riskSource available.');
    return null;
  }

  const { name, type, value, options, description } = selectedRisk.riskSource;

  console.log('Risk Source Details:', { name, type, value, options, description });

  if (type === 'multiselect') {
    return (
      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel>{toTitleCase(name)}</InputLabel>
        <Select
          multiple
          label={toTitleCase(name)}
          value={value || []}
          onChange={(e) =>
            handleFieldChange(
              selectedRisk.section,
              name,
              type,
              name,
              null,
              e
            )
          }
          onBlur={() =>
            handleFieldBlur(
              selectedRisk.section,
              name,
              name
            )
          }
          renderValue={(selected) => {
            console.log('Rendering multiselect value:', selected);
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((val) => (
                  <Chip key={val} label={val} />
                ))}
              </Box>
            );
          }}
          onMouseEnter={() => handleMouseEnter(`${name}-multiselect`)}
          onMouseLeave={() => handleMouseLeave(`${name}-multiselect`)}
          InputProps={{
            endAdornment:
              hoverStates[`${name}-multiselect`] && (
                <Tooltip
                  title={description || 'No additional information available'}
                  arrow
                >
                  <IconButton
                    edge="end"
                    style={{ color: '#1976d2', marginRight: '12px' }}
                    size="small"
                  >
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ),
          }}
        >
          {options?.map((option, idx) => {
            console.log(`Rendering multiselect option: ${option}`);
            return (
              <MenuItem key={idx} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  } else if (type === 'text') {
    return (
      <FormControl fullWidth margin="normal" variant="outlined">
        <TextField
          id={`${name}_value`}
          name={`${name}_value`}
          value={value || ''}
          label={toTitleCase(name)}
          onChange={(e) =>
            handleFieldChange(
              selectedRisk.section,
              name,
              type,
              name,
              null,
              e
            )
          }
          onBlur={() =>
            handleFieldBlur(
              selectedRisk.section,
              name,
              name
            )
          }
          variant="outlined"
          multiline
          onMouseEnter={() => handleMouseEnter(`${name}-textfield`)}
          onMouseLeave={() => handleMouseLeave(`${name}-textfield`)}
          InputProps={{
            endAdornment:
              hoverStates[`${name}-textfield`] && (
                <Tooltip
                  title={description || 'No additional information available'}
                  arrow
                >
                  <IconButton
                    edge="end"
                    style={{ color: '#1976d2', marginRight: '12px' }}
                    size="small"
                  >
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ),
          }}
        />
      </FormControl>
    );
  } else {
    console.warn(`Unsupported riskSource type: ${type}`);
    return null;
  }
};

/**
 * Renders the causes associated with a specific risk.
 * @param {Object} causes - The causes object containing categorized causes.
 * @param {String} section - The current section name.
 * @param {String} riskSource - The current risk source name.
 * @returns JSX.Element
 */
const renderCausesInternal = (causes, section, riskSource) => {
  console.log('Rendering Causes with data:', { causes, section, riskSource });
  if (!causes || typeof causes !== 'object') {
    console.error('Invalid causes:', causes);
    return null;
  }

  return (
    <Paper elevation={1} style={{ padding: '16px', marginTop: '16px' }}>
      {Object.entries(causes).map(([causeCategory, causeContent]) => {
        console.log(`Rendering cause category: ${causeCategory}`, causeContent);
        return (
          <Box key={causeCategory} mb={2}>
            <Typography variant="subtitle2" gutterBottom>
              {toTitleCase(causeCategory)}
            </Typography>
            {causeContent.type === 'multiselect' && (
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>{toTitleCase(causeCategory)}</InputLabel>
                <Select
                  multiple
                  label={toTitleCase(causeCategory)}
                  value={causeContent.value || []}
                  onChange={(e) => {
                    console.log(`Cause Category "${causeCategory}" changed to:`, e.target.value);
                    handleFieldChange(
                      section,
                      riskSource,
                      causeContent.type,
                      causeCategory,
                      causeCategory,
                      e
                    );
                  }}
                  onBlur={() =>
                    handleFieldBlur(section, riskSource, causeCategory)
                  }
                  renderValue={(selected) => {
                    console.log(`Rendering multiselect value for ${causeCategory}:`, selected);
                    return (
                      <div>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </div>
                    );
                  }}
                  onMouseEnter={() => handleMouseEnter(`${causeCategory}-multiselect`)}
                  onMouseLeave={() => handleMouseLeave(`${causeCategory}-multiselect`)}
                  endAdornment={
                    hoverStates[`${causeCategory}-multiselect`] && (
                      <Tooltip
                        title={causeContent.description || 'No additional information available'}
                        arrow
                      >
                        <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                          <HelpOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                >
                  {causeContent.options?.map((option, idx) => {
                    console.log(`Rendering cause multiselect option: ${option}`);
                    return (
                      <MenuItem key={idx} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            {causeContent.type === 'select' && (
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>{toTitleCase(causeCategory)}</InputLabel>
                <Select
                  label={toTitleCase(causeCategory)}
                  value={causeContent.value || ''}
                  onChange={(e) => {
                    console.log(`Cause Category "${causeCategory}" selected:`, e.target.value);
                    handleFieldChange(
                      section,
                      riskSource,
                      causeContent.type,
                      causeCategory,
                      causeCategory,
                      e
                    );
                  }}
                  onBlur={() =>
                    handleFieldBlur(section, riskSource, causeCategory)
                  }
                  onMouseEnter={() => handleMouseEnter(`${causeCategory}-select`)}
                  onMouseLeave={() => handleMouseLeave(`${causeCategory}-select`)}
                  endAdornment={
                    hoverStates[`${causeCategory}-select`] && (
                      <Tooltip
                        title={causeContent.description || 'No additional information available'}
                        arrow
                      >
                        <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                          <HelpOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                >
                  {causeContent.options?.map((option, idx) => {
                    console.log(`Rendering cause select option: ${option}`);
                    return (
                      <MenuItem key={idx} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            {/* Render TextField for "Other" causes */}
              {causeContent.type === 'text' && (
                <FormControl fullWidth margin="normal" variant="outlined">
                  <TextField
                    id={`${causeCategory}-text`}
                    name={`${causeCategory}-text`}
                    value={causeContent.value || ''}
                    label={toTitleCase(causeCategory)}
                    onChange={(e) => {
                      console.log(`Cause Category "${causeCategory}" text changed to:`, e.target.value);
                      handleFieldChange(
                        section,
                        riskSource,
                        causeContent.type,
                        causeCategory,
                        causeCategory,
                        e
                      );
                    }}
                    onBlur={() =>
                      handleFieldBlur(section, riskSource, causeCategory)
                    }
                    variant="outlined"
                    multiline
                    onMouseEnter={() => handleMouseEnter(`${causeCategory}-text`)}
                    onMouseLeave={() => handleMouseLeave(`${causeCategory}-text`)}
                    InputProps={{
                      endAdornment:
                        hoverStates[`${causeCategory}-text`] && (
                          <Tooltip
                            title={causeContent.description || 'No additional information available'}
                            arrow
                          >
                            <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
                              <HelpOutlineOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                    }}
                  />
                </FormControl>
              )}
          </Box>
        );
      })}
    </Paper>
  );
};

const handleMouseEnter = (fieldName) => {
  console.log(`Mouse entered on field: ${fieldName}`);
  setHoverStates((prev) => ({ ...prev, [fieldName]: true }));
};

const handleMouseLeave = (fieldName) => {
  console.log(`Mouse left from field: ${fieldName}`);
  setHoverStates((prev) => ({ ...prev, [fieldName]: false }));
};

return (
  <Drawer
    anchor="right"
    open={open}
    onClose={onClose}
    PaperProps={{ sx: { width: { xs: '100%', sm: 600 }, p: 3 } }} // Responsive width
  >
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
        Risk Source & Root Cause Analysis
      </Typography>
      {selectedRisk && selectedRisk.riskSource && (
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            mb: 2,
            textAlign: 'center',
          }}
        >
          Risk Area: {toTitleCase(selectedRisk.section)} | Risk Source: {toTitleCase(selectedRisk.riskSource.name)}
        </Typography>
      )}
      <Divider sx={{ mb: 2 }} />

      {/* Stepper */}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          console.log(`Rendering step: ${label}`);
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {/* Step Content */}
      <Box sx={{ mt: 2, mb: 2 }}>
        {activeStep === 0 && selectedRisk && (
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Step 1: Identify Risk Sources
            </Typography>
            <Box sx={{ position: 'relative', mb: 2 }}>
              <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
            </Box>
            {renderSources()}
          </Box>
        )}
        {activeStep === 1 && selectedRisk && (
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Step 2: Identify Root Causes
            </Typography>
            <Box sx={{ position: 'relative', mb: 2 }}>
              <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
            </Box>
            {renderCausesInternal(
              selectedRisk.riskSource.causes,
              selectedRisk.section,
              selectedRisk.riskSource.name
            )}
          </Box>
        )}
      </Box>

      {/* Navigation Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          disabled={activeStep === 0}
          onClick={() => {
            console.log('Navigating Back from step:', activeStep);
            setActiveStep((prev) => prev - 1);
          }}
          startIcon={<NavigateBeforeIcon />}
        >
          Back
        </Button>
        {activeStep < steps.length - 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log('Navigating Next from step:', activeStep);
              setActiveStep((prev) => prev + 1);
            }}
            endIcon={<NavigateNextIcon />}
          >
            Next
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log('Finish button clicked.');
              onClose();
            }}
          >
            Finish
          </Button>
        )}
      </Box>

      {/* Snackbar for Drawer */}
      <Snackbar
        open={localSnackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={localSnackbar.severity}
          sx={{ width: '100%' }}
        >
          {localSnackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  </Drawer>
);
}

// PropTypes for type checking
RootCauseAnalysis.propTypes = {
  selectedRisk: PropTypes.shape({
    section: PropTypes.string.isRequired,
    riskSource: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.any,
      options: PropTypes.array,
      description: PropTypes.string,
      causes: PropTypes.object,
    }).isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modelId: PropTypes.string.isRequired,
  assessmentUuid: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  toTitleCase: PropTypes.func.isRequired,
};


export default RootCauseAnalysis;
// import React, { useState, useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
// import {
//   FormControl,
//   TextField,
//   InputLabel,
//   Select,
//   MenuItem,
//   Chip,
//   Typography,
//   Drawer,
//   Divider,
//   Box,
//   Stepper,
//   Step,
//   StepLabel,
//   Button,
//   Snackbar,
//   Alert,
//   IconButton,
//   Tooltip,
//   Paper,
// } from '@mui/material';
// import {
//   HelpOutlineOutlined as HelpOutlineOutlinedIcon,
//   NavigateNext as NavigateNextIcon,
//   NavigateBefore as NavigateBeforeIcon,
// } from '@mui/icons-material';
// import AutosaveIndicator from './AutoSaveIndicator.js';
// import debounce from 'lodash/debounce';
// import { setFormData, saveRiskSource, saveCauses } from './redux/actions/riskActions.js';

// const RootCauseAnalysis = ({
//   selectedRisk,
//   open,
//   onClose,
//   modelId,
//   assessmentUuid,
//   dispatch,
//   toTitleCase,
// }) => {
//   const [isSaving, setIsSaving] = useState(false);
//   const [isSaved, setIsSaved] = useState(false);
//   const [activeStep, setActiveStep] = useState(0);
//   const steps = ['Identify Risk Sources', 'Identify Root Causes'];
//   const [hoverStates, setHoverStates] = useState({});

//   const [localSnackbar, setLocalSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'info',
//   });

//   // Debounced functions for saving risk sources and causes
//   const debouncedSaveRiskSource = useMemo(
//     () =>
//       debounce(async (riskSourceData) => {
//         setIsSaving(true);
//         try {
//           await dispatch(saveRiskSource(riskSourceData));
//           setIsSaving(false);
//           setIsSaved(true);
//           setTimeout(() => setIsSaved(false), 2000);
//         } catch (error) {
//           console.error('Error saving risk source:', error);
//           setLocalSnackbar({
//             open: true,
//             message: error.message || 'Failed to save risk source.',
//             severity: 'error',
//           });
//           setIsSaving(false);
//         }
//       }, 1000),
//     [dispatch]
//   );

//   const debouncedSaveCauses = useMemo(
//     () =>
//       debounce(async (causesData) => {
//         setIsSaving(true);
//         try {
//           await dispatch(saveCauses(causesData));
//           setIsSaving(false);
//           setIsSaved(true);
//           setTimeout(() => setIsSaved(false), 2000);
//         } catch (error) {
//           console.error('Error saving causes:', error);
//           setLocalSnackbar({
//             open: true,
//             message: error.message || 'Failed to save causes.',
//             severity: 'error',
//           });
//           setIsSaving(false);
//         }
//       }, 1000),
//     [dispatch]
//   );

//   // Cleanup on unmount
//   useEffect(() => {
//     return () => {
//       debouncedSaveRiskSource.cancel();
//       debouncedSaveCauses.cancel();
//     };
//   }, [debouncedSaveRiskSource, debouncedSaveCauses]);

//   const handleSnackbarClose = () => {
//     setLocalSnackbar((prev) => ({ ...prev, open: false }));
//   };

//   useEffect(() => {
//     // Reset the Stepper to the first step whenever a new risk source is selected
//     setActiveStep(0);
//   }, [selectedRisk]);

//   const handleFieldChange = (
//     section,
//     riskSource,
//     fieldType,
//     fieldName,
//     subCategory = null,
//     event
//   ) => {
//     let value;
//     switch (fieldType) {
//       case 'text':
//       case 'dropdown':
//         value = event.target.value;
//         break;
//       case 'checkbox':
//         value = event.target.checked;
//         break;
//       case 'multiselect':
//         value = Array.isArray(event.target.value)
//           ? event.target.value
//           : [event.target.value];
//         break;
//       default:
//         value = event.target.value;
//     }

//     let updatedFormData = { ...selectedRisk };

//     if (subCategory) {
//       // Handling changes to causes fields
//       if (!updatedFormData.riskSource.causes[subCategory]) {
//         console.error(
//           `Sub-category "${subCategory}" does not exist under risk source "${riskSource}".`
//         );
//         return;
//       }

//       updatedFormData.riskSource.causes[subCategory].value = value;
//     } else {
//       // Handling changes to risk source fields
//       if (riskSource) {
//         updatedFormData.riskSource.value = value;
//       } else {
//         // Handle non-risk source fields if any
//         // Add your logic here
//       }
//     }

//     // Dispatch the updated form data to Redux
//     dispatch(
//       setFormData(
//         {
//           sectionName: section,
//           riskSource,
//           subCategory, // Include subCategory if updating causes
//           value,
//         },
//         modelId
//       )
//     );

//     // Save the updated risk source or causes to the database
//     if (subCategory) {
//       // If updating causes
//       const causesData = {
//         modelId,
//         assessmentUuid,
//         risk_area: section,
//         risk_source: riskSource,
//         causes: {
//           [subCategory]: value,
//         },
//       };
//       debouncedSaveCauses(causesData);
//     } else {
//       // If updating risk source
//       const riskSourceData = {
//         modelId,
//         assessmentUuid,
//         risk_area: section,
//         risk_source: riskSource,
//         values: value,
//       };
//       debouncedSaveRiskSource(riskSourceData);
//     }
//   };

//   const handleFieldBlur = () => {
//     debouncedSaveRiskSource.flush();
//     debouncedSaveCauses.flush();
//   };

//   /**
//  * Renders the risk sources in the first step with identical design and behavior to renderCauses.
//  * This includes the multiselect field with the help button inside the adornment.
//  * @returns JSX.Element
//  */
// const renderSources = () => {
//   if (!selectedRisk || !selectedRisk.riskSource) {
//     console.error('No selectedRisk or riskSource available.');
//     return null;
//   }

//   const { name, type, value, options, description } = selectedRisk.riskSource;

//   if (type === 'multiselect') {
//     return (
//       <FormControl fullWidth margin="normal" variant="outlined">
//         <InputLabel>{toTitleCase(name)}</InputLabel>
//         <Select
//           multiple
//           label={toTitleCase(name)}
//           value={value || []}
//           onChange={(e) =>
//             handleFieldChange(
//               selectedRisk.section,
//               name,
//               type,
//               name,
//               null,
//               e
//             )
//           }
//           onBlur={() =>
//             handleFieldBlur(
//               selectedRisk.section,
//               name,
//               name
//             )
//           }
//           renderValue={(selected) => (
//             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//               {selected.map((val) => (
//                 <Chip key={val} label={val} />
//               ))}
//             </Box>
//           )}
//           onMouseEnter={() => handleMouseEnter(`${name}-multiselect`)}
//           onMouseLeave={() => handleMouseLeave(`${name}-multiselect`)}
//           InputProps={{
//             endAdornment:
//               hoverStates[`${name}-multiselect`] && (
//                 <Tooltip
//                   title={description || 'No additional information available'}
//                   arrow
//                 >
//                   <IconButton
//                     edge="end"
//                     style={{ color: '#1976d2', marginRight: '12px' }}
//                     size="small"
//                   >
//                     <HelpOutlineOutlinedIcon />
//                   </IconButton>
//                 </Tooltip>
//               ),
//           }}
//         >
//           {options?.map((option, idx) => (
//             <MenuItem key={idx} value={option}>
//               {option}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     );
//   } else if (type === 'text') {
//     return (
//       <FormControl fullWidth margin="normal" variant="outlined">
//         <TextField
//           id={`${name}_value`}
//           name={`${name}_value`}
//           value={value || ''}
//           label={toTitleCase(name)}
//           onChange={(e) =>
//             handleFieldChange(
//               selectedRisk.section,
//               name,
//               type,
//               name,
//               null,
//               e
//             )
//           }
//           onBlur={() =>
//             handleFieldBlur(
//               selectedRisk.section,
//               name,
//               name
//             )
//           }
//           variant="outlined"
//           multiline
//           onMouseEnter={() => handleMouseEnter(`${name}-textfield`)}
//           onMouseLeave={() => handleMouseLeave(`${name}-textfield`)}
//           InputProps={{
//             endAdornment:
//               hoverStates[`${name}-textfield`] && (
//                 <Tooltip
//                   title={description || 'No additional information available'}
//                   arrow
//                 >
//                   <IconButton
//                     edge="end"
//                     style={{ color: '#1976d2', marginRight: '12px' }}
//                     size="small"
//                   >
//                     <HelpOutlineOutlinedIcon />
//                   </IconButton>
//                 </Tooltip>
//               ),
//           }}
//         />
//       </FormControl>
//     );
//   } else {
//     console.warn(`Unsupported riskSource type: ${type}`);
//     return null;
//   }
// };

//   /**
//    * Renders the causes associated with a specific risk.
//    * @param {Object} causes - The causes object containing categorized causes.
//    * @param {String} section - The current section name.
//    * @param {String} riskSource - The current risk source name.
//    * @returns JSX.Element
//    */
//   const renderCausesInternal = (causes, section, riskSource) => {
//     if (!causes || typeof causes !== 'object') {
//       console.error('Invalid causes:', causes);
//       return null;
//     }

//     return (
//       <Paper elevation={1} style={{ padding: '16px', marginTop: '16px' }}>
//         {Object.entries(causes).map(([causeCategory, causeContent]) => (
//           <Box key={causeCategory} mb={2}>
//             <Typography variant="subtitle2" gutterBottom>
//               {toTitleCase(causeCategory)}
//             </Typography>
//             {causeContent.type === 'multiselect' && (
//               <FormControl fullWidth margin="normal" variant="outlined">
//                 <InputLabel>{toTitleCase(causeCategory)}</InputLabel>
//                 <Select
//                   multiple
//                   label={toTitleCase(causeCategory)}
//                   value={causeContent.value || []}
//                   onChange={(e) => {
//                     handleFieldChange(
//                       section,
//                       riskSource,
//                       causeContent.type,
//                       causeCategory,
//                       causeCategory,
//                       e
//                     );
//                   }}
//                   onBlur={() =>
//                     handleFieldBlur(section, riskSource, causeCategory)
//                   }
//                   renderValue={(selected) => (
//                     <div>
//                       {selected.map((value) => (
//                         <Chip key={value} label={value} />
//                       ))}
//                     </div>
//                   )}
//                   onMouseEnter={() => handleMouseEnter(`${causeCategory}-multiselect`)}
//                   onMouseLeave={() => handleMouseLeave(`${causeCategory}-multiselect`)}
//                   endAdornment={
//                     hoverStates[`${causeCategory}-multiselect`] && (
//                       <Tooltip
//                         title={causeContent.description || 'No additional information available'}
//                         arrow
//                       >
//                         <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
//                           <HelpOutlineOutlinedIcon />
//                         </IconButton>
//                       </Tooltip>
//                     )
//                   }
//                 >
//                   {causeContent.options?.map((option, idx) => (
//                     <MenuItem key={idx} value={option}>
//                       {option}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             )}

//             {causeContent.type === 'select' && (
//               <FormControl fullWidth margin="normal" variant="outlined">
//                 <InputLabel>{toTitleCase(causeCategory)}</InputLabel>
//                 <Select
//                   label={toTitleCase(causeCategory)}
//                   value={causeContent.value || ''}
//                   onChange={(e) => {
//                     handleFieldChange(
//                       section,
//                       riskSource,
//                       causeContent.type,
//                       causeCategory,
//                       causeCategory,
//                       e
//                     );
//                   }}
//                   onBlur={() =>
//                     handleFieldBlur(section, riskSource, causeCategory)
//                   }
//                   onMouseEnter={() => handleMouseEnter(`${causeCategory}-select`)}
//                   onMouseLeave={() => handleMouseLeave(`${causeCategory}-select`)}
//                   endAdornment={
//                     hoverStates[`${causeCategory}-select`] && (
//                       <Tooltip
//                         title={causeContent.description || 'No additional information available'}
//                         arrow
//                       >
//                         <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
//                           <HelpOutlineOutlinedIcon />
//                         </IconButton>
//                       </Tooltip>
//                     )
//                   }
//                 >
//                   {causeContent.options?.map((option, idx) => (
//                     <MenuItem key={idx} value={option}>
//                       {option}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             )}

//             {/* Render TextField for "Other" causes */}
//               {causeContent.type === 'text' && (
//                 <FormControl fullWidth margin="normal" variant="outlined">
//                   <TextField
//                     id={`${causeCategory}-text`}
//                     name={`${causeCategory}-text`}
//                     value={causeContent.value || ''}
//                     label={toTitleCase(causeCategory)}
//                     onChange={(e) =>
//                       handleFieldChange(
//                         section,
//                         riskSource,
//                         causeContent.type,
//                         causeCategory,
//                         causeCategory,
//                         e
//                       )
//                     }
//                     onBlur={() =>
//                       handleFieldBlur(section, riskSource, causeCategory)
//                     }
//                     variant="outlined"
//                     multiline
//                     onMouseEnter={() => handleMouseEnter(`${causeCategory}-text`)}
//                     onMouseLeave={() => handleMouseLeave(`${causeCategory}-text`)}
//                     InputProps={{
//                       endAdornment:
//                         hoverStates[`${causeCategory}-text`] && (
//                           <Tooltip
//                             title={causeContent.description || 'No additional information available'}
//                             arrow
//                           >
//                             <IconButton edge="end" style={{ color: '#1976d2', marginRight: '12px' }}>
//                               <HelpOutlineOutlinedIcon />
//                             </IconButton>
//                           </Tooltip>
//                         ),
//                     }}
//                   />
//                 </FormControl>
//               )}
//           </Box>
//         ))}
//       </Paper>
//     );
//   };

//   const handleMouseEnter = (fieldName) => {
//     setHoverStates((prev) => ({ ...prev, [fieldName]: true }));
//   };

//   const handleMouseLeave = (fieldName) => {
//     setHoverStates((prev) => ({ ...prev, [fieldName]: false }));
//   };

//   return (
//     <Drawer
//       anchor="right"
//       open={open}
//       onClose={onClose}
//       PaperProps={{ sx: { width: { xs: '100%', sm: 600 }, p: 3 } }} // Responsive width
//     >
//       <Box sx={{ width: '100%', position: 'relative' }}>
//         <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
//           Risk Source & Root Cause Analysis
//         </Typography>
//         {selectedRisk && selectedRisk.riskSource && (
//           <Typography
//             variant="subtitle1"
//             sx={{
//               fontWeight: 600,
//               mb: 2,
//               textAlign: 'center',
//             }}
//           >
//             Risk Area: {toTitleCase(selectedRisk.section)} | Risk Source: {toTitleCase(selectedRisk.riskSource.name)}
//           </Typography>
//         )}
//         <Divider sx={{ mb: 2 }} />

//         {/* Stepper */}
//         <Stepper activeStep={activeStep} alternativeLabel>
//           {steps.map((label) => (
//             <Step key={label}>
//               <StepLabel>{label}</StepLabel>
//             </Step>
//           ))}
//         </Stepper>

//         {/* Step Content */}
//         <Box sx={{ mt: 2, mb: 2 }}>
//           {activeStep === 0 && selectedRisk && (
//             <Box>
//               <Typography variant="subtitle1" gutterBottom>
//                 Step 1: Identify Risk Sources
//               </Typography>
//               <Box sx={{ position: 'relative', mb: 2 }}>
//                 <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
//               </Box>
//               {renderSources()}
//             </Box>
//           )}
//           {activeStep === 1 && selectedRisk && (
//             <Box>
//               <Typography variant="subtitle1" gutterBottom>
//                 Step 2: Identify Root Causes
//               </Typography>
//               <Box sx={{ position: 'relative', mb: 2 }}>
//                 <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
//               </Box>
//               {renderCausesInternal(
//                 selectedRisk.riskSource.causes,
//                 selectedRisk.section,
//                 selectedRisk.riskSource.name
//               )}
//             </Box>
//           )}
//         </Box>

//         {/* Navigation Buttons */}
//         <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//           <Button
//             disabled={activeStep === 0}
//             onClick={() => setActiveStep((prev) => prev - 1)}
//             startIcon={<NavigateBeforeIcon />}
//           >
//             Back
//           </Button>
//           {activeStep < steps.length - 1 && (
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => setActiveStep((prev) => prev + 1)}
//               endIcon={<NavigateNextIcon />}
//             >
//               Next
//             </Button>
//           )}
//           {activeStep === steps.length - 1 && (
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={onClose}
//             >
//               Finish
//             </Button>
//           )}
//         </Box>

//         {/* Snackbar for Drawer */}
//         <Snackbar
//           open={localSnackbar.open}
//           autoHideDuration={6000}
//           onClose={handleSnackbarClose}
//         >
//           <Alert
//             onClose={handleSnackbarClose}
//             severity={localSnackbar.severity}
//             sx={{ width: '100%' }}
//           >
//             {localSnackbar.message}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Drawer>
//   );
// };

// // PropTypes for type checking
// RootCauseAnalysis.propTypes = {
//   selectedRisk: PropTypes.shape({
//     section: PropTypes.string.isRequired,
//     riskSource: PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       type: PropTypes.string.isRequired,
//       value: PropTypes.any,
//       options: PropTypes.array,
//       description: PropTypes.string,
//       causes: PropTypes.object,
//     }).isRequired,
//   }).isRequired,
//   open: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
//   modelId: PropTypes.string.isRequired,
//   assessmentUuid: PropTypes.string.isRequired,
//   dispatch: PropTypes.func.isRequired,
//   toTitleCase: PropTypes.func.isRequired,
// };

// export default RootCauseAnalysis;
