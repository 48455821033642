// src/riskgpt/src/RiskCard.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  //FormControl,
  Grid,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,
  Button,
  //Chip,
  Tooltip,
} from '@mui/material';
import {
  SmartToyOutlined as SmartToyOutlinedIcon,
  ListAltOutlined as ListAltOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  EditOutlined as EditOutlinedIcon,
} from '@mui/icons-material';
import RootCauseAnalysis from './RootCauseAnalysis.js'; // Import the new component
import { fetchRiskCardData } from './redux/actions/riskActions.js';

const RiskCard = ({ modelId, assessmentUuid, sectionName }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const riskFormData = useSelector(
    (state) => state.risks.models[modelId]?.riskFormData || {}
  );
  const [expandedRows, setExpandedRows] = useState({});

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRisk, setSelectedRisk] = useState(null); // Track the risk source selected for analysis

  useEffect(() => {
    if (modelId && assessmentUuid) {
      setIsLoading(true);
      dispatch(fetchRiskCardData(modelId, assessmentUuid))
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch data:', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, modelId, assessmentUuid]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Optionally handle any global save operations here
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Cleanup
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const toTitleCase = (str) => {
    // First, handle known acronyms
    str = str.replace(/\b(EU|ROC|AUC)\b/g, (match) => match.toUpperCase());

    // Next, format camelCase and PascalCase
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before each capital in camelCase
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Handle sequences of capitals followed by lowercase
      .replace(/\b([Aa]nd)\b/g, '&')
      .split(/\s+/) // Split the string by spaces to capitalize each word
      .map((word) => {
        if (['EU', 'ROC', 'AUC'].includes(word)) {
          return word; // Return the acronym unchanged
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize each word
        }
      })
      .join(' ')
      .trim(); // Trim any extra spaces
  };

  const renderTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell style={{ fontWeight: 'bold', width: '50px' }}></TableCell> {/* Completion Status */}
        <TableCell style={{ fontWeight: 'bold' }}>Risk Source</TableCell>
        <TableCell style={{ fontWeight: 'bold', maxWidth: '250px' }}>Description</TableCell>
        <TableCell style={{ fontWeight: 'bold' }} align="center">
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => {
    const riskSources = riskFormData[sectionName]?.riskSources || [];

    return (
      <TableBody>
        {riskSources.map((riskSource, idx) => {
          const completed = isRiskCompleted(riskSource);
          const isExpanded = expandedRows[riskSource.name] || false;

          return (
            <React.Fragment key={riskSource.name || idx}>
              <TableRow hover>
                {/* Completion Status */}
                <TableCell align="center">
                  {completed ? (
                    <Tooltip title="Assessment Completed" arrow>
                      <CheckCircleIcon className="animated-checkmark" />
                    </Tooltip>
                  ) : (
                    <CheckCircleIcon className="incomplete-checkmark" />
                  )}
                </TableCell>

                {/* Risk Source Name */}
                <TableCell>{toTitleCase(riskSource.name)}</TableCell>

                {/* Description */}
                <TableCell>
                  {riskSource.description ? (
                    <>
                      {isExpanded || riskSource.description.length <= 50
                        ? riskSource.description
                        : `${riskSource.description.substring(0, 50)}...`}
                      {riskSource.description.length > 50 && (
                        <Button
                          size="small"
                          onClick={() =>
                            setExpandedRows({
                              ...expandedRows,
                              [riskSource.name]: !isExpanded,
                            })
                          }
                          style={{ color: '#1976d2', textTransform: 'none', marginLeft: '5px' }}
                        >
                          {isExpanded ? 'Less' : '...'}
                        </Button>
                      )}
                    </>
                  ) : (
                    'No description provided.'
                  )}
                </TableCell>

                {/* Action */}
                <TableCell align="center">
                  <IconButton
                    onClick={() => {
                      setSelectedRisk({ section: sectionName, riskSource });
                      setDrawerOpen(true);
                      //setActiveStep(0); // Reset to Step 1
                    }}
                    aria-label={completed ? 'Edit Risk' : 'Open Root Cause Analysis'}
                    color="primary"
                  >
                    {completed ? <EditOutlinedIcon /> : <ListAltOutlinedIcon />}
                  </IconButton>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
        {riskSources.length === 0 && (
          <TableRow>
            <TableCell colSpan={4} style={{ textAlign: 'center', padding: '20px' }}>
              No risk sources available.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  /**
   * Determines if a risk source is completed.
   * A risk source is considered completed if it has selected values and all its causes have selected values.
   * @param {Object} riskSource - The risk source object.
   * @returns {Boolean}
   */
  const isRiskCompleted = (riskSource) => {
    // Check if riskSource.value has selections
    const hasValues =
      Array.isArray(riskSource.value) ? riskSource.value.length > 0 : Boolean(riskSource.value);

    // Check if all causes have at least one selection
    const causes = riskSource.causes || {};
    const causesCompleted = Object.values(causes).every(
      (cause) =>
        Array.isArray(cause.value) ? cause.value.length > 0 : Boolean(cause.value)
    );

    return hasValues && causesCompleted;
  };

  return (
    <div>
      <Paper elevation={1} style={{ padding: '20px', marginBottom: '16px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          {/* Title and Loading Indicator */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              flex: 1,
            }}
          >
            <Typography variant="h5">{toTitleCase(sectionName)} Risks</Typography>
            {isLoading && (
              <div style={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress size={24} thickness={5} sx={{ color: 'primary.main' }} />
                <SmartToyOutlinedIcon
                  style={{
                    color: 'secondary.light',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '1rem',
                  }}
                />
              </div>
            )}
          </div>
          {/* Removed the global Autosave Indicator */}
        </div>
      </Paper>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <div>
            {riskFormData[sectionName] && (
              <Paper style={{ padding: '20px', boxShadow: '0px 3px 6px rgba(0,0,0,0.1)' }}>
                {/* Render Risk Sources Table */}
                <TableContainer component={Paper}>
                  <Table>
                    {renderTableHead()}
                    {renderTableBody()}
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </div>
        </Grid>
      </Grid>

      {/* Root Cause Analysis Drawer */}
      {selectedRisk && (
        <RootCauseAnalysis
          selectedRisk={selectedRisk}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          modelId={modelId}
          assessmentUuid={assessmentUuid}
          dispatch={dispatch}
          toTitleCase={toTitleCase}
          // steps={steps}
          // activeStep={activeStep}
          // setActiveStep={setActiveStep}
        />
      )}
    </div>
  );
};

export default RiskCard;