import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Paper,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  DialogContentText,
  Menu,
  MenuItem,
  Avatar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth0 } from '@auth0/auth0-react';
import { setModelId, deleteModelAction } from './redux/actions/riskActions';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ImportSageMakerModels from './utils/importSageMakerModels.js';
import ImportBedrockModels from './utils/importBedrockModels.js';
import ModelCardForm from './ModelCardForm';
import { RiskFormGenerator } from './redux/selectors/riskFormGenerator.js';


const HeaderPaper = styled(Paper)({
  padding: '20px',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
  borderRadius: '8px'
});

const AddModelButton = styled(Button)({
  backgroundColor: '#8FB996', // Soft sage green
  color: '#fff',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#7AA882',
  },
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
});

function ModelInventory() {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const models = useSelector((state) => {
    const modelArray = Object.entries(state.risks.models).map(([model_uuid, model]) => ({
      ...model,
      model_uuid,
    }));
    // Sort by created_at descending
    modelArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return modelArray;
  });

  const isLoading = useSelector((state) => state.risks.modelsLoading);
  const riskForm = useSelector(RiskFormGenerator);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openFormModal, setOpenFormModal] = useState(false);
  const [editingModel, setEditingModel] = useState(null); // null => create, else update
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [modelToDelete, setModelToDelete] = useState(null);

  //Cloud models

  const cloudModels = useSelector((state) => state.cloudModels.models);
  const cloudModelsLoading = useSelector((state) => state.cloudModels.loading);
  const [isImporting, setIsImporting] = useState(false);
  const [importMenuAnchorEl, setImportMenuAnchorEl] = useState(null);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddModel = () => {
    setEditingModel(null);
    setOpenFormModal(true);
  };

  const handleEditModel = (model) => {
    setEditingModel(model);
    setOpenFormModal(true);
  };

  const handleDeleteClick = (model) => {
    setModelToDelete(model);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!modelToDelete) return;
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`/.netlify/functions/deleteModel?uuid=${modelToDelete.model_uuid}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const result = await response.json();
      if (result.success) {
        dispatch(deleteModelAction(modelToDelete.model_uuid));
        setSnackbarMessage('Model deleted successfully.');
        setSnackbarSeverity('success');
      } else {
        console.error('Error deleting model:', result.error);
        setSnackbarMessage('Failed to delete model.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('Error deleting model:', error);
      setSnackbarMessage('Failed to delete model.');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
    setDeleteDialogOpen(false);
    setModelToDelete(null);
  };


  const handleImportMenuClick = (event) => {
    setImportMenuAnchorEl(event.currentTarget);
  };

  const handleImportMenuClose = () => {
    setImportMenuAnchorEl(null);
  };

  const handleFormSubmit = async (formData, formType, modelId) => {
    let response;
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'auth.riskgpt.io', 
          scope: 'read:models write:models delete:models' 
        }
      });
  
      const body = { ...formData };
      if (formType === 'update' && modelId) {
        body.modelId = modelId;
      }
  
      // Save or update the model
      if (formType === 'create') {
        response = await fetch('/.netlify/functions/SaveModelCard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(body),
        });
      } else if (formType === 'update' && modelId) {
        response = await fetch(`/.netlify/functions/SaveModelCard/${modelId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(body),
        });
      }
  
      const responseBody = await response.json();
      if (responseBody.success) {
        setSnackbarMessage(responseBody.message);
        setSnackbarSeverity('success');
  
        const { data } = responseBody;
  
        if (formType === 'create' && data) {
          // New model created
          const { model_uuid, model_name, created_at, created_by, avatar, createdByName } = data;
  
          // Attempt to fetch existing assessment
          let assessmentUuid;
          let assessmentStatus;
          let currentStep;
  
          let fetchResponse = await fetch('/.netlify/functions/manageAssessment', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ modelId: model_uuid, operation: 'fetch' }),
          });
  
          if (!fetchResponse.ok) {
            // If fetch fails, create a new assessment
            const createResponse = await fetch('/.netlify/functions/manageAssessment', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                modelId: model_uuid,
                operation: 'update',
                currentStep: 'Not Started',
                assessmentStatus: 'Not Started',
                created_at,
                created_by,
                avatar,
                createdByName
              }),
            });
  
            if (!createResponse.ok) {
              throw new Error('Failed to create a new assessment for the new model.');
            }
  
            const createResult = await createResponse.json();
            assessmentUuid = createResult.assessmentUuid;
            assessmentStatus = 'Not Started';
            currentStep = 'Not Started';
          } else {
            // Fetch succeeded
            const result = await fetchResponse.json();
            assessmentUuid = result.assessmentUuid;
            currentStep = result.currentStep;
            assessmentStatus = result.assessmentStatus;
          }
  
          dispatch(setModelId(model_uuid, model_name, created_at, created_by, avatar, createdByName, assessmentUuid, assessmentStatus, currentStep, riskForm));
  
        } else if (formType === 'update' && data) {
          // Model updated
          const { model_uuid, model_name, created_at, created_by, avatar, createdByName, assessmentUuid, assessmentStatus, currentStep } = data;
          const existingRiskFormData = models.find(model => model.model_uuid === modelId)?.riskFormData || {};
          // On update, no calls to manageAssessment since backend already returned assessment info
          // If no assessment found, assessmentUuid might be null; handle that if needed
          dispatch(setModelId(
            model_uuid,
            model_name,
            created_at,
            created_by,
            avatar,
            createdByName,
            assessmentUuid || null, 
            assessmentStatus || null,
            currentStep || null,
            existingRiskFormData
            
          ));
        }
  
      } else {
        // Server returned success = false
        setSnackbarMessage(responseBody.message);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage(`An error occurred: ${error.message}`);
      setSnackbarSeverity('error');
    }
  
    setSnackbarOpen(true);
    setOpenFormModal(false); // close form modal after submit
    setEditingModel(null);
  };

  return (
    <div style={{ padding: '24px' }}>
      <HeaderPaper>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>Model Inventory</Typography>
        {isLoading && <CircularProgress />}
      </HeaderPaper>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <AddModelButton onClick={handleAddModel}>
          <AddIcon />
          Add Model
        </AddModelButton>

        <Tooltip title="Import models from cloud">
          <Button
            variant="contained"
            style={{ backgroundColor: '#8FB996', color: '#fff', marginLeft: '10px',textTransform: 'none', }}
            color="primary"
            onClick={handleImportMenuClick}
            disabled={isImporting}
            size="small"
            startIcon={<CloudUploadOutlinedIcon />}
          >
            {isImporting ? 'Importing...' : 'Import'}
          </Button>
        </Tooltip>
        <Menu
          anchorEl={importMenuAnchorEl}
          open={Boolean(importMenuAnchorEl)}
          onClose={handleImportMenuClose}
        >
          <MenuItem>
            <ImportSageMakerModels setIsImporting={setIsImporting} />
          </MenuItem>
          <MenuItem>
            <ImportBedrockModels setIsImporting={setIsImporting} />
          </MenuItem>
        </Menu>
      </div>

      <TableContainer component={Paper} style={{ maxHeight: 440, borderRadius: '8px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Model Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Assessment Status</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Current Step</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Created By</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {models
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((model) => (
                <TableRow key={model.model_uuid} hover>
                  <TableCell>{model.modelName}</TableCell>
                  <TableCell>{model.assessmentStatus}</TableCell>
                  <TableCell>{model.step}</TableCell>
                  <TableCell align="center">
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Tooltip title={model.createdByName || 'Unknown User'}>
                          <Avatar
                            src={model.avatar}
                            alt="Owner Avatar"
                            sx={{ width: 24, height: 24, cursor: 'pointer', transition: '0.2s' }}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  <TableCell align="center">
                  <Tooltip title="Edit Model">
                    <IconButton 
                      onClick={() => handleEditModel(model)} 
                      sx={{ color: '#42424a' }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Model">
                    <IconButton 
                      onClick={() => handleDeleteClick(model)} 
                      sx={{ color: '#42424a', ml: 0.5 }} // Reduced margin-left using MUI's spacing (ml: 0.5 = 4px)
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                  
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={models.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </TableContainer>

      <Typography variant="h6" style={{ marginTop: '20px' }}>Cloud Models</Typography>
      {cloudModelsLoading ? (
        <CircularProgress style={{ marginTop: '10px' }} />
      ) : cloudModels.length > 0 ? (
        <TableContainer component={Paper} style={{ maxHeight: 440, marginTop: '10px', borderRadius:'8px' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Model Card Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Source</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Creation Time</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cloudModels.map((model, i) => (
                <TableRow key={i} hover>
                  <TableCell>{model.ModelCardName || model.modelName}</TableCell>
                  <TableCell>{model.source}</TableCell>
                  <TableCell>{model.CreationTime ? new Date(model.CreationTime).toLocaleString() : 'N/A'}</TableCell>
                  <TableCell>
                    <Button variant="contained" size="small" onClick={() => {/* handleViewDetails if needed */}} style={{textTransform:'none'}}>
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body2" style={{ marginTop: '10px' }}>No Cloud Models Imported.</Typography>
      )}

      {/* Model Form Modal */}
      <Dialog
        open={openFormModal}
        onClose={() => setOpenFormModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: '12px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 20px rgba(0,0,0,0.1)'
          }
        }}
      >
        <DialogTitle style={{ fontWeight: 'bold', fontSize: '1.25rem', color: '#2F3E46' }}>
          {editingModel ? 'Update Model Card' : 'Add Model Card'}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#FAFAFA', padding: '24px' }}>
          <ModelCardForm
            onSubmit={handleFormSubmit}
            modelId={editingModel?.model_uuid}
            initialData={editingModel ? {
              modelName: editingModel.modelName,
              modelShortDescription: editingModel.model_short_description,
              useCaseShortDescription: editingModel.use_case_short_description
            } : {}}
          />
        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid #E0E0E0', padding: '16px 24px' }}>
          <Button onClick={() => setOpenFormModal(false)} style={{ textTransform: 'none' }}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: '#8FB996', color: '#fff', textTransform: 'none' }}
            onClick={() => {
              const formEl = document.querySelector('form');
              if (formEl) {
                formEl.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
              }
            }}
          >
            {editingModel ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle style={{ fontWeight: 'bold' }}>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the model "{modelToDelete?.modelName}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} style={{ textTransform: 'none' }}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} style={{ textTransform: 'none' }} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ModelInventory;


