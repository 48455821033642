

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import CircularProgress from '@mui/material/CircularProgress';
import store, { persistor } from './redux/store';
import AuthWrapper from './authWrapper';
import reportWebVitals from './reportWebVitals.js';
import { Auth0Provider } from '@auth0/auth0-react';

// Retrieve Auth0 configuration from environment variables
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
//const redirectUri = window.location.origin;
console.log("Auth0 Domain:", domain);
console.log("Auth0 Client ID:", clientId);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience="auth.riskgpt.io"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience:"auth.riskgpt.io",
        scope: 'openid profile email offline_access read:models write:models delete:models', 
      }}
      useRefreshTokens={true}        // Enable Refresh Tokens
      cacheLocation="localstorage"  
    >
      <Provider store={store}>
        <PersistGate loading={<CircularProgress />} persistor={persistor}>
          <AuthWrapper />
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
