// src/utils/policyConstants.js
export const MAIN_TABS = {
  RISK_CATALOGUES: 0,
  GOVERNANCE_POLICIES: 1,
  AI_CONTROL_BASELINE: 2,
  SECURITY_POLICIES: 3
};

export const GOVERNANCE_SUBTABS = {
  RISK_POLICIES: 0,
  AUDIT_POLICIES: 1,
  AI_GOVERNANCE_POLICIES: 2
};

export const AI_CONTROL_SUBTABS = {
  DATA_GOVERNANCE_POLICIES: 0,
  BIAS_POLICIES: 1,
  LOGGING_MONITORING_POLICIES: 2,
  QUALITY_TEST_POLICIES: 3,
  INCIDENT_MANAGEMENT_POLICIES: 4,
  DOCUMENTATION_RECORD_KEEPING_POLICIES: 5
};

export const CATEGORIES = {
  RISK_CATALOGUES: 'risk-catalogues',
  RISK_POLICIES: 'risk-policies',
  AUDIT_POLICIES: 'audit-policies',
  AI_GOVERNANCE_POLICIES: 'ai-governance-policies',
  SECURITY_POLICIES: 'security-policies',

  // AI Control
  BIAS_POLICIES: 'bias-policies',
  DATA_GOVERNANCE_POLICIES: 'data-governance-policies',
  LOGGING_MONITORING_POLICIES: 'logging-monitoring-policies',
  QUALITY_TEST_POLICIES: 'quality-test-policies',
  INCIDENT_MANAGEMENT_POLICIES: 'incident-management-policies',
  DOCUMENTATION_RECORD_KEEPING_POLICIES: 'documentation-record-keeping-policies'
};
