// src/redux/reducers/riskReducer.js

import { 
  INITIALIZE_MODEL,
  FETCH_MODELS_START, 
  FETCH_MODELS_SUCCESS, 
  FETCH_MODELS_FAILURE,
  FETCH_MODEL_RISKS_SUCCESS,
  DELETE_MODEL,
  SET_FORM_DATA,
  SET_SECTION,
  SET_STEP,
  UPDATE_ASSESSMENT_STEP_SUCCESS,
  UPDATE_ASSESSMENT_STEP_FAILURE,
  SET_MODEL_ID,
  DISCARD_RISKS,
  DISCARD_UNSAVED_RISKS,
  UPDATE_RISK_NAME,
  FETCH_RISKS_START, 
  FETCH_RISKS_FAILURE, 
  SET_RISK_EVALUATION,
  UPDATE_RISK_PRIORITY,
  UPDATE_RISK_EVALUATION,
  UPDATE_CONTROL_IN_SELECTED_RISK,
  FETCH_RISK_CARD_DATA_START, 
  FETCH_RISK_CARD_DATA_SUCCESS, 
  FETCH_RISK_CARD_DATA_FAILURE, 
  FETCH_RISK_EVALUATIONS, 
  FETCH_RISK_EVALUATIONS_SUCCESS,
  FETCH_RISK_EVALUATIONS_FAILURE,
  UPDATE_ACTIVE_SECTIONS_FROM_SAVED_RISKS,
  MOVE_RISK_TO_SAVED,
  UPDATE_SAVED_RISK,
  DISCARD_SAVED_RISK,
  RESET_RISKS_STATE,
  FETCH_SECTION_SPECIFIC_RISKS_START,
  FETCH_SECTION_SPECIFIC_RISKS_SUCCESS,
  FETCH_SECTION_SPECIFIC_RISKS_FAILURE,
  ASSESSMENT_STATUS_UPDATED,
  ASSESSMENT_STATUS_FAILED,
  REFRESH_RISKS_SUCCESS,
  SET_RISK_PRIORITY_START,
  SET_RISK_PRIORITY_SUCCESS,
  SET_RISK_PRIORITY_FAILURE,
  UPDATE_LOADING_MESSAGE,
  UPDATE_SECTION_PROGRESS,
  UPDATE_CAUSE_FIELD,
  SAVE_RISK_SOURCE_START,
  SAVE_RISK_SOURCE_SUCCESS,
  SAVE_RISK_SOURCE_FAILURE,
  SAVE_CAUSES_START,
  SAVE_CAUSES_SUCCESS,
  SAVE_CAUSES_FAILURE,
  SET_CURRENT_SECTION,
  SET_CURRENT_SECTION_STEP,
  RESET_CURRENT_SECTION_STEP,
  CONFIG_UPDATE_SUCCESS, 
  CONFIG_UPDATE_FAILURE
} from '../actions/actionTypes.js';
import { mergeRiskSources, mergeRiskRootCauses } from '../reduxutils/riskFormUtils.js';
import evaluationForm from '../../evaluationForm.json';
import _ from 'lodash';

  


const initialState = {
  models: {},
  modelsLoading: false,
  loadingMessage: ''
};


const initializeModel = (modelId, modelName, created_at, created_by,avatar,createdByName,assessmentUuid, assessmentStatus, currentStep,riskFormData, state) => {
  if (modelId && !state.models[modelId]) {
    const evaluationCriteria = JSON.parse(JSON.stringify(evaluationForm));
    //const initializedRiskForm = _.cloneDeep(riskForm);

    return {
      ...state,
      models: {
        ...state.models,
        [modelId]: {
          modelName,
          created_at,
          created_by,
          avatar,
          createdByName,
          assessmentUuid,
          assessmentStatus,
          //riskFormData: initializedRiskForm,
          riskFormData,
          activeSections: [],
          sectionLoading: {},
          selectedRisks: [],
          risks: [],
          selectedThemes: [],
          discardedRisks: [],
          savedRisks: state.models[modelId]?.savedRisks?.map(risk => ({
            ...risk,
            evaluationData: risk.evaluationData || evaluationCriteria,
            selectedControls: risk.selectedControls || []
          })) || [],
          loading: false,
          error: null,
          step: currentStep || 'Not Started'

        },
      },
    };
  }
  return state;
}

const riskReducer = (state = initialState, action) => {
  switch (action.type) {

    case RESET_RISKS_STATE:
      return initialState;

    case FETCH_MODELS_START:
      return {
        ...state,
        modelsLoading: true,
        loadingMessage: 'Fetching models...'
      };


    case FETCH_MODELS_SUCCESS: {
      const { models } = action.payload;
    
      let newState = { ...state, modelsLoading: false, loadingMessage: '' };
      models.forEach(model => {
        if (!newState.models[model.model_uuid]) {
          newState = initializeModel(
            model.model_uuid,               // modelId
            model.model_name,               // modelName
            model.created_at,               // created_at
            model.created_by,               // created_by
            model.avatar,                   // avatar
            model.name,                     // createdByName
            model.assessment_uuid,          // assessmentUuid
            model.assessment_status,        // assessmentStatus
            model.current_step || 'Not Started',
            action.payload.riskFormData, 
            newState
          );
        }
      });
    
      return newState;
    }

    case FETCH_MODELS_FAILURE:
      return {
        ...state,
        modelsLoading: false,
        loadingMessage: 'Failed to load data.'
      };

    case INITIALIZE_MODEL: {
      const { modelId, modelName, created_at, created_by, avatar, createdByName,assessmentUuid, assessmentStatus, currentStep, riskFormData } = action.payload;
      return initializeModel(modelId, modelName, created_at,created_by, avatar, createdByName,assessmentUuid, assessmentStatus, currentStep,riskFormData, state);
    }

    case DELETE_MODEL: {
      const modelId = action.payload;
      const newModels = { ...state.models };
      delete newModels[modelId];
      return {
        ...state,
        models: newModels,
      };
    }

    

    case SET_MODEL_ID: {
      const {
        modelId,
        modelName,
        created_at,
        created_by,
        avatar,
        createdByName,
        assessmentUuid,
        assessmentStatus,
        currentStep, riskFormData
      } = action.payload;
      
      console.log('SET_MODEL_ID action payload:', action.payload);
      
      let newState = { ...state };
      
      if (modelId) {
        if (!newState.models[modelId]) {
          // Model doesn't exist yet, initialize it
          newState = initializeModel(
            modelId,
            modelName,
            created_at,
            created_by,
            avatar,
            createdByName,
            assessmentUuid,
            assessmentStatus,
            currentStep,
            riskFormData,
            newState
          );
        } else {
          // Model exists, just update the fields
          const existingModel = newState.models[modelId];
          newState.models[modelId] = {
            ...existingModel,
            modelName: modelName || existingModel.modelName,
            created_at: created_at || existingModel.created_at,
            created_by: created_by || existingModel.created_by,
            avatar: avatar || existingModel.avatar,
            createdByName: createdByName || existingModel.createdByName,
            assessmentUuid: assessmentUuid || existingModel.assessmentUuid,
            assessmentStatus: assessmentStatus || existingModel.assessmentStatus,
            step: currentStep || existingModel.step
          };
        }
      }
    
      return newState;
    }

    case UPDATE_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.payload
      };

    case SET_SECTION: {
      const { modelId, sectionName } = action.payload;
      const model = state.models[modelId];

      if (!model) {
        console.error(`Model with ID ${modelId} not found.`);
        return state;
      }

      const section = model.activeSections[sectionName];
      if (!section) {
        console.error(`Section ${sectionName} not found in model ${modelId}.`);
        return state;
      }

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...model,
            currentSection: sectionName,
            activeSections: {
              ...model.activeSections,
              [sectionName]: {
                ...section
              }
            }
          }
        }
      };
    }

    case CONFIG_UPDATE_SUCCESS: {
      const { name, data } = action.payload;
      console.log(`CONFIG_UPDATE_SUCCESS received for config: ${name}`);
      
      // Define which configurations affect this reducer
      const relevantConfigs = ['riskSources', 'riskRootCauses'];
      if (!relevantConfigs.includes(name)) {
        // If the updated config doesn't affect this reducer, do nothing
        return state;
      }

      // Determine which utility function to use based on the configName
      let updatedModels = _.cloneDeep(state.models);
      Object.keys(updatedModels).forEach(modelId => {
        const existingRiskFormData = updatedModels[modelId].riskFormData;
        if (name === 'riskSources') {
          updatedModels[modelId].riskFormData = mergeRiskSources(existingRiskFormData, data);
        } else if (name === 'riskRootCauses') {
          updatedModels[modelId].riskFormData = mergeRiskRootCauses(existingRiskFormData, data);
        }
      });

      return {
        ...state,
        models: updatedModels,
      };
    }

    // **Configuration Update Failure**
    case CONFIG_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

   

  //   case SET_FORM_DATA: {
  //     const { modelId, sectionName, riskSource, value } = action.payload;
  
  //     console.log('payload in set form data reducer', action.payload);
  
  //     if (!state.models[modelId]) {
  //         console.error('Model not found', modelId);
  //         return state;
  //     }
  
  //     const model = { ...state.models[modelId] };
  //     const section = { ...model.riskFormData[sectionName] };
  
  //     if (!section || !Array.isArray(section.riskSources)) {
  //         console.error('Section or riskSources not found', sectionName);
  //         return state;
  //     }
  
  //     // Find the index of the risk source to update
  //     const riskIndex = section.riskSources.findIndex(risk => risk.name === riskSource);
  //     if (riskIndex === -1) {
  //         console.error(`Risk source "${riskSource}" not found in section "${sectionName}".`);
  //         return state;
  //     }
  
  //     // Update the value field of the matched risk source
  //     const updatedRisk = { ...section.riskSources[riskIndex] };
  //     updatedRisk.value = Array.isArray(value) ? value : [value];
  
  //     // Update the riskSources array
  //     const updatedRiskSources = [...section.riskSources];
  //     updatedRiskSources[riskIndex] = updatedRisk;
  
  //     // Update the section with the new riskSources
  //     const updatedSection = {
  //         ...section,
  //         riskSources: updatedRiskSources,
  //     };
  
  //     // Update the model with the new riskFormData
  //     const updatedModel = {
  //         ...model,
  //         riskFormData: {
  //             ...model.riskFormData,
  //             [sectionName]: updatedSection,
  //         },
  //     };
  
  //     return {
  //         ...state,
  //         models: {
  //             ...state.models,
  //             [modelId]: updatedModel,
  //         },
  //     };
  // }

case SET_FORM_DATA: {
    const { modelId, sectionName, riskSource, subCategory, value } = action.payload;

    console.log('payload in set form data reducer', action.payload);

    if (!state.models[modelId]) {
        console.error('Model not found', modelId);
        return state;
    }

    const model = { ...state.models[modelId] };
    const section = { ...model.riskFormData[sectionName] };

    if (!section || !Array.isArray(section.riskSources)) {
        console.error('Section or riskSources not found', sectionName);
        return state;
    }

    // Find the index of the risk source to update
    const riskIndex = section.riskSources.findIndex(risk => risk.name === riskSource);
    if (riskIndex === -1) {
        console.error(`Risk source "${riskSource}" not found in section "${sectionName}".`);
        return state;
    }

    // Clone the specific risk source to update
    const updatedRisk = { ...section.riskSources[riskIndex] };

    if (subCategory) {
        // Update the causes subCategory's value
        // Deep clone 'causes' to prevent shared references
        const updatedCauses = _.cloneDeep(updatedRisk.causes);

        if (!updatedCauses[subCategory]) {
            console.error(`SubCategory "${subCategory}" not found in causes.`);
            return state;
        }

        updatedCauses[subCategory].value = Array.isArray(value) ? value : [value];

        updatedRisk.causes = updatedCauses;
    } else {
        // Update the value field of the risk source
        updatedRisk.value = Array.isArray(value) ? value : [value];
    }

    // Update the riskSources array
    const updatedRiskSources = [...section.riskSources];
    updatedRiskSources[riskIndex] = updatedRisk;

    // Update the section with the new riskSources
    const updatedSection = {
        ...section,
        riskSources: updatedRiskSources,
    };

    // Update the model with the new riskFormData
    const updatedModel = {
        ...model,
        riskFormData: {
            ...model.riskFormData,
            [sectionName]: updatedSection,
        },
    };
    const causesReferences = updatedSection.riskSources.map(source => source.causes);
    const uniqueCauses = new Set(causesReferences.map(cause => cause));
    console.log('Unique causes count:', uniqueCauses.size, 'Total causes count:', causesReferences.length);

    return {
        ...state,
        models: {
            ...state.models,
            [modelId]: updatedModel,
        },
    };
}

  case UPDATE_CAUSE_FIELD: {
    const { modelId, sectionName, riskSource, causeCategory, value } = action.payload;
  
    console.log('payload in update cause field reducer', action.payload);
  
    if (!state.models[modelId]) {
      console.error('Model not found', modelId);
      return state;
    }
  
    const model = { ...state.models[modelId] };
    const section = { ...model.riskFormData[sectionName] };
  
    if (!section || !Array.isArray(section.riskSources)) {
      console.error('Section or riskSources not found', sectionName);
      return state;
    }
  
    // Find the index of the risk source to update
    const riskIndex = section.riskSources.findIndex((risk) => risk.name === riskSource);
    if (riskIndex === -1) {
      console.error(`Risk source "${riskSource}" not found in section "${sectionName}".`);
      return state;
    }
  
    const updatedRisk = { ...section.riskSources[riskIndex] };
  
    // Ensure the causeCategory exists
    if (!updatedRisk.causes[causeCategory]) {
      console.error(`Cause category "${causeCategory}" does not exist in risk source "${riskSource}".`);
      return state;
    }
  
    // Update the causeCategory
    updatedRisk.causes[causeCategory] = {
      ...updatedRisk.causes[causeCategory],
      value: Array.isArray(value) ? value : [value], // Ensure multiselect compatibility
    };
  
    // Update the riskSources array
    const updatedRiskSources = [...section.riskSources];
    updatedRiskSources[riskIndex] = updatedRisk;
  
    // Update the section with the new riskSources
    const updatedSection = {
      ...section,
      riskSources: updatedRiskSources,
    };
  
    // Update the model with the new riskFormData
    const updatedModel = {
      ...model,
      riskFormData: {
        ...model.riskFormData,
        [sectionName]: updatedSection,
      },
    };
  
    return {
      ...state,
      models: {
        ...state.models,
        [modelId]: updatedModel,
      },
    };
  }

    case SAVE_RISK_SOURCE_START:
      return {
        ...state,
        savingRiskSource: true,
        saveRiskSourceError: null,
      };
    case SAVE_RISK_SOURCE_SUCCESS:
      return {
        ...state,
        savingRiskSource: false,
        // Optionally, handle success messages or state updates
      };
    case SAVE_RISK_SOURCE_FAILURE:
      return {
        ...state,
        savingRiskSource: false,
        saveRiskSourceError: action.payload,
      };
    case SAVE_CAUSES_START:
      return {
        ...state,
        savingCauses: true,
        saveCausesError: null,
      };
    case SAVE_CAUSES_SUCCESS:
      return {
        ...state,
        savingCauses: false,
        // Optionally, handle success messages or state updates
      };
    case SAVE_CAUSES_FAILURE:
      return {
        ...state,
        savingCauses: false,
        saveCausesError: action.payload,
      };

      case SET_CURRENT_SECTION: {
        const { modelId, sectionName } = action.payload;
        const model = state.models[modelId];
        if (!model) {
          console.error(`Model with ID ${modelId} not found.`);
          return state;
        }
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...model,
              currentSection: sectionName,
              currentSectionStep: 'riskIdentification', // Reset to initial step
            },
          },
        };
      }
  
      case SET_CURRENT_SECTION_STEP: {
        const { modelId, stepName } = action.payload;
        const model = state.models[modelId];
        if (!model) {
          console.error(`Model with ID ${modelId} not found.`);
          return state;
        }
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...model,
              currentSectionStep: stepName,
            },
          },
        };
      }
  
      case RESET_CURRENT_SECTION_STEP: {
        const { modelId } = action.payload;
        const model = state.models[modelId];
        if (!model) {
          console.error(`Model with ID ${modelId} not found.`);
          return state;
        }
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...model,
              currentSectionStep: 'riskIdentification', // Reset to initial step
            },
          },
        };
      }
  

    case FETCH_MODEL_RISKS_SUCCESS: {
      const { modelId, risks } = action.payload;

      console.log('Risks fetched from DB for modelId:', { risks, modelId });

      const risksBySection = risks.reduce((acc, risk) => {
        const section = risk.active_section;
        const category = risk.category || 'Uncategorized'; // Default to 'Uncategorized' if no category

        if (!acc[section]) {
          acc[section] = { sectionRisks: {} };
          console.log(`Initialized section: ${section}`);
        }

        if (!acc[section].sectionRisks[category]) {
          acc[section].sectionRisks[category] = [];
          console.log(`Initialized category: ${category} under section: ${section}`);
        }

        const owner = {
          name: risk.owner_name || 'Unknown',
          email: risk.owner_email || 'Unknown',
          picture: risk.owner_picture || 'https://via.placeholder.com/150', // Default avatar
        };

        const newRisk = {
          riskId: risk.risk_id,
          riskName: risk.risk_name,
          category: risk.category,
          priority: risk.priority,
          rationale: risk.rationale,
          status: 'Saved',
          owner: owner,
          type: risk.risk_type || 'Unknown', // Assign risk_type
        };

        // Assign risk_source only if type is 'RAG'
        if (risk.risk_type === 'RAG') {
          newRisk.rag_source_file = risk.rag_source_file || 'Unknown';
        
        };

        // Assign type
        newRisk.type = risk.type || 'Unknown';

        acc[section].sectionRisks[category].push(newRisk);

        return acc;
      }, {});


      console.log('Final risksBySection structure:', risksBySection);

      const updatedModel = {
        ...state.models[modelId],
        riskFormData: {
          ...state.models[modelId].riskFormData,
          ...Object.keys(risksBySection).reduce((acc, section) => {
            acc[section] = {
              ...state.models[modelId].riskFormData[section],
              sectionRisks: {
                ...state.models[modelId].riskFormData[section]?.sectionRisks,
                ...risksBySection[section].sectionRisks,
              },
            };
            return acc;
          }, {}),
        },
      };

      console.log('Updated model state:', updatedModel.riskFormData);

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: updatedModel,
        },
      };
    }

    case 'risks/fetchRisksForModel/fulfilled': {
      const modelId = action.meta.arg.modelId;
      const sectionName = action.meta.arg.sectionName;
      const fetchedRisks = action.payload;
      console.log('fetched risks in reducer', fetchedRisks);

      const categorizedRisks = fetchedRisks.reduce((acc, risk) => {
        const category = risk.category || 'Uncategorized';
        if (!acc[category]) acc[category] = [];
        acc[category].push({
          riskName: risk.risk_name,
          riskId: risk.risk_id,
          priority: risk.priority,
          rationale: risk.rationale,
          status: 'Saved',
        });
        return acc;
      }, {});

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                sectionRisks: categorizedRisks,
              }
            },
            loading: false,
          },
        },
      };
    }

    case 'risks/fetchRisksForModel/rejected': {
      const { modelId } = action.meta.arg;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            loading: false,
            error: action.error.message,
          },
        },
      };
    }

    case 'risks/updateActiveSections': {
      console.log('Received updateActiveSections action:', action);
      const { modelId, sections } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            activeSections: sections,
          },
        },
      };
    }
        case SET_RISK_PRIORITY_START: {
          const { modelId, sectionName, category, riskId } = action.payload; // Ensure category is included
          return {
            ...state,
            models: {
              ...state.models,
              [modelId]: {
                ...state.models[modelId],
                riskFormData: {
                  ...state.models[modelId].riskFormData,
                  [sectionName]: {
                    ...state.models[modelId].riskFormData[sectionName],
                    sectionRisks: {
                      ...state.models[modelId].riskFormData[sectionName].sectionRisks,
                      [category]: state.models[modelId].riskFormData[sectionName].sectionRisks[category].map(risk =>
                        risk.riskId === riskId ? { ...risk, loading: true } : risk
                      )
                    }
                  }
                }
              }
            }
          };
        }

    case SET_RISK_PRIORITY_SUCCESS: {
      const { modelId, sectionName, category, riskId, priority, rationale } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                sectionRisks: {
                  ...state.models[modelId].riskFormData[sectionName].sectionRisks,
                  [category]: state.models[modelId].riskFormData[sectionName].sectionRisks[category]?.map(risk => {
                    if (risk.riskId !== riskId) return risk;
                    return {
                      ...risk,
                      loading: false,
                      priority: priority,
                      rationale: rationale
                    };
                  })
                }
              }
            }
          }
        }
      };
    }

    case SET_RISK_PRIORITY_FAILURE: {
      const { modelId, sectionName, category, riskId, error } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                sectionRisks: {
                  ...state.models[modelId].riskFormData[sectionName].sectionRisks,
                  [category]: state.models[modelId].riskFormData[sectionName].sectionRisks[category]?.map(risk => {
                    if (risk.riskId !== riskId) return risk;
                    return {
                      ...risk,
                      loading: false,
                      error: error
                    };
                  })
                }
              }
            }
          }
        }
      };
    }

    case UPDATE_RISK_PRIORITY: {
      const { modelId, sectionName, category, riskId, priority } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                sectionRisks: {
                  ...state.models[modelId].riskFormData[sectionName].sectionRisks,
                  [category]: state.models[modelId].riskFormData[sectionName].sectionRisks[category]?.map(risk => {
                    if (risk.riskId !== riskId) return risk;
                    return { ...risk, priority };
                  })
                }
              }
            }
          }
        }
      };
    }

    case DISCARD_RISKS: {
      const { modelId, sectionName, risk } = action.payload;
      const model = state.models[modelId];
      if (!model) {
        console.error("Model not found in state", modelId);
        return state;
      }

      const currentSectionRisks = model.riskFormData[sectionName].sectionRisks;

      const updatedSectionRisks = Object.keys(currentSectionRisks).reduce((acc, category) => {
        acc[category] = currentSectionRisks[category].map(r =>
          r.riskName === risk.riskName ? { ...r, status: 'Discarded' } : r
        );
        return acc;
      }, {});

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...model,
            riskFormData: {
              ...model.riskFormData,
              [sectionName]: {
                ...model.riskFormData[sectionName],
                sectionRisks: updatedSectionRisks
              }
            }
          }
        }
      };
    }
    case DISCARD_UNSAVED_RISKS: {
      const { risksToDiscard, modelId } = action.payload;
      const model = state.models[modelId];

      if (!model) {
        console.error(`Model with ID ${modelId} not found.`);
        return state;
      }

      // Initialize discardedRisks array if it doesn't exist
      const existingDiscardedRisks = model.discardedRisks || [];

      // Filter out the risks to discard from their original sections
      const updatedRiskFormData = { ...model.riskFormData };
      risksToDiscard.forEach((risk) => {
        Object.keys(updatedRiskFormData).forEach((section) => {
          const categories = updatedRiskFormData[section].sectionRisks;
          Object.keys(categories).forEach((category) => {
            const updatedRisks = categories[category].filter(
              (r) => r.riskName !== risk.riskName || r.status !== 'Generated'
            );
            updatedRiskFormData[section].sectionRisks[category] = updatedRisks;
          });
        });
      });

      // Avoid duplicates in discardedRisks based on riskName
      const newDiscardedRisks = [
        ...existingDiscardedRisks,
        ...risksToDiscard.filter(
          (risk) => !existingDiscardedRisks.some((discarded) => discarded.riskName === risk.riskName)
        ),
      ];

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...model,
            riskFormData: updatedRiskFormData,
            discardedRisks: newDiscardedRisks,
          },
        },
      };
    }
    case FETCH_RISKS_START: {
      const { modelId, sectionsToFetch } = action.payload;
      let newState = { ...state };

      if (!newState.models[modelId]) {
        console.error(`Model with ID ${modelId} not found.`);
      } else {
        if (!newState.models[modelId].sectionLoading) {
          newState.models[modelId].sectionLoading = {};
        }

        sectionsToFetch.forEach(section => {
          newState.models[modelId].sectionLoading[section] = true;
        });
      }

      return newState;
    }

    case FETCH_RISKS_FAILURE: {
      const { modelId, sectionsToFetch } = action.payload;
      let newState = { ...state };

      if (newState.models[modelId] && newState.models[modelId].sectionLoading) {
        sectionsToFetch.forEach(section => {
          newState.models[modelId].sectionLoading[section] = false;
        });
      } else {
        console.error(`Model with ID ${modelId} not found or sectionLoading not initialized.`);
      }

      return newState;
    }

    case FETCH_RISK_CARD_DATA_START: {
      const { modelId } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            loading: true,
          },
        },
      };
    }
  



    // case FETCH_RISK_CARD_DATA_SUCCESS: {
    //   const { modelId, fetchedData } = action.payload;
    
    //   console.log('FETCH_RISK_CARD_DATA_SUCCESS action received:', { modelId, fetchedData });
    
    //   if (!state.models[modelId]?.riskFormData) {
    //     console.error(`Model ID ${modelId} not found.`);
    //     return state;
    //   }
    
    //   const updatedRiskFormData = _.cloneDeep(state.models[modelId].riskFormData);
    
    //   Object.entries(fetchedData).forEach(([riskArea, riskSources]) => {
    //     if (!updatedRiskFormData[riskArea]) {
    //       updatedRiskFormData[riskArea] = { riskSources: [] }; // Initialize missing risk areas
    //     }
    
    //     riskSources.forEach(({ name, value, causes }) => {
    //       const existingSourceIndex = updatedRiskFormData[riskArea].riskSources.findIndex(
    //         (source) => source.name === name
    //       );
    
    //       if (existingSourceIndex !== -1) {
    //         // Update existing source
    //         const existingSource = updatedRiskFormData[riskArea].riskSources[existingSourceIndex];
    //         existingSource.value = value;
    
    //         Object.entries(causes || {}).forEach(([causeCategory, causeData]) => {
    //           if (!existingSource.causes) {
    //             existingSource.causes = {};
    //           }
              
    //           if (!existingSource.causes[causeCategory]) {
    //             existingSource.causes[causeCategory] = causeData;
    //           } else {
    //             // Only update value if it's not empty
    //             if (causeData.value && causeData.value.length > 0) {
    //               existingSource.causes[causeCategory].value = causeData.value;
    //             }
    //           }
    //         });
    //       } else {
    //         // Add new risk source
    //         updatedRiskFormData[riskArea].riskSources.push({
    //           name,
    //           value,
    //           causes,
    //         });
    //       }
    //     });
    //   });
    
    //   console.log('Updated riskFormData:', updatedRiskFormData);
    
    //   return {
    //     ...state,
    //     models: {
    //       ...state.models,
    //       [modelId]: {
    //         ...state.models[modelId],
    //         riskFormData: updatedRiskFormData,
    //       },
    //     },
    //   };
    // }

    case FETCH_RISK_CARD_DATA_SUCCESS: {
      const { modelId, fetchedData } = action.payload;
    
      console.log('FETCH_RISK_CARD_DATA_SUCCESS action received:', { modelId, fetchedData });
    
      if (!state.models[modelId]?.riskFormData) {
        console.error(`Model ID ${modelId} not found.`);
        return state;
      }
    
      const updatedRiskFormData = _.cloneDeep(state.models[modelId].riskFormData);
    
      Object.entries(fetchedData).forEach(([riskArea, riskSources]) => {
        if (!updatedRiskFormData[riskArea]) {
          updatedRiskFormData[riskArea] = { riskSources: [] };
        }
    
        riskSources.forEach(({ name, value, causes }) => {
          const existingSourceIndex = updatedRiskFormData[riskArea].riskSources.findIndex(
            (source) => source.name === name
          );
    
          if (existingSourceIndex !== -1) {
            // Update existing source
            const existingSource = updatedRiskFormData[riskArea].riskSources[existingSourceIndex];
            existingSource.value = value;
    
            // Only update causes for the current specific risk source
            if (causes) {
              existingSource.causes = {
                ...existingSource.causes,
                ...Object.fromEntries(
                  Object.entries(causes).map(([causeCategory, causeData]) => [
                    causeCategory,
                    {
                      ...existingSource.causes[causeCategory],
                      value: causeData.value
                    }
                  ])
                )
              };
            }
          } else {
            // Add new risk source
            updatedRiskFormData[riskArea].riskSources.push({
              name,
              value,
              causes,
            });
          }
        });
      });
    
      console.log('Updated riskFormData:', updatedRiskFormData);
    
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: updatedRiskFormData,
          },
        },
      };
    }
  

    case FETCH_RISK_CARD_DATA_FAILURE: {
      const { modelId, error } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            error: error,
            loading: false,
          },
        },
      };
    }

    case SET_STEP: {
      const { modelId, sectionName, stepName } = action.payload;
      console.log('payload in set_step reducer', action.payload);

      if (state.models[modelId]?.riskFormData[sectionName]) {
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...state.models[modelId],
              riskFormData: {
                ...state.models[modelId].riskFormData,
                [sectionName]: {
                  ...state.models[modelId].riskFormData[sectionName],
                  currentStep: stepName  // Update the current step in the specific section
                }
              }
            }
          }
        };
      }

      console.warn(`Model with ID ${modelId} or section ${sectionName} not found.`);
      return state;
    }

    case UPDATE_ASSESSMENT_STEP_SUCCESS: {
      const { modelId, currentStep } = action.payload;
      if (state.models[modelId]) {
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...state.models[modelId],
              step: currentStep,
            }
          }
        };
      }
      console.warn(`Model with ID ${modelId} not found.`);
      return state;
    }

    case UPDATE_ASSESSMENT_STEP_FAILURE: {
      const { modelId, error } = action.payload;
      if (state.models[modelId]) {
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...state.models[modelId],
              error: error,
            },
          },
        };
      }
      console.warn(`Model with ID ${modelId} not found.`);
      return state;
    }

    case ASSESSMENT_STATUS_UPDATED: {
      const { modelId, assessmentStatus } = action.payload;
      console.log('assessment status received at reducer', action.payload);
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            assessmentStatus: assessmentStatus
          }
        }
      };
    }

    case ASSESSMENT_STATUS_FAILED: {
      const { modelId, error } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            error: error
          }
        }
      };
    }

    case UPDATE_RISK_NAME: {
      const { modelId, sectionName, originalName, newName } = action.payload;
      console.log('update risk in state payload:', action.payload);

      const currentSectionRisks = state.models[modelId]?.riskFormData[sectionName]?.sectionRisks;
      if (!currentSectionRisks) {
        console.error(`Section risks not found for modelId: ${modelId}, sectionName: ${sectionName}`);
        return state;
      }

      const updatedSectionRisks = Object.keys(currentSectionRisks).reduce((acc, category) => {
        acc[category] = currentSectionRisks[category].map(risk =>
          risk.riskName === originalName ? { ...risk, riskName: newName } : risk
        );
        return acc;
      }, {});

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                sectionRisks: updatedSectionRisks
              }
            }
          }
        }
      };
    }

    case SET_RISK_EVALUATION: {
      const { modelId, riskId, themeId, evaluationData, sectionName, category } = action.payload;
      console.log('action payload in set_risk_eval reducer', action.payload);
    
      // 1) Check existence of the category array
      if (!state.models[modelId]?.riskFormData[sectionName]?.sectionRisks?.[category]) {
        console.error(`Model with ID ${modelId} or category ${category} not found or sectionRisks not initialized.`);
        return state;
      }
    
      // 2) Deep clone the models
      const updatedModels = JSON.parse(JSON.stringify(state.models));
    
      // 3) Find the target risk within that category
      const sectionRisks = updatedModels[modelId].riskFormData[sectionName].sectionRisks[category];
      const targetRisk = sectionRisks.find(risk => risk.riskId === riskId);
    
      if (targetRisk) {
        if (!targetRisk.evaluationData) {
          // Initialize evaluationData if it doesn't exist
          targetRisk.evaluationData = {};
        }
    
        // 4) If there's no entry for this themeId, init it
        if (!targetRisk.evaluationData[themeId]) {
          targetRisk.evaluationData[themeId] = {};
        }
    
        // 5) Perform a deep merge of the incoming "evaluationData" into the existing data
        targetRisk.evaluationData[themeId] = _.merge(
          {},
          targetRisk.evaluationData[themeId], // existing data
          evaluationData                      // incoming data
        );
      
    
      } else {
        console.error(`Risk with ID ${riskId} not found in category ${category}.`);
      }
    
      // 6) Return updated state
      return {
        ...state,
        models: updatedModels
      };
    }

    case UPDATE_CONTROL_IN_SELECTED_RISK: {
      const { modelId, riskName, controlData } = action.payload;
      //console.log("Reducer Payload for selected controls: ", action.payload);
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            savedRisks: state.models[modelId].savedRisks.map(risk => {
              if (risk.riskName === riskName) {
                return {
                  ...risk,
                  selectedControls: controlData
                };
              }
              return risk;
            })
          }
        }
      };
    }

    // case UPDATE_RISK_EVALUATION: {
    //   const { modelId, riskId, category, sectionName, themeId, attribute, property, value, priority, rationale } = action.payload;
    //   console.log('received payload in update risk eval reducer', action.payload);

    //   // Get the relevant risks under the specified category
    //   const updatedRisks = state.models[modelId]?.riskFormData[sectionName]?.sectionRisks?.[category]?.map(risk => {
    //     if (risk.riskId === riskId) {
    //       // Update the evaluation data
    //       const evaluationData = risk.evaluationData || {};
    //       const themeEvaluation = evaluationData[themeId] || {};
    //       themeEvaluation[attribute] = { ...(themeEvaluation[attribute] || {}), [property]: value };
    //       evaluationData[themeId] = themeEvaluation;

    //       return {
    //         ...risk,
    //         evaluationData,
    //         priority,
    //         rationale
    //       };
    //     }
    //     return risk;
    //   });

    //   return {
    //     ...state,
    //     models: {
    //       ...state.models,
    //       [modelId]: {
    //         ...state.models[modelId],
    //         riskFormData: {
    //           ...state.models[modelId].riskFormData,
    //           [sectionName]: {
    //             ...state.models[modelId].riskFormData[sectionName],
    //             sectionRisks: {
    //               ...state.models[modelId].riskFormData[sectionName].sectionRisks,
    //               [category]: updatedRisks // Update only the category's risks
    //             }
    //           }
    //         }
    //       }
    //     }
    //   };
    // }

    case UPDATE_RISK_EVALUATION: {
      const {
        modelId,
        riskId,
        sectionName,
        themeId,
        attribute,  // e.g. "riskImpact" or "likelihood"
        property,   // e.g. "overallImpact", "duration", "probability", etc.
        value,
        priority,
        rationale,
        category,
      } = action.payload;
    
      console.log('received payload in updateRiskEvaluation reducer:', action.payload);
    
      // 1) Copy the array of risks under the specified category
      const existingRisks =
        state.models[modelId]?.riskFormData[sectionName]?.sectionRisks?.[category] || [];
    
      // 2) Map over them, looking for the risk to update
      const updatedRisks = existingRisks.map((risk) => {
        if (risk.riskId !== riskId) {
          return risk; // not the correct risk
        }
    
        // 3) Copy or init evaluationData
        const evaluationData = { ...(risk.evaluationData || {}) };
        const themeEvaluation = { ...(evaluationData[themeId] || {}) };
    
        // 4) Within that theme, ensure we have an object for the attribute (e.g. "riskImpact")
        // e.g. themeEvaluation.riskImpact = { overallImpact: "Low", intensity: ... }
        if (!themeEvaluation[attribute]) {
          themeEvaluation[attribute] = {};
        }
    
        // 5) Set the property on that attribute object
        // e.g. themeEvaluation.riskImpact.overallImpact = "Moderate"
        themeEvaluation[attribute][property] = value;
    
        // Write it back
        evaluationData[themeId] = themeEvaluation;
    
        // 6) Optionally set priority, rationale
        return {
          ...risk,
          evaluationData,
          priority: priority ?? risk.priority,
          rationale: rationale ?? risk.rationale,
        };
      });
    
      // 7) Return the updated state
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                sectionRisks: {
                  ...state.models[modelId].riskFormData[sectionName].sectionRisks,
                  [category]: updatedRisks,
                },
              },
            },
          },
        },
      };
    }

   
    case FETCH_RISK_EVALUATIONS: {
      const { modelId } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            loading: true,
            error: null,
          },
        },
      };
    }

// riskReducer.js (excerpt)

case FETCH_RISK_EVALUATIONS_SUCCESS: {
  const { modelId, evaluations, sectionName } = action.payload;
  const updatedModels = { ...state.models };
  const model = updatedModels[modelId];
  if (!model) return state;

  const section = model.riskFormData[sectionName];
  if (!section) return state;

  const updatedSectionRisks = { ...section.sectionRisks };

  // For each category -> risk -> merge from the DB array
  Object.keys(updatedSectionRisks).forEach((catName) => {
    updatedSectionRisks[catName] = updatedSectionRisks[catName].map((risk) => {
      const newEvaluationData = { ...risk.evaluationData };
      // We'll match by (riskId, themeId)
      evaluations.forEach((dbEval) => {
        // Instead of checking `&& dbEval.theme_id === risk.themeId`
        // we only check if the riskId matches
        if (dbEval.risk_id === risk.riskId) {
          const themeId = dbEval.theme_id;
          newEvaluationData[themeId] = newEvaluationData[themeId] || {};
      
          // Then loop over evaluationForm to fill in base + deepDive
          evaluationForm.forEach((criterion) => {
            const attr = criterion.attribute;
            if (!newEvaluationData[themeId][attr]) {
              newEvaluationData[themeId][attr] = {};
            }
            // baseEval
            const baseKey = criterion.baseEvaluation.name;
            newEvaluationData[themeId][attr][baseKey] = dbEval[baseKey] || '';
            // deepDive
            criterion.deepDive.forEach((field) => {
              newEvaluationData[themeId][attr][field.name] = dbEval[field.name] || '';
            });
          });
        }
      });
      return { ...risk, evaluationData: newEvaluationData };
    });
  });

  updatedModels[modelId].riskFormData[sectionName].sectionRisks = updatedSectionRisks;
  return { ...state, models: updatedModels };
}

    case FETCH_RISK_EVALUATIONS_FAILURE: {
      const { modelId, error } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            loading: false,
            error,
          },
        },
      };
    }

   

    case UPDATE_ACTIVE_SECTIONS_FROM_SAVED_RISKS: {
      const { modelId, uniqueSections } = action.payload;
      console.log('action payload in act sec reducer', action.payload);

      if (state.models[modelId]) {
        return {
          ...state,
          models: {
            ...state.models,
            [modelId]: {
              ...state.models[modelId],
              activeSections: uniqueSections,
            },
          },
        };
      }

      console.warn(`Model with ID ${modelId} not found.`);
      return state;
    }

    case MOVE_RISK_TO_SAVED: {
      const { modelId, sectionName, risk } = action.payload;
      const model = state.models[modelId];
      if (!model) {
        console.error("Model not found in state", modelId);
        return state;
      }

      const sectionRisks = { ...model.riskFormData[sectionName].sectionRisks };
      if (!sectionRisks[risk.category]) {
        sectionRisks[risk.category] = [];
      }
      sectionRisks[risk.category] = sectionRisks[risk.category].map(r =>
        r.riskName === risk.riskName ? { ...r, riskId: risk.riskId, status: 'Saved', category: risk.category, owner: risk.owner, type:risk.type, rag_source_file:risk.rag_source_file } : r
      );

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...model,
            riskFormData: {
              ...model.riskFormData,
              [sectionName]: {
                ...model.riskFormData[sectionName],
                sectionRisks,
              }
            }
          }
        }
      };
    }

    case UPDATE_SAVED_RISK: {
      const { modelId, sectionName, riskId, category, newDetails } = action.payload;
console.log ('props received in update saved risk', action.payload)
      if (!state.models[modelId] || !state.models[modelId].riskFormData[sectionName]) {
        console.error(`No model with ID ${modelId} or section ${sectionName} exists.`);
        return state;
      }

      const sectionRisks = {
        ...state.models[modelId].riskFormData[sectionName].sectionRisks,
      };

      if (!sectionRisks[category]) {
        sectionRisks[category] = [];
      }

      sectionRisks[category] = sectionRisks[category].map((risk) =>
        risk.riskId === riskId ? { ...risk, ...newDetails } : risk
      );

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                sectionRisks,
              },
            },
          },
        },
      };
    }

    case DISCARD_SAVED_RISK: {
      const { riskId, modelId, sectionName } = action.payload;
      const currentModel = state.models[modelId];
      if (!currentModel) {
        console.error(`Model with ID ${modelId} not found.`);
        return state;
      }

      const sectionRisks = { ...currentModel.riskFormData[sectionName].sectionRisks };

      Object.keys(sectionRisks).forEach(category => {
        sectionRisks[category] = sectionRisks[category].map(risk =>
          risk.riskId === riskId ? { ...risk, status: 'Discarded' } : risk
        );
      });

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...currentModel,
            riskFormData: {
              ...currentModel.riskFormData,
              [sectionName]: {
                ...currentModel.riskFormData[sectionName],
                sectionRisks,
              }
            }
          }
        }
      };
    }

    case FETCH_SECTION_SPECIFIC_RISKS_START: {
      const { modelId, sectionName } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            sectionLoading: {
              ...state.models[modelId].sectionLoading,
              [sectionName]: true,
            },
          },
        },
      };
    }

   

    case FETCH_SECTION_SPECIFIC_RISKS_SUCCESS: {
      const { modelId, sectionName, risks } = action.payload;
      console.log('Generated risks to enter into state', risks);

      // Get the existing risks in this section
      const existingRisks = state.models[modelId]?.riskFormData[sectionName]?.sectionRisks || {};

      // Process the new risks, merging them with existing risks
      const updatedRisks = Object.entries(risks).reduce((acc, [category, risksArray]) => {
        // Combine new risks with existing ones for each category
        acc[category] = [
          ...(existingRisks[category] || []).filter(risk => risk.status !== 'Generated'), // Preserve non-generated risks
          ...risksArray.map(risk => ({
            ...risk,
            status: risk.status || 'Generated', // Ensure each new risk has a 'Generated' status
            riskName: risk.riskName,
            category: risk.category,
            causes: risk.causes, // Ensure category is assigned correctly
            sourceFile: risk.sourceFile || 'Unknown', // Assign sourceFile if available
          }))
        ];
        return acc;
      }, {});

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                sectionRisks: updatedRisks,
              },
            },
            sectionLoading: {
              ...state.models[modelId].sectionLoading,
              [sectionName]: false,
            },
          },
        },
      };
    }

    case FETCH_SECTION_SPECIFIC_RISKS_FAILURE: {
      const { modelId, sectionName, error } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            sectionLoading: {
              ...state.models[modelId].sectionLoading,
              [sectionName]: false,
            },
            error,
          },
        },
      };
    }

    case REFRESH_RISKS_SUCCESS: {
      const { modelId, sectionName, categorizedRisks } = action.payload;
      const existingRisks = state.models[modelId]?.riskFormData[sectionName]?.sectionRisks || {};

      const updatedRisks = { ...existingRisks };

      Object.entries(categorizedRisks).forEach(([category, risksArray]) => {
        updatedRisks[category] = [
          ...(existingRisks[category] || []).filter(risk => risk.status !== 'Generated'), // Preserve non-generated risks
          ...risksArray.map(risk => ({
            ...risk,
            status: risk.status || 'Generated',
            riskName: risk.riskName,
          }))
        ];
      });

      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                sectionRisks: updatedRisks,
              },
            },
            sectionLoading: {
              ...state.models[modelId].sectionLoading,
              [sectionName]: false,
            },
          },
        },
      };
    }

    case UPDATE_SECTION_PROGRESS: {
      const { modelId, sectionName, progress } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          [modelId]: {
            ...state.models[modelId],
            riskFormData: {
              ...state.models[modelId].riskFormData,
              [sectionName]: {
                ...state.models[modelId].riskFormData[sectionName],
                assessmentProgress: progress,  // Store progress directly in the section
              },
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default riskReducer;
