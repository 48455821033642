import React, { useEffect, useState, useCallback,  useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion, AccordionSummary, AccordionDetails, Alert, Avatar,Box, Button, Dialog, DialogActions, DialogContent, 
  DialogContentText, DialogTitle, IconButton, Paper, Snackbar, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, TextField, Tooltip, Typography
} from '@mui/material';
import {
  Check as CheckIcon, Delete as DeleteIcon, Edit as EditIcon, ExpandLess as ExpandLessIcon, 
  ExpandMore as ExpandMoreIcon, Refresh as RefreshIcon, Save as SaveIcon,
  SmartToyOutlined as SmartToyOutlinedIcon
} from '@mui/icons-material';

import AutosaveIndicator from './AutoSaveIndicator';
import IconWithProgress from './IconWithProgress';
import { generateSecurityRisks, saveSecurityRisk, fetchSecurityRisks, discardSecurityRisk } from './redux/actions/securityActions';
import loadSecurityRisks from './loadSecurityRisks';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';

import { useAuth0 } from '@auth0/auth0-react';


const SecurityRiskCatalogue = ({ modelId, assessmentUuid}) => {
  const dispatch = useDispatch();
  const securityRisks = useSelector(state => state.security.models[modelId]?.securityRisks || []);
  const assessmentData = useSelector(state => state.security.models[modelId]?.assessmentData || {});

  const [expanded, setExpanded] = useState(false);
  const isLoading = useSelector(state => state.security.loading);
  const [editingRiskId, setEditingRiskId] = useState(null);
  const [editedRiskDetails, setEditedRiskDetails] = useState({});


const [openDialog, setOpenDialog] = useState(false);
const [currentRiskId, setCurrentRiskId] = useState(null);
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('info');

const [isSaving, setIsSaving] = useState(false);
const [isSaved, setIsSaved] = useState(false);

const { user } = useAuth0();


 
  useEffect(() => {
    console.log("Initiating fetch of security risks...");
    dispatch(fetchSecurityRisks(modelId, assessmentUuid, false));
}, [dispatch, modelId, assessmentUuid]);


const handleGenerateRisks = async () => {
    try {
      await dispatch(generateSecurityRisks(modelId, assessmentUuid));
      setSnackbarMessage('Security risks generated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
     
    } catch (error) {
      setSnackbarMessage(`Error generating risks: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };


  const allCategories = useMemo(() => loadSecurityRisks(), []);
  const groupedRisks = useMemo(() => {
    const grouped = {};
  
    for (const category of Object.keys(allCategories)) {
      grouped[category] = allCategories[category].map(riskName => {
        // Normalize riskName and category
        const normalizedRiskName = riskName.trim().toLowerCase();
        const normalizedCategory = category.trim().toLowerCase();
  
        // Find if the risk has been evaluated
        const existingRisk = securityRisks.find(risk => {
          const riskNameMatch =
            risk.risk_name &&
            risk.risk_name.trim().toLowerCase() === normalizedRiskName;
          const categoryMatch =
            risk.category &&
            risk.category.trim().toLowerCase() === normalizedCategory;
          return riskNameMatch && categoryMatch;
        });
  
        if (existingRisk) {
          return { ...existingRisk, evaluated: 'Yes' };
        } else {
          // Risk hasn't been evaluated yet
          return {
            risk_name: riskName,
            category,
            evaluated: 'No',
            threat_level: '',
            vulnerability_level: '',
            asset_value: '',
            risk_id: null, // No risk_id yet
          };
        }
      });
    }
    return grouped;
  }, [allCategories, securityRisks]);


  console.log ('security risks to render', groupedRisks)

  const handleEditRisk = useCallback((risk) => {

    const uniqueRiskId = risk.risk_id || `${risk.category}-${risk.risk_name}`;
    setEditingRiskId(uniqueRiskId);
    //setEditingRiskId(risk.risk_id || risk.risk_name);
    setEditedRiskDetails({
      risk_name: risk.risk_name,
      category: risk.category,
      threat_level: risk.threat_level,
      vulnerability_level: risk.vulnerability_level,
      asset_value: risk.asset_value
    });
  }, []);

  const handleChangeDetail = (field, value) => {
    setEditedRiskDetails(prev => ({ ...prev, [field]: value }));
  };

  const resetEditing = useCallback(() => {
    setEditingRiskId(null);
    setEditedRiskDetails({});
  }, []);



const handleSaveRisk = useCallback(async () => {
  setIsSaving(true); // Start saving

  const risk_id = isValidUUIDV4(editingRiskId) ? editingRiskId : null;

  const riskDetails = {
    risk_id,
    risk_name: editedRiskDetails.risk_name,
    category: editedRiskDetails.category,
    threat_level: editedRiskDetails.threat_level,
    vulnerability_level: editedRiskDetails.vulnerability_level,
    asset_value: editedRiskDetails.asset_value,
    justification: editedRiskDetails.justification,
    owner: {
      name: user.name,
      email: user.email,
      picture: user.picture,
    },
  };

  console.log('Saving risk:', riskDetails);

  try {
    await dispatch(saveSecurityRisk(modelId, assessmentUuid, riskDetails));
    setIsSaved(true);
    setTimeout(() => setIsSaved(false), 2000); // Clear saved state after 2 seconds
  } catch (error) {
    setSnackbarMessage(`Error saving risk: ${error.message}`);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  } finally {
    setIsSaving(false); // Stop saving
  }

  resetEditing();
}, [modelId, assessmentUuid, editingRiskId, editedRiskDetails, user.name, user.email, user.picture, resetEditing, dispatch]);
 
const handleRefreshRisks = () => {
  const securityData = loadSecurityRisks();
  dispatch(generateSecurityRisks(modelId, assessmentData, securityData));
};

  const handleOpenDialog = (riskId) => {
    setCurrentRiskId(riskId);
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  const handleDiscardRisk = () => {
    if (currentRiskId) {
      dispatch(discardSecurityRisk(modelId, currentRiskId))
        .then(() => {
          setSnackbarMessage('Risk discarded successfully.');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleCloseDialog();
        })
        .catch(error => {
          setSnackbarMessage(`Error discarding risk: ${error.message}`);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    }
  };

  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

const renderRisksByCategory = (categoryRisks, category) => (
  <TableContainer component={Paper}>
    <Table>
          <TableHead>
        <TableRow>
          <TableCell>Risk Name</TableCell>
          <TableCell>Threat Level</TableCell>
          <TableCell>Vulnerability Level</TableCell>
          <TableCell>Asset Value</TableCell>
          <TableCell>Evaluated</TableCell>
          <TableCell>Owner</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {categoryRisks.length > 0 ? (
          categoryRisks.map((risk) => {
            const uniqueRiskId = risk.risk_id || `${risk.category}-${risk.risk_name}`;
            return (
              <TableRow key={uniqueRiskId}>
                <TableCell>
                  {editingRiskId === uniqueRiskId ? (
                    <TextField
                      value={editedRiskDetails.risk_name}
                      onChange={(e) => handleChangeDetail('risk_name', e.target.value)}
                      fullWidth
                    />
                  ) : (
                    risk.risk_name
                  )}
                </TableCell>
                <TableCell>
                  {editingRiskId === uniqueRiskId ? (
                    <TextField
                      value={editedRiskDetails.threat_level}
                      onChange={(e) => handleChangeDetail('threat_level', e.target.value)}
                      fullWidth
                    />
                  ) : (
                    risk.threat_level || '-'
                  )}
                </TableCell>
                <TableCell>
                  {editingRiskId === uniqueRiskId ? (
                    <TextField
                      value={editedRiskDetails.vulnerability_level}
                      onChange={(e) =>
                        handleChangeDetail('vulnerability_level', e.target.value)
                      }
                      fullWidth
                    />
                  ) : (
                    risk.vulnerability_level || '-'
                  )}
                </TableCell>
                <TableCell>
                  {editingRiskId === uniqueRiskId ? (
                    <TextField
                      value={editedRiskDetails.asset_value}
                      onChange={(e) => handleChangeDetail('asset_value', e.target.value)}
                      fullWidth
                    />
                  ) : (
                    risk.asset_value || '-'
                  )}
                </TableCell>
                <TableCell>{risk.evaluated}</TableCell>
                <TableCell>
                  {risk.owner_name ? (
                    <Tooltip title={risk.owner_name}>
                      <Avatar alt={risk.owner_name} src={risk.owner_picture} />
                    </Tooltip>
                  ) : (
                    'No owner'
                  )}
                </TableCell>
                <TableCell>
                  {editingRiskId === uniqueRiskId ? (
                    <IconButton onClick={handleSaveRisk}>
                      <CheckIcon />
                    </IconButton>
                  ) : (
                    <>
                      {risk.evaluated === 'Yes' ? (
                        <>
                          <IconButton onClick={() => handleEditRisk(risk)}>
                            <EditIcon />
                          </IconButton>
                          {!risk.risk_id && (
                            <IconButton onClick={() => handleSaveRisk(risk)}>
                              <SaveIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => handleOpenDialog(risk.risk_id)}
                            style={{ color: 'red' }}
                            disabled={!risk.risk_id}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      ) : (
                        <Button onClick={() => handleEditRisk(risk)}>Evaluate</Button>
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={7} align="center">
              No {toTitleCase(category)} Risks
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

const handleChangePanel = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : false);
};

const isValidUUIDV4 = (uuid) => {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4;
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());
};

return (
    <div style={{ margin: '16px' }}>


<Paper elevation={2} style={{ padding: '20px', marginBottom: '16px' }}>
  <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
    Security Risks
    {/* Autosave Indicator 10px to the right of the title */}
    <Box sx={{ marginLeft: '10px' }}>
      <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
    </Box>
    {/* IconWithProgress aligned to the right with padding */}
    <Box sx={{ marginLeft: 'auto', paddingRight: '10px', display: 'flex', alignItems: 'center' }}>
      {/* Refresh Icon with Robot inside */}
      <Tooltip title="Refresh Risks">
        <IconButton onClick={handleRefreshRisks}>
          <RefreshIcon>
            <SmartToyOutlinedIcon style={{ position: 'absolute', fontSize: '0.8em' }} />
          </RefreshIcon>
        </IconButton>
      </Tooltip>
      {/* IconWithProgress */}
      {isLoading ? (
        <IconWithProgress isLoading={isLoading} />
      ) : (
        <IconWithProgress isLoading={isLoading} />
      )}
    </Box>
  </Typography>
</Paper>

        
      {/* {Object.entries(groupedRisks)
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([category, categoryRisks]) => (
        <Accordion key={category}
         expanded={expanded === category}
          onChange={handleChangePanel(category)} 
          style={{ marginBottom: '16px' }}>
           <AccordionSummary
            expandIcon={expanded === category ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    >
           <Typography style={{ fontWeight: 'bold' }}>{toTitleCase(category)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderRisksByCategory(categoryRisks, category)}
          </AccordionDetails>
        </Accordion>
      ))} */}
       {securityRisks.length > 0 ? (
        // Display existing security risks grouped by category
        Object.entries(groupedRisks)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([category, categoryRisks]) => (
            <Accordion
              key={category}
              expanded={expanded === category}
              onChange={handleChangePanel(category)}
              style={{ marginBottom: '16px' }}
            >
              <AccordionSummary
                expandIcon={expanded === category ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: 'bold' }}>{toTitleCase(category)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderRisksByCategory(categoryRisks, category)}
              </AccordionDetails>
            </Accordion>
          ))
      ) : (
        // If no security risks, show the "Generate Security Risks" button
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            No Security Risks Available
          </Typography>
          {/* <Typography variant="body1" gutterBottom>
            Complete the security assessment and generate risks.
          </Typography> */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<SmartToyOutlinedIcon />}
            onClick={handleGenerateRisks}
            sx={{ mt: 2 }}
          >
            Generate Security Risks
          </Button>
        </Box>
      )}

      <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Discard"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to discard this security risk?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDiscardRisk} color="primary" autoFocus>
          Discard
        </Button>
      </DialogActions>
    </Dialog>

    {/* Snackbar for Feedback */}
    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
    </div>
  );
};

export default SecurityRiskCatalogue;
