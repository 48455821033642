// RisksBySource.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import RisksBySourceTable from './SourceViewTable'; // New Component
import SecurityReportData from './SecurityReportData';

const RisksBySource = () => {
  const models = useSelector(state => state.risks.models);
  const securityReportData = SecurityReportData();

  // Aggregate risks by source and then by category
  const reportData = React.useMemo(() => {
    const sources = {};

    // Process model risks
    Object.entries(models || {}).forEach(([modelUuid, model]) => {
      // Filter only completed assessments
      if (model.assessmentStatus !== 'Completed') return;

      Object.entries(model.riskFormData || {}).forEach(([sectionName, sectionData]) => {
        Object.entries(sectionData.sectionRisks || {}).forEach(([category, risksArray]) => {
          if (!sources[sectionName]) {
            sources[sectionName] = {};
          }

          if (!sources[sectionName][category]) {
            sources[sectionName][category] = [];
          }

          if (Array.isArray(risksArray)) {
            risksArray.forEach(risk => {
              sources[sectionName][category].push({
                ...risk,
                modelName: model.modelName, // Track which model the risk belongs to
                modelUuid: modelUuid,
                assessmentUuid: model.assessmentUuid,
                assessmentStatus: model.assessmentStatus,
              });
            });
          }
        });
      });
    });

    // Process security risks as a separate source
    if (securityReportData && securityReportData.dataByModel) {
      Object.entries(securityReportData.dataByModel).forEach(([modelUuid, securityData]) => {
        if (!sources['Security']) {
          sources['Security'] = {};
        }

        const category = 'N/A'; // Security risks might not have categories; adjust if they do

        if (!sources['Security'][category]) {
          sources['Security'][category] = [];
        }

        if (Array.isArray(securityData.risks)) {
          securityData.risks.forEach(securityRisk => {
            sources['Security'][category].push({
              ...securityRisk,
              modelName: models[modelUuid]?.modelName || 'Unknown Model',
              modelUuid: modelUuid,
              assessmentUuid: models[modelUuid]?.assessmentUuid || '',
              assessmentStatus: models[modelUuid]?.assessmentStatus || '',
              isSecurityRisk: true, // Flag to identify security risks
            });
          });
        }
      });
    }

    console.log('Constructed reportData by Source and Category:', sources);

    return sources;
  }, [models, securityReportData]);

  return <RisksBySourceTable reportData={reportData} />;
};

export default RisksBySource;

// // RisksBySource.jsx
// import React from 'react';
// import { useSelector } from 'react-redux';
// import RisksBySourceTable from './SourceViewTable'; // New Component
// import SecurityReportData from './SecurityReportData';

// const RisksBySource = () => {
//   const models = useSelector(state => state.risks.models);
//   const securityReportData = SecurityReportData();

//   // Aggregate risks by category/source
//   const reportData = React.useMemo(() => {
//     const categories = {};

//     Object.entries(models || {}).forEach(([modelUuid, model]) => {
//       // Filter only completed assessments
//       if (model.assessmentStatus !== 'Completed') return;

//       Object.entries(model.riskFormData || {}).forEach(([sectionName, sectionData]) => {
//         Object.entries(sectionData.sectionRisks || {}).forEach(([category, risksArray]) => {
//           if (!categories[category]) {
//             categories[category] = [];
//           }

//           if (Array.isArray(risksArray)) {
//             risksArray.forEach(risk => {
//               categories[category].push({
//                 ...risk,
//                 modelName: model.modelName, // Keep track of which model the risk belongs to
//                 modelUuid: modelUuid,
//                 assessmentUuid: model.assessmentUuid,
//                 assessmentStatus: model.assessmentStatus,
//               });
//             });
//           }
//         });
//       });
//     });

//     // Add security risks as their own category/source
//     if (securityReportData && securityReportData.dataByModel) {
//       Object.entries(securityReportData.dataByModel).forEach(([modelUuid, securityData]) => {
//         if (!categories['Security']) {
//           categories['Security'] = [];
//         }
//         if (Array.isArray(securityData.risks)) {
//           securityData.risks.forEach(securityRisk => {
//             categories['Security'].push({
//               ...securityRisk,
//               modelName: models[modelUuid]?.modelName || 'Unknown Model',
//               modelUuid: modelUuid,
//               assessmentUuid: models[modelUuid]?.assessmentUuid || '',
//               assessmentStatus: models[modelUuid]?.assessmentStatus || '',
//               isSecurityRisk: true, // Flag to identify security risks
//             });
//           });
//         }
//       });
//     }

//     console.log('Constructed reportData by Category/Source:', categories);

//     return categories;
//   }, [models, securityReportData]);

//   return <RisksBySourceTable reportData={reportData} />;
// };

// export default RisksBySource;

// import React from 'react';
// import { useSelector } from 'react-redux';
// import SourceViewTable from './SourceViewTable';
// import SecurityReportData from './SecurityReportData';

// const RisksBySource = () => {
//   const models = useSelector(state => state.risks.models);
//   const securityReportData = SecurityReportData();
  
//   const reportData = React.useMemo(() => {
//     const sections = {};
  
//     Object.entries(models).forEach(([modelUuid, model]) => {
//       // Filter only completed assessments
//       if (model.assessmentStatus !== 'Completed') return;

//       Object.entries(model.riskFormData || {}).forEach(([sectionName, sectionData]) => {
//         if (!sections[sectionName]) {
//           sections[sectionName] = { models: {} };
//         }
//         if (!sections[sectionName].models[model.modelName]) {
//           sections[sectionName].models[model.modelName] = {
//             risks: [],
//             modelId: modelUuid,
//             modelName: model.modelName,
//             assessmentUuid: model.assessmentUuid,
//             assessmentStatus: model.assessmentStatus,
//           };
//         }
//         // Iterate over the categories inside sectionRisks
//         Object.entries(sectionData.sectionRisks || {}).forEach(([category, risksArray]) => {
//           if (Array.isArray(risksArray)) {
//             risksArray.forEach((risk) => {
//               sections[sectionName].models[model.modelName].risks.push({
//                 ...risk,
//                 riskName: risk.riskName,
//                 category: category, // Include category information if needed
//               });
//             });
//           }
//         });
//       });
//     });
  
//     // Adding security section
//     sections['security'] = { models: securityReportData.dataByModel };
  
//     console.log('Constructed reportData:', sections);
  
//     return sections;
//   }, [models, securityReportData]);

//   return <SourceViewTable reportData={reportData} securityReportData={securityReportData} />;
// };

// export default RisksBySource;