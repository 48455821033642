// src/redux/actions/modelActions.js

// Action Types
export const IMPORT_SAGEMAKER_MODELS_REQUEST = 'IMPORT_SAGEMAKER_MODELS_REQUEST';
export const IMPORT_SAGEMAKER_MODELS_SUCCESS = 'IMPORT_SAGEMAKER_MODELS_SUCCESS';
export const IMPORT_SAGEMAKER_MODELS_FAILURE = 'IMPORT_SAGEMAKER_MODELS_FAILURE';

export const IMPORT_BEDROCK_MODELS_REQUEST = 'IMPORT_BEDROCK_MODELS_REQUEST';
export const IMPORT_BEDROCK_MODELS_SUCCESS = 'IMPORT_BEDROCK_MODELS_SUCCESS';
export const IMPORT_BEDROCK_MODELS_FAILURE = 'IMPORT_BEDROCK_MODELS_FAILURE';

export const REMOVE_MODEL = 'REMOVE_MODEL';

// Action Creators for SageMaker
export const importSageMakerModelsRequest = () => ({
  type: IMPORT_SAGEMAKER_MODELS_REQUEST,
});

export const importSageMakerModelsSuccess = (models) => ({
  type: IMPORT_SAGEMAKER_MODELS_SUCCESS,
  payload: models,
});

export const importSageMakerModelsFailure = (error) => ({
  type: IMPORT_SAGEMAKER_MODELS_FAILURE,
  payload: error,
});

// Action Creators for Bedrock
export const importBedrockModelsRequest = () => ({
  type: IMPORT_BEDROCK_MODELS_REQUEST,
});

export const importBedrockModelsSuccess = (models) => ({
  type: IMPORT_BEDROCK_MODELS_SUCCESS,
  payload: models,
});

export const importBedrockModelsFailure = (error) => ({
  type: IMPORT_BEDROCK_MODELS_FAILURE,
  payload: error,
});

// Action Creator for Removing a Model
export const removeModel = (modelUuid) => ({
  type: REMOVE_MODEL,
  payload: modelUuid,
});