import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { riskPriorityColors } from './utils/riskColors'


const RiskCounters = ({ riskCounts }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {Object.entries(riskCounts).map(([priority, count]) => {
        if (count > 0) {
          return (
            <Tooltip key={priority} title={priority} arrow>
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mr: 1 }}>
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: riskPriorityColors[priority] || '#E0E0E0', // Use shared colors
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.75rem',
                  }}
                >
                  {count}
                </Box>
              </Box>
            </Tooltip>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default RiskCounters;
