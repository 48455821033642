import React from 'react';
import { Box, Tooltip } from '@mui/material';

const colors = {
  Critical: '#000000',    // Black
  High: '#FF1744',        // Red
  Significant: '#FFC107', // Amber
  Moderate: '#FFA726',    // Light Orange
  Low: '#2196F3',         // Blue
  Negligible: '#4CAF50',  // Green
  None: '#BDBDBD',        // Light Grey
};

const capitalizeFirstLetter = (string) => {
  if (!string) return 'None';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const SecurityRiskCounter = ({ securityRisks }) => {
  // Count security risks by priority
  const riskCounts = {
    Critical: 0,
    High: 0,
    Significant: 0,
    Moderate: 0,
    Low: 0,
    Negligible: 0,
    None: 0,
  };

  // Iterate over the security risks and count them by priority
  securityRisks.forEach((risk) => {
    const priority = capitalizeFirstLetter(risk.threat_level || 'None').trim();
    if (priority in riskCounts) {
      riskCounts[priority] += 1;
    }
  });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {Object.entries(riskCounts).map(([priority, count]) => {
        if (count > 0) {
          return (
            <Tooltip key={priority} title={priority} arrow>
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mr: 1 }}>
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: colors[priority] || '#E0E0E0',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.75rem',
                  }}
                >
                  {count}
                </Box>
              </Box>
            </Tooltip>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default SecurityRiskCounter;
