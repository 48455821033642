// src/RiskEvaluationForm.js
import React, { useEffect, useState, useMemo } from 'react';
import {
  Slider,
  Typography,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  IconButton,
  Collapse
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import evaluationCriteria from './evaluationForm.json';
import { fetchRiskEvaluations, updateRiskEvaluation } from './redux/actions/riskActions.js';

const toTitleCase = (str = '') => {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .trim()
    .split(' ')
    .map((word) =>
      word.length > 0
        ? word[0].toUpperCase() + word.slice(1).toLowerCase()
        : ''
    )
    .join(' ');
};

// Color scale for sliders
const sliderColorScale = [
  '#BDBDBD',
  '#4CAF50',
  '#FFA726',
  '#FFC107',
  '#FF1744',
  '#000000',
];

const RiskEvaluationForm = ({
  modelId,
  riskName,
  riskId,
  assessmentUuid,
  sectionName,
  handleSaveOperation,
  category,
}) => {
  const themes = useSelector((state) => state.themes);
  const dispatch = useDispatch();

  const savedRisk = useSelector((state) => {
    const risks =
      state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks?.[
        category
      ] || [];
    return risks.find((r) => r.riskId === riskId);
  });

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [isLoading, setIsLoading] = useState(true);

  // State to manage deep dive sections per theme and attribute
  const [deepDiveOpen, setDeepDiveOpen] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      await dispatch(fetchRiskEvaluations(modelId, assessmentUuid, sectionName,category));
      setIsLoading(false); // End loading
    };

    fetchData();
  }, [dispatch, modelId, assessmentUuid, sectionName, category]);

  const debouncedSaveEvaluation = useMemo(
    () =>
      debounce(async (themeId, attribute, property, value, priority, rationale) => {
        if (!savedRisk?.evaluationData) {
          // ...
          return;
        }
        try {
          const response = await fetch('/.netlify/functions/saveRiskEvaluation', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              modelUuid: modelId,
              assessmentUuid,
              riskName,
              riskId,
              themeId,
              category,
              priority,
              rationale,
              // Put the single property into the data object
              data: {
                [property]: value
              }
            }),
          });
          if (!response.ok) {
            throw new Error('Failed to save evaluation');
          }
        } catch (error) {
          setSnackbarMessage(`Failed to save risk evaluation: ${error.message}`);
           setSnackbarSeverity('error');
           setSnackbarOpen(true);
        }
      }, 400),
    [modelId, assessmentUuid, riskName, riskId, savedRisk, category]
  );

  const handleSliderChange = (themeId, attribute, property, options) => (e, sliderValue) => {
    const priority = savedRisk?.priority || '';
    const rationale = savedRisk?.rationale || '';
    const value = options[sliderValue];

    if (savedRisk) {
      dispatch(
        updateRiskEvaluation(
          modelId,
          riskId,
          sectionName,
          themeId,
          attribute,
          property,
          value,
          priority,
          rationale,
          category
        )
      );
      handleSaveOperation(() =>
        debouncedSaveEvaluation(themeId, attribute, property, value, priority, rationale)
      );
    }
  };

  const toggleDeepDive = (themeId, attribute) => {
    setDeepDiveOpen((prev) => ({
      ...prev,
      [`${themeId}-${attribute}`]: !prev[`${themeId}-${attribute}`],
    }));
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason !== 'clickaway') setSnackbarOpen(false);
  };

  if (isLoading) {
    // Display spinner while loading
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px', // Adjust as needed
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!savedRisk) {
    return <Typography>Risk not found.</Typography>;
  }
  
  // If savedRisk exists but has no evaluationData,
  // we can build an *empty* evaluationData on the fly:
  if (!savedRisk.evaluationData) {
    savedRisk.evaluationData = {}; 
    // or create an empty object with placeholders
  }

  return (
    <>
      {/* Title Section */}
      <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: 'text.primary' }}
            gutterBottom
          >
            Risk Evaluation
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 400, color: 'text.secondary' }}
          >
            {riskName}
          </Typography>
        </Box>
      </Paper>

      {/* Main Content */}
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 4 }}>
        {themes.map((theme) => (
          <Paper
            key={theme.theme_id}
            elevation={1}
            sx={{
              p: 3,
              backgroundColor: '#f9f9f9',
              borderRadius: 2,
            }}
          >
            {/* Theme Name */}
            <Typography variant="h6" sx={{ fontWeight: 700, mb: 3 }}>
              {theme.name}
            </Typography>

            {/* Evaluation Areas */}
            {evaluationCriteria.map((criterion) => (
              <Box key={criterion.attribute} sx={{ mb: 4 }}>
                {/* Attribute Header with Deep Dive Button */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, flexGrow: 1 }}>
                    {toTitleCase(criterion.attribute)} Analysis
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => toggleDeepDive(theme.theme_id, criterion.attribute)}
                    aria-label="Deep Dive"
                  >
                    {deepDiveOpen[`${theme.theme_id}-${criterion.attribute}`] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </IconButton>
                </Box>

                {/* Base Evaluation Slider */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  {/* Label */}
                  <Typography
                    variant="body2"
                    sx={{
                      width: '150px',
                      color: 'text.secondary',
                      fontWeight: 500,
                    }}
                  >
                    {toTitleCase(criterion.baseEvaluation.name)}
                  </Typography>

                  {/* Slider */}
                  <Box sx={{ flexGrow: 1, mx: 2 }}>
                    <Slider
                      aria-label={`Slider for ${toTitleCase(criterion.baseEvaluation.name)}`}
                      value={criterion.baseEvaluation.options.indexOf(
                        savedRisk.evaluationData[theme.theme_id]?.[criterion.attribute]?.[criterion.baseEvaluation.name] || ''
                      )}
                      step={1}
                      min={0}
                      max={criterion.baseEvaluation.options.length - 1}
                      marks={criterion.baseEvaluation.options.map((o, i) => ({ value: i, label: o }))}
                      onChangeCommitted={handleSliderChange(
                        theme.theme_id,
                        criterion.attribute,
                        criterion.baseEvaluation.name,
                        criterion.baseEvaluation.options
                      )}
                      valueLabelDisplay="off"
                      sx={{
                        color: sliderColorScale[
                          Math.min(
                            criterion.baseEvaluation.options.indexOf(
                              savedRisk.evaluationData[theme.theme_id]?.[criterion.attribute]?.[criterion.baseEvaluation.name] || ''
                            ),
                            sliderColorScale.length - 1
                          )
                        ],
                        '& .MuiSlider-track': {
                          backgroundColor: sliderColorScale[
                            Math.min(
                              criterion.baseEvaluation.options.indexOf(
                                savedRisk.evaluationData[theme.theme_id]?.[criterion.attribute]?.[criterion.baseEvaluation.name] || ''
                              ),
                              sliderColorScale.length - 1
                            )
                          ],
                        },
                        '& .MuiSlider-thumb': {
                          borderColor: sliderColorScale[
                            Math.min(
                              criterion.baseEvaluation.options.indexOf(
                                savedRisk.evaluationData[theme.theme_id]?.[criterion.attribute]?.[criterion.baseEvaluation.name] || ''
                              ),
                              sliderColorScale.length - 1
                            )
                          ],
                        },
                        '& .MuiSlider-markLabel': { fontSize: '0.75rem' },
                      }}
                    />
                  </Box>
                </Box>

                {/* Deep Dive Section */}
                <Collapse
                  in={deepDiveOpen[`${theme.theme_id}-${criterion.attribute}`]}
                  timeout="auto"
                  unmountOnExit
                >
                  <Paper
                    elevation={1}
                    sx={{
                      p: 2,
                      mt: 2,
                      backgroundColor: '#fff',
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ mb: 2 }}>
                      Detailed {toTitleCase(criterion.attribute)} Analysis
                    </Typography>
                    {/* Render deep dive fields */}
                    {criterion.deepDive.map((field) => {
                      const currentValue =
                        savedRisk.evaluationData[theme.theme_id]?.[criterion.attribute]?.[field.name] || '';
                      const sliderIndex = field.options.indexOf(currentValue);
                      const valueIndex = sliderIndex >= 0 ? sliderIndex : 0;

                      const colorIndex = Math.min(valueIndex, sliderColorScale.length - 1);
                      const sliderColor = sliderColorScale[colorIndex];

                      return (
                        <Box
                          key={field.name}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 2,
                          }}
                        >
                          {/* Label */}
                          <Typography
                            variant="body2"
                            sx={{
                              width: '150px',
                              color: 'text.secondary',
                              fontWeight: 500,
                            }}
                          >
                            {toTitleCase(field.name)}
                          </Typography>

                          {/* Slider */}
                          <Box sx={{ flexGrow: 1, mx: 2 }}>
                            <Slider
                              aria-label={`Slider for ${toTitleCase(field.name)} Details`}
                              value={valueIndex}
                              step={1}
                              min={0}
                              max={field.options.length - 1}
                              marks={field.options.map((o, i) => ({ value: i, label: o }))}
                              onChangeCommitted={handleSliderChange(
                                theme.theme_id,
                                criterion.attribute,
                                field.name,
                                field.options
                              )}
                              valueLabelDisplay="off"
                              sx={{
                                color: sliderColor,
                                '& .MuiSlider-track': { backgroundColor: sliderColor },
                                '& .MuiSlider-thumb': { borderColor: sliderColor },
                                '& .MuiSlider-markLabel': { fontSize: '0.75rem' },
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Paper>
                </Collapse>
              </Box>
            ))}
          </Paper>
        ))}
      </Box>

      {/* Snackbar Component */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RiskEvaluationForm;
// import React, { useEffect, useState, useMemo } from 'react';
// import {
//   Slider,
//   Typography,
//   Box,
//   Snackbar,
//   Alert,
//   CircularProgress,
//   Paper
// } from '@mui/material';
// import { useSelector, useDispatch } from 'react-redux';
// import { debounce } from 'lodash';

// import evaluationCriteria from './evaluationForm.json';
// import { fetchRiskEvaluations, updateRiskEvaluation } from './redux/actions/riskActions.js';


// const toTitleCase = (str = '') => {
//   return str
//     .replace(/([A-Z])/g, ' $1')
//     .replace(/_/g, ' ')
//     .trim()
//     .split(' ')
//     .map((word) =>
//       word.length > 0
//         ? word[0].toUpperCase() + word.slice(1).toLowerCase()
//         : ''
//     )
//     .join(' ');
// };

// // A simple color scale from left (index 0) to right (index n-1)
// const sliderColorScale = [
//   '#BDBDBD',
//   '#4CAF50',
//   '#FFA726',
//   '#FFC107',
//   '#FF1744',
//   '#000000',
// ];

// const RiskEvaluationForm = ({
//   modelId,
//   riskName,
//   riskId,
//   assessmentUuid,
//   sectionName,
//   handleSaveOperation,
//   category,
// }) => {
//   const themes = useSelector((state) => state.themes);
//   const dispatch = useDispatch();

//   const savedRisk = useSelector((state) => {
//     const risks =
//       state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks?.[
//         category
//       ] || [];
//     return risks.find((r) => r.riskId === riskId);
//   });

//   // Snackbar states
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [isLoading, setIsLoading] = useState(true);


//   useEffect(() => {
//     const fetchData = async () => {
//       setIsLoading(true); // Start loading
//       await dispatch(fetchRiskEvaluations(modelId, assessmentUuid, sectionName));
//       setIsLoading(false); // End loading
//     };

//     fetchData();
//   }, [dispatch, modelId, assessmentUuid, sectionName]);

//   // Debounced save
//   const debouncedSaveEvaluation = useMemo(
//     () =>
//       debounce(async (themeId, property, value, priority, rationale) => {
//         if (!savedRisk?.evaluationData) {
//           setSnackbarMessage('No evaluation data found for this risk');
//           setSnackbarSeverity('error');
//           setSnackbarOpen(true);
//           return;
//         }
//         try {
//           const response = await fetch('/.netlify/functions/saveRiskEvaluation', {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({
//               modelUuid: modelId,
//               assessmentUuid,
//               riskName,
//               riskId,
//               themeId,
//               property,
//               value,
//               category,
//               priority,
//               rationale,
//             }),
//           });
//           if (!response.ok) {
//             throw new Error('Failed to save evaluation');
//           }
//         } catch (error) {
//           setSnackbarMessage(`Failed to save risk evaluation: ${error.message}`);
//           setSnackbarSeverity('error');
//           setSnackbarOpen(true);
//         }
//       }, 400),
//     [modelId, assessmentUuid, riskName, riskId, savedRisk, category]
//   );

//   const handleSliderChange = (themeId, attribute, property, options) => (e, sliderValue) => {
//     const priority = savedRisk?.priority || '';
//     const rationale = savedRisk?.rationale || '';
//     const value = options[sliderValue];

//     if (savedRisk) {
//       dispatch(
//         updateRiskEvaluation(
//           modelId,
//           riskId,
//           sectionName,
//           themeId,
//           attribute,
//           property,
//           value,
//           priority,
//           rationale,
//           category
//         )
//       );
//       handleSaveOperation(() =>
//         debouncedSaveEvaluation(themeId, property, value, priority, rationale)
//       );
//     }
//   };

//   const handleSnackbarClose = (_, reason) => {
//     if (reason !== 'clickaway') setSnackbarOpen(false);
//   };

//   if (isLoading) {
//     // Display spinner while loading
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           minHeight: '200px', // Adjust as needed
//         }}
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (!savedRisk?.evaluationData) {
//     // Display message if no data is available after loading
//     return (
//       <Typography variant="body2">
//         No evaluation data available for this risk.
//       </Typography>
//     );
//   }


//   return (
//     <>
//       {/* Title Section */}
//       <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
//         <Box>
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: 700, color: 'text.primary' }}
//             gutterBottom
//           >
//             Risk Evaluation
//           </Typography>
//           <Typography
//             variant="subtitle1"
//             sx={{ fontWeight: 400, color: 'text.secondary' }}
//           >
//             {riskName}
//           </Typography>
//         </Box>
//       </Paper>

//       {/* Main Content */}
//       <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
//         {themes.map((theme) => {
//           const themeData = savedRisk.evaluationData[theme.theme_id]?.impact || {};

//           return (
//             <Box
//               key={theme.theme_id}
//               sx={{
//                 mb: 3,
//                 p: 2,
//                 backgroundColor: '#f8f8f8',
//                 borderRadius: 1,
//                 overflow: 'hidden', // Prevent child elements from overflowing
//               }}
//             >
//               {/* Theme Name */}
//               <Typography variant="body1" sx={{ fontWeight: 700, mb: 2 }}>
//                 {theme.name}
//               </Typography>

//               {/* Properties: Label and Slider */}
//               {evaluationCriteria[0].properties.map(({ name, options }) => {
//                 const currentValue = themeData[name] || '';
//                 const sliderIndex = options.indexOf(currentValue);
//                 const valueIndex = sliderIndex >= 0 ? sliderIndex : 0;

//                 const colorIndex = Math.min(valueIndex, sliderColorScale.length - 1);
//                 const sliderColor = sliderColorScale[colorIndex];

//                 return (
//                   <Box
//                     key={name}
//                     sx={{
//                       mb: 2, // Margin-bottom between each label-slider pair
//                       display: 'flex',
//                       flexDirection: 'column', // Stack label and slider vertically
//                       alignItems: 'flex-start', // Align items to the left
//                     }}
//                   >
//                     {/* Label */}
//                     <Typography
//                       variant="body2"
//                       sx={{
//                         color: 'text.secondary',
//                         mb: 1, // Reduced margin-bottom for closer spacing
//                         ml: 2, // Slight left margin for hierarchy
//                         fontWeight: 700, // Make label text bold
//                       }}
//                     >
//                       {toTitleCase(name)}
//                     </Typography>

//                     {/* Slider */}
//                     <Box
//                         sx={{
//                           ml: 4, // Further left margin for slider indentation
//                           mr: 2, // Margin-right for breathing room
//                           px: 1, // Padding-left and padding-right of 8px
//                           maxWidth: 400, 
//                           width: '100%', 
//                           boxSizing: 'border-box', // Ensure padding is included in width
//                         }}
//                       >
//                       <Slider
//                         aria-label={`Slider for ${toTitleCase(name)}`}
//                         value={valueIndex}
//                         step={1}
//                         min={0}
//                         max={options.length - 1}
//                         marks={options.map((o, i) => ({ value: i, label: o }))}
//                         onChangeCommitted={handleSliderChange(
//                           theme.theme_id,
//                           evaluationCriteria[0].attribute,
//                           name,
//                           options
//                         )}
//                         valueLabelDisplay="off"
//                         sx={{
//                           color: sliderColor,
//                           '& .MuiSlider-track': { backgroundColor: sliderColor },
//                           '& .MuiSlider-thumb': { borderColor: sliderColor },
//                           '& .MuiSlider-markLabel': { fontSize: '0.75rem' },
//                         }}
//                       />
//                     </Box>
//                   </Box>
//                 );
//               })}
//             </Box>
//           );
//         })}
//       </Box>

//       {/* Snackbar Component */}
//       <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity}
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </>
//   );
// };

// export default RiskEvaluationForm;
