import React, { useMemo, useState } from 'react';
//import { useDispatch } from 'react-redux';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  //TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Typography,
  Button,
  Tab,
  Tabs,
  Avatar,
  Tooltip,
   Modal, //Alert, Snackbar,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  Edit as EditIcon,
  SmartToyOutlined as SmartToyOutlinedIcon,
} from '@mui/icons-material';

import SecurityRiskCounter from './SecurityRiskCounter';
import SecurityReportData from './SecurityReportData'; // Custom hook
import SingleSecurityRiskReport from './SingleSecurityRiskReport';

import SecurityRiskAssessment from './SecurityRiskAssessment';
import SecurityRiskCatalogue from './SecurityRiskCatalogue';



const SecurityAssessmentReport = ({
  modelId,
  assessmentUuid,
  expandedSection,
  handleExpandSection,
  //handleOpenControlsModal,

}) => {
  
  //const dispatch = useDispatch();
  const securityReportData = SecurityReportData();

  const [editingSecurityRisk, setEditingSecurityRisk] = useState(null);
  const [categoryTabIndex, setCategoryTabIndex] = useState(0);
    // State for Snackbar feedback
   // const [snackbarOpen, setSnackbarOpen] = useState(false);
    // const [snackbarMessage, setSnackbarMessage] = useState('');
    // const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Assessment & RiskCard', 'Risk Catalogue'];

  // Derived data
  
  const allSecurityRisks = useMemo(() => {
    if (!securityReportData.dataByModel || !securityReportData.dataByModel[modelId]?.risks) return [];
    return securityReportData.dataByModel[modelId].risks.map((risk) => ({
      ...risk,
      riskId: risk.risk_id,
      status: risk.status || 'none',
    }));
  }, [securityReportData, modelId]);

  const securityRisksByCategory = useMemo(() => {
    const dataByCategory = securityReportData.dataByCategory || {};
    // Filter risks by the current modelId
    const risksByCategory = {};
    Object.entries(dataByCategory).forEach(([category, risks]) => {
      const risksForModel = risks.filter((risk) => risk.modelUuid === modelId);
      if (risksForModel.length > 0) {
        risksByCategory[category] = risksForModel;
      }
    });
    return risksByCategory;
  }, [securityReportData, modelId]);
  
  const allSecurityControls = useMemo(() => {
    if (!securityReportData.dataByModel || !securityReportData.dataByModel[modelId]?.controls) return {};
    const controls = {};
    Object.entries(securityReportData.dataByModel[modelId].controls).forEach(([riskId, controlList]) => {
      controls[riskId] = controlList.map((control) => ({
        ...control,
        status: control.status || 'none',
      }));
    });
    return controls;
  }, [securityReportData, modelId]);

  const renderControlsCount = (riskId) => {
    const controls = allSecurityControls[riskId];
    return controls ? controls.length : 0;
  };

  

  
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const hasNoSecurityRisks = allSecurityRisks.length === 0;
  
  const handleEditSecurityRisk = (riskData) => {
    setEditingSecurityRisk(riskData);
  };

  const handleCloseEditSecurityRisk = () => {
    setEditingSecurityRisk(null);
  };

  const handleCategoryTabChange = (event, newValue) => {
    setCategoryTabIndex(newValue);
  };




  if (editingSecurityRisk) {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell colSpan={3} sx={{ padding: 0 }}>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SingleSecurityRiskReport
                    risk={editingSecurityRisk}
                    modelId={modelId}
                    assessmentUuid={assessmentUuid}
                    onClose={handleCloseEditSecurityRisk}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

   // Function to handle modal open
   const handleOpenModal = () => {
    setIsModalOpen(true);
    setActiveStep(0); // Reset to first step when opening
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Function to handle Snackbar closure
  // const handleSnackbarClose = () => {
  //   setSnackbarOpen(false);
  // };

  // Function to handle successful risk generation
  // const handleRisksGenerated = () => {
  //   setSnackbarMessage('Security risks generated successfully!');
  //   setSnackbarSeverity('success');
  //   setSnackbarOpen(true);
  //   setIsModalOpen(false);
  // };

  // // Function to handle errors during risk generation
  // const handleRiskGenerationError = (error) => {
  //   setSnackbarMessage(`Error generating risks: ${error.message}`);
  //   setSnackbarSeverity('error');
  //   setSnackbarOpen(true);
  // };

  // Modal Styling
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
  };


  return (
    <React.Fragment>
      {/* Security Section Header */}
      <TableRow>
        <TableCell>
          <IconButton onClick={() => handleExpandSection('Security')}>
            {expandedSection['Security'] ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          Security
        </TableCell>
        <TableCell>
          <SecurityRiskCounter securityRisks={allSecurityRisks} />
        </TableCell>
        <TableCell></TableCell>
      </TableRow>

      {/* Security Section Content */}
      <TableRow>
        <TableCell colSpan={3}>
          <Collapse in={expandedSection['Security']} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              {hasNoSecurityRisks ? (
                // No risks available
                <Grid item xs={12}>
                  <Box margin={2}>
                    <Typography variant="body1" gutterBottom>
                      No security risks available.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SmartToyOutlinedIcon />}
                      onClick={handleOpenModal}
                      sx={{ mt: 2 }}
                    >
                      Generate Security Risks
                    </Button>
                  </Box>
                </Grid>
              ) : (
                <>
                  {/* Vertical Tabs for Categories */}
                  <Grid item xs={3}>
                    <Tabs
                      orientation="vertical"
                      value={categoryTabIndex}
                      onChange={handleCategoryTabChange}
                      variant="scrollable"
                      sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                      {Object.keys(securityRisksByCategory).map((category) => (
                        <Tab
                          key={category}
                          label={`${capitalizeFirstLetter(category)} (${
                            securityRisksByCategory[category].length
                          })`}
                          sx={{ textTransform: 'none' }}
                        />
                      ))}
                    </Tabs>
                  </Grid>

                  {/* Risks inside each Category */}
                  <Grid item xs={9}>
                    {Object.keys(securityRisksByCategory).map((category, index) => (
                      <Box key={category} hidden={categoryTabIndex !== index}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Risk Name</TableCell>
                              <TableCell>Threat Level</TableCell>
                              <TableCell>Controls</TableCell>
                              <TableCell>Owner</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {securityRisksByCategory[category].map((risk) => (
                              <TableRow key={risk.risk_id}>
                                <TableCell>{risk.risk_name}</TableCell>
                                <TableCell>{capitalizeFirstLetter(risk.threat_level || 'none')}</TableCell>
                                <TableCell>
                                {renderControlsCount(risk.risk_id)}
                                  </TableCell>
                                  <TableCell>
                                    {risk.owner_name ? (
                                      <Tooltip title={risk.owner_name}>
                                        <Avatar alt={risk.owner_name} src={risk.owner_picture} />
                                      </Tooltip>
                                    ) : (
                                      'No owner'
                                    )}
                                  </TableCell>
                                <TableCell>
                                  <IconButton onClick={() => handleEditSecurityRisk(risk)}>
                                    <EditIcon />
                                  </IconButton>
                                  
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="generate-security-risks-modal-title"
        aria-describedby="generate-security-risks-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="generate-security-risks-modal-title" variant="h6" component="h2" gutterBottom>
            Generate Security Risks
          </Typography>
          <Typography id="generate-security-risks-modal-description" sx={{ mb: 2 }}>
            Complete the security risk assessment and generate security risks.
          </Typography>
          
          <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
    
    {/* Step Content */}
    {activeStep === 0 && (
      <Box>
        {/* SecurityRiskAssessment Component */}
        <SecurityRiskAssessment
          modelId={modelId}
          assessmentUuid={assessmentUuid}
        />
        
        {/* Spacer */}
        <Box sx={{ my: 3 }} />
        
       
      </Box>
    )}

    {activeStep === 1 && (
      <Box>
        {/* SecurityRiskCatalogue Component */}
        <SecurityRiskCatalogue
          modelId={modelId}
          assessmentUuid={assessmentUuid}
        />
      </Box>
    )}
    
    {/* Modal Actions with Stepper Navigation */}
    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        variant="outlined"
      >
        Back
      </Button>
      <Box>
        {activeStep < steps.length - 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            sx={{ mr: 1 }}
          >
            Next
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
          >
            Finish
          </Button>
        )}
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseModal}
          sx={{ ml: 1 }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  </Box>
</Modal>

    
      {/* <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar> */}
    </React.Fragment>
  );
};


export default SecurityAssessmentReport;