// SecurityControlsList.js

import React, {useState} from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  Typography
} from '@mui/material';
import { Save as SaveIcon, Edit as EditIcon} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
//, useSelector
import { saveSecurityControl } from './redux/actions/securityActions';

const SecurityControlsList = ({ controls = [], modelId, riskId, assessmentUuid }) => {
  const dispatch = useDispatch();
  //const loading = useSelector((state) => state.security.loading);
  const [editingControlId, setEditingControlId] = useState(null);
  const [editedControlData, setEditedControlData] = useState({});
  const [isSavingControl, setIsSavingControl] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Can be 'success', 'error', etc.
  console.log('Controls received into control list:', controls);

  const handleEditControl = (control) => {
    setEditingControlId(control.controlId);
    setEditedControlData({
      name: control.name,
      description: control.description || '',
    });
  };
  
  const handleChangeControlDetail = (field, value) => {
    setEditedControlData((prev) => ({ ...prev, [field]: value }));
  };
  
  const handleSaveControl = async (control) => {
    setIsSavingControl(true);
    const controlToSave = editingControlId === control.controlId
      ? {
          controlId: editingControlId,
          ...editedControlData,
          status: 'saved',
        }
      : {
          ...control,
          status: 'saved',
        };
    try {
      await dispatch(saveSecurityControl(controlToSave, modelId, riskId, assessmentUuid));
      if (editingControlId === control.controlId) {
        setEditingControlId(null);
        setEditedControlData({});
      }
      // Show success Snackbar
      setSnackbarMessage('Control saved successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving control:', error);
      // Show error Snackbar
      setSnackbarMessage('Failed to save control.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSavingControl(false);
    }
  };
  
  const handleCancelEditControl = () => {
    setEditingControlId(null);
    setEditedControlData({});
  };

  // const handleSaveControl = (control) => {
  //   dispatch(saveSecurityControl(control, modelId, riskId, assessmentUuid));
  // };

  return (
    <>
      {Array.isArray(controls) && controls.length > 0 ? (
        <List>
          {controls.map((control) => (
            <ListItem key={control.controlId} disableGutters>
              {editingControlId === control.controlId ? (
                <Box sx={{ width: '100%' }}>
                  <TextField
                    label="Control Name"
                    value={editedControlData.name}
                    onChange={(e) => handleChangeControlDetail('name', e.target.value)}
                    fullWidth
                  />
                  <TextField
                    label="Description"
                    value={editedControlData.description}
                    onChange={(e) => handleChangeControlDetail('description', e.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                    sx={{ mt: 1 }}
                  />
                  <Box mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSaveControl(control)}
                      disabled={isSavingControl}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancelEditControl}
                      sx={{ ml: 1 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              ) : (
                <>
                  <ListItemText primary={control.name} secondary={control.description} />
                  <IconButton onClick={() => handleEditControl(control)}>
                    <EditIcon />
                  </IconButton>
                  {control.status !== 'saved' && (
                    <Tooltip title="Save Control">
                      <IconButton
                        onClick={() => handleSaveControl(control)}
                        disabled={isSavingControl}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">No controls to display.</Typography>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SecurityControlsList;