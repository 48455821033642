// src/redux/reducers/cloudModelReducer.js

import {
    IMPORT_SAGEMAKER_MODELS_REQUEST,
    IMPORT_SAGEMAKER_MODELS_SUCCESS,
    IMPORT_SAGEMAKER_MODELS_FAILURE,
    IMPORT_BEDROCK_MODELS_REQUEST,
    IMPORT_BEDROCK_MODELS_SUCCESS,
    IMPORT_BEDROCK_MODELS_FAILURE,
    REMOVE_MODEL,
  } from '../actions/cloudModels';
  
  const initialState = {
    models: [],
    loading: false,
    error: null,
  };
  
  const cloudModelReducer = (state = initialState, action) => {
    switch (action.type) {
      // SageMaker Import Cases
      case IMPORT_SAGEMAKER_MODELS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case IMPORT_SAGEMAKER_MODELS_SUCCESS:
        return {
          ...state,
          loading: false,
          models: [...state.models, ...action.payload],
        };
      case IMPORT_SAGEMAKER_MODELS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Bedrock Import Cases
      case IMPORT_BEDROCK_MODELS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case IMPORT_BEDROCK_MODELS_SUCCESS:
        return {
          ...state,
          loading: false,
          models: [...state.models, ...action.payload],
        };
      case IMPORT_BEDROCK_MODELS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Remove Model Case (if applicable)
      case REMOVE_MODEL:
        return {
          ...state,
          models: state.models.filter((model) => model.model_uuid !== action.payload),
        };
  
      default:
        return state;
    }
  };
  
  export default cloudModelReducer;