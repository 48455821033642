// SingleRiskReport.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Typography,
  //Box,
  Grid,
  Button,
  TextField,
  Select,
  IconButton,
  Divider,
  MenuItem, 
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  //Save as SaveIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
 // SmartToyOutlined as SmartToyOutlinedIcon
} from '@mui/icons-material';
import RiskEvaluationForm from './RiskEvaluationForm';
import ControlsList from './ControlsList';
import IconWithProgress from './IconWithProgress'; // New component to list and manage controls
import useSaveOperation from './useSaveOperation';
import { discardRisk, setRiskPriority, updateRiskPriority, updateSavedRisk } from './redux/actions/riskActions';
//import { refreshSingleRiskControls } from './redux/actions/controlActions';


const SingleRiskReport = ({
  risk,
  sectionName,
  sectionState,
  category,
  modelId,
  assessmentUuid,
  user,
  onClose,
}) => {
  
  const [editedRiskName, setEditedRiskName] = useState(risk.riskName || risk.name);
  const [isEditingRiskName, setIsEditingRiskName] = useState(false);
  const [isEditingPriority, setIsEditingPriority] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(risk.priority || '');
  
  const { handleSaveOperation } = useSaveOperation();
  const dispatch = useDispatch();
  const themes = useSelector((state) => state.themes);

  
  const [isLLMPriorityLoading, setIsLLMPriorityLoading] = useState(false);
   const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const updatedRisk = useSelector((state) => {
    
    const model = state.risks.models[modelId];
    if (!model) return risk;
    const section = model.riskFormData[sectionName];
    if (!section) return risk;
    const categoryRisks = section.sectionRisks[category];
    if (!categoryRisks) return risk;
    const foundRisk = categoryRisks.find(r => r.riskId === risk.riskId);
    return foundRisk || risk;
  });
 
  console.log('User in SSR', user);
  
  const [newRationale, setNewRationale] = useState(updatedRisk.rationale || '');
  


  useEffect(() => {
    // Update local state when risk is updated in Redux
    setEditedRiskName(updatedRisk.riskName || updatedRisk.name);
    setSelectedPriority(updatedRisk.priority || '');
    setNewRationale(updatedRisk.rationale || '');
  }, [updatedRisk]);

  const handleSaveRisk = async () => {
    try {
      await handleSaveOperation(async () => {
        const ownerInfo = {
          name: user.name,
          email: user.email,
          picture: user.picture,
        };
        const response = await fetch('/.netlify/functions/saveRisk', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            riskId: updatedRisk.riskId, // Include riskId here
            name: editedRiskName,
            section: sectionName,
            category: category,
            modelUuid: modelId,
            assessmentUuid: assessmentUuid,
            owner: ownerInfo,
          }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to save risk');
        }
  
        //const savedRisk = await response.json();
  
        // Update risk in Redux store
        dispatch(
          updateSavedRisk(modelId, updatedRisk.riskId, sectionName, category, {
            ...updatedRisk,
            riskName: editedRiskName,
            owner: ownerInfo,
          })
        );
  
        setSnackbarMessage('Risk saved successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
  
        // Set editing state back to false
        setIsEditingRiskName(false); // Ensure the icon changes back to Edit
      });
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDiscardRisk = () => {
    // Discard the risk
    dispatch(discardRisk(risk, modelId, sectionName, category));
    onClose(); // Close the editor
  };

 

  const handleUpdatePriority = (e) => {
    setSelectedPriority(e.target.value);
  };

  const handleSetLLMPriority = (risk) => {
    setIsLLMPriorityLoading(true);
    handleSaveOperation(async () => {
      const themeIds = Object.keys(risk.evaluationData || {});
      try {
        await Promise.all(
          themeIds.map(async (themeId) => {
            const numericThemeId = parseInt(themeId, 10);
            const theme = themes.find((t) => t.theme_id === numericThemeId);
            if (!theme) return;
            const themeName = theme.name;
            const evaluationData = risk.evaluationData[themeId].impact;
            await dispatch(
              setRiskPriority(
                modelId,
                risk.riskId,
                sectionName,
                category,
                risk.riskName,
                numericThemeId,
                themeName,
                evaluationData,
                assessmentUuid
              )
            );
          })
        );
      } catch (error) {
        console.error('Error setting priority:', error);
      } finally {
        setIsLLMPriorityLoading(false);
      }
    });
  };


  const handleSaveUpdatedPriority = async () => {
    if (!selectedPriority) return;

    try {
      // Dispatch action to update priority
      await dispatch(
        updateRiskPriority(
          modelId, 
          updatedRisk.riskId, 
          sectionName, 
          category, 
          selectedPriority, 
          assessmentUuid
        )
      );
      setIsEditingPriority(false);
    } catch (error) {
      console.error('Error updating risk priority:', error);
      // Optionally, show error notification to the user
    }
  };
  const handleEditPriority = () => {
    setIsEditingPriority(true);
  };

  const handleCancelUpdatedPriority = () => {
    setSelectedPriority(updatedRisk.priority || '');
    setIsEditingPriority(false);
  };
 



  const getPriorityColor = (priority) => {
    const colors = {
      critical: 'red',
      high: 'orange',
      significant: 'gold',
      moderate: 'blue',
      low: 'green',
      negligible: 'grey',
    };
    return colors[priority.toLowerCase()] || 'black';
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
<Grid container spacing={2} alignItems="center">
  <Grid item xs={10}>
    {isEditingRiskName ? (
      <TextField
        label="Risk Name"
        value={editedRiskName}
        onChange={(e) => setEditedRiskName(e.target.value)}
        fullWidth
      />
    ) : (
      <Typography variant="h6">{editedRiskName}</Typography>
    )}
  </Grid>
  <Grid item xs={2} sx={{ textAlign: 'right' }}>
    {isEditingRiskName ? (
      <IconButton onClick={handleSaveRisk} color="primary">
        <CheckIcon />
      </IconButton>
    ) : (
      <IconButton onClick={() => setIsEditingRiskName(true)} color="primary">
        <EditIcon />
      </IconButton>
    )}
    <IconButton onClick={handleDiscardRisk} color="secondary">
      <DeleteIcon />
    </IconButton>
    <IconButton onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </Grid>
</Grid>
      <Divider sx={{ my: 2 }} />


<Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6">Priority</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          {updatedRisk.priority ? (
            <>
              {/* Display Priority and Rationale */}
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ color: getPriorityColor(updatedRisk.priority) }}
                  >
                    {capitalizeFirstLetter(updatedRisk.priority)}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleEditPriority} color="primary" aria-label="Edit Priority">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              {updatedRisk.rationale && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <strong>Rationale:</strong> {updatedRisk.rationale}
                </Typography>
              )}
            </>
          ) : (
            <>
              {/* Display 'None' and SmartToyOutlinedIcon */}
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography variant="body1">None</Typography>
                </Grid>
                <Grid item>
                {/* <IconButton onClick={() => handleSetLLMPriority(updatedRisk)} color="primary" aria-label="Set Priority Automatically">
                    <SmartToyOutlinedIcon />
                  </IconButton> */}
                     <IconWithProgress 
                    onClick={() => handleSetLLMPriority(updatedRisk)} 
                    isLoading={isLLMPriorityLoading} 
                  />

                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {/* Priority Editing Section */}
      {isEditingPriority && updatedRisk.priority && (
        <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="update-priority-select-label">Select New Priority</InputLabel>
              <Select
                labelId="update-priority-select-label"
                value={selectedPriority}
                label="Select New Priority"
                onChange={handleUpdatePriority}
              >
                <MenuItem value="critical">Critical</MenuItem>
                <MenuItem value="high">High</MenuItem>
                <MenuItem value="significant">Significant</MenuItem>
                <MenuItem value="moderate">Moderate</MenuItem>
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="negligible">Negligible</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Rationale"
              value={newRationale}
              onChange={(e) => setNewRationale(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleSaveUpdatedPriority} sx={{ mr: 1 }}>
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="secondary" onClick={handleCancelUpdatedPriority}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Divider sx={{ my: 2 }} />
      <Typography variant="h6">Risk Evaluation</Typography>
      <RiskEvaluationForm
        riskId={risk.riskId}
        riskName={editedRiskName}
        sectionName={sectionName}
        category={category}
        modelId={modelId}
        assessmentUuid={assessmentUuid}
        handleSaveOperation={handleSaveOperation}
      />
<Divider sx={{ my: 2 }} />

{/* Controls Section */}
{/* <Typography variant="h6">Controls</Typography> */}

   <ControlsList
        // controls={controls}
        risk={updatedRisk}
        modelId={modelId}
        assessmentUuid={assessmentUuid}
        handleSaveOperation={handleSaveOperation}
        category={category}
        sectionName={sectionName}
        sectionState={sectionState}
      />


<Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </Paper>

      
  );

};

export default SingleRiskReport;
