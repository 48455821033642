import React, { useState } from 'react';
import { TextField, Grid } from '@mui/material';

function ModelCardForm({ onSubmit, modelId, initialData = {} }) {
  const formType = modelId ? 'update' : 'create';

  const [formData, setFormData] = useState({
    modelName: initialData.modelName || '',
    modelShortDescription: initialData.modelShortDescription || '',
    useCaseShortDescription: initialData.useCaseShortDescription || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData, formType, modelId);
  };

  return (
    <form onSubmit={handleSubmit}>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Model Name"
            variant="outlined"
            fullWidth
            name="modelName"
            value={formData.modelName}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Model Short Description"
            variant="outlined"
            fullWidth
            name="modelShortDescription"
            value={formData.modelShortDescription}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Use Case Short Description"
            variant="outlined"
            fullWidth
            name="useCaseShortDescription"
            value={formData.useCaseShortDescription}
            onChange={handleChange}
          />
        </Grid>
       
      </Grid>
    </form>
  );
}

export default ModelCardForm;