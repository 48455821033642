import { 
  INITIALIZE_MODEL,SET_FORM_DATA, SET_STEP,  SET_SECTION, FETCH_RISKS_SUCCESS,FETCH_MODEL_RISKS_START, FETCH_MODEL_RISKS_FAILURE, FETCH_MODEL_RISKS_SUCCESS, TOGGLE_SELECTED_THEME,  
  SET_RISKS,HANDLE_SELECTED_RISKS_UPDATE,SET_RISK_EVALUATION,SET_RISK_PRIORITY_START,  SET_RISK_PRIORITY_SUCCESS,SET_RISK_PRIORITY_FAILURE,UPDATE_RISK_PRIORITY,
  UPDATE_RISK_EVALUATION,SET_MODEL_ID,UPDATE_RISKS_AFTER_SELECTION, SAVE_SELECTED_RISKS, DISCARD_RISKS, 
  DISCARD_UNSAVED_RISKS,FETCH_RISKS_START, FETCH_RISKS_FAILURE,UPDATE_RISK_NAME, UPDATE_CONTROL_IN_SELECTED_RISK,
   FETCH_RISK_CARD_DATA_START, FETCH_RISK_CARD_DATA_SUCCESS,FETCH_RISK_CARD_DATA_FAILURE, FETCH_RISK_EVALUATIONS,FETCH_RISK_EVALUATIONS_SUCCESS, FETCH_RISK_EVALUATIONS_FAILURE,
    MOVE_RISK_TO_SAVED, UPDATE_SAVED_RISK,DISCARD_SAVED_RISK, RESET_RISKS_STATE,   FETCH_SECTION_SPECIFIC_RISKS_START,
    FETCH_SECTION_SPECIFIC_RISKS_SUCCESS, FETCH_SECTION_SPECIFIC_RISKS_FAILURE, UPDATE_ASSESSMENT_STEP_SUCCESS,ASSESSMENT_STATUS_UPDATED,ASSESSMENT_STATUS_FAILED,
  REFRESH_RISKS_SUCCESS, FETCH_MODELS_START, FETCH_MODELS_SUCCESS, FETCH_MODELS_FAILURE, UPDATE_LOADING_MESSAGE,UPDATE_SECTION_PROGRESS, DELETE_MODEL, UPDATE_CAUSE_FIELD, SAVE_RISK_SOURCE_START,
  SAVE_RISK_SOURCE_SUCCESS,
  SAVE_RISK_SOURCE_FAILURE,
  SAVE_CAUSES_START,
  SAVE_CAUSES_SUCCESS,
  SAVE_CAUSES_FAILURE, SET_CURRENT_SECTION,
  SET_CURRENT_SECTION_STEP,
  RESET_CURRENT_SECTION_STEP

} from './actionTypes'; 
//import riskForm from '../../riskForm.json'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchThemes } from './themeActions';
import { setSavedControls, fetchSavedControlEvaluations } from './controlActions';
import { fetchSecurityRisks, fetchSecurityControls } from './securityActions';
import { fetchContextData } from './contextActions';
import { fetchAllConfigs,fetchConfig } from './configActions';
import { RiskFormGenerator } from '../selectors/riskFormGenerator.js';
import _ from 'lodash';





export const fetchAllModels = (token) => async (dispatch, getState) => {
  dispatch({ type: FETCH_MODELS_START });
  dispatch(updateLoadingMessage('Initializing data fetch...'));

  try {
    // **Step 1: Fetch All Configuration Names**
    dispatch(updateLoadingMessage('Fetching all configuration names...'));
    await dispatch(fetchAllConfigs());

    const configState = getState().config;

    if (configState.error) {
      throw new Error(`Error fetching configurations: ${configState.error}`);
    }

    const configNames = configState.configNames;

    if (!configNames || configNames.length === 0) {
      throw new Error('No configuration names found.');
    }

    // **Step 2: Fetch Each Configuration Individually**
    dispatch(updateLoadingMessage('Fetching configuration data...'));
    await Promise.all(configNames.map(name => dispatch(fetchConfig(name))));

    // Check for any errors after fetching configurations
    if (configState.error) {
      throw new Error(`Error fetching configurations: ${configState.error}`);
    }
    dispatch(updateLoadingMessage('Preparing riskForm data...'));
    const riskFormData = RiskFormGenerator(getState());
    
    if (!riskFormData || Object.keys(riskFormData).length === 0) {
      throw new Error('riskFormData is empty or undefined.');
    }

    // **Step 3: Fetch Models**
    dispatch(updateLoadingMessage('Fetching models...'));
    const response = await fetch('/.netlify/functions/FetchModelInventory', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();

    if (jsonResponse.success) {
      const models = jsonResponse.data;

      // **Step 4: Fetch Associated Data for Each Model**
      await Promise.all(models.map(async (model) => {
        // Initialize model only if it does not exist in the state
        if (!getState().risks.models[model.model_uuid]) {
          dispatch({
            type: INITIALIZE_MODEL,
            payload: {
              modelId: model.model_uuid,
              modelName: model.model_name,
              created_at: model.created_at,
              created_by: model.created_by,
              avatar: model.avatar,
              createdByName: model.name,
              assessmentUuid: model.assessment_uuid,
              assessmentStatus: model.assessment_status,
              currentStep: model.current_step || 'Not Started',
              riskFormData: riskFormData,
            },
          });
        }

        // Fetch themes
        dispatch(updateLoadingMessage(`Fetching themes for ${model.model_name}...`));
        await dispatch(fetchThemes());

        // Fetch risk card data
        dispatch(updateLoadingMessage(`Fetching risk cards for ${model.model_name}...`));
        await dispatch(fetchRiskCardData(model.model_uuid, model.assessment_uuid));

        // Fetch context data
        dispatch(updateLoadingMessage(`Fetching context for ${model.model_name}...`));
        await dispatch(fetchContextData(model.model_uuid, model.assessment_uuid));

        // Fetch model risks
        dispatch(updateLoadingMessage(`Fetching risks for ${model.model_name}...`));
        await dispatch(fetchModelRisks(model.model_uuid, model.assessment_uuid));

        // Fetch action plans
        dispatch(updateLoadingMessage(`Fetching action plans for ${model.model_name}...`));
        await dispatch(setSavedControls(model.model_uuid, model.assessment_uuid));
        await dispatch(fetchSavedControlEvaluations(model.model_uuid, model.assessment_uuid));

        // Fetch security risks
        dispatch(updateLoadingMessage(`Fetching security risks for ${model.model_name}...`));
        await dispatch(fetchSecurityRisks(model.model_uuid, model.assessment_uuid));

        const securityRisks = getState().security.models[model.model_uuid]?.securityRisks;

        // Fetch security controls if security risks exist
        if (securityRisks && securityRisks.length > 0) {
          dispatch(updateLoadingMessage(`Fetching security controls for ${model.model_name}...`));

          await Promise.all(securityRisks.map(async (risk) => {
            await dispatch(fetchSecurityControls(model.model_uuid, risk.risk_id));
          }));
        } else {
          console.warn(`No security risks found for model ${model.model_name} (ID: ${model.model_uuid})`);
        }
      }));

      dispatch({ type: FETCH_MODELS_SUCCESS, payload: { models } });
      dispatch(updateLoadingMessage('Data fetch completed.'));
    } else {
      throw new Error('Failed to fetch models: ' + jsonResponse.message);
    }
  } catch (error) {
    console.error('Fetch models failed:', error);
    dispatch({ type: FETCH_MODELS_FAILURE, payload: error.message });
    dispatch(updateLoadingMessage('Failed to load data.'));
  }
};
// Helper action to update loading message
const updateLoadingMessage = (message) => ({
  type: UPDATE_LOADING_MESSAGE,
  payload: message
});
export const deleteModelAction = (modelId) => ({
  type: DELETE_MODEL,
  payload: modelId,
});


export const fetchModelRisks = (modelId, assessmentUuid) => async (dispatch) => {
  dispatch({ type: FETCH_MODEL_RISKS_START, payload: { modelId } });
  try {
    const response = await fetch('/.netlify/functions/fetchModelRisks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ modelId, assessmentUuid })  // Make sure the API expects these field names
    });
    const data = await response.json();

    console.log ('model risks fetched and ready to be passed on to reducer', data.data)

    if (data.success) {
      dispatch({ type: FETCH_MODEL_RISKS_SUCCESS, payload: { modelId, risks: data.data } });
    } else {
      throw new Error(data.message || "Failed to fetch risks.");
    }
  } catch (error) {
    dispatch({ type: FETCH_MODEL_RISKS_FAILURE, payload: { modelId, error: error.message } });
  }
};




export const fetchRisksFromLLM = (riskFormData, sectionName, activeSections,  sectionToUpdate, sectionsToFetch, modelId) => {
  return async (dispatch) => {
    dispatch(fetchRisksStart({ modelId, sectionsToFetch }));
    try {
        const body = {
          riskFormData,
          activeSections,
          sectionsToFetch
        };
        console.log('body sent to fetch risks', body)
        if (sectionToUpdate) {
          body.sectionToUpdate = sectionToUpdate;
        }

        const response = await fetch('/.netlify/functions/fetchRisksForSection', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
          if (!response.ok) {
              throw new Error(`Error fetching risks: ${response.status} ${response.statusText}`);
          }

          const fetchedRisks = await response.json();
          console.log('fetchedRisks:', fetchedRisks);
          if (!Array.isArray(fetchedRisks)) {
            throw new Error('Unexpected response format: fetchedRisks is not an array');
          }
          console.log('Dispatching fetchRisksFromLLM');
          dispatch(fetchRisksSuccess(fetchedRisks, sectionsToFetch, modelId));
      } catch (error) {
          console.error('Fetch error:', error.message);
          dispatch(fetchRisksFailure(error.message));
      }
  };
};

// src/redux/actions/riskActions.js

export const generateRisksWithRAG = (modelId, sectionName,riskAreaData, risks, narratives) => async (dispatch, getState) => {
  const state = getState();
  const sectionData = state.risks.models[modelId]?.riskFormData[sectionName] || {};
  const existingRisks = state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || {};

  const hasExistingRisks = Object.values(existingRisks)
    .flatMap(risksArray => risksArray)
    .some(risk => risk?.status === 'Saved');

  if (hasExistingRisks) {
    console.log('Existing risks found, skipping fetch.');
    return;  // Skip fetch if saved risks are found
  }

  dispatch({ type: FETCH_SECTION_SPECIFIC_RISKS_START, payload: { modelId, risks, sectionName } });

  try {
    const body = {
      sectionName,
      riskAreaData,
      risks,
      sectionData,
      narratives,
      useRAG: true // Indicate that RAG should be used
    };

    console.log('Request body for RAG-based risk generation:', body);

    const response = await fetch('/.netlify/functions/generateRisksWithRAG', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch section-specific risks: ${response.status}`);
    }

    const fetchedRisks = await response.json();

    // Process the fetched risks to categorize them
    const categorizedRisks = fetchedRisks.reduce((acc, risk) => {
      const { category = 'Uncategorized' } = risk; // Default to 'Uncategorized' if no category
      if (!acc[category]) acc[category] = [];
      acc[category].push({
        ...risk,
        status: risk.status || 'Generated'  // Ensure each risk has a 'Generated' status if none is present
      });
      return acc;
    }, {});

    dispatch({
      type: FETCH_SECTION_SPECIFIC_RISKS_SUCCESS,
      payload: { modelId, sectionName, risks: categorizedRisks },
    });

  } catch (error) {
    console.error('Error fetching section-specific risks with RAG:', error);
    dispatch({
      type: FETCH_SECTION_SPECIFIC_RISKS_FAILURE,
      payload: { modelId, sectionName, error: error.message },
    });
  }
};


export const fetchNewRisks = (modelId, sectionName, riskAreaData, allRisks, narratives, sectionData) => async dispatch => {
  try {
    const response = await fetch('/.netlify/functions/generateRisks', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        modelId,
        sectionName,
        riskAreaData,
        risks: allRisks,
        narratives,
        sectionData,
        useRAG: false
      }),
    });
    const newRisks = await response.json();
    console.log('received risks to refresh to dispatch', newRisks);

    // Process the new risks to categorize them
    const categorizedRisks = newRisks.reduce((acc, risk) => {
      const { category = 'Uncategorized' } = risk; // Fallback to 'Uncategorized' if no category
      if (!acc[category]) acc[category] = [];
      acc[category].push(risk);
      return acc;
    }, {});

    dispatch({ type: REFRESH_RISKS_SUCCESS, payload: { modelId, sectionName, categorizedRisks } });
  } catch (error) {
    console.error('Failed to fetch new risks:', error);
  }
};



export const updateActiveSections = (modelId, sections) => {
  // Log the action payload before dispatching
  console.log('Dispatching updateActiveSections with:', { modelId, sections });

  return {
    type: 'risks/updateActiveSections',
    payload: { modelId, sections },
  };
};





export const fetchRisksForModel = createAsyncThunk(
  'risks/fetchRisksForModel',
  async ({ modelId, assessmentUuid, sectionName }, { rejectWithValue }) => {
    try {
      if (!modelId || !assessmentUuid) {
        throw new Error('Model ID or Assessment UUID is missing');
      }
      const response = await fetch('/.netlify/functions/fetchRisks', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ modelId, assessmentUuid, sectionName }),
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch risks for model ${modelId} and assessment ${assessmentUuid} and section ${sectionName} `);
      }
      const fetchedRisks = await response.json();
      console.log ('fetchedrisks from db to dispatch to reducer', fetchedRisks)
      return fetchedRisks; 
    } catch (error) {
      console.error(`Error fetching risks for model ${modelId} and assessment ${assessmentUuid}:`, error);
      return rejectWithValue(error.message); // This will be the payload for the rejected action
    }
  }
);

export const setModelId = (
  modelId,
  modelName,
  created_at,
  created_by,
  avatar,
  createdByName,
  assessmentUuid,
  assessmentStatus,
  currentStep, 
  riskFormData
  
) => ({
  type: SET_MODEL_ID,
  payload: {
    modelId,
    modelName,
    created_at,
    created_by,
    avatar,
    createdByName,
    assessmentUuid,
    assessmentStatus,
    currentStep, 
    riskFormData
  },
});


export const fetchRisksSuccess = (fetchedRisks, sectionsToFetch, modelId) => ({
  type: FETCH_RISKS_SUCCESS,
  payload: { fetchedRisks, sectionsToFetch, modelId }
});


export const fetchRisksFailure = (error, sectionName, modelId) => ({
  type: FETCH_RISKS_FAILURE,
  payload: { error, sectionName, modelId },
});

export const saveSelectedRisks = (risks, modelId) => ({
  type: SAVE_SELECTED_RISKS,
  payload: { risks, modelId }
});

export const discardRisk = (risk, modelId, sectionName) => ({
  type: DISCARD_RISKS,
  payload:  {risk, modelId, sectionName}
});

export const discardUnselectedRisks = (risksToDiscard, modelId) => {
  return {
    type: DISCARD_UNSAVED_RISKS,
    payload: { risksToDiscard, modelId },
  };
};



export const setRiskPriority = (modelId, riskId, sectionName, category, riskName, numericThemeId, themeName, evaluationData, assessmentUuid) => {
  return async (dispatch) => {
    dispatch({ 
      type: SET_RISK_PRIORITY_START,
      payload: { modelId, sectionName, category, riskId },
    });
    try {
      const payload = JSON.stringify({
        riskName,
        themeName,
        evaluationData,
      });

      const response = await fetch('/.netlify/functions/prioritizeRisks', {
        method: 'POST',
        body: payload,
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error('HTTP error ' + response.statusText);
      }

      const data = await response.json();

      // Dispatch action to update Redux store
      dispatch({
        type: SET_RISK_PRIORITY_SUCCESS,
        payload: {
          modelId,
          riskId,
          sectionName,
          category,
          numericThemeId,
          themeName,
          priority: data.priority,
          rationale: data.rationale,
        },
      });

      // Save priority and rationale to the database
      const savePayload = {
        modelUuid: modelId,
        assessmentUuid,
        riskId,
        priority: data.priority,
        rationale: data.rationale,
      };

      const saveResponse = await fetch('/.netlify/functions/saveRiskPriority', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(savePayload),
      });

      if (!saveResponse.ok) {
        throw new Error('Failed to save priority and rationale to database');
      }

      return Promise.resolve(); // Successfully resolved the promise
    } catch (error) {
      dispatch({
        type: SET_RISK_PRIORITY_FAILURE,
        payload: { modelId, sectionName, category, riskId, error: error.message },
      });
      return Promise.reject(error); // Reject the promise if there's an error
    }
  };
};


export const updateRiskPriority = (modelId, riskId, sectionName, category, priority, assessmentUuid) => {
  return async (dispatch) => {
    // Dispatch action to update Redux store
    dispatch({
      type: UPDATE_RISK_PRIORITY,
      payload: { modelId, riskId, sectionName, category, priority },
    });

    // Save updated priority to the database
    try {
      const savePayload = {
        modelUuid: modelId,
        assessmentUuid,
        riskId,
        priority,
        rationale: '', // Include rationale if available
      };

      const saveResponse = await fetch('/.netlify/functions/saveRiskPriority', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(savePayload),
      });

      if (!saveResponse.ok) {
        throw new Error('Failed to save updated priority to database');
      }
    } catch (error) {
      console.error('Error saving updated priority:', error);
    }
  };
};
// export const updateRiskPriority = (modelId,riskId, sectionName,category, priority) => {
//   return {
//     type: UPDATE_RISK_PRIORITY,
//     payload: { modelId,riskId,sectionName,category,priority },
//   };
// };
export const setSection = (sectionName, modelId) => ({
  type: SET_SECTION,
  payload: { sectionName, modelId }
});
export const setCurrentSection = (modelId, sectionName) => ({
  type: SET_CURRENT_SECTION,
  payload: { modelId, sectionName },
});

// Action to set the current step within a section
export const setCurrentSectionStep = (modelId, stepName) => ({
  type: SET_CURRENT_SECTION_STEP,
  payload: { modelId, stepName },
});

// Action to reset the current step (useful when changing sections)
export const resetCurrentSectionStep = (modelId) => ({
  type: RESET_CURRENT_SECTION_STEP,
  payload: { modelId },
});


export const setStep = ( modelId, sectionName,stepName) => ({
  type: SET_STEP,
  payload: { modelId, sectionName, stepName }
});

// Redux Action
export const updateAssessmentStep = (modelId, assessmentUuid,step) => async (dispatch) => {
  try {
    const response = await fetch('/.netlify/functions/manageAssessment', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({modelId,assessmentUuid, currentStep: step, operation: 'update' }),
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Failed to update step');
    dispatch({
      type: UPDATE_ASSESSMENT_STEP_SUCCESS,
      payload: { modelId, currentStep: step }
    });
  } catch (error) {
    console.error('Error updating step:', error);
  }
};


export const setFormData = (data, modelId) => ({
    type: SET_FORM_DATA,
    payload: {...data, modelId }
});



export const setRisks = (risks) => ({
    type: SET_RISKS,
    payload: risks
});


export const toggleSelectedTheme = (theme) => ({
    type: TOGGLE_SELECTED_THEME,
    payload: theme
});


export const handleSelectedRisksUpdate = (selectedRisk, modelId) => {
  return {
    type: HANDLE_SELECTED_RISKS_UPDATE,
    payload: { selectedRisk, modelId}
  };
};
export const updateRisksAfterSelection = (updatedRisks) => {
  return {
      type: UPDATE_RISKS_AFTER_SELECTION,
      payload: updatedRisks,
  };
};

export const fetchRisksStart = ({ modelId, sectionsToFetch }) => ({
  type: FETCH_RISKS_START,
  payload: { modelId, sectionsToFetch },
});


// If you want the call order to be: modelId, riskId, riskName, themeId, evaluationData, sectionName, category
// Then define the function signature in that same order:

export const setRiskEvaluation = (
  modelId,
  riskId,
  themeId,
  evaluationData,
  sectionName,
  category
) => ({
  type: SET_RISK_EVALUATION,
  payload: { modelId, riskId, themeId, evaluationData, sectionName, category }
});

export const updateRiskEvaluation = (modelId, riskId, sectionName, themeId, attribute, property, value, priority, rationale, category) => ({
  type: UPDATE_RISK_EVALUATION,
  payload: { modelId, riskId, sectionName,themeId, attribute, property, value, priority, rationale, category}
});


// Action to update the name of a risk that hasn't been saved to the backend yet
export const updateRiskName = (modelId, sectionName,category, originalName, newName) => ({
  type: UPDATE_RISK_NAME,
  payload: { modelId, sectionName, category, originalName, newName }
});


export const updateRiskWithControls = (modelId, riskName, controlData) => dispatch => {
  dispatch({
    type: UPDATE_CONTROL_IN_SELECTED_RISK,
    payload: { modelId, riskName, controlData },
  });
};
export const fetchRiskCardDataStart = (modelId) => ({
  type: FETCH_RISK_CARD_DATA_START,
  payload: { modelId },
});

// Action creator for a successful fetch operation
export const fetchRiskCardDataSuccess = (data, modelId) => ({
  type: FETCH_RISK_CARD_DATA_SUCCESS,
  payload: { data, modelId },
});

// Action creator for a failed fetch operation
export const fetchRiskCardDataFailure = (error, modelId) => ({
  type: FETCH_RISK_CARD_DATA_FAILURE,
  payload: { error, modelId },
});

// const transformFetchedData = (rows) => {
//   const grouped = _.groupBy(rows, 'risk_area');
//   console.log('Grouped rows by risk_area:', grouped);
//   return _.mapValues(grouped, (riskSources) =>
//     riskSources.map(({ risk_source, risk_source_values, causes }) => ({
//       name: risk_source,
//       value: Array.isArray(risk_source_values) ? risk_source_values : [], // Ensure it's an array
//       causes: Object.fromEntries(
//         Object.entries(causes || {}).map(([category, values]) => [
//           category,
//           {
//             type: 'multiselect',
//             options:values  || [], // Options would come from the form or another configuration
//             value: values || [], 
//           },
//         ])
//       ),
//     }))
//   );
// };


// Action creator

const transformFetchedData = (rows) => {
  const grouped = _.groupBy(rows, 'risk_area');
  return _.mapValues(grouped, (riskSources) =>
    riskSources.map(({ risk_source, risk_source_values, causes }) => ({
      name: risk_source,
      value: Array.isArray(risk_source_values) ? risk_source_values : [],
      causes: Object.fromEntries(
        Object.entries(causes || {}).map(([category, values]) => [
          category,
          {
            type: 'multiselect', // Define the type explicitly
            options: Array.isArray(values) ? values : [], // Ensure it's an array
            value: Array.isArray(values) ? values : [], // Populate the user-selected values
          },
        ])
      ),
    }))
  );
};
export const fetchRiskCardData = (modelId, assessmentUuid) => async (dispatch) => {
  try {
    const response = await fetch('/.netlify/functions/fetchRiskCard', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ model_uuid: modelId, assessment_uuid: assessmentUuid }),
    });

    if (!response.ok) throw new Error('Failed to fetch risk card data.');

    const dbRows = await response.json();
    const transformedData = transformFetchedData(dbRows);

    //console.log('Transformed risk card data from DB:', transformedData);

    dispatch({
      type: FETCH_RISK_CARD_DATA_SUCCESS,
      payload: { modelId, fetchedData: transformedData },
    });
  } catch (error) {
    console.error('Error fetching risk card data:', error.message);
    dispatch({
      type: FETCH_RISK_CARD_DATA_FAILURE,
      payload: { modelId, error: error.message },
    });
  }
};


export const updateCauseField = (modelId, sectionName, riskSource, causeCategory, value) => ({
  type: UPDATE_CAUSE_FIELD,
  payload: { modelId, sectionName, riskSource, causeCategory, value },
});

export const saveRiskSource = (riskSourceData) => async (dispatch) => {
  dispatch({ type: SAVE_RISK_SOURCE_START });

  try {
    const response = await fetch('/.netlify/functions/saveRiskSource', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(riskSourceData),
    });

    const result = await response.json();

    if (result.success) {
      dispatch({ type: SAVE_RISK_SOURCE_SUCCESS, payload: result.message });
    } else {
      dispatch({ type: SAVE_RISK_SOURCE_FAILURE, payload: result.message });
    }
  } catch (error) {
    dispatch({ type: SAVE_RISK_SOURCE_FAILURE, payload: error.message });
  }
};

// Save Causes Action
export const saveCauses = (causesData) => async (dispatch) => {
  dispatch({ type: SAVE_CAUSES_START });

  try {
    const response = await fetch('/.netlify/functions/saveCauses', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(causesData),
    });

    const result = await response.json();

    if (result.success) {
      dispatch({ type: SAVE_CAUSES_SUCCESS, payload: result.message });
    } else {
      dispatch({ type: SAVE_CAUSES_FAILURE, payload: result.message });
    }
  } catch (error) {
    dispatch({ type: SAVE_CAUSES_FAILURE, payload: error.message });
  }
};



export const fetchRiskEvaluations = (
  modelId,
  assessmentUuid,
  sectionName,
  category
) => async (dispatch) => {
  // Indicate we're starting the fetch
  dispatch({ type: FETCH_RISK_EVALUATIONS, payload: { modelId } });

  try {
    // Hit the netlify function
    const response = await fetch('/.netlify/functions/fetchRiskEvaluation', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ modelId, assessmentUuid }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok, status: ${response.status}`);
    }

    const data = await response.json(); // { modelId, evaluations: [ ... ] }
    console.log('data into fetch riskeval', data);

    if (!Array.isArray(data.evaluations)) {
      throw new Error('Expected an array of evaluations, but did not receive one.');
    }



    dispatch({
      type: FETCH_RISK_EVALUATIONS_SUCCESS,
      payload: {
        modelId,
        evaluations: data.evaluations, // if the reducer expects an object
        sectionName,
        category
      },
    });
  } catch (error) {
    console.error('Error fetching risk evaluations:', error);
    dispatch({
      type: FETCH_RISK_EVALUATIONS_FAILURE,
      payload: { modelId, error: error.toString(), sectionName, category }
    });
  }
};

export const moveRiskToSaved = (modelId, sectionName, risk) => ({
  type: MOVE_RISK_TO_SAVED,
  payload: { modelId, sectionName, risk }
});

export const updateSavedRisk = (modelId, riskId,sectionName,category, newDetails) => ({
  type: UPDATE_SAVED_RISK,
  payload: { modelId, riskId, sectionName,category,newDetails }
});

export const discardSavedRisk = (riskId, modelId, sectionName) => ({
  type: DISCARD_SAVED_RISK,
  payload: { riskId, modelId, sectionName }
});


export const resetRisksState = () => ({
  type: RESET_RISKS_STATE
});

export const fetchSectionSpecificRisks = (modelId, sectionName, riskAreaData, risks, narratives) => async (dispatch, getState) => {
  const state = getState();
  //const sectionData = state.risks.models[modelId]?.riskFormData[sectionName] || {};
  const existingRisks = state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || {};
 
  const hasExistingRisks = Object.values(existingRisks)
  .flatMap(risksArray => risksArray) // Handle arrays correctly
  .some(risk => risk?.status === 'Saved');


  if (hasExistingRisks) {
    console.log('Existing risks found, skipping fetch.');
    return;  // Skip fetch if saved risks are found
  }
  



  dispatch({ type: FETCH_SECTION_SPECIFIC_RISKS_START, payload: { modelId, sectionName } });

  try {
      const response = await fetch('/.netlify/functions/generateRisks', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sectionName, riskAreaData, risks, narratives }),
      });

      if (!response.ok) {
          throw new Error(`Failed to fetch section-specific risks: ${response.status}`);
      }

      const fetchedRisks = await response.json();

      // Process the fetched risks to categorize them
      const categorizedRisks = fetchedRisks.reduce((acc, risk) => {
          const { category = 'Uncategorized' } = risk; // Default to 'Uncategorized' if no category is provided
          if (!acc[category]) acc[category] = [];
          acc[category].push({
              ...risk,
              status: risk.status || 'Generated'  // Ensure each risk has a 'Generated' status if none is present
          });
          return acc;
      }, {});

      dispatch({
          type: FETCH_SECTION_SPECIFIC_RISKS_SUCCESS,
          payload: { modelId, sectionName, risks: categorizedRisks },
      });

  } catch (error) {
      console.error('Error fetching section-specific risks:', error);
      dispatch({
          type: FETCH_SECTION_SPECIFIC_RISKS_FAILURE,
          payload: { modelId, sectionName, error: error.message },
      });
  }
};


export const updateAssessmentStatus = (modelId, assessmentUuid,assessmentStatus) => async (dispatch) => {

  console.log(`Attempting to update status: ${assessmentStatus} and Id ${assessmentUuid}`);
  
    try {
    const response = await fetch('/.netlify/functions/manageAssessment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({modelId, assessmentUuid, operation: 'updateStatus', assessmentStatus })
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.error);

    console.log ('status received from database', data)
    dispatch(assessmentStatusUpdated(modelId,data.assessmentStatus));
  } catch (error) {
    console.error('Failed to update assessment status:', error);
    dispatch(assessmentStatusFailed(error.toString()));
  }
};


export const assessmentStatusUpdated = (modelId, assessmentStatus) => ({
  type: ASSESSMENT_STATUS_UPDATED,
  payload: {modelId, assessmentStatus}
});

export const assessmentStatusFailed = (error) => ({
  type: ASSESSMENT_STATUS_FAILED,
  payload: error
});
export const updateSectionProgress = (modelId, sectionName, progress) => ({
  type: UPDATE_SECTION_PROGRESS,
  payload: { modelId, sectionName, progress }
});