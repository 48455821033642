import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import AutosaveIndicator from './AutoSaveIndicator';
import useSaveOperation from './useSaveOperation';
import RiskAccordion from './RiskAccordion'; // <-- Pull the row logic out

const RiskEvaluation = ({ modelId, assessmentUuid, sectionName }) => {
  const sectionRisks = useSelector(
    (state) => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || []
  );
  const { isSaving, isSaved, handleSaveOperation } = useSaveOperation();

  const groupedRisks = useMemo(() => {
    // Flatten the sectionRisks structure by iterating over categories and risks
    const risksWithCategory = Object.entries(sectionRisks).flatMap(
      ([categoryName, risks]) =>
        risks
          .filter((risk) => risk?.status === 'Saved') // Filter only "Saved" risks
          .map((risk) => ({
            ...risk,
            category: categoryName // Assign the correct category from the loop
          }))
    );

    // Group the risks by their category
    return risksWithCategory.reduce((acc, risk) => {
      const category = risk.category || 'Uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(risk);
      return acc;
    }, {});
  }, [sectionRisks]);

  console.log('section risks in risk eval', sectionRisks);
  console.log('grouped risks in risk eval', groupedRisks);

  if (!Object.keys(groupedRisks).length) {
    return (
      <div>
        <Typography variant="h6">No Risks Saved</Typography>
      </div>
    );
  }

  return (
    <div style={{ margin: '16px' }}>
      <Paper elevation={2} style={{ padding: '20px', marginBottom: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" style={{ flexGrow: 0, marginRight: '16px' }}>
            Risk Analysis &amp; Evaluation
          </Typography>
          <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
        </div>
      </Paper>

      <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead style={{ backgroundColor: '#e0e0e0' }}>
            <TableRow>
              <TableCell style={{ width: '60%', fontWeight: 'bold' }}>Risk Name</TableCell>
              <TableCell style={{ width: '15%', fontWeight: 'bold' }}>Priority</TableCell>
              <TableCell style={{ width: '15%', fontWeight: 'bold' }}>Rationale</TableCell>
              <TableCell style={{ width: '10%', fontWeight: 'bold' }}>Evaluate</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.entries(groupedRisks).map(([category, risks]) => (
              <React.Fragment key={category}>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography variant="h6">{category}</Typography>
                  </TableCell>
                </TableRow>

                {risks.map((risk) => (
                  <RiskAccordion
                    key={`${risk.category}-${risk.riskName}`}
                    risk={risk}
                    modelId={modelId}
                    assessmentUuid={assessmentUuid}
                    sectionName={sectionName}
                    category={risk.category}
                    handleSaveOperation={handleSaveOperation}
                  />
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RiskEvaluation;
