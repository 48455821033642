import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
} from '@mui/material';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, ListAltOutlined as ListAltOutlinedIcon } from '@mui/icons-material';
import ControlEvaluationForm from './ControlEvaluation'; // Use the same form as ActionPlan

const ResidualRisk = ({ modelId, assessmentUuid, sectionName }) => {
  const [expandedRisk, setExpandedRisk] = React.useState(null);
  const [expandedControlEvaluation, setExpandedControlEvaluation] = useState(null);

const handleControlEvaluationToggle = (controlId) => {
  setExpandedControlEvaluation((prev) => (prev === controlId ? null : controlId));
};

  // Fetch risks and saved controls from Redux
  const sectionRisks = useSelector(
    (state) => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || []
  );
  const savedControls = useSelector((state) => state.controls.savedControls || {});

  // Filter and group saved risks
  const groupedRisks = useMemo(() => {
    const risksWithCategory = Object.entries(sectionRisks)
      .flatMap(([categoryName, risks]) =>
        risks
          .filter((risk) => risk?.status === 'Saved')
          .map((risk) => ({
            ...risk,
            category: categoryName,
          }))
      );

    return risksWithCategory.reduce((acc, risk) => {
      const category = risk.category || 'Uncategorized';
      if (!acc[category]) acc[category] = [];
      acc[category].push(risk);
      return acc;
    }, {});
  }, [sectionRisks]);

  // Toggle risk expansion
  const handleExpandRisk = (riskId) => {
    setExpandedRisk((prev) => (prev === riskId ? null : riskId));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Residual Risk Assessment
      </Typography>
      {Object.keys(groupedRisks).map((category) => (
        <Paper key={category} sx={{ p: 2, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Category: {category}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Risk Name</TableCell>
                  <TableCell>Expand</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedRisks[category].map((risk) => (
                  <React.Fragment key={risk.riskId}>
                    <TableRow hover>
                      <TableCell>{risk.riskName}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleExpandRisk(risk.riskId)}
                          aria-label={expandedRisk === risk.riskId ? 'Collapse' : 'Expand'}
                        >
                          {expandedRisk === risk.riskId ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} style={{ padding: 0, borderBottom: 'none' }}>
                        <Collapse in={expandedRisk === risk.riskId} timeout="auto" unmountOnExit>
                          <Box margin={1}>
                            <Typography variant="h6" gutterBottom>
                              Saved Controls
                            </Typography>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Control Name</TableCell>
                                    <TableCell>Evaluation</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(savedControls[risk.riskId] || []).map((control) => {
                                        const isExpanded = expandedControlEvaluation === control.controlId;

                                        return (
                                        <React.Fragment key={control.controlId}>
                                            <TableRow hover>
                                            <TableCell>{control.name}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                onClick={() => handleControlEvaluationToggle(control.controlId)}
                                                aria-label={isExpanded ? 'Collapse details' : 'Expand details'}
                                                >
                                                {isExpanded ? <ExpandLessIcon /> : <ListAltOutlinedIcon />}
                                                </IconButton>
                                            </TableCell>
                                            </TableRow>
                                            <TableRow>
                                            <TableCell colSpan={2} style={{ padding: 0, borderBottom: 'none' }}>
                                                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                                <Box margin={1}>
                                                    <ControlEvaluationForm
                                                    riskId={risk.riskId}
                                                    controlId={control.controlId}
                                                    modelId={modelId}
                                                    assessmentUuid={assessmentUuid}
                                                    />
                                                </Box>
                                                </Collapse>
                                            </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                        );
                                    })}
                                    {(!savedControls[risk.riskId] || savedControls[risk.riskId].length === 0) && (
                                        <TableRow>
                                        <TableCell colSpan={2} style={{ textAlign: 'center', padding: '20px' }}>
                                            No saved controls for this risk.
                                        </TableCell>
                                        </TableRow>
                                    )}
                                    </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ))}
    </Box>
  );
};

export default ResidualRisk;