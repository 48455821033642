import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOverdueActions } from './redux/selectors/overdueActions'; // Adjust the path as necessary
import {
  Grid,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Modal,
} from '@mui/material';
import SecurityReportData from './SecurityReportData';
import DynamicLabel from './DynamicLabel'; 
import { differenceInDays, parseISO } from 'date-fns';
// Adjust the path as necessary
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  LabelList,
} from 'recharts';

// Define a more subtle and professional color palette focusing on red, amber, green, blue, grey, and beige tones
const COLORS = [
  '#FF6F61', // Soft Red
  '#EF476F', // Muted Pink
  '#FFD166', // Soft Amber
  '#06D6A0', // Soft Green
  '#118AB2', // Soft Blue
  '#073B4C', // Deep Blue Grey
  '#B0BEC5', // Light Grey
  '#FF8A65', // Light Orange
  '#FFE0B2', // Soft Light Brown
  '#D9D9D9', // Light Grey
  '#FF8A65', // Light Orange
  '#BA68C8', // Light Purple
  '#4DB6AC', // Light Teal
  '#9575CD', // Light Indigo
];

const CATEGORY_COLORS = [
  '#FF6F61', // Color 1
  '#6B5B95', // Color 2
  '#88B04B', // Color 3
  '#F7CAC9', // Color 4
  '#92A8D1', // Color 5
  '#955251', // Color 6
  '#B565A7', // Color 7
  '#009B77', // Color 8
  '#DD4124', // Color 9
  '#45B8AC', // Color 10
  // Add more colors if needed
];

// Define risk priority colors consistent with AssessmentReport
const riskPriorityColors = {
  Critical: '#000000',    // Black
  High: '#FF1744',        // Red
  Significant: '#FFC107', // Amber
  Moderate: '#FFA726',    // Light Orange
  Low: '#2196F3',         // Blue
  Negligible: '#4CAF50',  // Green
  None: '#BDBDBD',        // Light Grey
};

// Helper function to convert camelCase to Title Case and normalize
const toTitleCase = (str) => {
  if (!str) return '';
  return str
    .trim() // Trim whitespace
    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
    .replace(/^./, (char) => char.toUpperCase()) // Capitalize first character
    .trim();
};


const RiskLandscape = () => {
  // Selectors to access necessary parts of the Redux state
  const models = useSelector((state) => state.risks.models);

  //console.log ('models in RiskLandscape', models)

  // Use the SecurityReportData component
  const { dataByModel, dataByCategory } = SecurityReportData();

  //console.log ('security data in RL', dataByModel)


  const overdueActions = useSelector(selectOverdueActions);
const [openOwnerModal, setOpenOwnerModal] = useState(false);
const [selectedOwnerRisks, setSelectedOwnerRisks] = useState([]);
const [selectedOwnerName, setSelectedOwnerName] = useState('');


const riskCounts = useMemo(() => {
  const counts = {
    Critical: 0,
    High: 0,
    Significant: 0,
    Moderate: 0,
    Low: 0,
    Negligible: 0,
    None: 0,
  };

  // Aggregate risks from standard models
  Object.values(models).forEach((model) => {
    Object.entries(model.riskFormData || {}).forEach(([sectionName, sectionData]) => {
      Object.entries(sectionData.sectionRisks || {}).forEach(([category, risks]) => {
        if (Array.isArray(risks)) {
          risks.forEach((risk) => {
            const priority = toTitleCase((risk.priority || 'none').trim()); // Updated line
            if (counts.hasOwnProperty(priority)) {
              counts[priority] += 1;
            } else {
              // Handle unexpected priority by assigning to 'None'
              counts['None'] += 1;
              console.warn(`Unexpected priority "${priority}" encountered. Assigned to 'None'.`);
            }
          });
        }
      });
    });
  });

  // Aggregate security risks
  Object.entries(dataByModel).forEach(([modelUuid, modelData]) => {
    modelData.risks.forEach((risk) => {
      const priority = toTitleCase((risk.threat_level || 'none').trim()); // Updated line
      if (counts.hasOwnProperty(priority)) {
        counts[priority] += 1;
      } else {
        // Handle unexpected priority by assigning to 'None'
        counts['None'] += 1;
        console.warn(`Unexpected threat level "${priority}" encountered. Assigned to 'None'.`);
      }
    });
  });

  return counts;
}, [models, dataByModel]);
  // Transform riskCounts into an array suitable for BarChart
  const riskPriorityData = useMemo(() => {
    return Object.keys(riskCounts).map((key) => ({
      name: toTitleCase(key),
      count: riskCounts[key],
      risks: [], // Initialize an array to store risk names
    }));
  }, [riskCounts]);

  // Populate the risks for each priority
  useMemo(() => {
    // Clear existing risks
    riskPriorityData.forEach((data) => {
      data.risks = [];
    });

    // Add Model Risks
    Object.values(models).forEach((model) => {
      Object.entries(model.riskFormData || {}).forEach(([sectionName, sectionData]) => {
        Object.entries(sectionData.sectionRisks || {}).forEach(([category, risks]) => {
          if (Array.isArray(risks)) {
            risks.forEach((risk) => {
              const priority = toTitleCase(risk.priority || 'none');
              const riskName = risk.riskName || 'Unnamed Risk';
              const priorityData = riskPriorityData.find((data) => data.name === priority);
              if (priorityData) {
                priorityData.risks.push(riskName);
              } else {
                // Assign to 'None' if priority not found
                const noneData = riskPriorityData.find((data) => data.name === 'None');
                if (noneData) {
                  noneData.risks.push(riskName);
                }
              }
            });
          }
        });
      });
    });

    // Add Security Risks
    Object.entries(dataByModel).forEach(([modelUuid, modelData]) => {
      //const modelName = `Security Model ${modelUuid}`; // Placeholder for model name
      modelData.risks.forEach((risk) => {
        const priority = toTitleCase(risk.threat_level || 'none');
        const riskName = risk.risk_name || 'Unnamed Security Risk';
        const priorityData = riskPriorityData.find((data) => data.name === priority);
        if (priorityData) {
          priorityData.risks.push(riskName);
        } else {
          // Assign to 'None' if priority not found
          const noneData = riskPriorityData.find((data) => data.name === 'None');
          if (noneData) {
            noneData.risks.push(riskName);
          }
        }
      });
    });
  }, [models, dataByModel, riskPriorityData]);

  const assessmentStatusCounts = useMemo(() => {
    const counts = { Completed: 0, 'On-Going': 0, 'Not Started': 0 };

    Object.values(models).forEach((model) => {
      if (model.assessmentStatus === 'Completed') counts.Completed += 1;
      else if (model.assessmentStatus === 'On-Going') counts['On-Going'] += 1;
      else counts['Not Started'] += 1;
    });

    return counts;
  }, [models]);

  // Aggregate risks by source and category
  const risksBySource = useMemo(() => {
    const sourceData = {};

    // Process standard models
    Object.values(models).forEach((model) => {
      Object.entries(model.riskFormData || {}).forEach(([source, sectionData]) => {
        Object.entries(sectionData.sectionRisks || {}).forEach(([category, risks]) => {
          if (!sourceData[source]) {
            sourceData[source] = {};
          }
          const sanitizedCategory = toTitleCase(category.toLowerCase());
          sourceData[source][sanitizedCategory] = (sourceData[source][sanitizedCategory] || 0) + (Array.isArray(risks) ? risks.length : 0);
        });
      });
    });

    // Process security models using data from the custom hook
    Object.entries(dataByModel).forEach(([modelUuid, modelDetails]) => {
      modelDetails.risks.forEach((risk) => {
        const source = 'Security'; // Assuming all security risks belong to 'Security' source
        const category = risk.category
          ? toTitleCase(risk.category.trim().toLowerCase())
          : 'Uncategorized';
        if (!sourceData[source]) {
          sourceData[source] = {};
        }
        sourceData[source][category] = (sourceData[source][category] || 0) + 1;
      });
    });

    // Transform into array suitable for Recharts
    const chartData = Object.entries(sourceData).map(([source, categories]) => {
      return {
        source: toTitleCase(source),
        ...categories,
      };
    });

    return chartData;
  }, [models, dataByModel]);

  // Aggregate security risks by category for pie chart using data from the custom hook
  const securityRisksByCategory = useMemo(() => {
    const categoryMap = {};

    // Aggregate risks
    Object.entries(dataByCategory).forEach(([category, risks]) => {
      const sanitizedCategory = toTitleCase(category.toLowerCase());
      if (categoryMap[sanitizedCategory]) {
        categoryMap[sanitizedCategory] += Array.isArray(risks) ? risks.length : 0;
      } else {
        categoryMap[sanitizedCategory] = Array.isArray(risks) ? risks.length : 0;
      }
    });

    // Transform into array suitable for PieChart
    return Object.entries(categoryMap).map(([name, value]) => ({
      name,
      value,
    }));
  }, [dataByCategory]);
const uniqueCategories = useMemo(() => {
  const categorySet = new Set();

  // Categories from risksBySource data
  risksBySource.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'source' && key !== 'total') {
        categorySet.add(key);
      }
    });
  });

  // Categories from securityRisksByCategory data
  securityRisksByCategory.forEach((item) => {
    categorySet.add(item.name);
  });

  // Convert Set to Array and sort
  return Array.from(categorySet).sort();
}, [risksBySource, securityRisksByCategory]);

// Map each unique category to a color
const categoryColorMap = useMemo(() => {
  return uniqueCategories.reduce((acc, category, index) => {
    acc[category] = CATEGORY_COLORS[index % CATEGORY_COLORS.length];
    return acc;
  }, {});
}, [uniqueCategories]);



  // Extract critical risks for the Critical Risks Table
  const criticalRisks = useMemo(() => {
    const criticalList = [];

    // Process standard models
    Object.values(models).forEach((model) => {
      const modelName = model.modelName; // Adjust based on actual model properties
      Object.entries(model.riskFormData || {}).forEach(([sectionName, sectionData]) => {
        Object.entries(sectionData.sectionRisks || {}).forEach(([category, risks]) => {
          if (Array.isArray(risks)) {
            risks.forEach((risk) => {
              if (risk.priority && risk.priority.toLowerCase() === 'critical') {
                criticalList.push({
                  id: risk.riskId || 'N/A',
                  name: risk.riskName,
                  modelName, // Added modelName
                  category: toTitleCase(category),
                  source: toTitleCase(sectionName),
                });
              }
            });
          }
        });
      });
    });

    // Process security models
    Object.entries(dataByModel).forEach(([modelUuid, modelData]) => {
      const model = models[modelUuid]; // Retrieve the model using modelUuid
      const modelName = model?.modelName; 
      modelData.risks.forEach((risk) => {
        if (risk.threat_level && risk.threat_level.toLowerCase() === 'critical') {
          criticalList.push({
            id: risk.id || 'N/A',
            name: risk.risk_name || 'Unnamed Security Risk',
            modelName, // Added modelName
            category: risk.category ? toTitleCase(risk.category.trim().toLowerCase()) : 'Uncategorized',
            source: 'Security',
          });
        }
      });
    });

    return criticalList;
  }, [models, dataByModel]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Default rows per page

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  // Slice criticalRisks based on pagination
  const paginatedCriticalRisks = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return criticalRisks.slice(start, end);
  }, [criticalRisks, page, rowsPerPage]);
  
  // Prepare data for the Assessment Status Pie Chart
  const assessmentStatusData = useMemo(() => [
    { name: 'Completed', value: assessmentStatusCounts.Completed },
    { name: 'On-Going', value: assessmentStatusCounts['On-Going'] },
    { name: 'Not Started', value: assessmentStatusCounts['Not Started'] },
  ], [assessmentStatusCounts]);

  const risksByOwnerData = useMemo(() => {
    const ownerCounts = {};
  
    // Process standard models
    Object.values(models).forEach((model) => {
      Object.entries(model.riskFormData || {}).forEach(([sectionName, sectionData]) => {
        Object.entries(sectionData.sectionRisks || {}).forEach(([category, risks]) => {
          if (Array.isArray(risks)) {
            risks.forEach((risk) => {
              const ownerName = risk.owner ? risk.owner.name : 'Unassigned';
              if (!ownerCounts[ownerName]) {
                ownerCounts[ownerName] = { count: 0, risks: [] };
              }
              ownerCounts[ownerName].count += 1;
              ownerCounts[ownerName].risks.push(risk.riskName || 'Unnamed Risk');
            });
          }
        });
      });
    });

  
    // Process security risks
    Object.entries(dataByModel).forEach(([modelUuid, modelData]) => {
      modelData.risks.forEach((risk) => {
        const ownerName = risk.owner_name || 'Unassigned';
        if (!ownerCounts[ownerName]) {
          ownerCounts[ownerName] = { count: 0, risks: [] };
        }
        ownerCounts[ownerName].count += 1;
        ownerCounts[ownerName].risks.push(risk.risk_name || 'Unnamed Security Risk');
      });
    });
  
    return Object.keys(ownerCounts).map((ownerName) => ({
      name: ownerName,
      count: ownerCounts[ownerName].count,
      risks: ownerCounts[ownerName].risks,
    }));
  }, [models, dataByModel]);


  const handleOwnerBarClick = (data) => {
    if (data && data.risks) {
      setSelectedOwnerRisks(data.risks);
      setSelectedOwnerName(data.name);
      setOpenOwnerModal(true);
    }
  };
  
  const handleCloseOwnerModal = () => {
    setOpenOwnerModal(false);
  };
  const CustomOwnerBarTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1">{`Risks owned by ${label}`}</Typography>
          <ul>
            {Array.isArray(data.risks) && data.risks.length > 0 ? (
              data.risks.map((riskName, index) => (
                <li key={index}>{riskName}</li>
              ))
            ) : (
              <li>No risks available.</li>
            )}
          </ul>
        </Paper>
      );
    }
    return null;
  };

  // Prepare data for the Risks by Priority Pie Chart
  const risksByPriorityData = useMemo(() => riskPriorityData.map((item) => ({
    name: item.name,
    value: item.count,
  })), [riskPriorityData]);

  // Custom Tooltip Component for PieChart
  const CustomPieTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Paper sx={{ p: 1 }}>
          <Typography variant="subtitle2">{data.name}</Typography>
          <Typography variant="body2">Count: {data.value}</Typography>
        </Paper>
      );
    }
    return null;
  };

  // Custom Tooltip Component for BarChart
  const CustomBarTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper sx={{ p: 1 }}>
          <Typography variant="subtitle2">{label}</Typography>
          {payload.map((entry, index) => (
            <Typography key={index} variant="body2" style={{ color: entry.color }}>
              {entry.name}: {entry.value}
            </Typography>
          ))}
        </Paper>
      );
    }
    return null;
  };

  const calculateDaysOverdue = (dueDate) => {
    if (!dueDate) return null;
  
    const dueDateParsed = parseISO(dueDate);
    const now = new Date();
  
    const diffDays = differenceInDays(now, dueDateParsed);
  
    return diffDays > 0 ? diffDays : 0;
  };
  



  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={3}>
      
<Grid item xs={12} sm={6} md={6}>
  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Critical Risks
    </Typography>
    {criticalRisks.length > 0 ? (
      <>
        <TableContainer>
          <Table size="small" aria-label="critical risks table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Model Name</TableCell> {/* New Column */}
                <TableCell>Category</TableCell>
                <TableCell>Source</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCriticalRisks.map((risk, index) => (
                <TableRow key={`${risk.id}-${index}`}>
                  <TableCell>{risk.name}</TableCell>
                  <TableCell>{risk.modelName}</TableCell> {/* New Data Cell */}
                  <TableCell>{risk.category}</TableCell>
                  <TableCell>{risk.source}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination Controls */}
        <TablePagination
          component="div"
          count={criticalRisks.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </>
    ) : (
      <Typography variant="body2">No critical risks found.</Typography>
    )}
  </Paper>
</Grid>
       
<Grid item xs={12} sm={6} md={6}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Overdue Actions
            </Typography>
            {overdueActions.length > 0 ? (
              <TableContainer>
                <Table size="small" aria-label="overdue actions table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Control Name</TableCell>
                      <TableCell>Risk Name</TableCell>
                      <TableCell>Days Overdue</TableCell>
                      <TableCell>Model Name</TableCell>
                      <TableCell>Owner</TableCell> {/* Added Owner Column */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      {overdueActions.map((action) => {
                        const daysOverdue = calculateDaysOverdue(action.dueDate);

                        return (
                          <TableRow key={action.id} style={{ backgroundColor: 'inherit' }}>
                            <TableCell>{action.controlName}</TableCell>
                            <TableCell>{action.riskName}</TableCell>
                            <TableCell>{daysOverdue} days</TableCell>
                            <TableCell>{action.modelName}</TableCell> {/* Display Model Name */}
                            <TableCell>{action.owner}</TableCell> {/* Display Owner */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                          </Table>
              </TableContainer>
            ) : (
              <Typography variant="body2">No overdue actions.</Typography>
            )}
          </Paper>
        </Grid>

        {/* Second Row: Risks by Source Bar Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Risks by Source
            </Typography>
            <ResponsiveContainer width="100%" height={500}>

              <BarChart
  layout="vertical"
  data={risksBySource}
  margin={{ top: 20, right: 30, left: 150, bottom: 20 }}
>
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis type="number" allowDecimals={false} />
  <YAxis dataKey="source" type="category" />
  <Tooltip content={<CustomBarTooltip />} />

  {uniqueCategories.map((category, index) => (
    <Bar
      key={category}
      dataKey={category}
      stackId="a"
      // minPointSize={2}
      fill={categoryColorMap[category]} // Assign color from categoryColors
    >
      <LabelList
        content={(props) => (
          <DynamicLabel {...props} categoryName={category} />
        )}
      />
    </Bar>
  ))}
</BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Third Row: Risks by Priority and Security Risks by Category */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Risks by Priority
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={risksByPriorityData}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" height={70} />
                <YAxis allowDecimals={false} />
                <Tooltip content={<CustomBarTooltip />} />
                <Bar dataKey="value">
                  {risksByPriorityData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={riskPriorityColors[entry.name] || '#8884d8'} // Use mapped colors
                    />
                  ))}
                  <LabelList dataKey="value" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Security Risks by Category
            </Typography>


            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={securityRisksByCategory}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                  nameKey="name"
                  label={({ name, percent }) =>
                    `${name} (${(percent * 100).toFixed(0)}%)`
                  }
                  labelLine={false}
                >
                  {securityRisksByCategory.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={categoryColorMap[entry.name] || '#8884d8'} // Fallback color
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomPieTooltip />} />
                <Legend verticalAlign="bottom" height={36} />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Bottom Row: Assessment Status & Owners Pie Chart */}

        {/* Bottom Row: Risks by Owner and Assessment Status Charts */}
<Grid item xs={12} sm={6} md={6}>
  <Paper sx={{ p: 2, mt: 4, height: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Risks by Owner
    </Typography>
    {risksByOwnerData && risksByOwnerData.length > 0 ? (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={risksByOwnerData}
          onClick={(data) => {
            if (data && data.activePayload && data.activePayload.length > 0) {
              handleOwnerBarClick(data.activePayload[0].payload);
            }
          }}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" angle={-45} textAnchor="end" interval={0} height={60} />
          <YAxis allowDecimals={false} />
          <Tooltip content={<CustomOwnerBarTooltip />} />
          <Bar dataKey="count" fill="#8884d8">
            <LabelList dataKey="count" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    ) : (
      <Typography variant="body1">No data available for Risks by Owner.</Typography>
    )}
  </Paper>
</Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper sx={{ p: 2, mt: 4, mx: 'auto', maxWidth: 500 }}>
            <Typography variant="h6" gutterBottom>
              Assessment Status
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={assessmentStatusData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                  label={({ name, percent }) =>
                    `${name} (${(percent * 100).toFixed(0)}%)`
                  }
                  labelLine={false}
                >
                  {assessmentStatusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomPieTooltip />} />
                <Legend verticalAlign="bottom" height={36} />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
      {/* Owner Modal */}
<Modal
  open={openOwnerModal}
  onClose={handleCloseOwnerModal}
  aria-labelledby="owner-modal-title"
  aria-describedby="owner-modal-description"
>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
  }}>
    <Typography id="owner-modal-title" variant="h6">
      Risks owned by {selectedOwnerName}
    </Typography>
    {Array.isArray(selectedOwnerRisks) && selectedOwnerRisks.length > 0 ? (
      <ul>
        {selectedOwnerRisks.map((riskName, index) => (
          <li key={index}>{riskName}</li>
        ))}
      </ul>
    ) : (
      <Typography variant="body1">No risks available for this owner.</Typography>
    )}
  </Box>
</Modal>
    </Box>
  );
};

export default RiskLandscape;
