import React, { useMemo, useState, useCallback, useEffect, useRef  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Tabs,
  Tab,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Button,
  Snackbar,
  Alert,
  CircularProgress, 
  Tooltip,
   Chip,
   Avatar,
    Stepper,
  Step,
  StepLabel,
  AppBar,
  Toolbar,
  useMediaQuery
  } from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  ArrowBack,
  Edit as EditIcon,
  SmartToyOutlined as SmartToyOutlinedIcon,
  Add as AddIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from 'recharts';

import { useTheme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual'; // Add this import at the top with other imports

import SingleRiskReport from './SingleRiskReport';
import SecurityReportData from './SecurityReportData'; // Custom hook
import RiskCard from './RiskCard';
import SourceRiskCounter from './SourceRiskCounter';
import RiskCatalogue from './RiskCatalogue';

import SecurityAssessmentReport from './SecurityAssessmentReport';
//import IconWithProgress from './IconWithProgress';

import { useAuth0 } from '@auth0/auth0-react';

import { moveRiskToSaved } from './redux/actions/riskActions'
import useSaveOperation from './useSaveOperation';

// Risk priority colors
const riskPriorityColors = {
  Critical: '#000000',    // Black
  High: '#FF1744',        // Red
  Significant: '#FFC107', // Amber
  Moderate: '#FFA726',    // Light Orange
  Low: '#2196F3',         // Blue
  Negligible: '#4CAF50',  // Green
  None: '#BDBDBD',        // Light Grey
};

// Define a blue color palette for the PieChart
const blueShades = [
  '#1565C0', // Dark Blue
  '#1E88E5', // Blue
  '#1976D2', // Blue
  '#42A5F5', // Sky Blue
  '#5C6BC0', // Indigo
  '#64B5F6', // Light Blue
  '#90CAF9', // Lighter Blue
];

// Utility Functions
const toTitleCase = (str) => {
  if (!str) return '';
  // Handle known acronyms
  str = str.replace(/\b(EU|ROC|AUC)\b/g, match => match.toUpperCase());

  // Format camelCase and PascalCase
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before capitals
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Handle sequences of capitals
    .replace(/\b([Aa]nd)\b/g, '&')
    .split(/\s+/) // Split by spaces
    .map(word => {
      if (['EU', 'ROC', 'AUC'].includes(word)) {
        return word; // Return acronyms unchanged
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize each word
      }
    })
    .join(' ')
    .trim(); // Trim extra spaces
};

const AssessmentReport = () => {

  //{ modelId, assessmentUuid }
  const { modelId, assessmentUuid } = useParams();
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const risksState = useSelector((state) => state.risks.models[modelId]);
  const controlsState = useSelector((state) => state.controls.savedControls);
  const contextState = useSelector((state) => state.context.data[modelId]);
  const contextData = useSelector((state) => state.context.data[modelId] || {});
  const securityReportData = SecurityReportData();
  const riskFormData = useMemo(() => risksState?.riskFormData || {}, [risksState?.riskFormData]);
  const riskCardSections = Object.keys(riskFormData || {});
  const { user } = useAuth0();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const modelName=risksState.modelName;
  // Local State
 
  const [expandedSection, setExpandedSection] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [categoryTabIndex, setCategoryTabIndex] = useState({});
  const [expandedRiskCard, setExpandedRiskCard] = useState({});
  const [editingRisk, setEditingRisk] = useState(null);
  const [openPriorityModal, setOpenPriorityModal] = useState(false);
  const [selectedPriorityRisks, setSelectedPriorityRisks] = useState([]);
  const [selectedPriorityName, setSelectedPriorityName] = useState('');
  const [openSourceModal, setOpenSourceModal] = useState(false);
  const [selectedSourceRisks, setSelectedSourceRisks] = useState([]);
  const [selectedSourceName, setSelectedSourceName] = useState('');
  const [selectedSectionName, setSelectedSectionName] = useState('');
  const [openRiskGenerationModal, setOpenRiskGenerationModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['RiskCard & Assessment', 'Risk Catalogue'];
   const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
// State for Details modal
const [detailsModalOpen, setDetailsModalOpen] = useState(false);
const [selectedSectionDetails, setSelectedSectionDetails] = useState({ summary: [], details: [] });
const [selectedSectionNameForDetails, setSelectedSectionNameForDetails] = useState('');
const [changedCategories, setChangedCategories] = useState([]);

const changedCategoriesWithoutRisks = useMemo(() => {
  return changedCategories.filter(cat => !cat.hasRisks);
}, [changedCategories]);

useEffect(() => {
  const original = originalRiskFormDataRef.current;
  const current = riskFormData;

  const changed = [];

  // Iterate over each section
  Object.keys(current).forEach((section) => {
    const originalSection = original[section] || {};
    const currentSection = current[section] || {};

    // Get all categories excluding 'sectionRisks' and other non-category keys
    const categories = Object.keys(currentSection).filter(
      (key) => key !== 'sectionRisks' && key !== 'assessmentProgress'
    );

    categories.forEach((category) => {
      const originalCategory = originalSection[category] || {};
      const currentCategory = currentSection[category] || {};

      // Check if the category has changed
      if (!isEqual(originalCategory, currentCategory)) {
        // Check if the category has risks
        const sectionRisks = currentSection.sectionRisks || {};
        const risks = sectionRisks[category] || [];
        const hasRisks = Array.isArray(risks) && risks.length > 0;

        changed.push({ section, category, hasRisks });
      }
    });
  });

  setChangedCategories(changed);


}, [riskFormData]);
  const { isSaving, isSaved, handleSaveOperation, setIsSaved} = useSaveOperation();

  useEffect(() => {
    if (isSaved) {
      const timer = setTimeout(() => {
        setIsSaved(false);
      }, 2000); // Reset after 2 seconds
      return () => clearTimeout(timer);
    }
  }, [isSaved, setIsSaved]);

  
  // Helper Functions
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Ref to store the original riskFormData
  const originalRiskFormDataRef = useRef(riskFormData);


  // Memoized Data
  const allSectionRisks = useMemo(() => {
    if (!risksState || !risksState.riskFormData) return {};
    const filtered = {};
    Object.entries(risksState.riskFormData).forEach(([section, categories]) => {
      filtered[section] = {};
      Object.entries(categories.sectionRisks || {}).forEach(([category, risks]) => {
        const processedRisks = Array.isArray(risks)
          ? risks.map((risk) => ({
              ...risk,
              status: risk.status || 'none',
            }))
          : [];
        filtered[section][category] = processedRisks;
      });
    });
    return filtered;
  }, [risksState]);

  const allControls = useMemo(() => {
    const controls = {};
    Object.entries(controlsState || {}).forEach(([riskId, controlList]) => {
      controls[riskId] = Array.isArray(controlList)
        ? controlList.map((control) => ({
            ...control,
            status: control.status || 'none',
          }))
        : [];
    });
    return controls;
  }, [controlsState]);

  const allSecurityRisks = useMemo(() => {
    if (!securityReportData.dataByModel || !securityReportData.dataByModel[modelId]?.risks) return [];
    return Array.isArray(securityReportData.dataByModel[modelId].risks)
      ? securityReportData.dataByModel[modelId].risks.map((risk) => ({
          ...risk,
          status: risk.status || 'none',
        }))
      : [];
  }, [securityReportData, modelId]);

  const allSecurityControls = useMemo(() => {
    if (!securityReportData.dataByModel || !securityReportData.dataByModel[modelId]?.controls) return {};
    const controls = {};
    Object.entries(securityReportData.dataByModel[modelId].controls).forEach(([riskId, controlList]) => {
      controls[riskId] = Array.isArray(controlList)
        ? controlList.map((control) => ({
            ...control,
            status: control.status || 'none',
          }))
        : [];
    });
    return controls;
  }, [securityReportData, modelId]);

  const { useCase, businessContext, modelContext } = useMemo(() => {
    if (!contextState) return { useCase: 'none', businessContext: 'none', modelContext: 'none' };
    return {
      useCase: contextState.useCase?.narrative || 'none',
      businessContext: contextState.businessContext || 'none',
      modelContext: contextState.modelContext || 'none',
    };
  }, [contextState]);

  const narratives = useMemo(() => {
    return Object.keys(contextData).reduce((acc, section) => {
      const narrative = contextData[section]?.narrative
        ? contextData[section].narrative
        : `No narrative for ${section}`;
      acc[section] = narrative;
      return acc;
    }, {});
  }, [contextData]);

  const riskCounts = useMemo(() => {
    const counts = {
      Critical: 0,
      High: 0,
      Significant: 0,
      Moderate: 0,
      Low: 0,
      Negligible: 0,
      None: 0,
    };

    // Process Model Risks
    Object.values(allSectionRisks).forEach((categories) => {
      Object.values(categories).forEach((risks) => {
        risks.forEach((risk) => {
          const priority = capitalizeFirstLetter(risk.priority || 'none');
          if (counts.hasOwnProperty(priority)) {
            counts[priority] += 1;
          } else {
            // Handle unexpected priority by assigning to 'None'
            counts['None'] += 1;
            console.warn(`Unexpected priority "${priority}" encountered. Assigned to 'None'.`);
          }
        });
      });
    });

    // Process Security Risks
    allSecurityRisks.forEach((risk) => {
      const priority = capitalizeFirstLetter(risk.threat_level || 'none');
      if (counts.hasOwnProperty(priority)) {
        counts[priority] += 1;
      } else {
        // Handle unexpected priority by assigning to 'None'
        counts['None'] += 1;
        console.warn(`Unexpected threat level "${priority}" encountered. Assigned to 'None'.`);
      }
    });

    return counts;
  }, [allSectionRisks, allSecurityRisks]);

  // Prepare riskPriorityData with risk names
  const riskPriorityData = useMemo(() => {
    return Object.keys(riskCounts).map((key) => ({
      name: key,
      count: riskCounts[key],
      risks: [], // Initialize an array to store risk names
    }));
  }, [riskCounts]);

  // Populate the risks for each priority
  useMemo(() => {
    // Clear existing risks
    riskPriorityData.forEach((data) => {
      data.risks = [];
    });

    // Add Model Risks
    Object.values(allSectionRisks).forEach((categories) => {
      Object.values(categories).forEach((risks) => {
        risks.forEach((risk) => {
          const priority = capitalizeFirstLetter(risk.priority || 'none');
          const riskName = risk.riskName || 'Unnamed Risk';
          const priorityData = riskPriorityData.find((data) => data.name === priority);
          if (priorityData) {
            priorityData.risks.push(riskName);
          } else {
            // Assign to 'None' if priority not found
            const noneData = riskPriorityData.find((data) => data.name === 'None');
            if (noneData) {
              noneData.risks.push(riskName);
            }
          }
        });
      });
    });

    // Add Security Risks
    allSecurityRisks.forEach((risk) => {
      const priority = capitalizeFirstLetter(risk.threat_level || 'none');
      const riskName = risk.risk_name || 'Unnamed Security Risk';
      const priorityData = riskPriorityData.find((data) => data.name === priority);
      if (priorityData) {
        priorityData.risks.push(riskName);
      } else {
        // Assign to 'None' if priority not found
        const noneData = riskPriorityData.find((data) => data.name === 'None');
        if (noneData) {
          noneData.risks.push(riskName);
        }
      }
    });
  }, [allSectionRisks, allSecurityRisks, riskPriorityData]);

  const riskPriorityChartData = useMemo(() => {
    const counts = Object.keys(riskPriorityColors).reduce((acc, key) => {
      acc[key] = { security: 0, model: 0 };
      return acc;
    }, {});

    // Calculate counts for model risks
    Object.values(allSectionRisks).forEach((categories) => {
      Object.values(categories).forEach((risks) => {
        risks.forEach((risk) => {
          const priority = capitalizeFirstLetter(risk.priority || 'None');
          if (counts.hasOwnProperty(priority)) {
            counts[priority].model += 1;
          } else {
            // Assign to 'None' if unexpected priority
            counts['None'].model += 1;
            console.warn(`Unexpected priority "${priority}" in model risks. Assigned to 'None'.`);
          }
        });
      });
    });

    // Calculate counts for security risks
    allSecurityRisks.forEach((risk) => {
      const priority = capitalizeFirstLetter(risk.threat_level || 'None');
      if (counts.hasOwnProperty(priority)) {
        counts[priority].security += 1;
      } else {
        // Assign to 'None' if unexpected priority
        counts['None'].security += 1;
        console.warn(`Unexpected threat level "${priority}" in security risks. Assigned to 'None'.`);
      }
    });

    return Object.keys(counts).map((priority) => ({
      name: priority,
      ...counts[priority],
    }));
  }, [allSectionRisks, allSecurityRisks]);

  // Prepare sectionDistributionData for PieChart
  const sectionDistributionData = useMemo(() => {
    const sourceRiskCounts = {};

    // Collect risks from all sections
    Object.entries(allSectionRisks).forEach(([section, categories]) => {
      let totalRisks = 0;
      let riskNames = [];
      Object.values(categories).forEach((risks) => {
        if (Array.isArray(risks)) {
          totalRisks += risks.length;
          riskNames = riskNames.concat(risks.map((risk) => risk.riskName || risk.name || 'Unnamed Risk'));
        }
      });
      sourceRiskCounts[toTitleCase(section)] = {
        count: totalRisks,
        risks: riskNames,
      };
    });

    // Include security risks
    sourceRiskCounts['Security'] = {
      count: allSecurityRisks.length,
      risks: allSecurityRisks.map((risk) => risk.risk_name || 'Unnamed Security Risk'),
    };

    // Convert to array for pie chart
    return Object.keys(sourceRiskCounts).map((key) => ({
      name: key,
      count: sourceRiskCounts[key].count,
      risks: Array.isArray(sourceRiskCounts[key].risks) ? sourceRiskCounts[key].risks : [],
    }));
  }, [allSectionRisks, allSecurityRisks]);


  

  // Custom Tooltip Component for BarChart
  const CustomBarTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const risks = payload[0].payload.risks;
      return (
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1">{`${toTitleCase(label)} Risks`}</Typography>
          <ul>
            {Array.isArray(risks) && risks.length > 0 ? (
              risks.map((riskName, index) => (
                <li key={index}>{riskName}</li>
              ))
            ) : (
              <li>No risks available.</li>
            )}
          </ul>
        </Paper>
      );
    }
    return null;
  };

  // Custom Tooltip Component for PieChart
  const CustomPieTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1">{`${toTitleCase(data.name)} Risks`}</Typography>
          <ul>
            {Array.isArray(data.risks) && data.risks.length > 0 ? (
              data.risks.map((riskName, index) => (
                <li key={index}>{riskName}</li>
              ))
            ) : (
              <li>No risks available.</li>
            )}
          </ul>
        </Paper>
      );
    }
    return null;
  };
  const legendFormatter = (value, entry) => {
    //const { color } = entry;
    return (
      <span style={{ color: '#333' }}>
        {value} ({entry.payload.count})
      </span>
    );
  };
  // Event Handlers
  const handleBarClick = (data) => {
    if (data && data.risks) {
      setSelectedPriorityRisks(data.risks);
      setSelectedPriorityName(data.name);
      setOpenPriorityModal(true);
    }
  };

  const handleClosePriorityModal = () => {
    setOpenPriorityModal(false);
  };

  const handlePieClick = (data) => {
    if (data && data.risks) {
      setSelectedSourceName(data.name);
      setSelectedSourceRisks(data.risks);
      setOpenSourceModal(true);
    }
  };

  const handleCloseSourceModal = () => {
    setOpenSourceModal(false);
  };

  const handleExpandSection = (sectionName) => {
    setExpandedSection((prevExpandedSection) => {
      const isExpanding = !prevExpandedSection[sectionName];
      if (isExpanding && categoryTabIndex[sectionName] === undefined) {
        setCategoryTabIndex((prevCategoryTabIndex) => ({
          ...prevCategoryTabIndex,
          [sectionName]: 0,
        }));
      }
      return {
        ...prevExpandedSection,
        [sectionName]: isExpanding,
      };
    });
  };

  const handleEditRisk = (riskData, sectionName, category) => {
    setEditingRisk({ risk: riskData, sectionName, category });
    console.log('Editing risk:', riskData);
  };

  const handleCloseEditRisk = () => {
    setEditingRisk(null);
  };




  const handleSaveRisk = useCallback(
    async (risk, sectionName, category) => {
      if (!risk.riskId) {
        handleSaveOperation(async () => {
          const ownerInfo = {
            name: user.name,
            email: user.email,
            picture: user.picture,
          };
          const response = await fetch('/.netlify/functions/saveRisk', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              name: risk.riskName,
              section: sectionName,
              category: category,
              modelUuid: modelId,
              assessmentUuid: assessmentUuid,
              owner:ownerInfo,
            }),
          });
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to save risk');
          }
          const savedRisk = await response.json();
          dispatch(
            moveRiskToSaved(modelId, sectionName, {
              ...risk,
              riskId: savedRisk.riskId,
              status: 'Saved',
              category: category,
              owner: {
                name: user.name,
                email: user.email,
                picture: user.picture,
              },
            })
          );
          setSnackbarMessage('Risk saved successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        }).catch((error) => {
          setSnackbarMessage(error.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
      }
    },
    [handleSaveOperation, dispatch, user.name, user.email, user.picture,modelId, assessmentUuid]
  );

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setExpandedSection({});
  };

  const handleCategoryTabChange = (sectionName, newIndex) => {
    setCategoryTabIndex((prev) => ({
      ...prev,
      [sectionName]: newIndex,
    }));
  };

  // Render controls count for each risk
  const renderControlsCount = (riskId, isSecurityRisk = false) => {
    const controls = isSecurityRisk ? allSecurityControls[riskId] : allControls[riskId];
    console.log(`renderControlsCount called with riskId: '${riskId}'`);
    console.log(`controls retrieved:`, controls);
    return Array.isArray(controls) ? controls.length : 0;
  };

  const handleExpandRiskCard = (sectionName) => {
    setExpandedRiskCard((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  const handleAddRiskManually = (sectionName) => {
    console.log(`Adding a risk manually for section: ${sectionName}`);
    // Implement your logic here, e.g., open a modal to input new risk details
  };

  // Handlers for Risk Generation Modal
  const handleOpenRiskGenerationModal = (sectionName) => {
    setSelectedSectionName(sectionName);
    setOpenRiskGenerationModal(true);
    setActiveStep(0);
  };

  const handleCloseRiskGenerationModal = () => {
    setOpenRiskGenerationModal(false);
    setSelectedSectionName('');
    setActiveStep(0);
  };


  // Modal Styling
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
  };

  // Early Exit: If no risks and no security risks, display a message
  const hasNoRisks = useMemo(() => {
    const totalModelRisks = Object.values(allSectionRisks).reduce((acc, categories) => {
      return acc + Object.values(categories).reduce((catAcc, risks) => catAcc + (Array.isArray(risks) ? risks.length : 0), 0);
    }, 0);
    const totalSecurityRisks = Array.isArray(allSecurityRisks) ? allSecurityRisks.length : 0;
    return totalModelRisks === 0 && totalSecurityRisks === 0;
  }, [allSectionRisks, allSecurityRisks]);


const formatValue = (value) => {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return 'None';
    }
    return (
      <ul>
        {value.map((item, index) => (
          <li key={index}>{formatValue(item)}</li>
        ))}
      </ul>
    );
  } else if (typeof value === 'object' && value !== null) {
    if (Object.keys(value).length === 0) {
      return 'None';
    }
    return (
      <ul>
        {Object.entries(value).map(([key, val], index) => (
          <li key={index}>
            <strong>{toTitleCase(key)}:</strong> {formatValue(val)}
          </li>
        ))}
      </ul>
    );
  } else {
    return value ? value.toString() : 'None';
  }
};

 const findDifferences = (original, current, path = []) => {
  let differences = [];

  // Get all unique keys from both original and current
  const keys = new Set([...Object.keys(original || {}), ...Object.keys(current || {})]);

  keys.forEach((key) => {
    // Exclude certain keys
    if (key === 'sectionRisks' || key === 'assessmentProgress') return;

    const origValue = original ? original[key] : undefined;
    const currValue = current ? current[key] : undefined;
    let currentPath = [...path];

    // Use 'name' instead of key if available
    if (typeof currValue === 'object' && currValue !== null && currValue.hasOwnProperty('name')) {
      currentPath.push(currValue.name);
    } else if (typeof origValue === 'object' && origValue !== null && origValue.hasOwnProperty('name')) {
      currentPath.push(origValue.name);
    } else {
      currentPath.push(toTitleCase(key));
    }

    if (typeof origValue === 'object' && origValue !== null && typeof currValue === 'object' && currValue !== null) {
      // Recurse into nested objects
      differences = differences.concat(findDifferences(origValue, currValue, currentPath));
    } else if (!isEqual(origValue, currValue)) {
      // Record the difference
      differences.push({
        path: currentPath.join(' > '),
        original: origValue,
        new: currValue,
      });
    }
  });

  return differences;
}; 

  const getChangedFields = (sectionName) => {
    const originalSection = originalRiskFormDataRef.current[sectionName] || {};
    const currentSection = riskFormData[sectionName] || {};
  
    const differences = findDifferences(originalSection, currentSection);
  
    if (differences.length === 0) {
      return { summary: 'No changes detected.', details: null };
    }
  
    // Collect unique top-level categories or key names
    const changedCategories = new Set();
  
    differences.forEach((diff) => {
      // Get the top-level category/key from the path
      const topLevelKey = diff.path.split(' > ')[0];
      changedCategories.add(topLevelKey);
    });
  
    return {
      summary: Array.from(changedCategories),
      details: differences,
    };
  };

  const handleOpenDetailsModal = (sectionName) => {
    const { summary, details } = getChangedFields(sectionName);
    setSelectedSectionDetails({ summary, details });
    setSelectedSectionNameForDetails(sectionName);
    setDetailsModalOpen(true);
  };
  
  const handleCloseDetailsModal = () => {
    setDetailsModalOpen(false);
    setSelectedSectionDetails({ summary: [], details: [] });
    setSelectedSectionNameForDetails('');
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };


  return (

    <Box sx={{ backgroundColor: '#FAFAFA', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
  
    <AppBar position="static" elevation={0} sx={{ backgroundColor: '#FFFFFF', borderBottom: '1px solid #E0E0E0' }}>
      <Toolbar>
        <IconButton edge="start" onClick={() => navigate(-1)} aria-label="back" sx={{ mr: 2 }}>
          <ArrowBack sx={{ color: '#42424a' }} />
        </IconButton>
        <Typography variant="h6" color="text.primary" sx={{ flexGrow: 1 }}>
          Assessment Report - {modelName}
        </Typography>
      </Toolbar>
    </AppBar>
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={3}>
        {editingRisk ? (
          // Render SingleRiskReport when editingRisk is set
          <Grid item xs={12}>
            <SingleRiskReport
              risk={editingRisk.risk}
              sectionName={editingRisk.sectionName}
              sectionState={riskFormData[editingRisk.sectionName]}
              category={editingRisk.category}
              modelId={modelId}
              assessmentUuid={assessmentUuid}
              user={user}
              onClose={handleCloseEditRisk} // Handle closing the edit view
            />
          </Grid>
        ) : hasNoRisks ? (
          // Display a message when there are no risks
          <Grid item xs={12}>
            <Paper sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                No Risks Available
              </Typography>
              <Typography variant="body1" gutterBottom>
                There are currently no risks to display. You can add risks manually or generate them.
              </Typography>
              <Box display="flex" justifyContent="center" gap={2} mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SmartToyOutlinedIcon />}
                  onClick={() => handleOpenRiskGenerationModal('general')}
                >
                  Generate Risks
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={() => handleAddRiskManually('general')}
                >
                  Add Risk Manually
                </Button>
              </Box>
            </Paper>
          </Grid>
        ) : (
          <>
            {/* Tabs for Different Sections */}
            <Grid item xs={12}>
              <Paper elevation={3}>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  variant="fullWidth"
                >
                  <Tab label="Model & Security Risks" />
                  <Tab label="Risk Card" />
                  <Tab label="Use Case, Business & Model Context" />
                </Tabs>
              </Paper>
            </Grid>

            {/* Tab Content */}
            {tabIndex === 0 && (
              <>
                {/* Risks by Priority - BarChart */}
                <Grid item xs={12} sm={6} md={6}>
                  <Paper sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                      Risks by Priority
                    </Typography>
                    {riskPriorityChartData && riskPriorityChartData.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          data={riskPriorityChartData}
                          onClick={(data) => {
                            if (data.activePayload && data.activePayload.length > 0) {
                              handleBarClick(data.activePayload[0].payload);
                            }
                          }}
                          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" vertical={false} />
                          <XAxis dataKey="name" angle={-45} textAnchor="end" interval={0} height={60} />
                          <YAxis allowDecimals={false} />
                          <RechartsTooltip content={<CustomBarTooltip />} />

                          {/* Bar for Model Risks */}
                          <Bar dataKey="model" stackId="a">
                            {riskPriorityChartData.map((entry, index) => (
                              <Cell key={`cell-model-${index}`} fill={riskPriorityColors[entry.name] || '#CCCCCC'} />
                            ))}
                          </Bar>

                          {/* Bar for Security Risks */}
                          <Bar dataKey="security" stackId="a">
                            {riskPriorityChartData.map((entry, index) => (
                              <Cell
                                key={`cell-security-${index}`}
                                fill={riskPriorityColors[entry.name] || '#CCCCCC'}
                                style={{ stroke: '#FFFFFF', strokeWidth: 2 }}
                              />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography variant="body1">No data available for Risks by Priority.</Typography>
                    )}
                  </Paper>
                </Grid>




<Grid item xs={12} sm={6} md={6}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom align="center">
          Risks by Source
        </Typography>
        {sectionDistributionData && sectionDistributionData.length > 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={sectionDistributionData}
                  dataKey="count"
                  nameKey="name"
                  cx={isSmallScreen ? '50%' : '30%'}
                  cy="50%"
                  outerRadius={80}
                  labelLine={false}
                  label={false}
                  onClick={(data, index) => handlePieClick(data.payload)}
                >
                  {sectionDistributionData.map((entry, index) => (
                    <Cell
                      key={`cell-pie-${index}`}
                      fill={blueShades[index % blueShades.length]}
                    />
                  ))}
                </Pie>
                <RechartsTooltip content={<CustomPieTooltip />} />
                <Legend
                  layout={isSmallScreen ? 'horizontal' : 'vertical'}
                  align={isSmallScreen ? 'center' : 'right'}
                  verticalAlign={isSmallScreen ? 'bottom' : 'middle'}
                  iconType="circle"
                  formatter={legendFormatter}
                  wrapperStyle={{
                    paddingLeft: isSmallScreen ? 0 : 20,
                    paddingTop: isSmallScreen ? 20 : 0,
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Typography variant="body1">
            No data available for Risks by Source.
          </Typography>
        )}
      </Paper>
    </Grid>

                {/* Risks & Controls Table */}
                <Grid item xs={12}>
                  <Paper sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                      Risks & Controls
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <TableContainer component={Paper}>
                      <Table>
                      <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Risk Source</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Risks By Priority</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Details</TableCell> {/* New Details Column */}
                            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Render non-security risks */}
                          {Object.entries(riskFormData).map(([sectionName, sectionData]) => {
                            // Determine if the entire section has no risks
                            const hasNoRisksInSection = !Object.values(sectionData.sectionRisks || {}).some(
                              (risks) => Array.isArray(risks) && risks.length > 0
                            );

                            // Check if the section has changes
                            const isChanged = changedCategories.some(cat => cat.section === sectionName);

                            // Find changed categories with no risks for the current section
                            const changedCategoriesForSection = changedCategoriesWithoutRisks.filter(
                              (cat) => cat.section === sectionName
                            );

                            // Get existing categories
                            const existingCategories = Object.keys(sectionData.sectionRisks || {});

                            // Combine existing and changed categories
                            const combinedCategories = [
                              ...existingCategories,
                              ...changedCategoriesForSection.map(cat => cat.category),
                            ];

                            // Prepare a mapping to identify which categories are new and have no risks
                            const categoryInfo = combinedCategories.map(category => {
                              const isNewCategory = changedCategoriesForSection.some(
                                (cat) => cat.category === category
                              );
                              const hasRisks = Array.isArray(sectionData.sectionRisks?.[category]) &&
                                sectionData.sectionRisks[category].length > 0;
                              return {
                                name: category,
                                isNew: isNewCategory && !hasRisks,
                                hasRisks: hasRisks,
                              };
                            });

                            return (
                              <React.Fragment key={sectionName}>
                                {/* Source Row */}
                                <TableRow>
                                  <TableCell>
                                    <Box display="flex" alignItems="center">
                                      <IconButton onClick={() => handleExpandSection(sectionName)} size="small">
                                        {expandedSection[sectionName] ? <ExpandLess /> : <ExpandMore />}
                                      </IconButton>
                                      <Typography variant="body1" component="span" sx={{ ml: 1 }}>
                                        {toTitleCase(sectionName)}
                                      </Typography>
                                    </Box>
                                  </TableCell>

                                  <TableCell>
                                    <SourceRiskCounter sectionRisks={sectionData.sectionRisks} />
                                  </TableCell>

                                  <TableCell>
                                    {isChanged && (
                                      <Chip
                                        label="Changed"
                                        color="error"
                                        size="small"
                                        sx={{ cursor: 'pointer' }}
                                      />
                                    )}
                                  </TableCell>

                                  {/* <TableCell>
                                    {isChanged && (
                                      <Box>
                                        {getChangedFields(sectionName)}
                                      </Box>
                                    )}
                                  </TableCell> */}

                                    <TableCell>
                                      {isChanged && (
                                        <Box>
                                          {(() => {
                                            const { summary } = getChangedFields(sectionName);
                                            if (summary === 'No changes detected.') {
                                              return <Typography>{summary}</Typography>;
                                            } else {
                                              return (
                                                <Box>
                                                  <Typography variant="body2" component="div">
                                                    Changes detected in: {summary.join(', ')}
                                                  </Typography>
                                                  <Button variant="text" onClick={() => handleOpenDetailsModal(sectionName)} sx={{ textTransform: 'none' }}>
                                                    See more
                                                  </Button>
                                                </Box>
                                              );
                                            }
                                          })()}
                                        </Box>
                                      )}
                                    </TableCell>

                                  {/* Actions Column */}
                                  <TableCell>
                                    {isChanged && hasNoRisksInSection ? (
                                      <>
                                        <Tooltip title="Update Risks Manually" arrow>
                                          <IconButton
                                            color="secondary"
                                            onClick={() => handleAddRiskManually(sectionName)}
                                          >
                                            <AddIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Generate Risks" arrow>
                                          <IconButton
                                            color="primary"
                                            onClick={() => handleOpenRiskGenerationModal(sectionName)}
                                          >
                                            <SmartToyOutlinedIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    ) : isChanged && !hasNoRisksInSection ? (
                                      <Tooltip title="Generate Risks" arrow>
                                        <IconButton
                                          color="primary"
                                          onClick={() => handleOpenRiskGenerationModal(sectionName)}
                                        >
                                          <SmartToyOutlinedIcon />
                                        </IconButton>
                                      </Tooltip>
                                    ) : null}
                                  </TableCell>
                                </TableRow>

                                {/* Expandable section with categories */}
                                <TableRow>
                                  <TableCell colSpan={5}>
                                    <Collapse in={expandedSection[sectionName]} timeout="auto" unmountOnExit>
                                      <Grid container spacing={2}>
                                        {hasNoRisksInSection ? (
                                          // **No Risks: Show Message and Buttons**
                                          <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom>
                                              No risks for this risk source.
                                            </Typography>
                                            <Box display="flex" gap={2}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<SmartToyOutlinedIcon />}
                                                onClick={() => handleOpenRiskGenerationModal(sectionName)}
                                              >
                                                Generate Risks
                                              </Button>
                                              <IconButton
                                                color="secondary"
                                                onClick={() => handleAddRiskManually(sectionName)}
                                                aria-label="Add Risk Manually"
                                              >
                                                <AddIcon />
                                              </IconButton>
                                            </Box>
                                          </Grid>
                                        ) : (
                                          // **Risks Exist: Show Categories and Risks**
                                          <>
                                            {/* Vertical Tabs for Categories */}
                                            <Grid item xs={3}>
                                              <Tabs
                                                orientation="vertical"
                                                value={categoryTabIndex[sectionName] || 0}
                                                onChange={(e, newIndex) => handleCategoryTabChange(sectionName, newIndex)}
                                                variant="scrollable"
                                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                              >
                                                {categoryInfo.map((cat, idx) => (
                                                  <Tab
                                                    key={cat.name}
                                                    label={
                                                      <Box display="flex" alignItems="center">
                                                        <Typography sx={{ textTransform: 'none', mr: 1 }}>
                                                          {toTitleCase(cat.name)}
                                                        </Typography>
                                                        {cat.isNew && (
                                                          <Chip
                                                            label="New"
                                                            color="success"
                                                            size="small"
                                                          />
                                                        )}
                                                      </Box>
                                                    }
                                                    sx={{ textTransform: 'none' }}
                                                  />
                                                ))}
                                              </Tabs>
                                            </Grid>

                                            {/* Risks inside each Category */}
                                            <Grid item xs={9}>
                                            {categoryInfo.map((cat, idx) => (
                                              <Box key={cat.name} hidden={(categoryTabIndex[sectionName] || 0) !== idx}>
                                                {cat.hasRisks ? (
                                                  // **Existing Rendering for Categories with Risks**
                                                  <Table>
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell>Risk Name</TableCell>
                                                        <TableCell>Priority</TableCell>
                                                        <TableCell>Controls</TableCell>
                                                        <TableCell>Owner</TableCell> {/* Added Owner column */}
                                                        <TableCell>Actions</TableCell>
                                                        
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {sectionData.sectionRisks?.[cat.name]?.map((risk) => (
                                                        <TableRow key={risk.riskId}>
                                                          <TableCell>{risk.riskName || 'Unnamed Risk'}</TableCell>
                                                          <TableCell>{capitalizeFirstLetter(risk.priority || 'none')}</TableCell>
                                                      
                                                          <TableCell>{renderControlsCount(risk.riskId)}</TableCell>
                                                          <TableCell>
                                                            {risk.owner ? (
                                                              <Tooltip title={risk.owner.name}>
                                                                <Avatar alt={risk.owner.name} src={risk.owner.picture} />
                                                              </Tooltip>
                                                            ) : (
                                                              'No owner'
                                                            )}
                                                          </TableCell>
                                                          <TableCell>
                                                            <IconButton onClick={() => handleEditRisk(risk, sectionName, cat.name)}>
                                                              <EditIcon />
                                                            </IconButton>
                                                            {risk.status !== 'Saved' && (
                                                              <IconButton onClick={() => handleSaveRisk(risk, sectionName, cat.name)} disabled={isSaving}>
                                                                {isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
                                                              </IconButton>
                                                            )}
                                                          </TableCell>
                                                          
                                                        </TableRow>
                                                      ))}
                                                    </TableBody>
                                                  </Table>
                                                ) : (
                                                  // **Updated Rendering for Categories Without Risks**
                                                  <Table>
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell>Risk Name</TableCell>
                                                        <TableCell>Priority</TableCell>
                                                        <TableCell>Controls</TableCell>
                                                        <TableCell>Actions</TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      <TableRow>
                                                        <TableCell>No risks generated</TableCell>
                                                        <TableCell>-</TableCell>
                                                        <TableCell>-</TableCell>
                                                        <TableCell>
                                                          <Tooltip title="Generate Risks" arrow>
                                                            <IconButton
                                                              color="primary"
                                                              onClick={() => handleOpenRiskGenerationModal(sectionName, cat.name)}
                                                            >
                                                              <SmartToyOutlinedIcon />
                                                            </IconButton>
                                                          </Tooltip>
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableBody>
                                                  </Table>
                                                )}
                                              </Box>
                                            ))}
                                            </Grid>
                                          </>
                                        )}
                                      </Grid>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}

                          {/* Security Risks */}
                          <SecurityAssessmentReport
                            modelId={modelId}
                            assessmentUuid={assessmentUuid}
                            expandedSection={expandedSection}
                            handleExpandSection={handleExpandSection}
                            renderControlsCount={renderControlsCount}
                            //handleOpenControlsModal={handleOpenControlsModal}
                          />
                        </TableBody>


 
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </>
            )}

            {/* Risk Card Tab */}
            {tabIndex === 1 && (
              <Grid item xs={12}>
                <Paper sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Risk Card
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  {riskCardSections.length > 0 ? (
                    riskCardSections.map((sectionName) => (
                      <Accordion
                        key={sectionName}
                        expanded={expandedRiskCard[sectionName] || false}
                        onChange={() => handleExpandRiskCard(sectionName)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls={`${sectionName}-content`}
                          id={`${sectionName}-header`}
                        >
                          <Typography>{toTitleCase(sectionName)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RiskCard
                            modelId={modelId}
                            sectionName={sectionName}
                            assessmentUuid={assessmentUuid}
                            sx={{ width: '100%' }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : (
                    <Typography variant="body1">No Risk Card sections available.</Typography>
                  )}
                </Paper>
              </Grid>
            )}

            {/* Use Case, Business & Model Context Tab */}
            {tabIndex === 2 && (
              <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  {/* Tabs on the left */}
                  <Grid item xs={3}>
                    <Tabs
                      orientation="vertical"
                      value={categoryTabIndex['context'] || 0}
                      onChange={(e, newIndex) => handleCategoryTabChange('context', newIndex)}
                      sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                      <Tab label="Use Case" />
                      <Tab label="Business Context" />
                      <Tab label="Model Context" />
                    </Tabs>
                  </Grid>

                  {/* Content on the right */}
                  <Grid item xs={9}>
                    <Box>
                      <Box hidden={categoryTabIndex['context'] !== 0}>
                        <Typography variant="subtitle1">
                          <strong>Use Case:</strong>
                        </Typography>
                        {typeof useCase === 'object' && useCase !== null ? (
                          // Render the object properties
                          Object.entries(useCase).map(([key, value]) => (
                            <Typography key={key} variant="body1" sx={{ mb: 1 }}>
                              <strong>{key}:</strong> {value}
                            </Typography>
                          ))
                        ) : (
                          // Render as string
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {useCase}
                          </Typography>
                        )}
                      </Box>
                      <Box hidden={categoryTabIndex['context'] !== 1}>
                        <Typography variant="subtitle1">
                          <strong>Business Context:</strong>
                        </Typography>
                        {typeof businessContext === 'object' && businessContext !== null ? (
                          Object.entries(businessContext).map(([key, value]) => (
                            <Typography key={key} variant="body1" sx={{ mb: 1 }}>
                              <strong>{key}:</strong> {value}
                            </Typography>
                          ))
                        ) : (
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {businessContext}
                          </Typography>
                        )}
                      </Box>
                      <Box hidden={categoryTabIndex['context'] !== 2}>
                        <Typography variant="subtitle1">
                          <strong>Model Context:</strong>
                        </Typography>
                        {typeof modelContext === 'object' && modelContext !== null ? (
                          Object.entries(modelContext).map(([key, value]) => (
                            <Typography key={key} variant="body1" sx={{ mb: 1 }}>
                              <strong>{key}:</strong> {value}
                            </Typography>
                          ))
                        ) : (
                          <Typography variant="body1">{modelContext}</Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            )}
          </>
        )}

        {/* Priority Modal */}
        <Modal
          open={openPriorityModal}
          onClose={handleClosePriorityModal}
          aria-labelledby="priority-modal-title"
          aria-describedby="priority-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="priority-modal-title" variant="h6">
              Risks with {selectedPriorityName} Priority
            </Typography>
            {Array.isArray(selectedPriorityRisks) && selectedPriorityRisks.length > 0 ? (
              <ul>
                {selectedPriorityRisks.map((riskName, index) => (
                  <li key={index}>{riskName}</li>
                ))}
              </ul>
            ) : (
              <Typography variant="body1">No risks available for this priority.</Typography>
            )}
          </Box>
        </Modal>

        {/* Source Modal */}
        <Modal
          open={openSourceModal}
          onClose={handleCloseSourceModal}
          aria-labelledby="source-modal-title"
          aria-describedby="source-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="source-modal-title" variant="h6">
              Risks from {selectedSourceName}
            </Typography>
            {Array.isArray(selectedSourceRisks) && selectedSourceRisks.length > 0 ? (
              <ul>
                {selectedSourceRisks.map((riskName, index) => (
                  <li key={index}>{riskName}</li>
                ))}
              </ul>
            ) : (
              <Typography variant="body1">No risks available from this source.</Typography>
            )}
          </Box>
        </Modal>

        {/* Risk Generation Modal */}
        <Modal
          open={openRiskGenerationModal}
          onClose={handleCloseRiskGenerationModal}
          aria-labelledby="risk-generation-modal-title"
          aria-describedby="risk-generation-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="risk-generation-modal-title" variant="h6" component="h2" gutterBottom>
              Generate Risks
            </Typography>

            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {/* RiskCard Component for Context Input */}
            {activeStep === 0 && (
                <Box>
            <RiskCard
              modelId={modelId}
              assessmentUuid={assessmentUuid}
              sectionName={selectedSectionName}
            />
              </Box>
          )}

            <Divider sx={{ my: 2 }} />

            {/* RiskCatalogue Component to Display Generated Risks */}
            {activeStep === 1 && (
               <Box>
            <RiskCatalogue
              modelId={modelId}
              sectionName={selectedSectionName}
              assessmentUuid={assessmentUuid}
              narratives={narratives}
            />
              </Box>
          )}

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                >
                  Back
                </Button>
                <Box>
                  {activeStep < steps.length - 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      Next
                    </Button>
                  )}
                  {activeStep === steps.length - 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseRiskGenerationModal}
                    >
                      Finish
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCloseRiskGenerationModal}
                    sx={{ ml: 1 }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>



{/* Details Modal */}
<Modal
  open={detailsModalOpen}
  onClose={handleCloseDetailsModal}
  aria-labelledby="details-modal-title"
  aria-describedby="details-modal-description"
>
  <Box sx={modalStyle}>
    <Typography id="details-modal-title" variant="h6" component="h2" gutterBottom>
      Detailed Changes for {toTitleCase(selectedSectionNameForDetails)}
    </Typography>
    <Box sx={{ mt: 2 }}>
      {selectedSectionDetails.details && selectedSectionDetails.details.length > 0 ? (
        selectedSectionDetails.details.map((diff, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
              {diff.path}:
            </Typography>
            <Box sx={{ ml: 2 }}>
              <Typography variant="body2" component="div">
                <strong>Original:</strong> {formatValue(diff.original)}
              </Typography>
              <Typography variant="body2" component="div" color="primary">
                <strong>New:</strong> {formatValue(diff.new)}
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <Typography>No detailed changes available.</Typography>
      )}
    </Box>
    <Button variant="contained" onClick={handleCloseDetailsModal} sx={{ mt: 2 }}>
      Close
    </Button>
  </Box>
</Modal>
      </Grid>
      <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        sx={{ width: '100%' }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
    </Box>
    </Box>
  );
};

export default AssessmentReport;
