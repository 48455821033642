// src/redux/reducers/complianceReducer.js

import {
    FETCH_BASELINE_CONTROLS_START,
    FETCH_BASELINE_CONTROLS_SUCCESS,
    FETCH_BASELINE_CONTROLS_FAILURE,
  } from '../actions/complianceActions.js';
  
  const initialState = {
    baselineControls: [],
    loading: false,
    error: null,
  };
  
  const complianceReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BASELINE_CONTROLS_START:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_BASELINE_CONTROLS_SUCCESS:
        return {
          ...state,
          loading: false,
          baselineControls: action.payload,
        };
      case FETCH_BASELINE_CONTROLS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default complianceReducer;