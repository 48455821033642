
import { FETCH_CONFIG_START,
  FETCH_CONFIG_SUCCESS, 
  FETCH_CONFIG_FAILURE, 
  FETCH_ALL_CONFIGS_START,
  FETCH_ALL_CONFIGS_SUCCESS,
  FETCH_ALL_CONFIGS_FAILURE, 
  SAVE_CONFIG_START, 
  SAVE_CONFIG_SUCCESS, 
  SAVE_CONFIG_FAILURE,
  CONFIG_UPDATE_SUCCESS,
  CONFIG_UPDATE_FAILURE }from './actionTypes.js'

// Fetch a single configuration
export const fetchConfigStart = () => ({
  type: FETCH_CONFIG_START,
});

export const fetchConfigSuccess = (name, data) => ({
  type: FETCH_CONFIG_SUCCESS,
  payload: { name, data },
});

export const fetchConfigFailure = (error) => ({
  type: FETCH_CONFIG_FAILURE,
  payload: error,
});

// Fetch all configuration names
export const fetchAllConfigsStart = () => ({
  type: FETCH_ALL_CONFIGS_START,
});

export const fetchAllConfigsSuccess = (configNames) => ({
  type: FETCH_ALL_CONFIGS_SUCCESS,
  payload: configNames,
});

export const fetchAllConfigsFailure = (error) => ({
  type: FETCH_ALL_CONFIGS_FAILURE,
  payload: error,
});

// Save a configuration
export const saveConfigStart = () => ({
  type: SAVE_CONFIG_START,
});

export const saveConfigSuccess = (name, data) => ({
  type: SAVE_CONFIG_SUCCESS,
  payload: { name, data },
});

export const saveConfigFailure = (error) => ({
  type: SAVE_CONFIG_FAILURE,
  payload: error,
});

// Thunk to fetch a single configuration
export const fetchConfig = (name) => async (dispatch, getState) => {
    const { config } = getState();
    
    // Prevent fetching if already loading or already fetched
    if (config.configs[name]) {
      return;
    }
  
    dispatch(fetchConfigStart(name));
    try {
      const response = await fetch('/.netlify/functions/getConfig', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch configuration: ${response.statusText}`);
      }
  
      const data = await response.json();
      dispatch(fetchConfigSuccess(name, data));
    } catch (error) {
      console.error('Error fetching configuration:', error);
      dispatch(fetchConfigFailure(name, error.message));
    }
  };

// Thunk to fetch all configuration names
export const fetchAllConfigs = () => async (dispatch) => {
  dispatch(fetchAllConfigsStart());
  try {
    const response = await fetch('/.netlify/functions/listConfigs', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch configurations: ${response.statusText}`);
    }

    const data = await response.json();
    dispatch(fetchAllConfigsSuccess(data.configNames)); // Assume backend returns { configNames: ['contextForm', ...] }
  } catch (error) {
    console.error('Error fetching all configurations:', error);
    dispatch(fetchAllConfigsFailure(error.message));
  }
};

// Thunk to save a configuration
export const saveConfig = (name, data) => async (dispatch) => {
  dispatch(saveConfigStart());
  try {
    const response = await fetch('/.netlify/functions/saveConfig', {
      method: 'POST', // or 'PUT' based on your backend
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, data }),
    });

    const responseBody = await response.json();

    if (response.ok) {
      dispatch(saveConfigSuccess(name, data));
       dispatch(configUpdateSuccess(name, data));
    } else {
      throw new Error(responseBody.message || 'Failed to save configuration');
    }
  } catch (error) {
    console.error('Error saving configuration:', error);
    dispatch(saveConfigFailure(error.message));
    dispatch(configUpdateFailure(name, error.message));
  }
};

export const configUpdateSuccess = (name, data) => ({
  type: CONFIG_UPDATE_SUCCESS,
  payload: { name, data },
});

export const configUpdateFailure = (name, error) => ({
  type: CONFIG_UPDATE_FAILURE,
  payload: { name, error },
});