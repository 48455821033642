import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const SecurityReportData = () => {
  const securityState = useSelector(state => state.security);

  const securityReportData = useMemo(() => {
    const dataByModel = {};
    const dataByCategory = {};

    // Iterate over each model
    Object.entries(securityState.models ?? {}).forEach(([modelUuid, modelDetails]) => {
      const { securityRisks, securityControls } = modelDetails;

      // Organize data by model
      dataByModel[modelUuid] = {
        modelUuid,
        risks: securityRisks.map(risk => ({
          ...risk,
          controls: securityControls[risk.risk_id] || [] // Attach controls directly to the risk
        })),
        controls: securityControls
      };

      // Organize risks by category
      securityRisks.forEach(risk => {
        const category = risk.category.toLowerCase(); // Normalize the category for consistent access
        if (!dataByCategory[category]) {
          dataByCategory[category] = [];
        }
        // Add risk and its associated controls to the category
        dataByCategory[category].push({
          ...risk,
          modelUuid: modelUuid,
          controls: securityControls[risk.risk_id] || [], // Attach controls directly to the risk
        });
      });
    });

    return { dataByModel, dataByCategory };
  }, [securityState]);

  return securityReportData;
};

export default SecurityReportData;