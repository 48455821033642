import React, { useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Button, IconButton, TextField, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Typography, Snackbar, Alert, Badge,
  Box, Chip, Avatar, Tooltip, Fade, List, ListItem, ListItemText, ListItemSecondaryAction
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Check as CheckIcon
} from '@mui/icons-material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'; // Added for restoration
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'; 
import CloseIcon from '@mui/icons-material/Close';
import AutosaveIndicator from './AutoSaveIndicator';
import useSaveOperation from './useSaveOperation';
import {
  discardRisk, moveRiskToSaved, updateSavedRisk, discardSavedRisk,
  updateRiskName, fetchNewRisks, fetchSectionSpecificRisks, generateRisksWithRAG
} from './redux/actions/riskActions';
import _ from 'lodash';
import IconWithProgress from './IconWithProgress';
import { useAuth0 } from '@auth0/auth0-react';

/** DnDKit Imports **/
import {
  DndContext,
  useDroppable,
  useDraggable,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
  DragOverlay
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

/** ========= DND Wrappers ========= **/
function DroppableArea({ id, disabled = false, children }) {
  const { isOver, setNodeRef } = useDroppable({ id, disabled });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        // Subtle highlight if user drags an item over this droppable
        backgroundColor: isOver ? 'rgba(0, 127, 255, 0.08)' : 'transparent',
        transition: 'background-color 0.3s ease',
        width: '100%'  // ensure it can fill the container
      }}
    >
      {children}
    </Box>
  );
}

function DraggableRow({ risk, children }) {
  const dragId = risk.riskId || risk.riskName;
  const { attributes, listeners, setNodeRef, transform, transition } = useDraggable({ id: dragId });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'grab'
  };

  return (
    <TableRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </TableRow>
  );
}

const FloatingSaveButton = ({
  displayMode,
  onToggle,
  countSaved,
  countGenerated,
  isPulse
}) => {
  const { setNodeRef } = useDroppable({ id: 'savedRisks' }); // Assign id for DnD
  const isSavedMode = displayMode === 'saved';
  const icon = isSavedMode ? <SmartToyOutlinedIcon /> : <SaveIcon />;
  const badgeValue = isSavedMode ? countGenerated : countSaved;
  const tooltip = isSavedMode
    ? 'Switch to Generated Risks'
    : 'Switch to Saved Risks';

  return (
    <Box 
    ref={setNodeRef}
    sx={{ position: 'absolute',
     right: '72px', top: '50%', transform: 'translateY(-50%)' }}>
      <Tooltip title={tooltip} arrow placement="left">
        <Badge badgeContent={badgeValue} color="primary" max={99} overlap="circular">
          <IconButton
            onClick={onToggle}
            className={isPulse ? 'pulse-animation' : ''}
            sx={{
             backgroundColor: 'background.paper',
             boxShadow: 3,
             '&:hover': {
              backgroundColor: 'action.hover',
              transform: 'scale(1.1)',
              },
              transition: 'transform 0.2s ease-in-out'
             }}
          >
            {icon}
          </IconButton>
        </Badge>
      </Tooltip>
    </Box>
  );
};

// Floating Trash Button Component
const FloatingTrashButton = ({ trashCount, onClick, isPulse }) => {
  const { setNodeRef } = useDroppable({ id: 'discardedRisks' }); // Assign id for DnD

  return (
    <Box
      ref={setNodeRef} // Assign the Droppable ref
      sx={{
        position: 'absolute',
        right: '24px',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
      }}
    >
      <Tooltip title="View Discarded Risks" arrow placement="left">
        <Badge
          badgeContent={trashCount}
          color="error"
          max={99}
          overlap="circular"
          sx={{
            '& .MuiBadge-badge': {
              fontSize: '0.75rem',
              height: '22px',
              minWidth: '22px',
              padding: '0 4px',
              top: '0px',
              right: '-5px',
            }
          }}
        >
          <IconButton
            onClick={onClick}
            className={isPulse ? 'pulse-animation' : ''}
            sx={{
              backgroundColor: 'background.paper',
              boxShadow: 3,
              '&:hover': {
                backgroundColor: 'action.hover',
                transform: 'scale(1.1)',
              },
              transition: 'transform 0.2s ease-in-out'
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Badge>
      </Tooltip>
    </Box>
  );
};

// Trash Modal Component
const TrashModal = ({ open, onClose, discardedRisks, onRestore }) => (
  <Dialog 
    open={open} 
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    TransitionComponent={Fade}
    transitionDuration={300}
  >
    <DialogTitle sx={{ 
      display: 'flex', 
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: 1,
      borderColor: 'divider',
      pb: 2
    }}>
      <Box display="flex" alignItems="center" gap={1}>
        <DeleteForeverIcon color="error" />
        <Typography variant="h6">Discarded Risks</Typography>
      </Box>
      <IconButton onClick={onClose} size="small">
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    
    <DialogContent sx={{ mt: 2 }}>
      {discardedRisks.length > 0 ? (
        <List>
          {discardedRisks.map((risk, index) => (
            <ListItem
              key={risk.riskId || index}
              sx={{
                borderRadius: 1,
                mb: 1,
                '&:hover': {
                  backgroundColor: 'action.hover',
                }
              }}
            >
              <ListItemText
                primary={risk.riskName}
                secondary={`Category: ${risk.category}`}
                primaryTypographyProps={{
                  variant: 'body1',
                  fontWeight: 500
                }}
                secondaryTypographyProps={{
                  variant: 'body2',
                  color: 'text.secondary'
                }}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Restore risk" arrow>
                  <IconButton 
                    edge="end" 
                    onClick={() => onRestore(risk)}
                    size="small"
                    sx={{
                      '&:hover': {
                        color: 'primary.main'
                      }
                    }}
                  >
                    <RestoreFromTrashIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          minHeight={200}
          flexDirection="column"
          gap={2}
        >
          <DeleteForeverIcon sx={{ fontSize: 48, color: 'text.disabled' }} />
          <Typography color="text.secondary" variant="body1">
            No discarded risks
          </Typography>
        </Box>
      )}
    </DialogContent>
    
    <DialogActions sx={{ 
      borderTop: 1, 
      borderColor: 'divider',
      px: 3,
      py: 2
    }}>
      <Button 
        onClick={onClose}
        variant="outlined"
        startIcon={<CloseIcon />}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const RiskCatalogue = ({ modelId, sectionName, assessmentUuid, narratives }) => {
  const dispatch = useDispatch();
  const risks = useSelector(
    (state) => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || {}
  );

  const getUnsavedRisks = () => {
    const unsavedRisks = [];

    Object.values(risks).forEach((riskArray) => {
      riskArray.forEach((risk) => {
        if (!risk.riskId && risk.status === 'Generated') {
          unsavedRisks.push(risk);
        }
      });
    });

    return unsavedRisks;
  };

  const unsavedRisks = getUnsavedRisks();
  const { user } = useAuth0();
  const { isSaving, isSaved, handleSaveOperation, setIsSaved } = useSaveOperation();

  // Local states
  const [editingRisk, setEditingRisk] = useState(null);
  const [editedRiskName, setEditedRiskName] = useState('');
  const [confirmDiscardRisk, setConfirmDiscardRisk] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

  // For DnD
  const [activeRisk, setActiveRisk] = useState(null);

  // Loading states
  const [loadingLLM, setLoadingLLM] = useState(false);
  const [loadingRAG, setLoadingRAG] = useState(false);
  const [hasLLMGenerated, setHasLLMGenerated] = useState(false);

  // Animation trigger states
  const [triggerSavePulse, setTriggerSavePulse] = useState(false);
  const [triggerTrashPulse, setTriggerTrashPulse] = useState(false);

  // Counts
  
  const countSavedRisks = useCallback(() => {
    return Object.values(risks)
      .flat()
      .filter(risk => risk.status === 'Saved').length;
  }, [risks]);


  const countDiscardedRisks = useCallback(() => {
    return Object.values(risks).flat().filter(r => r.status === 'Discarded').length;
  }, [risks]);

  const countGeneratedRisks = useCallback(() => {
    return Object.values(risks).flat().filter(r => r.status === 'Generated').length;
  }, [risks]);
 
  const [displayMode, setDisplayMode] = useState(() => {
    return countSavedRisks() > 0 ? 'saved' : 'generated';
  });

  // Omit irrelevant keys
  let sectionData = useSelector(
    (state) => state.risks.models[modelId]?.riskFormData[sectionName] || {}
  );
  sectionData = _.omit(sectionData, ['assessmentProgress', 'currentStep']);

  const riskAreaData = useSelector(
    (state) => state.risks.models[modelId]?.riskFormData[sectionName]?.riskSources || []
  );

  const hasAnyRisks = Object.keys(risks).some(
    (category) => Array.isArray(risks[category]) && risks[category].length > 0
  );

  const shouldShowRefresh = hasLLMGenerated || hasAnyRisks;

  const findRiskById = (id) => {
    const allRisks = Object.values(risks).flat();
    return allRisks.find((r) => (r.riskId || r.riskName) === id);
  };

  /** ======= DnDKit config & handlers ======= **/
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }));

  const handleDragStart = ({ active }) => {
    const foundRisk = findRiskById(active.id);
    setActiveRisk(foundRisk || null);
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over?.id || !active?.id) {
      setActiveRisk(null);
      return;
    }
    const draggedRisk = findRiskById(active.id);
    if (!draggedRisk) {
      setActiveRisk(null);
      return;
    }

    if (over.id === 'savedRisks') {
      handleSaveRisk(draggedRisk);
      triggerSaveAnimation(); // Trigger save pulse animation
    } else if (over.id === 'discardedRisks') {
      handleDiscardRisk(draggedRisk);
      triggerTrashAnimation(); // Trigger trash pulse animation
    }
    setActiveRisk(null);
  };

  const handleDragCancel = () => {
    setActiveRisk(null);
  };

  /** ======= Animation Triggers ======= **/
  const triggerSaveAnimation = () => {
    setTriggerSavePulse(true);
    setTimeout(() => setTriggerSavePulse(false), 500); // Duration matches the animation
  };

  const triggerTrashAnimation = () => {
    setTriggerTrashPulse(true);
    setTimeout(() => setTriggerTrashPulse(false), 500); // Duration matches the animation
  };

  /** ======= Refresh & Generation logic ======= **/
  const handleRefreshRisks = useCallback(() => {
    setLoadingLLM(true);
    // pass the same shape as fetchSectionSpecificRisks
    dispatch(fetchNewRisks(modelId, sectionName, riskAreaData, risks, narratives, sectionData))
      .finally(() => setLoadingLLM(false));
  }, [dispatch, modelId, sectionName, riskAreaData, risks, narratives, sectionData]);

  const startGenerateMode = useCallback(
    (type) => {
      if (type === 'RAG') {
        setLoadingRAG(true);
        dispatch(generateRisksWithRAG(modelId, sectionName, riskAreaData, risks, narratives))
          .finally(() => setLoadingRAG(false));
      } else {
        setLoadingLLM(true);
        dispatch(fetchSectionSpecificRisks(modelId, sectionName, riskAreaData, risks, narratives, false))
          .then(() => setHasLLMGenerated(true))
          .finally(() => setLoadingLLM(false));
      }
    },
    [dispatch, modelId, sectionName, narratives, riskAreaData, risks]
  );

  /** ======= Save & Discard logic ======= **/
  const handleSaveRisk = useCallback(
    async (risk) => {
      if (!risk.riskId) {
        handleSaveOperation(async () => {
          const response = await fetch('/.netlify/functions/saveRisk', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              name: risk.riskName,
              section: risk.section,
              category: risk.category,
              type: risk.type, // Include type
              rag_source_file: risk.type === 'RAG' ? risk.rag_source_file : null,
              modelUuid: modelId,
              assessmentUuid: assessmentUuid,
              owner: {
                name: user.name,
                email: user.email,
                picture: user.picture
              }
            })
          });
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to save risk');
          }
          const savedRisk = await response.json();
          dispatch(
            moveRiskToSaved(modelId, sectionName, {
              ...risk,
              riskId: savedRisk.riskId,
              status: 'Saved',
              category: risk.category,
              owner: {
                name: user.name,
                email: user.email,
                picture: user.picture
              }, 
              type: risk.type, // Ensure type is preserved
              sourceFile: risk.type === 'RAG' ? risk.sourceFile : null,
            })
          );
        }).catch((error) => {
          setSnackbarMessage(error.message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
      }
    },
    [handleSaveOperation, dispatch, modelId, sectionName, assessmentUuid, user.name, user.email, user.picture]
  );

  const handleDiscardRisk = useCallback(
    async (risk) => {
      if (risk.riskId) {
        const response = await fetch('/.netlify/functions/discardRisk', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ riskId: risk.riskId })
        });
        if (!response.ok) {
          throw new Error('Failed to discard the risk');
        }
        dispatch(discardSavedRisk(risk.riskId, modelId, sectionName, risk.category));
      } else {
        dispatch(discardRisk(risk, modelId, sectionName, risk.category));
      }
    },
    [dispatch, modelId, sectionName]
  );

  const discardUnsavedRisks = useCallback(() => {
    Object.entries(risks).forEach(([category, risksArray]) => {
      risksArray.forEach((risk) => {
        if (risk.status === 'Generated') {
          dispatch(discardRisk(risk, modelId, sectionName, risk.category));
        }
      });
    });
    setOpenDiscardDialog(false);
  }, [dispatch, risks, modelId, sectionName]);

  /** ======= Edit logic ======= **/
  const handleEditRisk = useCallback((risk) => {
    setEditingRisk(risk);
    console.log('handleEditRisk => risk.category is:', risk.category);
    setEditedRiskName(risk.riskName);
  }, []);

  const handleConfirmEdit = useCallback(async () => {
    if (!editingRisk) return;
    console.log ('risk to edit in handleconfirmedit', editingRisk)
    handleSaveOperation(async () => {
      if (editingRisk.status === 'Saved') {
        const response = await fetch('/.netlify/functions/saveRisk', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            riskId: editingRisk.riskId,
            name: editedRiskName,
            section: sectionName,
            category: editingRisk.category,
            type: editingRisk.type,
            rag_source_file: editingRisk.type === 'RAG' ? editingRisk.rag_source_file : null,
            modelUuid: modelId,
            assessmentUuid: assessmentUuid,
            owner: {
              name: user.name,
              email: user.email,
              picture: user.picture
            }
          })
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to update risk');
        }
        dispatch(
          updateSavedRisk(modelId, editingRisk.riskId, sectionName, editingRisk.category, {
            ...editingRisk,
            riskName: editedRiskName
          })
        );
      } else {
        dispatch(
          updateRiskName(
            modelId,
            sectionName,
            editingRisk.category,
            editingRisk.riskName,
            editedRiskName
          )
        );
      }
    }).finally(() => {
      setEditingRisk(null);
      setEditedRiskName('');
    });
  }, [
    modelId,
    sectionName,
    editingRisk,
    editedRiskName,
    handleSaveOperation,
    dispatch,
    assessmentUuid,
    user.name,
    user.email,
    user.picture
  ]);

  /** ======= Counts & placeholders ======= **/
  
  const renderPlaceholder = (text) => (
    <TableRow>
      <TableCell colSpan={5} sx={{ textAlign: 'center', py: 3, color: 'text.secondary' }}>
        Drag risks here to {text}
      </TableCell>
    </TableRow>
  );

  const renderRiskRow = (risk, category) => {
    const riskCategory = category || 'Uncategorized';
  console.log(`\n--- Rendering Risk: "${risk.riskName}" ---`);
  console.log(`Category Assigned: "${riskCategory}"`);
  console.log(`Risk Status: "${risk.status}"`);
  console.log(`Risk Owner: ${risk.owner ? risk.owner.name : 'No owner'}`);
  return (
  <DraggableRow key={risk.riskId || `${risk.riskName}-${riskCategory}`} risk={risk}>
      <TableCell>{editingRisk === risk ? (
        <TextField value={editedRiskName} onChange={(e) => setEditedRiskName(e.target.value)} fullWidth />
      ) : (
        risk.riskName
      )}</TableCell>

      <TableCell>{risk.status}</TableCell>

      <TableCell>
        {risk.type === 'LLM' ? (
          <Chip label="LLM" color="primary" size="small" />
        ) : (
          <Chip label={risk.rag_source_file || 'Unknown'} color="secondary" size="small" />
        )}
      </TableCell>

      <TableCell>
        {risk.owner ? (
          <Tooltip title={risk.owner.name}>
            <Avatar alt={risk.owner.name} src={risk.owner.picture} />
          </Tooltip>
        ) : 'No owner'}
      </TableCell>

      <TableCell>
        {editingRisk === risk ? (
           <IconButton onClick={() => handleConfirmEdit({ ...risk, category })}><CheckIcon /></IconButton>
        ) : (
          <>
            <IconButton onClick={() => handleEditRisk(risk)}><EditIcon /></IconButton>
            {risk.status !== 'Saved' && (
              <IconButton onClick={() => handleSaveRisk(risk)}>
                <SaveIcon />
              </IconButton>
            )}
            <IconButton onClick={() => setConfirmDiscardRisk(risk)} sx={{ color: 'red' }}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </TableCell>
    </DraggableRow>
  )
  };

  const renderRisksByStatus = (status) => {
    const out = [];
    Object.entries(risks).forEach(([category, arr]) => {
      const these = arr.filter(r => r.status === status);
      if (!these.length) return;
      out.push(
        <React.Fragment key={category}>
          <TableRow>
            <TableCell colSpan={5} sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
              {category}
            </TableCell>
          </TableRow>
          {these.map(risk => renderRiskRow(risk, category))}
        </React.Fragment>
      );
    });
   return out;
  };

  const handleDiscardClick = () => {
    if (unsavedRisks.length === 0) {
      // Optionally, notify the user that there are no unsaved risks to discard
      return;
    }
    setOpenDiscardDialog(true);
  };

  const handleConfirmDiscard = () => {
    discardUnsavedRisks();
    setSnackbarMessage('All unsaved risks have been discarded.');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  const handleCancelDiscard = () => {
    setOpenDiscardDialog(false);
  };

  /** ======= Table Display Logic ======= **/

  /** ======= UI / Return ======= **/
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      modifiers={[restrictToWindowEdges]}
    >
      <Box sx={{ m: 2, position: 'relative' }}> {/* Added position relative for floating buttons */}
        {/* === Top Panel === */}
        <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
          {/* Title + Autosave */}
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Risk Identification
            </Typography>
            <AutosaveIndicator
              isSaving={isSaving}
              isSaved={isSaved}
              setIsSaved={setIsSaved}
            />
          </Box>

          {/* Buttons */}
          <Box display="flex" gap={2} mt={2}>
            {!shouldShowRefresh ? (
                  <Button
                    variant="outlined"
                    onClick={() => startGenerateMode('LLM')}
                    disabled={loadingLLM || loadingRAG}
                    startIcon={
                      loadingLLM ? (
                        <IconWithProgress isLoading tooltip="Generating with AI" />
                      ) : (
                        <SmartToyOutlinedIcon />
                      )
                    }
                  >
                    Generate with AI
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleRefreshRisks}
                    disabled={loadingLLM || loadingRAG}
                    startIcon={
                      loadingLLM ? (
                        <IconWithProgress isLoading tooltip="Refresh from AI" />
                      ) : (
                        <RefreshIcon />
                      )
                    }
                  >
                    Refresh from AI
                  </Button>
                )}

            <Button
              variant="outlined"
              onClick={() => startGenerateMode('RAG')}
              disabled={loadingLLM || loadingRAG}
              startIcon={
                loadingRAG ? <IconWithProgress isLoading tooltip="Retrieving from Catalogue" /> : <ListAltOutlinedIcon />
              }
            >
              Retrieve from Catalogue
            </Button>
          </Box>

          {(loadingLLM || loadingRAG) && (
            <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
              Loading...
            </Typography>
          )}
        </Paper>

        {/* === Risks Table === */}
        <DroppableArea id="generatedRisks" disabled>
          <Paper sx={{ p: 2, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
               <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 3 }}>
                  {displayMode === 'saved' ? 'Saved Risks' : 'Generated Risks'}
                </Typography>
              {/* </Badge> */}

              {/* Floating Trash and Save Icons */}
              <FloatingTrashButton 
                trashCount={countDiscardedRisks()} 
                onClick={() => setShowTrashModal(true)} 
                isPulse={triggerTrashPulse} // State to control animation
              />
              <FloatingSaveButton 
                displayMode={displayMode}
                onToggle={() => setDisplayMode((prev) => (prev === 'saved' ? 'generated' : 'saved'))}
                countSaved={countSavedRisks()}
                countGenerated={countGeneratedRisks()}
                isPulse={triggerSavePulse}// State to control animation
              />
            </Box>

            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Risk Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Origin</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayMode === 'saved' ? (
                    countSavedRisks() > 0
                      ? renderRisksByStatus('Saved')
                      : renderPlaceholder('save your risks here')
                  ) : (
                    countGeneratedRisks() > 0
                      ? renderRisksByStatus('Generated')
                      : renderPlaceholder('save or discard risks')
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DroppableArea>

        {/* === Drag Overlay === */}
        <DragOverlay>
          {activeRisk && (
            <Paper 
              sx={{ 
                px: 2,
                py: 1,
                backgroundColor: '#fff',
                boxShadow: 4,
                width: 'auto',
                maxWidth: '300px',
                borderRadius: '4px',
                opacity: 0.9,
              }}
            >
              {activeRisk.riskName}
            </Paper>
          )}
        </DragOverlay>

        {/* === Discard Risk Dialog === */}
        <Dialog open={!!confirmDiscardRisk} onClose={() => setConfirmDiscardRisk(null)}>
          <DialogTitle>Confirm Discard</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to discard this risk?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDiscardRisk(null)}>Cancel</Button>
            <Button
              onClick={() => {
                if (confirmDiscardRisk) {
                  handleDiscardRisk(confirmDiscardRisk);
                  setConfirmDiscardRisk(null);
                  setSnackbarMessage('Risk discarded successfully.');
                  setSnackbarSeverity('info');
                  setSnackbarOpen(true);
                }
              }}
            >
              Discard
            </Button>
          </DialogActions>
        </Dialog>

        {/* === Trash Modal === */}
        <TrashModal
          open={showTrashModal}
          onClose={() => setShowTrashModal(false)}
          discardedRisks={Object.values(risks).flat().filter(r => r.status === 'Discarded')}
          onRestore={(risk) => {
            dispatch(moveRiskToSaved(modelId, sectionName, risk));
            setSnackbarMessage('Risk restored successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
          }}
        />

        {/* === Discard All Unsaved Risks Button === */}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDiscardClick}
            disabled={unsavedRisks.length === 0}
          >
            Discard All Unsaved Risks
          </Button>
        </Box>

        {/* === Discard All Unsaved Risks Dialog === */}
        <Dialog
          open={openDiscardDialog}
          onClose={handleCancelDiscard}
          aria-labelledby="discard-dialog-title"
        >
          <DialogTitle id="discard-dialog-title">Discard Unsaved Risks?</DialogTitle>
          <DialogContent>
            <Typography>
              You have {unsavedRisks.length} unsaved {unsavedRisks.length === 1 ? 'risk' : 'risks'}. Are you sure you want to discard all unsaved risks?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDiscard} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDiscard} color="secondary" variant="contained">
              Discard
            </Button>
          </DialogActions>
        </Dialog>

        {/* === Snackbar === */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={(_, reason) => {
            if (reason !== 'clickaway') setSnackbarOpen(false);
          }}
        >
          <Alert severity={snackbarSeverity} onClose={() => setSnackbarOpen(false)} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </DndContext>
  );
};

export default RiskCatalogue;