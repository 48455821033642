// src/redux/selectors/controlSelectors.js

import { createSelector } from 'reselect';

// Selector to get saved control evaluations
const selectSavedControlEvaluations = (state) => state.controls.savedControlEvaluations;

// Selector to get saved controls
const selectSavedControls = (state) => state.controls.savedControls;

// Selector to get models
const selectModels = (state) => state.risks.models;

// Selector to compute overdue actions based on 'executedNext'
export const selectOverdueActions = createSelector(
  [selectSavedControlEvaluations, selectSavedControls, selectModels],
  (savedControlEvaluations, savedControls, models) => {
    const today = new Date();
    const overdue = [];

    Object.entries(savedControlEvaluations).forEach(([riskId, controls]) => {
      Object.entries(controls).forEach(([controlId, evaluations]) => {
        evaluations.forEach((evaluation) => {
          const dueDateStr = evaluation.executed_next; // Using 'executedNext' as the due date field
          if (dueDateStr) {
            const dueDate = new Date(dueDateStr);
            // Compare dates (ignoring time)
            if (dueDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
              const control = savedControls[riskId]?.find(c => c.controlId === controlId);
              if (control) {
                const model = models[control.modelUuid]; // Get model using modelUuid
                const modelName = model?.modelName || 'Unnamed Model';
                const riskName = control.riskName || 'Unnamed Risk'; // Assuming model has 'riskName'

                const owner = evaluation.control_owner || 'Unassigned'; // Assuming 'control_owner' field

                overdue.push({
                  id: control.controlId,
                  controlName: control.name,
                  dueDate: dueDateStr,
                  riskId,
                  riskName,
                  modelName, // Add modelName
                  owner,
                });
              }
            }
          }
        });
      });
    });
    return overdue;
    
  }
  
);

// // src/redux/selectors/controlSelectors.js

// import { createSelector } from 'reselect';

// // Selector to get saved control evaluations
// const selectSavedControlEvaluations = (state) => state.controls.savedControlEvaluations;

// // Selector to get saved controls
// const selectSavedControls = (state) => state.controls.savedControls;

// // Selector to get models
// const selectModels = (state) => state.risks.models;

// // Selector to compute overdue actions based on 'executedNext'
// export const selectOverdueActions = createSelector(
//   [selectSavedControlEvaluations, selectSavedControls, selectModels],
//   (savedControlEvaluations, savedControls, models) => {
//     const today = new Date();
//     const overdue = [];

//     Object.entries(savedControlEvaluations).forEach(([riskId, controls]) => {
//       Object.entries(controls).forEach(([controlId, evaluations]) => {
//         evaluations.forEach((evaluation) => {
//           const dueDateStr = evaluation.executedNext; // Using 'executedNext' as the due date field
//           if (dueDateStr) {
//             const dueDate = new Date(dueDateStr);
//             // Compare dates (ignoring time)
//             if (dueDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
//               const control = savedControls[riskId]?.find(c => c.controlId === controlId);
//               const model = models[control?.riskId]; // Assuming riskId maps to model
//               const riskName = model?.riskName || 'Unnamed Risk';

//               if (control) {
//                 overdue.push({
//                   id: control.controlId,
//                   controlName: control.name,
//                   dueDate: dueDateStr,
//                   riskId,
//                   riskName,
//                   modelUuid: control.modelUuid,
//                 });
//               }
//             }
//           }
//         });
//       });
//     });

//     return overdue;
//   }
// );
// // // src/redux/selectors/controlSelectors.js

// // import { createSelector } from 'reselect';

// // // Selector to get saved control evaluations
// // const selectSavedControlEvaluations = (state) => state.controls.savedControlEvaluations;

// // // Selector to get saved controls
// // const selectSavedControls = (state) => state.controls.savedControls;

// // // Selector to compute overdue actions
// // export const selectOverdueActions = createSelector(
// //   [selectSavedControlEvaluations, selectSavedControls],
// //   (savedControlEvaluations, savedControls) => {
// //     const today = new Date();
// //     const overdue = [];

// //     Object.entries(savedControlEvaluations).forEach(([riskId, controls]) => {
// //       Object.entries(controls).forEach(([controlId, evaluations]) => {
// //         evaluations.forEach((evaluation) => {
// //           const dueDateStr = evaluation.executed_next; // Assuming 'executed_next' is the due date field
// //           if (dueDateStr) {
// //             const dueDate = new Date(dueDateStr);
// //             // Compare dates (ignoring time)
// //             if (dueDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
// //               const control = savedControls[riskId]?.find(c => c.controlId === controlId);
// //               if (control) {
// //                 overdue.push({
// //                   id: control.controlId,
// //                   controlName: control.name,
// //                   dueDate: dueDateStr,
// //                   riskId,
// //                   modelUuid: control.modelUuid,
// //                 });
// //               }
// //             }
// //           }
// //         });
// //       });
// //     });

// //     return overdue;
// //   }
// // );