
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthPage from './authPage';
import App from './App';
import { Box, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import IconWithProgress from './IconWithProgress.js';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LoadingContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: '#f7f9fc',
  animation: `${fadeIn} 1s ease-in-out`,
});

const LoadingText = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: '500',
  marginLeft: '15px',
  color: '#42424a',
  animation: `${fadeIn} 2s ease-in-out`,
});

const AuthWrapper = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <LoadingContainer>
        <IconWithProgress isLoading={isLoading} />
        <LoadingText>Loading RiskGPT...</LoadingText>
      </LoadingContainer>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/*" element={isAuthenticated ? <App /> : <Navigate to="/auth" />} />
      </Routes>
    </Router>
  );
};

export default AuthWrapper;
